import { Component, ViewChild, OnInit, AfterViewInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Usuario } from '@app/_models';
import { Idioma } from '@app/_models/idioma';
import { AlertService, MenuService, PlanosService, UsuariosService } from '@app/_services';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

@Component({ templateUrl: 'hojaImpresion.component.html' })
export class HojaImpresionComponent {
    dataUsuarios: Usuario[];
    interval: number;
    selectedIdioma:Idioma;
    selectedNumber:number;
    inputTextBox:string;
    idiomaSeleccionado:string;
    id: any;
    desdeId: any;

    //GRID INFERIOR
    public checkAroForjaOk: any;
    public checkAroForjaNoOk: any;
    public checkAroForjaEsmerilar: any;
    public checkAroForjaTornear: any;
    public checkAroForjaNuevo: any;

    public checkPostizoSuperiorOk: any;
    public checkPostizoSuperiorNoOk: any;
    public checkPostizoSuperiorEsmerilar: any;
    public checkPostizoSuperiorTornear: any;
    public checkPostizoSuperiorNuevo: any;

    public checkPostizo1OPOk: any;
    public checkPostizo1OPNoOk: any;
    public checkPostizo1OPEsmerilar: any;
    public checkPostizo1OPTornear: any;
    public checkPostizo1OPNuevo: any;

    public checkPostizo2OPOk: any;
    public checkPostizo2OPNoOk: any;
    public checkPostizo2OPEsmerilar: any;
    public checkPostizo2OPTornear: any;
    public checkPostizo2OPNuevo: any;

    public checkMatrizSuperiorOk: any;
    public checkMatrizSuperiorNoOk: any;
    public checkMatrizSuperiorEsmerilar: any;
    public checkMatrizSuperiorTornear: any;
    public checkMatrizSuperiorNuevo: any;

    public checkMatrizInferiorOk: any;
    public checkMatrizInferiorNoOk: any;
    public checkMatrizInferiorEsmerilar: any;
    public checkMatrizInferiorTornear: any;
    public checkMatrizInferiorNuevo: any;

    public checkPrepunzadoSuperiorOk: any;
    public checkPrepunzadoSuperiorNoOk: any;
    public checkPrepunzadoSuperiorEsmerilar: any;
    public checkPrepunzadoSuperiorTornear: any;
    public checkPrepunzadoSuperiorNuevo: any;

    public checkPrepunzado1OPOk: any;
    public checkPrepunzado1OPNoOk: any;
    public checkPrepunzado1OPEsmerilar: any;
    public checkPrepunzado1OPTornear: any;
    public checkPrepunzado1OPNuevo: any;

    public checkPrepunzado2OPOk: any;
    public checkPrepunzado2OPNoOk: any;
    public checkPrepunzado2OPEsmerilar: any;
    public checkPrepunzado2OPTornear: any;
    public checkPrepunzado2OPNuevo: any;

    public datos: any;
    public fecha: any;

    //FILTRO
    listaPlanos: any;
    planosSeleccionados: any;
    listaFiguras: any;
    figurasSeleccionadas: any;
    listaEquivalencias: any;
    equivalenciasSeleccionadas: any;
    datosFiltro: any = [];

    constructor(private usuariosService: UsuariosService, private alertService: AlertService, private modalService: NgbModal,
      private route: ActivatedRoute, public planosService: PlanosService, private formBuilder: FormBuilder,
        private menuService: MenuService, public router: Router, private translateService: TranslateService) {    
          this.menuService.titulo = this.translateService.instant('hojaImpresion.titulo');
          this.id = this.route.snapshot.params['id'];
          this.desdeId = this.route.snapshot.params['desdeId']; //Desde que ventana venimos, 0 si desde datos forja, 1 si desde menú
      }
    
      ngOnInit(): void {
          this.cargarDatos();
      }

      cargarDatos(){
        this.planosService.getById(this.id)
              .pipe(first())
              .subscribe((result: any) => {
                console.log(result[0]);
                this.datos= result[0];
                this.fecha = new Date();
              });
      }

      imprimir(){
        var data = document.getElementById('fichaImprimir');  //Id of the table
        html2canvas(data).then(canvas => {  
          // Few necessary setting options  
          let imgWidth = 297; //210 //porque estos son los tamaños de un a4     
          let imgHeight = canvas.height * imgWidth / canvas.width;  

          const contentDataURL = canvas.toDataURL('image/png')  
          let pdf = new jsPDF('l', 'mm', 'a4'); // A4 size page of PDF  
          pdf.addImage(contentDataURL, 'PNG', 5, 5, imgWidth-5, imgHeight-5)  
          pdf.save(this.datos.codigoPlano + "_" + this.datos.figura); // Generated PDF   
        });  
      }

      atras(){
        if(this.desdeId == 0)
          this.router.navigate(['datosForja/editar', this.id]);
        else if(this.desdeId == 1)
          this.router.navigate(['datosForja/']);
      }
     
}