<div class="row">
  <div class="col-lg-6 col-12">
    <div class="card">
      <div class="card-header">
        <h3>{{ 'catalogoForjaRuedas.titulo' | translate}}</h3>
        <div class="plegarpanel"></div>
      </div>
      <div class="card-body">
        <div class="clearfix">
          <div class="float-left mr-2">
            <div class="caja">
              <kendo-fileselect [(ngModel)]="archivoAgregar" [restrictions]="restrictions" [multiple]="false"
                (select)="archivoSeleccionado($event)" (remove)="archivoEliminado($event)">
                <kendo-upload-messages cancel="{{ 'uploads.cancelar' | translate }}"
                  clearSelectedFiles="{{ 'uploads.clearSelectedFiles' | translate }}"
                  dropFilesHere="{{ 'uploads.dropFilesHere' | translate }}"
                  externalDropFilesHere="{{ 'uploads.externalDropFilesHere' | translate }}"
                  fileStatusFailed="{{ 'uploads.fileStatusFailed' | translate }}"
                  fileStatusUploaded="{{ 'uploads.fileStatusUploaded' | translate }}"
                  filesBatchStatus="{{ 'uploads.filesBatchStatus' | translate }}"
                  filesBatchStatusFailed="{{ 'uploads.filesBatchStatusFailed' | translate }}"
                  filesBatchStatusUploaded="{{ 'uploads.filesBatchStatusUploaded' | translate }}"
                  headerStatusPaused="{{ 'uploads.headerStatusPaused' | translate }}"
                  headerStatusUploaded="{{ 'uploads.headerStatusUploaded' | translate }}"
                  headerStatusUploading="{{ 'uploads.headerStatusUploading' | translate }}"
                  invalidFileExtension="{{ 'uploads.invalidFileExtension' | translate }}"
                  invalidMaxFileSize="{{ 'uploads.invalidMaxFileSize' | translate }}"
                  invalidMinFileSize="{{ 'uploads.invalidMinFileSize' | translate }}"
                  pause="{{ 'uploads.pause' | translate }}" remove="{{ 'uploads.remove' | translate }}"
                  resume="{{ 'uploads.resume' | translate }}" retry="{{ 'uploads.retry' | translate }}"
                  select="{{ 'uploads.select' | translate }}"
                  uploadSelectedFiles="{{ 'uploads.uploadSelectedFiles' | translate }}">
                </kendo-upload-messages>
              </kendo-fileselect>
            </div>
          </div>
        </div>
        <div class="clearfix mt-2">
          <div class="float-left mr-2">
            <button kendoButton (click)="onClickAcoplar()" class="btn mr-1  btn-success btn-sm mr-1">{{
              'botones.acoplarAPDF' | translate}}</button>
          </div>
          <div class="float-left mr-2">
            <button kendoButton (click)="onClickActualizar()" class="btn mr-1  btn-success btn-sm mr-1">{{
              'botones.actualizarPDF' | translate}}</button>
          </div>
          <div class="float-left mr-2">
            <button kendoButton (click)="onClickVerPDF()" class="btn mr-1  btn-turquesa btn-sm mr-1">{{
              'botones.verPDF' | translate}}</button>
          </div>
          <div class="float-left mr-2">
            <button kendoButton (click)="onClickDescargar()" class="btn mr-1  btn-primary btn-sm mr-1">{{
              'botones.descargar' | translate}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<router-outlet></router-outlet>