import { Component, Inject, Injectable, LOCALE_ID } from '@angular/core';
import { MenuService, UsuariosService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpProgressEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { Observable, of, concat } from 'rxjs';
import { delay } from 'rxjs/operators';
import { CldrIntlService, IntlService } from '@progress/kendo-angular-intl';

@Component({ selector: 'app', templateUrl: 'app.component.html' })
export class AppComponent {

  user = this.usuariosService.userValue;
  titulo = this.menuService.titulo;
  isExpanded = false;
  tema = 1;
  iniciales = "";

  constructor(@Inject(LOCALE_ID) public localeId: string,
    private usuariosService: UsuariosService, public intlService: IntlService,
    private translateService: TranslateService,
    private menuService: MenuService) {

    this.usuariosService.user.subscribe(x => this.user = x);
    
    if (this.user != null) {
      
      translateService.setDefaultLang(this.user.idioma);
      this.isExpanded = this.user.menuExpandido;
      this.tema = this.user.tema;
      this.iniciales = this.user.nombre.substring(0, 1).toUpperCase() + this.user.apellido1.substring(0, 1).toUpperCase();
      if (this.tema == 1) {
        const body = document.getElementsByTagName('body')[0];
        body.classList.remove('iluna');
      } else {
        const body = document.getElementsByTagName('body')[0];
        body.classList.add('iluna');
      }
    } else {
      translateService.setDefaultLang('es');
      this.isExpanded = false;
      this.tema = 1;
      if (this.tema == 1) {
        const body = document.getElementsByTagName('body')[0];
        body.classList.remove('iluna');
      }
    }

  }

  public onLocaleChange(locale: string): void {
    this.localeId = locale;
    (<CldrIntlService>this.intlService).localeId = locale;
  }

  temaIluna() {
    this.tema = 2;
    this.menuService.updateUserSettings(this.user.id, this.user.idDb, this.user.idioma, this.tema, this.user.menuExpandido).subscribe();
    this.user.tema = this.tema;
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('iluna');
    body.classList.add('iluna');
  }

  temaArgia() {
    this.tema = 1;
    this.menuService.updateUserSettings(this.user.id, this.user.idDb, this.user.idioma, this.tema, this.user.menuExpandido).subscribe();
    this.user.tema = this.tema;
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('iluna');
  }

  expandir() {
    this.isExpanded = !this.isExpanded;
    this.menuService.updateUserSettings(this.user.id, this.user.idDb, this.user.idioma, this.user.tema, this.isExpanded).subscribe();
    this.user.menuExpandido = this.isExpanded;
  }

  useLanguage(language: string) {
    this.menuService.updateUserSettings(this.user.id, this.user.idDb, language, this.user.tema, this.user.menuExpandido).subscribe(
      f => (
        window.location.reload()
      )
    )
  }

  logout() {
    this.usuariosService.logout();
  }

}

@Injectable()
export class UploadInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url === 'saveUrl') {
      const events: Observable<HttpEvent<any>>[] = [0, 30, 60, 100].map((x) => of(<HttpProgressEvent>{
        type: HttpEventType.UploadProgress,
        loaded: x,
        total: 100
      }).pipe(delay(1000)));

      const success = of(new HttpResponse({ status: 200 })).pipe(delay(1000));
      events.push(success);

      return concat(...events);
    }

    if (req.url === 'removeUrl') {
      return of(new HttpResponse({ status: 200 }));
    }

    return next.handle(req);
  }
}
