import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TipoUsuario, Usuario } from '@app/_models';
import { AlertService, MenuService, cebollasService, UsuariosTiposService, PlanosService, catalogoForjaRuedasService } from '@app/_services';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { FileRestrictions } from '@progress/kendo-angular-upload';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-catalogo-forja-ruedas',
  templateUrl: './catalogo-forja-ruedas.component.html'
})
export class CatalogoForjaRuedasComponent implements OnInit {

  public archivo: any = "";
  public archivoAgregar: any ="";

  public restrictions: FileRestrictions = {
    allowedExtensions: ['.pdf'],
    maxFileSize: 28000000
  };

  constructor(private catalogoForjaRuedasService: catalogoForjaRuedasService,
    private alertService: AlertService, 
    private menuService: MenuService, 
    public router: Router, 
    private route: ActivatedRoute, 
    private formBuilder: FormBuilder,
    private translateService: TranslateService,) {
    this.menuService.titulo = this.translateService.instant('catalogoForjaRuedas.titulo');
    this.cargarDatos();
  }

  ngOnInit(): void {
  }

  cargarDatos(){
    this.catalogoForjaRuedasService.obtenerManual()
          .pipe(first())
          .subscribe((response: any) => {
           this.archivo = response.data;
          
    });
  }

   //Funciones PDF 
   archivoSeleccionado(e){
    var th = this;
    setTimeout(async function () {
      var archivoBase64: any = "";
      if (th.archivoAgregar !== "" && th.archivoAgregar !== null) 
        archivoBase64 = await th.toBase64(th.archivoAgregar[0]);
      else 
        archivoBase64 = "";
      th.archivoAgregar = archivoBase64;
    }, 500);
  }

  archivoEliminado(e){
    this.archivoAgregar = undefined;
  }

  toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  onClickVerPDF(){
      const win = window.open("", "_blank");
      console.log(this.archivo);
      let html = '';

      html += '<html>';
      html += '<body style="margin:0!important">';
      html += '<embed width="100%" height="100%" src="' + this.archivo + '" type="application/pdf" />';
      html += '</body>';
      html += '</html>';

      setTimeout(() => {
        win.document.write(html);
        win.document.title = "Manual";
      }, 2000);
  }

  onClickAcoplar(){
    this.catalogoForjaRuedasService.acoplarArchivo(this.archivoAgregar)
          .pipe(first())
          .subscribe((response: any) => {
            this.alertService.success(this.translateService.instant('botones.editadocorrectamente'), { keepAfterRouteChange: true });
            this.archivo = response.data;
    });
  }

  onClickActualizar(){
    this.catalogoForjaRuedasService.actualizarArchivo(this.archivoAgregar)
          .pipe(first())
          .subscribe((response: any) => {
            this.alertService.success(this.translateService.instant('botones.editadocorrectamente'), { keepAfterRouteChange: true });
            this.archivo = response.data;
    });
  }

  onClickDescargar(){
    const downloadLink = document.createElement("a");

    downloadLink.href = this.archivo;
    downloadLink.download = "catalogo.pdf";
    downloadLink.click();  
  }

  
  
}
