<div  class="card"  *ngIf="router.url === '/poleas'">
    <kendo-grid [kendoGridBinding]="dataPoleas"
                [sortable]="true"
                [selectable]="true"
                kendoGridSelectBy="id"
                [navigable]="true"
                filterable="menu"
                (cellClick)="cellClick($event)"
                [selectedKeys]="mySelection"> 
      <ng-template kendoGridToolbarTemplate position="top">
        <button kendoButton (click)="onClickEditar()" class="btn mr-1  btn-success btn-sm mr-1">{{ 'botones.editar' | translate}}</button>
        <button kendoButton (click)="onClickNuevo()" class="btn mr-1  btn-primary btn-sm mr-1">{{ 'botones.nuevo' | translate}}</button>
        <button kendoButton (click)="onClickEliminar()" class="btn mr-1  btn-danger btn-sm mr-1" [disabled]="isDeleting">{{ 'botones.eliminar' | translate}}</button>
      </ng-template>
      <kendo-grid-checkbox-column width="5%" showSelectAll="true"></kendo-grid-checkbox-column>
      <kendo-grid-column width="90%" field="nombre" title="{{ 'usuarios.nombre' | translate}}">   </kendo-grid-column>
      <kendo-grid-messages filter="{{'grids.filter' | translate}}" filterAfterOperator="{{'grids.filterAfterOperator' | translate}}"
        filterAfterOrEqualOperator="{{'grids.filterAfterOrEqualOperator' | translate}}"
        filterAndLogic="{{'grids.filterAndLogic' | translate}}" filterBeforeOperator="{{'grids.filterBeforeOperator' | translate}}"
        filterBeforeOrEqualOperator="{{'grids.filterBeforeOrEqualOperator' | translate}}"
        filterBooleanAll="{{'grids.filterBooleanAll' | translate}}" filterClearButton="{{'filterClearButton' | translate}}"
        filterContainsOperator="{{'grids.filterContainsOperator' | translate}}"
        filterDateToday="{{'grids.filterDateToday' | translate}}" filterDateToggle="{{'grids.filterDateToggle' | translate}}"
        filterEndsWithOperator="{{'grids.filterEndsWithOperator' | translate}}"
        filterEqOperator="{{'grids.filterEqOperator' | translate}}" filterFilterButton="{{'grids.filterFilterButton' | translate}}"
        filterGtOperator="{{'grids.filterGtOperator' | translate}}" filterGteOperator="{{'grids.filterGteOperator' | translate}}"
        filterIsEmptyOperator="{{'grids.filterIsEmptyOperator' | translate}}" filterIsFalse="{{'grids.filterIsFalse' | translate}}"
        filterIsNotEmptyOperator="{{'grids.filterIsNotEmptyOperator' | translate}}"
        filterIsNotNullOperator="{{'grids.filterIsNotNullOperator' | translate}}"
        filterIsNullOperator="{{'grids.filterIsNullOperator' | translate}}" filterIsTrue="{{'grids.filterIsTrue' | translate}}"
        filterLtOperator="{{'grids.filterLtOperator' | translate}}" filterLteOperator="{{'grids.filterLteOperator' | translate}}"
        filterNotContainsOperator="{{'grids.filterNotContainsOperator' | translate}}"
        filterNotEqOperator="{{'grids.filterNotEqOperator' | translate}}"
        filterNumericDecrement="{{'grids.filterNumericDecrement' | translate}}"
        filterNumericIncrement="{{'grids.filterNumericIncrement' | translate}}"
        filterOrLogic="{{'grids.filterOrLogic' | translate}}"
        filterStartsWithOperator="{{'grids.filterStartsWithOperator' | translate}}" loading="{{'grids.loading' | translate}}"
        noRecords="{{'grids.noRecords' | translate}}" unlock="{{'unlock' | translate}}">
    </kendo-grid-messages>
    </kendo-grid>
  </div>
  
  <router-outlet></router-outlet>
  
