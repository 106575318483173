<div class="form-group">
    <kendo-label text="{{ label }}">
        <div *ngIf="labelArriba">
        </div>
    <kendo-textbox #textboxdni
      placeholder="'{{ placeholder }}'"
      [clearButton]="true"
      [(ngModel)]="dni"
      (valueChange)="valueChange($event, textboxdni)"
    >
      <!-- <ng-template kendoTextBoxPrefixTemplate>
        <button kendoButton look="clear" icon="calendar"></button>
      </ng-template> -->
    </kendo-textbox>
    </kendo-label>
  </div>