import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Usuario } from '@app/_models';
import { Idioma } from '@app/_models/idioma';
import { AlertService, MenuService, PlanosService, UsuariosService } from '@app/_services';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';


@Component({ templateUrl: 'datosForja.component.html' })
export class DatosForjaComponent {
    user = this.usuariosService.userValue;
    dataUsuarios: Usuario[];
    interval: number;
    selectedIdioma:Idioma;
    selectedNumber:number;
    inputTextBox:string;
    listaUsuarios:Usuario[];
    idiomaSeleccionado:string;

    listaPlanos: any;
    planosSeleccionados: any = [];
    listaFiguras: any;
    figurasSeleccionadas: any = [];
    listaEquivalenciasP10000: any;
    equivalenciasP10000Seleccionadas: any = [];
    listaEquivalenciasP5000: any;
    equivalenciasP5000Seleccionadas: any = [];
    listaEquivalenciasLaminador: any;
    equivalenciasLaminadorSeleccionadas: any = [];
    datosFiltro: any = [];

    //GRID
    dataPlanosFiltrados: any;
    planosSelected: any = [];

    constructor(private usuariosService: UsuariosService, public planosService: PlanosService, private alertService: AlertService,
        private menuService: MenuService, public router: Router, private translateService: TranslateService) {    
            
      }
    
      ngOnInit(): void {
        this.menuService.titulo = this.translateService.instant('datosForja.titulo');
        this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
          // TODO This as a workaround.
          this.menuService.titulo = this.translateService.instant('datosForja.titulo');
        });
        this.cargarDatos();
      }

      cargarDatos(){
        this.planosService.getAllFiltro().pipe(first()).subscribe((result: any) =>{
          this.datosFiltro = result;
          this.cambioFiltro();
          this.onClickFiltrar();
        });
      }

      cellClick(e){
        if (e.columnIndex > 0 && e.columnIndex!=4 && e.columnIndex!=5) {
          this.router.navigate(['datosForja/editar/', e.dataItem.id]);
        }else if(e.columnIndex==4){
          this.router.navigate(['hojaImpresion/', e.dataItem.id, 1]);
        }
      }

      onClickEditar(){
        if (this.planosSelected.length > 0) {
          this.router.navigate(['datosForja/editar/', this.planosSelected[0]]);
        }
      }

      onClickNuevo(){
        this.router.navigate(['datosForja/editar/-1']);
      }

      onClickEliminar(){
        this.planosSelected.forEach(element => {
          var auxi = this.dataPlanosFiltrados.filter(x=>x.id==element)[0];
          var idequivalenciaP10000= -1;
          var idequivalenciaP5000 = -1;
          var idequivalencialaminador=-1;
          if(auxi!=undefined){
            idequivalenciaP10000=auxi.idEquivalenciasP10000;
            idequivalenciaP5000 = auxi.idEquivalenciasP5000;
            idequivalencialaminador=auxi.idEquivalenciasLaminador;
          }
          this.planosService.delete(element, idequivalenciaP10000, idequivalenciaP5000, idequivalencialaminador)
            .pipe(first())
            .subscribe((result: any) => {
              if(result>=0){
                this.onClickFiltrar(); //si filtramos, como esta eliminado no nos lo devolvera
                this.alertService.success(this.translateService.instant('botones.eliminadocorrectamente'), { keepAfterRouteChange: true });
              }else{
                this.alertService.error(this.translateService.instant('botones.erroreliminar'), { keepAfterRouteChange: true });
              }
              });
        });
      }

      onClickFiltrar(){
        var listaPlanos = (this.planosSeleccionados === undefined) ? [] : this.planosSeleccionados.map(a => "'" + a.codigoPlano + "'");
        var listaFiguras = (this.figurasSeleccionadas === undefined) ? [] : this.figurasSeleccionadas.map(a => "'" + a.figura + "'");
        var listaEquivalenciasP10000 = (this.equivalenciasP10000Seleccionadas === undefined) ? [] : this.equivalenciasP10000Seleccionadas.map(a => a.id);
        var listaEquivalenciasP5000 = (this.equivalenciasP5000Seleccionadas === undefined) ? [] : this.equivalenciasP5000Seleccionadas.map(a => a.id);
        var listaEquivalenciasLaminador = (this.equivalenciasLaminadorSeleccionadas === undefined) ? [] : this.equivalenciasLaminadorSeleccionadas.map(a => a.id);
        this.planosService.getAllFiltrados(listaPlanos.join(","), listaFiguras.join(","),
        listaEquivalenciasP10000.join(","), listaEquivalenciasP5000.join(","), listaEquivalenciasLaminador.join(",")).pipe(first()).subscribe((result: any) =>{
          this.dataPlanosFiltrados = result;
        });
      }

      cambioFiltro(){

        var planos = [];
        if (this.planosSeleccionados != undefined)
          this.planosSeleccionados.forEach(plano => planos.push(plano));

        var figuras = [];
        if (this.figurasSeleccionadas != undefined)
          this.figurasSeleccionadas.forEach(figura => figuras.push(figura));

        var equivalenciasP10000 = [];
        if (this.equivalenciasP10000Seleccionadas != undefined)
          this.equivalenciasP10000Seleccionadas.forEach(equivalencia => equivalenciasP10000.push({id: equivalencia.id, equivalencia: equivalencia.equivalencia}));

        var equivalenciasP5000 = [];
        if (this.equivalenciasP5000Seleccionadas != undefined)
          this.equivalenciasP5000Seleccionadas.forEach(equivalencia => equivalenciasP5000.push({id: equivalencia.id, equivalencia: equivalencia.equivalencia}));

        var equivalenciasLaminador = [];
        if (this.equivalenciasLaminadorSeleccionadas != undefined)
          this.equivalenciasLaminadorSeleccionadas.forEach(equivalencia => equivalenciasLaminador.push({id: equivalencia.id, equivalencia: equivalencia.equivalencia}));
    
        var groupByPlano = [];
        var groupByFigura = [];
        var groupByEquivalenciaP10000 = [];
        var groupByEquivalenciaP5000 = [];
        var groupByEquivalenciaLaminador = [];

        //GROUP BY POR PLANOS
        var lag: any = {};
        this.datosFiltro.forEach(
          row => {
            if (!lag[row.codigoPlano]
              && (figuras.some(x=>x.figura==row.figura) || figuras[0] == undefined)
              && (equivalenciasP10000.some(x=>x.id==row.idEquivalenciasP10000) || equivalenciasP10000[0] == undefined)
              && (equivalenciasP5000.some(x=>x.id==row.idEquivalenciasP5000) || equivalenciasP5000[0] == undefined)
              && (equivalenciasLaminador.some(x=>x.id==row.idEquivalenciasLaminador) || equivalenciasLaminador[0] == undefined)
            ) {
              lag[row.codigoPlano] = { codigoPlano: row.codigoPlano };
              groupByPlano.push(lag[row.codigoPlano]);
            }
          });

        this.listaPlanos = groupByPlano.filter(item => (item.codigoPlano != null));
        this.listaPlanos.sort((a, b) => (a.codigoPlano > b.codigoPlano) ? 1 : ((b.codigoPlano > a.codigoPlano) ? -1 : 0));

        //GROUP BY POR FIGURAS
        lag = {};
        this.datosFiltro.forEach(
          row => {
            if (!lag[row.figura]
              && (planos.some(x=>x.codigoPlano==row.codigoPlano) || planos[0] == undefined)
              && (equivalenciasP10000.some(x=>x.id==row.idEquivalenciasP10000) || equivalenciasP10000[0] == undefined)
              && (equivalenciasP5000.some(x=>x.id==row.idEquivalenciasP5000) || equivalenciasP5000[0] == undefined)
              && (equivalenciasLaminador.some(x=>x.id==row.idEquivalenciasLaminador) || equivalenciasLaminador[0] == undefined)
            ) {
              lag[row.figura] = {
                figura: row.figura
              };
              groupByFigura.push(lag[row.figura]);
            }
          });

        this.listaFiguras = groupByFigura.filter(item => (item.figura != null));
        this.listaFiguras.sort((a, b) => (a.figura > b.figura) ? 1 : ((b.figura > a.figura) ? -1 : 0));

        //GROUP BY POR EQUIVALENCIAP10000
        lag = {};
        this.datosFiltro.forEach(
          row => {
            if (!lag[row.equivalenciaP10000]
              && (planos.some(x=>x.codigoPlano==row.codigoPlano) || planos[0] == undefined)
              && (figuras.some(x=>x.figura==row.figura) || figuras[0] == undefined)
              && (equivalenciasP5000.some(x=>x.id==row.idEquivalenciasP5000) || equivalenciasP5000[0] == undefined)
              && (equivalenciasLaminador.some(x=>x.id==row.idEquivalenciasLaminador) || equivalenciasLaminador[0] == undefined)
             ) {
              lag[row.equivalenciaP10000] = {
                id: row.idEquivalenciasP10000, equivalencia: row.equivalenciaP10000,
              };
              groupByEquivalenciaP10000.push(lag[row.equivalenciaP10000]);
            }
          });
        this.listaEquivalenciasP10000 = groupByEquivalenciaP10000.filter(item => (item.id != -1));
        this.listaEquivalenciasP10000.sort((a, b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0));

        //GROUP BY POR EQUIVALENCIAP5000
        lag = {};
        this.datosFiltro.forEach(
          row => {
            if (!lag[row.equivalenciaP5000]
              && (planos.some(x=>x.codigoPlano==row.codigoPlano) || planos[0] == undefined)
              && (figuras.some(x=>x.figura==row.figura) || figuras[0] == undefined)
              && (equivalenciasP10000.some(x=>x.id==row.idEquivalenciasP10000) || equivalenciasP10000[0] == undefined)
              && (equivalenciasLaminador.some(x=>x.id==row.idEquivalenciasLaminador) || equivalenciasLaminador[0] == undefined)
             ) {
              lag[row.equivalenciaP5000] = {
                id: row.idEquivalenciasP5000, equivalencia: row.equivalenciaP5000,
              };
              groupByEquivalenciaP5000.push(lag[row.equivalenciaP5000]);
            }
          });
        this.listaEquivalenciasP5000 = groupByEquivalenciaP5000.filter(item => (item.id != -1));
        this.listaEquivalenciasP5000.sort((a, b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0));

        //GROUP BY POR EQUIVALENCIALAMINADOR
        lag = {};
        this.datosFiltro.forEach(
          row => {
            if (!lag[row.equivalenciaLaminador]
              && (planos.some(x=>x.codigoPlano==row.codigoPlano) || planos[0] == undefined)
              && (figuras.some(x=>x.figura==row.figura) || figuras[0] == undefined)
              && (equivalenciasP10000.some(x=>x.id==row.idEquivalenciasP10000) || equivalenciasP10000[0] == undefined)
              && (equivalenciasP5000.some(x=>x.id==row.idEquivalenciasP5000) || equivalenciasP5000[0] == undefined)
             ) {
              lag[row.equivalenciaLaminador] = {
                id: row.idEquivalenciasLaminador, equivalencia: row.equivalenciaLaminador,
              };
              groupByEquivalenciaLaminador.push(lag[row.equivalenciaLaminador]);
            }
          });
        this.listaEquivalenciasLaminador = groupByEquivalenciaLaminador.filter(item => (item.id != -1));
        this.listaEquivalenciasLaminador.sort((a, b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0));
 
      }
  
}