import { Injectable } from '@angular/core';
import { UsuariosService } from '@app/_services';
import * as moment from 'moment-timezone';
@Injectable()
export class MyFunctions {
  user = this.userService.userValue;
  constructor(private userService: UsuariosService) {    
  }

  //FECHAS
  public dateToString(m) {
    if (m == null) {
      return "";
    } else if (m == "-") {
      return "-";
    } else {
      var dateString =
        ("0" + m.getDate()).slice(-2) + "/" +
        ("0" + (m.getMonth() + 1)).slice(-2) + "/" +
        m.getFullYear() + " " +
        ("0" + m.getHours()).slice(-2) + ":" +
        ("0" + m.getMinutes()).slice(-2) + ":" +
        ("0" + m.getSeconds()).slice(-2);
      return dateString;
    }
  }
 
  public dateToDayString(m) {
    if (m == null) {
      return "";
    } else {
      var dateString =
        ("0" + m.getDate()).slice(-2) + "/" +
        ("0" + (m.getMonth() + 1)).slice(-2) + "/" +
        m.getFullYear();
      return dateString;
    }
  }

  public dateToHourString(m) {
    if (m == null) {
      return "";
    } else {
      var dateString =
        ("0" + m.getHours()).slice(-2) + ":" +
        ("0" + m.getMinutes()).slice(-2) + ":" +
        ("0" + m.getSeconds()).slice(-2);
      return dateString;
    }
  }

  public sqlToJsDate(dateString) {
    //var dateString = "2010-08-09 01:02:03";
    if (dateString === "") {
      return null;
    } else {
      var reggie = /(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})/;
      var dateArray = reggie.exec(dateString);
      var dateObject = new Date(
        (+dateArray[1]),
        (+dateArray[2]) - 1, // Careful, month starts at 0!
        (+dateArray[3]),
        (+dateArray[4]),
        (+dateArray[5]),
        (+dateArray[6])
      );
      return dateObject;
    }
  }

  public secondsTo_HH_MM_SS(segundos) {

    if (segundos == undefined) return "00:00:00";

    var segs;
    if (segundos < 0) segs = -segundos;
    else segs = segundos;

    var sec_num = parseInt(segs, 10);
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);

    var hs, ms, ss;

    if (hours < 10) { hs = "0" + hours; } else { hs = hours; }
    if (minutes < 10) { ms = "0" + minutes; } else { ms = minutes; }
    if (seconds < 10) { ss = "0" + seconds; } else { ss = seconds; }

    if (segundos < 0)
      return '-' + hs + ':' + ms + ':' + ss;
    else
      return hs + ':' + ms + ':' + ss;
  }

  public secondsTo_HH_MM(segundos) {

    if (segundos == undefined) return "00:00:00";

    var segs;
    if (segundos < 0) segs = -segundos;
    else segs = segundos;

    var sec_num = parseInt(segs, 10);
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);

    var hs, ms, ss;

    if (hours < 10) { hs = "0" + hours; } else { hs = hours; }
    if (minutes < 10) { ms = "0" + minutes; } else { ms = minutes; }
    if (seconds < 10) { ss = "0" + seconds; } else { ss = seconds; }

    if (segundos < 0)
      return '-' + hs + ':' + ms;
    else
      return hs + ':' + ms;
  }

  public milisecondsTo_HH_MM(milisegundos) {

    if (milisegundos == undefined) return "00:00:00";

    var segs;
    if (milisegundos < 0) segs = -(milisegundos / 1000);
    else segs = (milisegundos / 1000);

    var sec_num = parseInt(segs, 10);
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);

    var hs, ms, ss;

    if (hours < 10) { hs = "0" + hours; } else { hs = hours; }
    if (minutes < 10) { ms = "0" + minutes; } else { ms = minutes; }
    if (seconds < 10) { ss = "0" + seconds; } else { ss = seconds; }

    if (milisegundos < 0) return '-' + hs + ':' + ms;
    else return hs + ':' + ms;
  }

  public milisecondsTo_HH_MM_SS(milisegundos) {

    if (milisegundos == undefined) return "00:00:00";

    var segs;
    if (milisegundos < 0) segs = -(milisegundos / 1000);
    else segs = (milisegundos / 1000);

    var sec_num = parseInt(segs, 10);
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);

    var hs, ms, ss;

    if (hours < 10) { hs = "0" + hours; } else { hs = hours; }
    if (minutes < 10) { ms = "0" + minutes; } else { ms = minutes; }
    if (seconds < 10) { ss = "0" + seconds; } else { ss = seconds; }

    if (milisegundos < 0) return '-' + hs + ':' + ms + ':' + ss;
    else return hs + ':' + ms + ':' + ss;
  }

  public dateToYYYYMMDDtHHmmSSz(fecha: Date) {
    //2020-10-25T23:00:00Z
    var ano = fecha.getFullYear();
    var mes = fecha.getMonth() + 1;
    var dia = fecha.getDate(); //getDay da el dia de la semana!
    var hora = fecha.getHours();
    var minutos = fecha.getMinutes();
    var segundos = fecha.getSeconds();
    return ano + '-' + this.addZero(mes) + '-' + this.addZero(dia) + 'T' + this.addZero(hora) + ':' + this.addZero(minutos) + ':' + this.addZero(segundos) + 'Z';
  }

  dateToYYYYMMDDHHmmSS(fecha: Date) { // es para ordenar en ascendente el string
    //20201025230000
    var ano = fecha.getFullYear();
    var mes = fecha.getMonth() + 1;
    var dia = fecha.getDate();
    var hora = fecha.getHours();
    var minutos = fecha.getMinutes();
    var segundos = fecha.getSeconds();
    return ano + '-' + this.addZero(mes) + this.addZero(dia) + this.addZero(hora) + this.addZero(minutos) + this.addZero(segundos);
  }

  public dateToYYYY_MM_DD(fecha: Date) {
    //25_10_2020
    var ano = fecha.getFullYear();
    var mes = fecha.getMonth() + 1;
    var dia = fecha.getDate(); //getDay da el dia de la semana!
    return ano + '_' + this.addZero(mes) + '_' + this.addZero(dia);
  }

  public dateToYYYY_MM_DD_guion(fecha: Date) {
    //2020-10-25
    var ano = fecha.getFullYear();
    var mes = fecha.getMonth() + 1;
    var dia = fecha.getDate(); //getDay da el dia de la semana!
    return ano + '-' + this.addZero(mes) + '-' + this.addZero(dia);
  }

  public HH_MM_ToSeconds(s: String) {
    var segundos = 0;

    var sSplited: any = s.split(":", 2);

    segundos = sSplited[0] * 3600 + sSplited[1] * 60;

    return segundos;
  }

  dateCopy(miFecha: Date) {
    return new Date(miFecha.getFullYear(), miFecha.getMonth(), miFecha.getDate(), miFecha.getHours(), miFecha.getMinutes(), miFecha.getSeconds(), miFecha.getMilliseconds());
  }
  secondsToHms(seconds: number) {
    const days = Math.floor(seconds / 86400);
    const remainderSeconds = seconds % 86400;
    const hms = new Date(remainderSeconds * 1000).toISOString().substring(11, 19);
    return hms.replace(/^(\d+)/, h => `${Number(h) + days * 24}`.padStart(2, '0'));
  }



  //LIST

  public listToStirng(lista, separator, emptyCase) {
    if (lista == undefined) {
      return emptyCase;
    } else {
      var s = "";

      lista.forEach(
        elem => {
          if (s == "")
            s = elem.toString();
          else
            s += separator + elem.toString();
        }
      );

      if (emptyCase != undefined && s == "")
        s = emptyCase

      return s;
    }
  }

  public dateToDD_MM_YYYY_forwardslash(start: Date) {
    // 12/11/2020
    var fecha = new Date(start);

    var ano = fecha.getFullYear();
    var mes = fecha.getMonth() + 1;
    var dia = fecha.getDate(); //getDay da el dia de la semana!
    return this.addZero(dia) + "/" + this.addZero(mes) + "/" + ano;
  }

  // ADD zero
  public addZero(n: number) {
    if (n < 10)
      return '0' + n.toString();
    else
      return n.toString();
  }


  // TRAZAS
  public log(variable) {
    //console.log(variable);
  }

}
