
<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="card col-lg-4">
    <div class="card-header">
      <h3 *ngIf="isAddMode">{{ 'tablasMaestras.crearprepunzonadoSuperior' | translate}}</h3>
      <h3 *ngIf="!isAddMode">{{ 'tablasMaestras.editarprepunzonadoSuperior' | translate}}</h3>
      <div class="plegarpanel"></div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="form-group col-md-12" >
          <kendo-label text="{{ 'usuarios.nombre' | translate}}">
            <kendo-textbox formControlName="nombre" [ngClass]="{ 'is-invalid': submitted && f.nombre.errors }">
            </kendo-textbox>
            <div *ngIf="submitted && f.nombre.errors" class="invalid-feedback">
              <div *ngIf="f.nombre.errors.required">{{ 'usuarios.nombrerequerido' | translate}}</div>
            </div>
          </kendo-label>
        </div>
      </div>
      </div>
    </div>
      <button kendoButton [disabled]="loading" class="btn mr-1  btn-primary">
        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
        {{ 'botones.guardar' | translate}}
      </button>
      <a routerLink="/prepunzonadoSuperior" class="btn mr-1  btn-danger">{{ 'botones.cancelar' | translate}}</a>
</form>
