import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Usuario } from '@app/_models';
import { Idioma } from '@app/_models/idioma';
import { AlertService, MenuService, PlanosService, UsuariosService } from '@app/_services';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';


@Component({ templateUrl: 'buscador.component.html' })
export class BuscadorComponent {
    user = this.usuariosService.userValue;
    dataUsuarios: Usuario[];
    interval: number;
    selectedIdioma:Idioma;
    selectedNumber:number;
    inputTextBox:string;
    listaUsuarios:Usuario[];
    idiomaSeleccionado:string;
    loading = false;

    es5000: any = true;
    listaCombo: any;
    comboSeleccionado: any;
    //Variables para saber lo que se ha seleccionado en el filtro, por si se cambia este
    comboSeleccionadoFiltro: any;
    es5000SeleccionadoFiltro: any;
    mostrarTercerValor: any = false;
    comboYaSeleccionado: any = false;
    valorGenerico1: any;
    valorGenerico2: any;
    valorGenerico3: any;
    datosFiltradosParecidos: any = [];
    datosFiltradosIguales: any = [];
    datosFiltradosParecidosSelected: any = [];
    datosFiltradosIgualesSelected: any = [];

    constructor(private usuariosService: UsuariosService, public planosService: PlanosService, private alertService: AlertService,
        private menuService: MenuService, public router: Router, private translateService: TranslateService) {    
            
      }
    
      ngOnInit(): void {
        this.menuService.titulo = this.translateService.instant('buscador.titulo');
        this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
          // TODO This as a workaround.
          this.menuService.titulo = this.translateService.instant('buscador.titulo');
        });
        this.cargarDatos();
      }

      cargarDatos(){
        this.loading = true;
        this.cambioChecks();
        this.mostrarTercerValor = false;
        this.comboYaSeleccionado = false;
        this.valorGenerico1=0.0;
        this.valorGenerico2=0.0;
        this.valorGenerico3=0.0;
        this.loading = false;
      }

      btn5000(){
        this.es5000 = true;
        this.cambioChecks();
      }

      btn10000(){
        this.es5000 = false;
        this.cambioChecks();
      }

      cambioCombo(){
        this.comboYaSeleccionado= true;
        switch(this.comboSeleccionado.value){
          case "arandela":{
            this.mostrarTercerValor= true;
            break;
          }
          case "prepunzonadoInferior1aOP":{
            this.mostrarTercerValor= false;
            break;
          }
          case "prepunzonadoInferior2aOP":{
            this.mostrarTercerValor= true;
            break;
          }
          case "prepunzonadoSuperior":{
            this.mostrarTercerValor= true;
            break;
          }
        }
      }

      cambioChecks(){
        if(this.es5000){
          //Estamos con la p5000, dar sus variables
          this.listaCombo = [{nombre: this.translateService.instant('datosForja.arandela'), value: "arandela"}];
        }else{
          //Estamos con la p10000 dar sus variables
          this.listaCombo = [{nombre: this.translateService.instant('datosForja.prepunzonadoInferior1aOP'), value: "prepunzonadoInferior1aOP"},
          {nombre: this.translateService.instant('datosForja.prepunzonadoInferior2aOP'), value: "prepunzonadoInferior2aOP"},
          {nombre: this.translateService.instant('datosForja.arandela'), value: "arandela"},
          {nombre: this.translateService.instant('datosForja.prepunzonadoSuperior'), value: "prepunzonadoSuperior"}];
        }
      }

      onClickBuscar(){
        this.loading = true;
        this.comboSeleccionadoFiltro = this.comboSeleccionado.value;
        this.es5000SeleccionadoFiltro = this.es5000;
        this.planosService.filtrarPiezasMedidas(this.comboSeleccionado.value, this.valorGenerico1, 
          this.valorGenerico2, this.valorGenerico3, this.es5000)
              .pipe(first())
              .subscribe((result: any) => {
                this.datosFiltradosParecidos = result.parecidos;
                this.datosFiltradosIguales = result.iguales;
                this.loading = false;
              });
      }
  
      cellClick(e) {
        if (e.columnIndex > 0) {
          this.router.navigate(['datosForja/editar/', e.dataItem.id]);
        }
      }
}