<div class="card" *ngIf="router.url === '/usuarios'">
    <kendo-grid [kendoGridBinding]="dataUsuarios"
                [sortable]="true"
                [selectable]="true"
                kendoGridSelectBy="id"
                [navigable]="true"
                filterable="menu"
                (cellClick)="cellClick($event)" 
                [selectedKeys]="mySelection">
      <ng-template kendoGridToolbarTemplate position="top">
        <button kendoButton (click)="onClickEditar()" class="btn mr-1  btn-success btn-sm mr-1">{{ 'botones.editar' | translate}}</button>
        <button kendoButton (click)="onClickNuevo()" class="btn mr-1  btn-primary btn-sm mr-1">{{ 'botones.nuevo' | translate}}</button>
        <button kendoButton (click)="onClickEliminar()" class="btn mr-1  btn-danger btn-sm mr-1" [disabled]="isDeleting">{{ 'botones.eliminar' | translate}}</button>
      </ng-template>
      <kendo-grid-checkbox-column width="5%" showSelectAll="true"></kendo-grid-checkbox-column>
      <kendo-grid-column width="15%" field="nombreUsuario" title="{{ 'usuarios.usuario' | translate}}">
      </kendo-grid-column>
      <kendo-grid-column width="20%" field="email" title="{{ 'usuarios.email' | translate}}">
      </kendo-grid-column>
      <kendo-grid-column width="20%" field="nombre" title="{{ 'usuarios.nombre' | translate}}">
      </kendo-grid-column>
      <kendo-grid-column width="20%" field="apellido1" title="{{ 'usuarios.apellido1' | translate}}">
      </kendo-grid-column>
      <kendo-grid-column width="20%" field="apellido2" title="{{ 'usuarios.apellido2' | translate}}">
      </kendo-grid-column>
    </kendo-grid>
  </div>
  
  <router-outlet></router-outlet>
