<div class="">
  <table id="fichaImprimir" *ngIf="datos">
    <tbody>
      <tr>
        <td>
          <table class="no-vertical">
            <thead>
              <tr>
                <th colspan="5" rowspan="2">{{('hojaImpresion.fichaTecnica' | translate).toUpperCase()}}</th>
                <th colspan="3" rowspan="2" class="header-negrita">{{datos.codigoPlano}}/{{datos.figura}}</th>
                <th colspan="1" rowspan="1">{{fecha | kendoDate}} {{fecha | kendoDate:'HH:mm'}}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th rowspan="1">{{('hojaImpresion.ubicacion' | translate).toUpperCase()}}</th>
                <th rowspan="1"></th>
                <th colspan="7" rowspan="1">{{('hojaImpresion.herramientaPrensa10000NuevaGeneracion' |
                  translate).toUpperCase()}}</th>
              </tr>
              <tr>
                <th colspan="5" class="header-negrita">{{('hojaImpresion.herramienta10000Superior' |
                  translate).toUpperCase()}}
                </th>
                <th colspan="2" class="header-negrita">{{('hojaImpresion.herramientaInferior1OP' |
                  translate).toUpperCase()}}
                </th>
                <th colspan="2" class="header-negrita">{{('hojaImpresion.htaInferior2OP' | translate).toUpperCase()}}
                </th>
              </tr>
              <tr>
                <td>{{('hojaImpresion.placaSuperior' | translate).toUpperCase()}}</td>
                <td>{{('hojaImpresion.postizoSuperior' | translate).toUpperCase()}}</td>
                <td>{{('hojaImpresion.casquilloGuia' | translate).toUpperCase()}}</td>
                <td>{{('hojaImpresion.aroForja' | translate).toUpperCase()}}</td>
                <td>{{('hojaImpresion.medidasAro' | translate).toUpperCase()}}</td>
                <td>{{('hojaImpresion.placaInferior' | translate).toUpperCase()}}</td>
                <td>{{('hojaImpresion.postizoInferior' | translate).toUpperCase()}}</td>
                <td>{{('hojaImpresion.placaInferior' | translate).toUpperCase()}}</td>
                <td>{{('hojaImpresion.postizoInferior' | translate).toUpperCase()}}</td>
              </tr>
              <tr>
                <td>{{datos.placaSuperiorH15A}}</td>
                <td>{{datos.h20a_value}}</td>
                <td>{{datos.h22a_value}}</td>
                <td>{{datos.h51a_value1}}</td>
                <td>{{datos.h51a_value2}}</td>
                <td>{{datos.placaSuperiorH30A1}}</td>
                <td>{{datos.h39a_value}}</td>
                <td>{{datos.placaSuperiorH30A2}}</td>
                <td>{{datos.h40a_value}}</td>
              </tr>
              <tr class="tabla-doble-linea">
                <td colspan="2" class="celda-negrita">{{('hojaImpresion.comentariop10000' | translate).toUpperCase()}}
                </td>
                <td colspan="7">{{datos.notas_p10000}}</td>
              </tr>
              <tr>
                <th colspan="2" class="header-negrita">{{('hojaImpresion.piecero10000Inferior1OP' |
                  translate).toUpperCase()}}
                </th>
                <th colspan="5" class="header-negrita">{{('hojaImpresion.piecero10000Inferior2OP' |
                  translate).toUpperCase()}}
                </th>
                <th colspan="2" class="header-negrita">{{('hojaImpresion.piecero10000Superior' |
                  translate).toUpperCase()}}</th>
              </tr>
              <tr>
                <td colspan="2">{{('hojaImpresion.medidasRepunzado' | translate).toUpperCase()}}</td>
                <td colspan="2">{{('hojaImpresion.medidasYTipoExtractor' | translate).toUpperCase()}}</td>
                <td colspan="2">{{('hojaImpresion.medidasPrepunzonado' | translate).toUpperCase()}}</td>
                <td>{{('hojaImpresion.medidasArandelas' | translate).toUpperCase()}}</td>
                <td colspan="2">{{('hojaImpresion.repunzonadoSuperior' | translate).toUpperCase()}}</td>
              </tr>
              <tr>
                <td colspan="2">{{datos.prepunzonadoInferior1OP_diametro}} x
                  {{datos.prepunzonadoInferior1OP_altura}}</td>
                <td colspan="2">{{datos.subextractor10000}}</td>
                <td colspan="2">{{datos.prepunzonadoInferior2OP_diametro}} x {{datos.prepunzonadoInferior2OP_l}}
                  x {{datos.prepunzonadoInferior2OP_l1}}</td>
                <td>{{datos.arandelap10000_diametro}} x {{datos.arandelap10000_l}} x {{datos.arandelap10000_l1}}</td>
                <td colspan="2">{{datos.prepunzonadoSuperior_diametro}} x {{datos.prepunzonadoSuperior_l}} x
                  {{datos.prepunzonadoSuperior_l1}}</td>
              </tr>
              <tr>
                <td colspan="2">{{'hojaImpresion.informacion' | translate}}</td>
                <td colspan="2" rowspan="2">&nbsp;</td>
                <td colspan="2">{{'hojaImpresion.informacion' | translate}}</td>
                <td>{{'hojaImpresion.informacion' | translate}}</td>
                <td colspan="2">{{'hojaImpresion.informacion' | translate}}</td>
              </tr>
              <tr>
                <td colspan="2">{{datos.prepunzonadoInferior1OP_inferior}}</td>
                <td colspan="2">{{datos.prepunzonadoInferior2OP_inferior}}</td>
                <td>{{datos.arandelap10000_inferior}}</td>
                <td colspan="2">{{datos.prepunzonadoSuperior_inferior}}</td>
              </tr>
            </tbody>
          </table>
          <table class="no-vertical">
            <thead>
              <tr>
                <th rowspan="1">{{('hojaImpresion.ubicacion' | translate).toUpperCase()}}</th>
                <th rowspan="1"></th>
                <th colspan="6">{{('hojaImpresion.herramientaPrensa5000' | translate).toUpperCase()}}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{('hojaImpresion.matrizSuperior' | translate).toUpperCase()}}</td>
                <td>{{('hojaImpresion.matrizInferior' | translate).toUpperCase()}}</td>
                <td>{{('hojaImpresion.distanciaCubo' | translate).toUpperCase()}}</td>
                <td>{{('hojaImpresion.diametroSubextractor' | translate).toUpperCase()}}</td>
                <td>{{('hojaImpresion.alturaExtractor' | translate).toUpperCase()}}</td>
                <td>{{('hojaImpresion.medidasArandelas' | translate).toUpperCase()}}</td>
                <td>{{('hojaImpresion.diametroMandrino' | translate).toUpperCase()}}</td>
              </tr>
              <tr>
                <td>{{datos.h60H61}}</td>
                <td>{{datos.h70H71_value1}}</td>
                <td>{{datos.h70H71_value2}}</td>
                <td>{{datos.subextractor5000}}</td>
                <td>{{datos.subextractorP5000_datos}}</td>
                <td>{{datos.arandelap5000_diametro}} x {{datos.arandelap5000_l}} x {{datos.arandelap5000_l1}}</td>
                <td>{{datos.mandrino5000}}</td>
              </tr>
              <tr class="tabla-doble-linea">
                <td colspan="2" class="celda-negrita">{{('hojaImpresion.comentariop5000' | translate).toUpperCase()}}
                </td>
                <td colspan="3">{{datos.notas_p5000}}</td>
                <td>{{'hojaImpresion.informacion' | translate}}</td>
                <td>{{datos.arandelap5000_inferior}}</td>
              </tr>
              <tr class="tabla-doble-linea">
                <td colspan="2" class="celda-negrita">{{('hojaImpresion.comentarioGeneral' | translate).toUpperCase()}}
                </td>
                <td colspan="5">{{datos.notas_general}}</td>
              </tr>
              <tr>
                <td colspan="7" class="celda-negrita">{{('hojaImpresion.estadoHerramienta' | translate).toUpperCase()}}
                </td>
              </tr>
            </tbody>
          </table>
          <table class="no-vertical">
            <thead>
              <tr>
                <th>{{'hojaImpresion.nombre' | translate}}</th>
                <th>{{('hojaImpresion.ok' | translate).toUpperCase()}}</th>
                <th>{{('hojaImpresion.noOk' | translate).toUpperCase()}}</th>
                <th>{{'hojaImpresion.esmerilar' | translate}}</th>
                <th>{{'hojaImpresion.tornear' | translate}}</th>
                <th>{{'hojaImpresion.nuevo' | translate}}</th>
                <th>{{'hojaImpresion.realizadoPor' | translate}}</th>
                <th>{{'hojaImpresion.fecha' | translate}}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="text-left">{{'hojaImpresion.aroForja' | translate}}</td>
                <td><input type="checkbox" [checked]="checkAroForjaOk" [disabled]="true"></td>
                <td><input type="checkbox" [checked]="checkAroForjaNoOk" [disabled]="true"></td>
                <td><input type="checkbox" [checked]="checkAroForjaEsmerilar" [disabled]="true"></td>
                <td><input type="checkbox" [checked]="checkAroForjaTornear" [disabled]="true"></td>
                <td><input type="checkbox" [checked]="checkAroForjaNuevo" [disabled]="true"></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td class="text-left">{{'hojaImpresion.postizoSuperior' | translate}}</td>
                <td><input type="checkbox" [checked]="checkPostizoSuperiorOk" [disabled]="true"></td>
                <td><input type="checkbox" [checked]="checkPostizoSuperiorNoOk" [disabled]="true"></td>
                <td><input type="checkbox" [checked]="checkPostizoSuperiorEsmerilar" [disabled]="true"></td>
                <td><input type="checkbox" [checked]="checkPostizoSuperiorTornear" [disabled]="true"></td>
                <td><input type="checkbox" [checked]="checkPostizoSuperiorNuevo" [disabled]="true"></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td class="text-left">{{'hojaImpresion.postizo1OP' | translate}}</td>
                <td><input type="checkbox" [checked]="checkPostizo1OPOk" [disabled]="true"></td>
                <td><input type="checkbox" [checked]="checkPostizo1OPNoOk" [disabled]="true"></td>
                <td><input type="checkbox" [checked]="checkPostizo1OPEsmerilar" [disabled]="true"></td>
                <td><input type="checkbox" [checked]="checkPostizo1OPTornear" [disabled]="true"></td>
                <td><input type="checkbox" [checked]="checkPostizo1OPNuevo" [disabled]="true"></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td class="text-left">{{'hojaImpresion.postizo2OP' | translate}}</td>
                <td><input type="checkbox" [checked]="checkPostizo2OPOk" [disabled]="true"></td>
                <td><input type="checkbox" [checked]="checkPostizo2OPNoOk" [disabled]="true"></td>
                <td><input type="checkbox" [checked]="checkPostizo2OPEsmerilar" [disabled]="true"></td>
                <td><input type="checkbox" [checked]="checkPostizo2OPTornear" [disabled]="true"></td>
                <td><input type="checkbox" [checked]="checkPostizo2OPNuevo" [disabled]="true"></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td class="text-left">{{'hojaImpresion.matrizSuperior' | translate}}</td>
                <td><input type="checkbox" [checked]="checkMatrizSuperiorOk" [disabled]="true"></td>
                <td><input type="checkbox" [checked]="checkMatrizSuperiorNoOk" [disabled]="true"></td>
                <td><input type="checkbox" [checked]="checkMatrizSuperiorEsmerilar" [disabled]="true"></td>
                <td><input type="checkbox" [checked]="checkMatrizSuperiorTornear" [disabled]="true"></td>
                <td><input type="checkbox" [checked]="checkMatrizSuperiorNuevo" [disabled]="true"></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td class="text-left">{{'hojaImpresion.matrizInferior' | translate}}</td>
                <td><input type="checkbox" [checked]="checkMatrizInferiorOk" [disabled]="true"></td>
                <td><input type="checkbox" [checked]="checkMatrizInferiorNoOk" [disabled]="true"></td>
                <td><input type="checkbox" [checked]="checkMatrizInferiorEsmerilar" [disabled]="true"></td>
                <td><input type="checkbox" [checked]="checkMatrizInferiorTornear" [disabled]="true"></td>
                <td><input type="checkbox" [checked]="checkMatrizInferiorNuevo" [disabled]="true"></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td class="text-left">{{'hojaImpresion.prepunzonadoSuperior' | translate}}</td>
                <td><input type="checkbox" [checked]="checkPrepunzadoSuperiorOk" [disabled]="true"></td>
                <td><input type="checkbox" [checked]="checkPrepunzadoSuperiorNoOk" [disabled]="true"></td>
                <td><input type="checkbox" [checked]="checkPrepunzadoSuperiorEsmerilar" [disabled]="true"></td>
                <td><input type="checkbox" [checked]="checkPrepunzadoSuperiorTornear" [disabled]="true"></td>
                <td><input type="checkbox" [checked]="checkPrepunzadoSuperiorNuevo" [disabled]="true"></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td class="text-left">{{'hojaImpresion.prepunzonado1OP' | translate}}</td>
                <td><input type="checkbox" [checked]="checkPrepunzado1OPOk" [disabled]="true"></td>
                <td><input type="checkbox" [checked]="checkPrepunzado1OPNoOk" [disabled]="true"></td>
                <td><input type="checkbox" [checked]="checkPrepunzado1OPEsmerilar" [disabled]="true"></td>
                <td><input type="checkbox" [checked]="checkPrepunzado1OPTornear" [disabled]="true"></td>
                <td><input type="checkbox" [checked]="checkPrepunzado1OPNuevo" [disabled]="true"></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td class="text-left">{{'hojaImpresion.prepunzonado2OP' | translate}}</td>
                <td><input type="checkbox" [checked]="checkPrepunzado2OPOk" [disabled]="true"></td>
                <td><input type="checkbox" [checked]="checkPrepunzado2OPNoOk" [disabled]="true"></td>
                <td><input type="checkbox" [checked]="checkPrepunzado2OPEsmerilar" [disabled]="true"></td>
                <td><input type="checkbox" [checked]="checkPrepunzado2OPTornear" [disabled]="true"></td>
                <td><input type="checkbox" [checked]="checkPrepunzado2OPNuevo" [disabled]="true"></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </td>
        <td class="vertical"><span>{{datos.codigoPlano}}/{{datos.figura}}</span></td>
      </tr>
    </tbody>
  </table>
</div>

<div class="clearfix">
  <button kendoButton class="btn mr-1  btn-success" (click)="imprimir()">
    {{ 'datosForja.imprimir' | translate}}
  </button>
  <button kendoButton class="btn mr-1  btn-danger" (click)="atras()">
    {{ 'botones.cancelar' | translate}}
  </button>
</div>
