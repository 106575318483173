import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Usuario } from '@app/_models';
import { Idioma } from '@app/_models/idioma';
import { AlertService, MenuService, PlanosService, UsuariosService } from '@app/_services';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';


@Component({ templateUrl: 'home.component.html' })
export class HomeComponent {
    dataUsuarios: Usuario[];
    interval: number;
    selectedIdioma:Idioma;
    selectedNumber:number;
    inputTextBox:string;
    listaUsuarios:Usuario[];
    idiomaSeleccionado:string;

    constructor(private usuariosService: UsuariosService, private alertService: AlertService,
        private menuService: MenuService, public router: Router, private translateService: TranslateService) {    
            
      }
    
      ngOnInit(): void {
        
        this.menuService.titulo = this.translateService.instant('menu.inicio');
        this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
          // TODO This as a workaround.
          this.menuService.titulo = this.translateService.instant('menu.inicio');
        });

        this.router.navigate(['datosForja']);
      }

  
}