<form [formGroup]="form">

  <div class="">
    <div class="">
      <div class="card">
        <div class="card-header">
          <h3>{{ 'datos' | translate}}</h3>

        </div>
        <div class="card-body">
          <div class="clearfix">
            <div class="float-left mr-2">
              <kendo-label text="{{ 'datosForja.codigoPlano' | translate}}">
                <kendo-textbox formControlName="codigoPlano" [disabled]="isCopia" id="codigoPlano"
                               [ngClass]="{ 'is-invalid': submitted && f.codigoPlano.errors }">
                </kendo-textbox>
                <div *ngIf="submitted && f.codigoPlano.errors" class="invalid-feedback">
                  <div *ngIf="f.codigoPlano.errors.required">{{ 'datosForja.codigoPlanoObligatorio' | translate}}</div>
                </div>
              </kendo-label>
            </div>
            <div class="float-left mr-2">
              <kendo-label text="{{ 'datosForja.figura' | translate}}">
                <kendo-textbox formControlName="figura" [disabled]="isCopia" id="figura" [ngClass]="{ 'is-invalid': submitted && f.figura.errors }">
                </kendo-textbox>
                <div *ngIf="submitted && f.figura.errors" class="invalid-feedback">
                  <div *ngIf="f.figura.errors.required">{{ 'datosForja.figuraObligatoria' | translate}}</div>
                </div>
              </kendo-label>
            </div>
            <div class="float-left mr-2">
              <div class="form-group">
                <label>{{ 'datosForja.equivalenciaP10000' | translate }}</label>
                <div class="caja">
                  <kendo-combobox id="equivalenciaP10000" [disabled]="!isAddMode || isCopia" [allowCustom]="true"
                                  style="min-width: 230px"
                                  [ngClass]="{ 'is-invalid': submitted && errorEquivalenciaP10000 }" [data]="dataEquivalenciasP10000"
                                  [(value)]="equivalenciaP10000Seleccionada" [textField]="'nombre'" [valueField]="'id'"
                                  (valueChange)="cambioEquivalenciaP10000($event)" [kendoDropDownFilter]="{operator: 'contains'}">
                    <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                      <span class="k-icon k-i-arrow-s"></span>
                      <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombre}}</ng-container>
                    </ng-template>
                    <kendo-combobox-messages noDataText="{{'grids.noRecords' | translate}}"></kendo-combobox-messages>
                  </kendo-combobox>
                  <div *ngIf="submitted && errorEquivalenciaP10000" class="invalid-feedback">
                    <div *ngIf="errorEquivalenciaP10000">{{ 'datosForja.equivalenciaObligatoria' | translate}}</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="float-left mr-2">
              <div class="form-group">
                <label>{{ 'datosForja.equivalenciaP5000' | translate }}</label>
                <div class="caja">
                  <kendo-combobox id="equivalenciaP50000" [disabled]="!isAddMode || isCopia" [allowCustom]="true"
                                  style="min-width: 230px"
                                  [ngClass]="{ 'is-invalid': submitted && errorEquivalenciaP5000 }" [data]="dataEquivalenciasP5000"
                                  [(value)]="equivalenciaP5000Seleccionada" [textField]="'nombre'" [valueField]="'id'"
                                  (valueChange)="cambioEquivalenciaP5000($event)" [kendoDropDownFilter]="{operator: 'contains'}">
                    <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                      <span class="k-icon k-i-arrow-s"></span>
                      <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombre}}</ng-container>
                    </ng-template>
                    <kendo-combobox-messages noDataText="{{'grids.noRecords' | translate}}"></kendo-combobox-messages>
                  </kendo-combobox>
                  <div *ngIf="submitted && errorEquivalenciaP5000" class="invalid-feedback">
                    <div *ngIf="errorEquivalenciaP5000">{{ 'datosForja.equivalenciaObligatoria' | translate}}</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="float-left mr-2">
              <div class="form-group">
                <label>{{ 'datosForja.equivalenciaLaminador' | translate }}</label>
                <div class="caja">
                  <kendo-combobox id="equivalenciaLaminador" [disabled]="!isAddMode || isCopia" [allowCustom]="true"
                                  style="min-width: 230px"
                                  [ngClass]="{ 'is-invalid': submitted && errorEquivalenciaLaminador }" [data]="dataEquivalenciasLaminador"
                                  [(value)]="equivalenciaLaminadorSeleccionada" [textField]="'nombre'" [valueField]="'id'"
                                  (valueChange)="cambioEquivalenciaLaminador($event)" [kendoDropDownFilter]="{operator: 'contains'}">
                    <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                      <span class="k-icon k-i-arrow-s"></span>
                      <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombre}}</ng-container>
                    </ng-template>
                    <kendo-combobox-messages noDataText="{{'grids.noRecords' | translate}}"></kendo-combobox-messages>
                  </kendo-combobox>
                  <div *ngIf="submitted && errorEquivalenciaLaminador" class="invalid-feedback">
                    <div *ngIf="errorEquivalenciaLaminador">{{ 'datosForja.equivalenciaObligatoria' | translate}}</div>
                  </div>
                </div>
              </div>
            </div>


            <div class="float-left mr-2 contendor-textarea-formulario">
              <div class="form-group">
                <label class="check-label">{{ 'datosForja.notas' | translate }}</label>
                <div class="checkbox">
                  <textarea formControlName="notas_general" class="form-control en-formulario"></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>

<div>
  <kendo-tabstrip>
    <kendo-tabstrip-tab title="{{ 'datosForja.geometriaHtap10000' | translate}}" [selected]="true" [ngClass]="{ 'is-invalid': submitted && (geometriaValueH==null || geometriaValueH==undefined) || (geometriaValueC==null || geometriaValueC==undefined) || (geometriaValueD==null || geometriaValueD==undefined)
    || (geometriaValueF==null || geometriaValueF==undefined) || (geometriaValueG==null || geometriaValueG==undefined) ||
    (geometriaValueI==null || geometriaValueI==undefined) || (geometriaValueL==null || geometriaValueL==undefined) || (geometriaValueJ==null || geometriaValueJ==undefined)
    || (geometriaValueA==null || geometriaValueA==undefined) || (geometriaValueB==null || geometriaValueB==undefined) || (geometriaValueE==null || geometriaValueE==undefined)}">
      <ng-template kendoTabContent>
        <div>
          <img [src]="imagenGeometria">
          <div class="caja-geometria" style="top: 428px;left: 548px;" [ngClass]="{ 'is-invalid': submitted && (geometriaValueC==null || geometriaValueC==undefined)}">
            <kendo-label text="{{ 'C'}}">
              <kendo-numerictextbox [min]="0.00" [autoCorrect]="true" [step]="0.1" [decimals]="2" [format]="'n2'"
                                    [(value)]="geometriaValueC">
              </kendo-numerictextbox>
            </kendo-label>
          </div>
          <div class="caja-geometria" [ngClass]="{'geometria-destacada': cambioDelSistema == true, 'is-invalid': submitted && (geometriaValueD==null || geometriaValueD==undefined)}"
               style="top: 395px;left: 548px;">
            <kendo-label text="{{ 'D'}}">
              <kendo-numerictextbox (focusout)="onValueChangeD($event)" [min]="0.00" [autoCorrect]="true" [step]="0.1"
                                    [decimals]="2" [format]="'n2'" [(value)]="geometriaValueD">
              </kendo-numerictextbox>
            </kendo-label>
          </div>
          <div class="caja-geometria" style="top: 456px;left:1165px;" [ngClass]="{ 'is-invalid': submitted && (geometriaValueF==null || geometriaValueF==undefined)}">
            <kendo-label text="{{ 'F'}}">
              <kendo-numerictextbox [min]="0.00" [autoCorrect]="true" [step]="0.1" [decimals]="2" [format]="'n2'"
                                    [(value)]="geometriaValueF">
              </kendo-numerictextbox>
            </kendo-label>
          </div>
          <div class="caja-geometria" style="top: 407px;left:1165px;" [ngClass]="{ 'is-invalid': submitted && (geometriaValueG==null || geometriaValueG==undefined)}">
            <kendo-label text="{{ 'G'}}">
              <kendo-numerictextbox (focusout)="onValueChangeG($event)" [min]="0.00" [autoCorrect]="true" [step]="0.1" [decimals]="2" [format]="'n2'"
                                    [(value)]="geometriaValueG">
              </kendo-numerictextbox>
            </kendo-label>
          </div>
          <div class="caja-geometria" style="top:230px;left:1165px;" [ngClass]="{ 'is-invalid': submitted && (geometriaValueI==null || geometriaValueI==undefined)}">
            <kendo-label text="{{ 'I'}}">
              <kendo-numerictextbox [min]="0.00" [autoCorrect]="true" [step]="0.1" [decimals]="2" [format]="'n2'"
                                    [(value)]="geometriaValueI">
              </kendo-numerictextbox>
            </kendo-label>
          </div>
          <div class="caja-geometria" style="top:288px;left:1165px;" [ngClass]="{ 'is-invalid': submitted && (geometriaValueL==null || geometriaValueL==undefined)}">
            <kendo-label text="{{ 'L'}}">
              <kendo-numerictextbox [min]="0.00" [autoCorrect]="true" [step]="0.1" [decimals]="2" [format]="'n2'"
                                    [(value)]="geometriaValueL">
              </kendo-numerictextbox>
            </kendo-label>
          </div>
          <div class="caja-geometria" style="top:330px;left:1165px;" [ngClass]="{ 'is-invalid': submitted && (geometriaValueJ==null || geometriaValueJ==undefined)}">
            <kendo-label text="{{ 'J'}}">
              <kendo-numerictextbox [min]="0.00" [autoCorrect]="true" [step]="0.1" [decimals]="2" [format]="'n2'"
                                    [(value)]="geometriaValueJ">
              </kendo-numerictextbox>
            </kendo-label>
          </div>
          <div class="caja-geometria" style="top: 522px;left: 548px;" [ngClass]="{ 'is-invalid': submitted && (geometriaValueA==null || geometriaValueA==undefined)}">
            <kendo-label text="{{'A'}}">
              <kendo-numerictextbox [min]="0.00" [autoCorrect]="true" [step]="0.1" [decimals]="2" [format]="'n2'"
                                    [readonly]="true" [(value)]="geometriaValueA">
              </kendo-numerictextbox>
            </kendo-label>
          </div>
          <div class="caja-geometria" style="top: 460px;left: 548px;" [ngClass]="{ 'is-invalid': submitted && (geometriaValueB==null || geometriaValueB==undefined)}">
            <kendo-label text="{{ 'B'}}">
              <kendo-numerictextbox [min]="0.00" [autoCorrect]="true" [step]="0.1" [decimals]="2" [format]="'n2'"
                                    [readonly]="true" [(value)]="geometriaValueB">
              </kendo-numerictextbox>
            </kendo-label>
          </div>
          <div class="caja-geometria" style="top: 363px;left: 548px;" *ngIf="cambioDelSistema">
            <kendo-label text="{{'D'}}">
              <kendo-numerictextbox [min]="0.00" [autoCorrect]="true" [step]="0.1" [decimals]="2" [format]="'n2'"
                                    [readonly]="true" [value]="geometriaValueDVieja">
              </kendo-numerictextbox>
            </kendo-label>
          </div>
          <div class="caja-geometria" style="top:522px;left:1165px;" [ngClass]="{ 'is-invalid': submitted && (geometriaValueE==null || geometriaValueE==undefined)}">
            <kendo-label text="{{ 'E'}}">
              <kendo-numerictextbox [min]="0.00" [autoCorrect]="true" [step]="0.1" [decimals]="2" [format]="'n2'"
                                    [readonly]="true" [(value)]="geometriaValueE">
              </kendo-numerictextbox>
            </kendo-label>
          </div>

          <div class="caja-geometria" style="top:136px;left:1165px;" [ngClass]="{ 'is-invalid': submitted && (geometriaValueH==null || geometriaValueH==undefined)}">
            <kendo-label text="{{ 'H'}}">
              <kendo-numerictextbox [min]="0.00" [autoCorrect]="true" [step]="0.1" [decimals]="2" [format]="'n2'"
                                    [readonly]="true" [(value)]="geometriaValueH">
              </kendo-numerictextbox>
            </kendo-label>
          </div>
        </div>
      </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab title="{{ 'datosForja.p10000' | translate}}">
      <ng-template kendoTabContent>
        <!-- P10000 -->
        <div class="row">
          <div class="col-lg-2 col-12">
            <div class="card">
              <div class="card-header">
                <h3>{{ 'datosForja.htaSuperior' | translate}}</h3>

              </div>
              <div class="card-body">
                <div class="caja-dentro-tab">
                  <div class="izq">
                    <kendo-label text="{{ 'datosForja.h15a' | translate}}">
                      <span><i class="fas fa-image"
                           (click)="abrirModalImagen('imagenH15aMaestra')"></i></span>
                      <span><i class="fas fa-file-pdf"
                           (click)="abrirPDF('pdfH15aMaestra')"></i></span>
                      <div class="caja">
                        <kendo-dropdownlist [data]="dataH15AMaestro" [(value)]="h15aMaestroSelected"
                                            [textField]="'nombre'" [valueField]="'id'" [ngClass]="{ 'is-invalid': submitted && (h15aMaestroSelected==null 
                          || h15aMaestroSelected == undefined || h15aMaestroSelected.id == -1)}">
                        </kendo-dropdownlist>
                      </div>

                      <label class="descripcionInput">{{'datosForja.placaSuperior' | translate}}</label>
                    </kendo-label>
                  </div>
                  <div class="dcha">
                    <img [src]="imagenH15aMaestra" style="max-width:115px">
                  </div>
                </div>
                <div class="caja-dentro-tab">
                  <div class="izq">
                    <kendo-label text="{{ 'datosForja.h20a' | translate}}">
                      <span><i class="fas fa-image" (click)="abrirModalImagen('imagenH20a')"></i></span>
                      <span><i class="fas fa-file-pdf"
                           (click)="abrirPDF('pdfH20a')"></i></span>
                      <div class="caja">
                        <kendo-textbox [(value)]="htaSupH20a" [ngClass]="{ 'is-invalid': submitted && (htaSupH20a==null 
                        || htaSupH20a==undefined || htaSupH20a=='')}">
                        </kendo-textbox>
                      </div>
                      <label class="descripcionInput">{{'datosForja.postizoSuperior' | translate}}</label>
                    </kendo-label>
                  </div>
                  <div class="dcha">
                    <img [src]="imagenH20a" style="max-width:115px">
                  </div>
                </div>
                <div class="caja-dentro-tab">
                  <div class="izq">
                    <kendo-label text="{{ 'datosForja.h22a' | translate}}">
                      <span><i class="fas fa-image" (click)="abrirModalImagen('imagenH22a')"></i></span>
                      <span><i class="fas fa-file-pdf"
                           (click)="abrirPDF('pdfH22a')"></i></span>
                      <div class="caja">
                        <kendo-textbox [(value)]="htaSupH22a" [ngClass]="{ 'is-invalid': submitted && (htaSupH22a==null 
                        || htaSupH22a==undefined || htaSupH22a=='')}">
                        </kendo-textbox>
                      </div>
                      <label class="descripcionInput">{{'datosForja.casquilloGuiaSuperior' | translate}}</label>
                    </kendo-label>
                  </div>
                  <div class="dcha">
                    <img [src]="imagenH22a" style="max-width:115px">
                  </div>
                </div>
                <div class="caja-dentro-tab">
                  <div class="izq">
                    <kendo-label text="{{ 'datosForja.h51a' | translate}}">
                      <span><i class="fas fa-image" (click)="abrirModalImagen('imagenH51a')"></i></span>
                      <span><i class="fas fa-file-pdf"
                           (click)="abrirPDF('pdfH51a')"></i></span>
                      <div class="clearfix">
                        <div class="caja">
                          <kendo-textbox [(value)]="htaSupH51a1" [ngClass]="{ 'is-invalid': submitted && (htaSupH51a1==null 
                          || htaSupH51a1==undefined || htaSupH51a1=='' )}">
                          </kendo-textbox>
                        </div>
                        <label class="descripcionInput">{{'datosForja.aroForja' | translate}}</label>
                      </div>
                      <div class="clearfix">
                        <div class="caja">
                          <kendo-textbox [(value)]="htaSupH51a2" [ngClass]="{ 'is-invalid': submitted && (htaSupH51a2==null 
                          || htaSupH51a2==undefined || htaSupH51a2=='' )}">
                          </kendo-textbox>
                        </div>
                        <label class="descripcionInput">{{'datosForja.medidasAro' | translate}}</label>
                      </div>
                    </kendo-label>
                  </div>
                  <div class="dcha">
                    <img [src]="imagenH51a" style="max-width:115px">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-2 col-12">
            <div class="card">
              <div class="card-header">
                <h3>{{ 'datosForja.primeraOP' | translate}}</h3>
              </div>
              <div class="card-body">
                <div class="caja-dentro-tab">
                  <div class="izq">
                    <kendo-label text="{{ 'datosForja.h30a' | translate}}">
                      <span><i class="fas fa-image"
                           (click)="abrirModalImagen('imagenH30aMaestro1')"></i></span>
                      <span><i class="fas fa-file-pdf"
                           (click)="abrirPDF('pdfH30aMaestro1')"></i></span>
                      <div class="caja">
                        <kendo-dropdownlist [data]="dataH30AMaestro" [(value)]="h30aMaestroSelected1"
                                            [textField]="'nombre'" [valueField]="'id'"
                                            [ngClass]="{ 'is-invalid': submitted && (h30aMaestroSelected1==null 
                          || h30aMaestroSelected1 == undefined || h30aMaestroSelected1.id == -1)}">
                        </kendo-dropdownlist>
                      </div>
                      <label class="descripcionInput">{{'datosForja.placaInferior' | translate}}</label>
                    </kendo-label>
                  </div>
                  <div class="dcha">
                    <img [src]="imagenH30aMaestro1" style="max-width:115px">
                  </div>
                </div>
                <div class="caja-dentro-tab">
                  <div class="izq">
                    <kendo-label text="{{ 'datosForja.h39a' | translate}}">
                      <span><i class="fas fa-image" (click)="abrirModalImagen('imagenH39a')"></i></span>
                      <span><i class="fas fa-file-pdf"
                           (click)="abrirPDF('pdfH39a')"></i></span>
                      <div class="caja">
                        <kendo-textbox [(value)]="htaInfH39a" [ngClass]="{ 'is-invalid': submitted && (htaInfH39a==null 
                          || htaInfH39a==undefined || htaInfH39a=='' )}">
                        </kendo-textbox>
                      </div>
                      <label class="descripcionInput">{{'datosForja.postizoInferior1aOP' | translate}}</label>
                    </kendo-label>
                  </div>
                  <div class="dcha">
                    <img [src]="imagenH39a" style="max-width:115px">
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header">
                <h3>{{ 'datosForja.segundaOP' | translate}}</h3>
              </div>
              <div class="card-body">
                <div class="caja-dentro-tab">
                  <div class="izq">
                    <kendo-label text="{{ 'datosForja.h30a' | translate}}">
                      <span><i class="fas fa-image"
                           (click)="abrirModalImagen('imagenH30aMaestro2')"></i></span>
                      <span><i class="fas fa-file-pdf"
                           (click)="abrirPDF('pdfH30aMaestro2')"></i></span>
                      <div class="caja">
                        <div class="caja">
                          <kendo-dropdownlist [data]="dataH30AMaestro" [(value)]="h30aMaestroSelected2"
                                              [textField]="'nombre'" [valueField]="'id'"
                                              [ngClass]="{ 'is-invalid': submitted && (h30aMaestroSelected2==null 
                                                || h30aMaestroSelected2 == undefined || h30aMaestroSelected2.id == -1)}">
                          </kendo-dropdownlist>
                        </div>
                      </div>
                      <label class="descripcionInput">{{'datosForja.placaInferior' | translate}}</label>
                    </kendo-label>
                  </div>
                  <div class="dcha">
                    <img [src]="imagenH30aMaestro2" style="max-width:115px">
                  </div>
                </div>
                <div class="caja-dentro-tab">
                  <div class="izq">
                    <kendo-label text="{{ 'datosForja.h40a' | translate}}">
                      <span><i class="fas fa-image" (click)="abrirModalImagen('imagenH40a')"></i></span>
                      <span><i class="fas fa-file-pdf"
                           (click)="abrirPDF('pdfH40a')"></i></span>
                      <div class="caja">
                        <kendo-textbox [(value)]="htaInfH40a" [ngClass]="{ 'is-invalid': submitted && (htaInfH40a==null 
                          || htaInfH40a==undefined || htaInfH40a=='' )}">
                        </kendo-textbox>
                      </div>
                      <label class="descripcionInput">{{'datosForja.postizoInferior2aOP' | translate}}</label>
                    </kendo-label>
                  </div>
                  <div class="dcha">
                    <img [src]="imagenH40a" style="max-width:115px">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-12">
            <div class="card">
              <div class="card-header">
                <h3>{{ 'datosForja.piecerioHerramientaP10000' | translate}}</h3>

              </div>
              <div class="card-body">
                <h4 class="subtitulo">{{'datosForja.inferior' | translate}}</h4>
                <div class="clearfix">
                  <div class="clearfix">
                    <div class="caja-dentro-tab">
                      <div class="izq">
                        <kendo-label text="{{ 'datosForja.prepunzonadoInferior1aOP' | translate}}">
                          <span><i class="fas fa-image"
                               (click)="abrirModalImagen('imagenP10000PrepunzonadoInferior1OP')"></i></span>
                          <span><i class="fas fa-file-pdf"
                               (click)="abrirPDF('pdfP10000PrepunzonadoInferior1OP')"></i></span>
                          <div class="caja">
                            <div class="clearfix">
                              <div class="cajaNumero">
                                <kendo-numerictextbox [min]="0" [autoCorrect]="true" [step]="0.1"
                                                      [decimals]="2" [format]="'n'" [(value)]="piecerioHerramientaPrepunzado1aOP1"
                                                      [ngClass]="{ 'is-invalid': submitted && (piecerioHerramientaPrepunzado1aOP1==null 
                                  || piecerioHerramientaPrepunzado1aOP1==undefined )}">
                                </kendo-numerictextbox>
                              </div>
                              <div class="cajaX">x</div>
                              <div class="cajaNumero">
                                <kendo-numerictextbox [min]="0" [autoCorrect]="true" [step]="0.1"
                                                      [decimals]="2" [format]="'n'" [(value)]="piecerioHerramientaPrepunzado1aOP2"
                                                      [ngClass]="{ 'is-invalid': submitted && (piecerioHerramientaPrepunzado1aOP2==null 
                                  || piecerioHerramientaPrepunzado1aOP2==undefined )}">
                                </kendo-numerictextbox>
                              </div>
                            </div>
                            <div>
                              <kendo-textbox [(value)]="piecerioHerramientaPrepunzado1aOP3">
                              </kendo-textbox>
                            </div>
                          </div>
                          <label class="descripcionInput">{{'datosForja.medidasDiametroxAltura' | translate}}</label>
                        </kendo-label>
                      </div>
                      <div class="dcha">
                        <img [src]="imagenP10000PrepunzonadoInferior1OP" style="max-width:115px">
                      </div>
                    </div>
                    <div class="caja-dentro-tab">
                      <div class="izq">
                        <kendo-label text="{{ 'datosForja.subextractor' | translate}}">
                          <span><i class="fas fa-image"
                               (click)="abrirModalImagen('imagenP10000Subextractor')"></i></span>
                          <span><i class="fas fa-file-pdf"
                               (click)="abrirPDF('pdfP10000Subextractor')"></i></span>
                          <div class="caja">
                            <kendo-dropdownlist [data]="datosSubextractor10000"
                                                [(value)]="piecerioHerramientaSubextractorSelected" [textField]="'nombre'"
                                                [ngClass]="{ 'is-invalid': submitted && (piecerioHerramientaSubextractorSelected==null 
                                  || piecerioHerramientaSubextractorSelected==undefined ||
                                  piecerioHerramientaSubextractorSelected.id==-1 )}"
                                                [valueField]="'id'">
                            </kendo-dropdownlist>
                          </div>
                          <label class="descripcionInput">{{'datosForja.figura' | translate}}</label>
                        </kendo-label>
                      </div>
                      <div class="dcha">
                        <img [src]="imagenP10000Subextractor" style="max-width:115px">
                      </div>
                    </div>
                    <div class="caja-dentro-tab">
                      <div class="izq">
                        <kendo-label text="{{ 'datosForja.basePrepunzonadoInferior' | translate}}">
                          <span><i class="fas fa-image"
                               (click)="abrirModalImagen('imagenP10000BasePrepunzonadoInferior')"></i></span>
                          <span><i class="fas fa-file-pdf"
                               (click)="abrirPDF('pdfP10000BasePrepunzonadoInferior')"></i></span>
                          <div class="caja">
                            <kendo-dropdownlist [data]="datosBasePrepunzonado"
                                                [(value)]="piecerioHerramientaBasePrepunzonadoSelected" [textField]="'nombre'"
                                                [valueField]="'id'"
                                                [ngClass]="{ 'is-invalid': submitted && (piecerioHerramientaBasePrepunzonadoSelected==null 
                                  || piecerioHerramientaBasePrepunzonadoSelected==undefined ||
                                  piecerioHerramientaBasePrepunzonadoSelected.id==-1 )}">
                            </kendo-dropdownlist>
                          </div>
                          <label class="descripcionInput">{{'datosForja.figura' | translate}}</label>
                        </kendo-label>
                      </div>
                      <div class="dcha">
                        <img [src]="imagenP10000BasePrepunzonadoInferior" style="max-width:115px">
                      </div>
                    </div>
                  </div>
                  <div class="clearfix">
                    <div class="caja-dentro-tab">
                      <div class="izq">
                        <kendo-label text="{{ 'datosForja.prepunzonadoInferior2aOP' | translate}}">
                          <span><i class="fas fa-image"
                               (click)="abrirModalImagen('imagenP10000PrepunzonadoInferior2OP')"></i></span>
                          <span><i class="fas fa-file-pdf"
                               (click)="abrirPDF('pdfP10000PrepunzonadoInferior2OP')"></i></span>
                          <div class="caja grupodetres">
                            <div class="clearfix">

                              <div class="cajaNumero">
                                <kendo-combobox [allowCustom]="true" class="sin-boton-vaciar"
                                                style="width: 100%" clearButton="false"
                                                [ngClass]="{ 'is-invalid': submitted && (piecerioHerramientaPrepunzado2aOP1==null 
                                  || piecerioHerramientaPrepunzado2aOP1==undefined )}" [data]="dataMandrino5000"
                                                [(value)]="piecerioHerramientaPrepunzado2aOP1" [textField]="'nombre'" [valueField]="'id'"
                                                (valueChange)="cambiopiecerioHerramientaPrepunzado2aOP1($event)" [kendoDropDownFilter]="{operator: 'contains'}">
                                  <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                                    <span class="k-icon k-i-arrow-s"></span>
                                    <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombre}}</ng-container>
                                  </ng-template>
                                  <kendo-combobox-messages noDataText="{{'grids.noRecords' | translate}}"></kendo-combobox-messages>
                                </kendo-combobox>
                              </div>
                              <div class="cajaX">x</div>
                              <div class="cajaNumero">
                                <kendo-numerictextbox [min]="0" [autoCorrect]="true" [step]="0.1"
                                                      [decimals]="2" [format]="'n'" [(value)]="piecerioHerramientaPrepunzado2aOP2"
                                                      [ngClass]="{ 'is-invalid': submitted && (piecerioHerramientaPrepunzado2aOP2==null 
                                  || piecerioHerramientaPrepunzado2aOP2==undefined )}">
                                </kendo-numerictextbox>
                              </div>

                              <div class="cajaX">x</div>

                              <div class="cajaNumero">
                                <kendo-numerictextbox [min]="0" [autoCorrect]="true" [step]="0.1"
                                                      [decimals]="2" [format]="'n'" [(value)]="piecerioHerramientaPrepunzado2aOP3"
                                                      [ngClass]="{ 'is-invalid': submitted && (piecerioHerramientaPrepunzado2aOP3==null 
                                  || piecerioHerramientaPrepunzado2aOP3==undefined )}">
                                </kendo-numerictextbox>
                              </div>
                            </div>
                            <div>
                              <kendo-textbox [(value)]="piecerioHerramientaPrepunzado2aOP4">
                              </kendo-textbox>
                            </div>
                          </div>
                          <label class="descripcionInput">{{'datosForja.medidasDiametroLL1' | translate}}</label>
                        </kendo-label>
                      </div>
                      <div class="dcha">
                        <img [src]="imagenP10000PrepunzonadoInferior2OP" style="max-width:115px">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-12">
            <div class="card">
              <div class="card-header">
              </div>
              <div class="card-body">
                <h4 class="subtitulo">{{'datosForja.inferior' | translate}}</h4>
                <div class="clearfix">
                  <div class="clearfix">
                    <div class="caja-dentro-tab">
                      <div class="izq">
                        <kendo-label text="{{ 'datosForja.arandela' | translate}}">
                          <span><i class="fas fa-image"
                               (click)="abrirModalImagen('imagenP10000Arandela')"></i></span>
                          <span><i class="fas fa-file-pdf"
                               (click)="abrirPDF('pdfP10000Arandela')"></i></span>
                          <div class="caja grupodetres">
                            <div class="clearfix">

                              <div class="cajaNumero">
                                <kendo-numerictextbox [min]="0.0" [max]="999.0" [autoCorrect]="true" [step]="0.1" [decimals]="2"
                                                      [format]="'n2'" [(value)]="piecerioHerramientaArandela1"
                                                      [ngClass]="{ 'is-invalid': submitted && (piecerioHerramientaArandela1==null 
                                  || piecerioHerramientaArandela1==undefined )}">
                                </kendo-numerictextbox>
                              </div>

                              <div class="cajaX">x</div>



                              <div class="cajaNumero">
                                <kendo-combobox [allowCustom]="true" class="sin-boton-vaciar"
                                                style="width: 100%" clearButton="false"
                                                [ngClass]="{ 'is-invalid': submitted && (piecerioHerramientaArandela2==null 
                                || piecerioHerramientaArandela2==undefined )}" [data]="dataMandrino5000"
                                                [(value)]="piecerioHerramientaArandela2" [textField]="'nombre'" [valueField]="'id'"
                                                (valueChange)="cambiopiecerioHerramientaArandela2($event)" [kendoDropDownFilter]="{operator: 'contains'}">
                                  <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                                    <span class="k-icon k-i-arrow-s"></span>
                                    <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombre}}</ng-container>
                                  </ng-template>
                                  <kendo-combobox-messages noDataText="{{'grids.noRecords' | translate}}"></kendo-combobox-messages>
                                </kendo-combobox>
                              </div>

                              <div class="cajaX">x</div>
                              <div class="cajaNumero">
                                <kendo-numerictextbox [min]="0" [autoCorrect]="true" [step]="0.1"
                                                      [format]="'n'" [(value)]="piecerioHerramientaArandela3"
                                                      [ngClass]="{ 'is-invalid': submitted && (piecerioHerramientaArandela3==null 
                                  || piecerioHerramientaArandela3==undefined )}">
                                </kendo-numerictextbox>
                              </div>
                            </div>
                            <div>
                              <kendo-textbox [(value)]="piecerioHerramientaArandelaResultado">
                              </kendo-textbox>
                            </div>
                          </div>
                          <label class="descripcionInput">{{'datosForja.diametroExteriorDiametroInterior' | translate}}</label>
                        </kendo-label>
                      </div>
                      <div class="dcha">
                        <img [src]="imagenP10000Arandela" style="max-width:115px">
                      </div>
                    </div>
                  </div>
                </div>
                <h4 class="subtitulo">{{'datosForja.htaSuperior' | translate}}</h4>
                <div class="clearfix">
                  <div class="clearfix">
                    <div class="caja-dentro-tab">
                      <div class="izq">
                        <kendo-label text="{{ 'datosForja.prepunzonadoSuperior' | translate}}">
                          <span><i class="fas fa-image"
                               (click)="abrirModalImagen('imagenP10000PrepunzonadoSuperior')"></i></span>
                          <span><i class="fas fa-file-pdf"
                               (click)="abrirPDF('pdfP10000PrepunzonadoSuperior')"></i></span>
                          <div class="caja grupodetres">
                            <div class="clearfix">
                              <div class="cajaNumero">
                                <kendo-combobox [allowCustom]="true" class="sin-boton-vaciar"
                                                style="width: 100%" clearButton="false"
                                                [ngClass]="{ 'is-invalid': submitted && (piecerioHerramientaPrepunzadoSuperior1==null 
                                  || piecerioHerramientaPrepunzadoSuperior1==undefined )}" [data]="dataPrepunzonadoSuperior"
                                                [(value)]="piecerioHerramientaPrepunzadoSuperior1" [textField]="'nombre'" [valueField]="'id'"
                                                (valueChange)="cambiopiecerioHerramientaPrepunzadoSuperior1($event)" [kendoDropDownFilter]="{operator: 'contains'}">
                                  <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                                    <span class="k-icon k-i-arrow-s"></span>
                                    <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombre}}</ng-container>
                                  </ng-template>
                                  <kendo-combobox-messages noDataText="{{'grids.noRecords' | translate}}"></kendo-combobox-messages>
                                </kendo-combobox>
                              </div>
                              <div class="cajaX">x</div>
                              <div class="cajaNumero">
                                <kendo-numerictextbox [min]="0" [autoCorrect]="true" [step]="0.1"
                                                      [decimals]="2" [format]="'n'" [(value)]="piecerioHerramientaPrepunzadoSuperior2"
                                                      [ngClass]="{ 'is-invalid': submitted && (piecerioHerramientaPrepunzadoSuperior2==null 
                                  || piecerioHerramientaPrepunzadoSuperior2==undefined )}">
                                </kendo-numerictextbox>
                              </div>
                              <div class="cajaX">x</div>
                              <div class="cajaNumero">
                                <kendo-numerictextbox [min]="0" [autoCorrect]="true" [step]="0.1"
                                                      [decimals]="2" [format]="'n'" [(value)]="piecerioHerramientaPrepunzadoSuperior3"
                                                      [ngClass]="{ 'is-invalid': submitted && (piecerioHerramientaPrepunzadoSuperior3==null 
                                  || piecerioHerramientaPrepunzadoSuperior3==undefined )}">
                                </kendo-numerictextbox>
                              </div>
                            </div>
                            <div>
                              <kendo-textbox [(value)]="piecerioHerramientaPrepunzadoSuperior4">
                              </kendo-textbox>
                            </div>
                          </div>
                          <label class="descripcionInput">{{'datosForja.medidasDiametroLL1' | translate}}</label>
                        </kendo-label>
                      </div>
                      <div class="dcha">
                        <img [src]="imagenP10000PrepunzonadoSuperior" style="max-width:115px">
                      </div>
                    </div>
                  </div>
                  <div class="clearfix">
                    <div class="caja-dentro-tab">
                      <div class="izq">
                        <kendo-label text="{{ 'datosForja.mandrinoMasAdaptadorMandrino' | translate}}">
                          <span><i class="fas fa-image"
                               (click)="abrirModalImagen('imagenP10000Mandrino')"></i></span>
                          <span><i class="fas fa-file-pdf"
                               (click)="abrirPDF('pdfP10000Mandrino')"></i></span>
                          <div class="caja">
                            <kendo-dropdownlist [data]="dataMandrino10000" [(value)]="mandrino10000Selected"
                                                [textField]="'nombre'" [valueField]="'id'"
                                                [ngClass]="{ 'is-invalid': submitted && (mandrino10000Selected==null 
                                  || mandrino10000Selected==undefined || mandrino10000Selected.id==-1 )}">
                            </kendo-dropdownlist>
                          </div>
                        </kendo-label>
                      </div>
                      <div class="dcha">
                        <img [src]="imagenP10000Mandrino" style="max-width:115px">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-2 col-12">
            <div class="card">
              <!--NOTAS-->
              <div class="card-body">
                <div class="caja-dentro-tab">
                  <div class="form-group">
                    <label class="check-label">{{ 'datosForja.notas' | translate }}</label>
                    <div class="checkbox">
                      <textarea [(ngModel)]="notas_p10000" class="form-control" style="height: 370px;"></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </kendo-tabstrip-tab>

    <kendo-tabstrip-tab title="{{ 'datosForja.p5000' | translate}}">
      <ng-template kendoTabContent>
        <!-- P5000 -->
        <div class="row">
          <div class="col-lg-4 col-12">

            <div class="card">
              <div class="card-header">
                <h3>{{ 'datosForja.htaP5000' | translate}}</h3>

              </div>
              <div class="card-body">
                <div class="caja-dentro-tab">
                  <div class="izq">
                    <kendo-label text="{{ 'datosForja.h60h61' | translate}}">
                      <span><i class="fas fa-image"
                           (click)="abrirModalImagen('imagenp5000h60h61')"></i></span>
                      <span><i class="fas fa-file-pdf"
                           (click)="abrirPDF('pdfp5000h60h61')"></i></span>
                      <div class="caja">
                        <kendo-textbox [(value)]="h60h61Value" [ngClass]="{ 'is-invalid': submitted && (h60h61Value==null 
                          || h60h61Value==undefined || h60h61Value =='')}">
                        </kendo-textbox>
                      </div>
                      <label class="descripcionInput">{{'datosForja.matrizSuperior' | translate}}</label>
                    </kendo-label>
                  </div>
                  <div class="dcha">
                    <img [src]="imagenp5000h60h61" style="max-width:115px">
                  </div>
                </div>
                <div class="caja-dentro-tab">
                  <div class="izq">
                    <kendo-label text="{{ 'datosForja.h70h71' | translate}}">
                      <span><i class="fas fa-image"
                           (click)="abrirModalImagen('imagenp5000h70h71')"></i></span>
                      <span><i class="fas fa-file-pdf"
                           (click)="abrirPDF('pdfp5000h70h71')"></i></span>
                      <div class="clearfix">
                        <div class="caja">
                          <kendo-textbox [(value)]="h70h71Value1" [ngClass]="{ 'is-invalid': submitted && (h70h71Value1==null 
                            || h70h71Value1==undefined || h70h71Value1 =='')}">
                          </kendo-textbox>
                        </div>
                        <label class="descripcionInput">{{'datosForja.matrizInferior' | translate}}</label>
                      </div>
                      <div class="clearfix">
                        <div class="caja">
                          <kendo-textbox [(value)]="h70h71Value2" [ngClass]="{ 'is-invalid': submitted && (h70h71Value2==null 
                            || h70h71Value2==undefined || h70h71Value2 =='')}">
                          </kendo-textbox>
                        </div>
                        <label class="descripcionInput">{{'datosForja.distanciaCubo' | translate}}</label>
                      </div>
                    </kendo-label>
                  </div>
                  <div class="dcha">
                    <img [src]="imagenp5000h70h71" style="max-width:115px">
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-12">

            <div class="card">
              <div class="card-header">
                <h3>{{ 'datosForja.pieceriohta5000' | translate}}</h3>

              </div>
              <div class="card-body">
                <div class="caja-dentro-tab">
                  <div class="izq">
                    <kendo-label text="{{ 'datosForja.subextractorExtractor' | translate}}">
                      <span><i class="fas fa-image"
                           (click)="abrirModalImagen('imagenp5000subextractor')"></i></span>
                      <span><i class="fas fa-file-pdf"
                           (click)="abrirPDF('pdfp5000subextractor')"></i></span>
                      <div class="caja">
                        <div class="clearfix">
                          <div class="caja-mediana">
                            <kendo-dropdownlist [data]="datosSubextractor5000" [(value)]="subextractor5000Selected"
                                                [textField]="'nombre'" [valueField]="'id'"
                                                [ngClass]="{ 'is-invalid': submitted && (subextractor5000Selected==null 
                            || subextractor5000Selected==undefined || subextractor5000Selected.id ==-1)}">
                            </kendo-dropdownlist>
                          </div>
                          <div class="caja-mediana">
                            <kendo-textbox [(value)]="subextractor5000Selected2"
                                           [ngClass]="{ 'is-invalid': submitted && (subextractor5000Selected2==null 
                                           || subextractor5000Selected2==undefined || subextractor5000Selected2 == '')}">
                            </kendo-textbox>
                          </div>
                        </div>
                      </div>
                      <label class="descripcionInput">{{'datosForja.diametroLongitud' | translate}}</label>
                    </kendo-label>
                  </div>
                  <div class="dcha">
                    <img [src]="imagenp5000subextractor" style="max-width:115px">
                  </div>
                </div>
                <div class="caja-dentro-tab">
                  <div class="izq">
                    <kendo-label text="{{ 'datosForja.arandela' | translate}}">
                      <span><i class="fas fa-image"
                           (click)="abrirModalImagen('imagenp5000Arandela')"></i></span>
                      <span><i class="fas fa-file-pdf"
                           (click)="abrirPDF('pdfp5000Arandela')"></i></span>
                      <div class="caja grupodetres">
                        <div class="clearfix">

                          <div class="cajaNumero">
                            <kendo-numerictextbox [min]="0.0" [max]="999.0" [autoCorrect]="true" [step]="0.1" [decimals]="2"
                                                  [format]="'n2'" [(value)]="arandelap50001"
                                                  [ngClass]="{ 'is-invalid': submitted && (arandelap50001==null 
                            || arandelap50001==undefined)}">
                            </kendo-numerictextbox>
                          </div>

                          <div class="cajaX">x</div>



                          <div class="cajaNumero">
                            <kendo-combobox [allowCustom]="true" class="sin-boton-vaciar"
                                            style="width: 100%" clearButton="false"
                                            [ngClass]="{ 'is-invalid': submitted && (arandelap50002==null 
                                || arandelap50002==undefined)}" [data]="dataMandrino5000"
                                            [(value)]="arandelap50002" [textField]="'nombre'" [valueField]="'id'"
                                            (valueChange)="cambioArandelaP5000($event)" [kendoDropDownFilter]="{operator: 'contains'}">
                              <ng-template kendoMultiSelectGroupTagTemplate let-dataItems>
                                <span class="k-icon k-i-arrow-s"></span>
                                <ng-container *ngIf="dataItems.length == 1">{{ dataItems[0].nombre}}</ng-container>
                              </ng-template>
                              <kendo-combobox-messages noDataText="{{'grids.noRecords' | translate}}"></kendo-combobox-messages>
                            </kendo-combobox>
                            <!--  <kendo-numerictextbox [min]="0"   [autoCorrect]="true" [step]="1"
                              [decimals]="0" [format]="'n'" [(value)]="arandelap50002"
                                [ngClass]="{ 'is-invalid': submitted && (arandelap50002==null 
                            || arandelap50002==undefined)}">
                              </kendo-numerictextbox> -->
                          </div>

                          <div class="cajaX">x</div>
                          <div class="cajaNumero">
                            <kendo-numerictextbox [min]="0" [autoCorrect]="true" [step]="0.1" [decimals]="2"
                                                  [format]="'n'" [(value)]="arandelap50003"
                                                  [ngClass]="{ 'is-invalid': submitted && (arandelap50003==null 
                            || arandelap50003==undefined)}">
                            </kendo-numerictextbox>
                          </div>
                        </div>
                        <div>
                          <kendo-textbox [(value)]="arandelap50004">
                          </kendo-textbox>
                        </div>
                      </div>
                      <label class="descripcionInput">{{'datosForja.diametroExteriorDiametroInterior' |
                        translate}}</label>
                    </kendo-label>
                  </div>
                  <div class="dcha">
                    <img [src]="imagenp5000Arandela" style="max-width:115px">
                  </div>
                </div>
                <div class="caja-dentro-tab">
                  <div class="izq">
                    <kendo-label text="{{ 'datosForja.mandrinoMasBulonCorte' | translate}}">
                      <span><i class="fas fa-image"
                           (click)="abrirModalImagen('imagenP5000Mandrino')"></i></span>
                      <span><i class="fas fa-file-pdf"
                           (click)="abrirPDF('pdfimagenP5000Mandrino')"></i></span>
                      <div class="caja">
                        <kendo-dropdownlist [data]="dataMandrino5000" [(value)]="mandrino5000Selected"
                                            [textField]="'nombre'" [valueField]="'id'"
                                            [ngClass]="{ 'is-invalid': submitted && (mandrino5000Selected==null 
                            || mandrino5000Selected==undefined || mandrino5000Selected.id==-1)}">
                        </kendo-dropdownlist>
                      </div>
                      <label class="descripcionInput">{{'datosForja.diametroMM' | translate}}</label>
                    </kendo-label>
                  </div>
                  <div class="dcha">
                    <img [src]="imagenP5000Mandrino" style="max-width:115px">
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-12">
            <div class="card">
              <!--NOTAS-->
              <div class="card-body">
                <div class="caja-dentro-tab">
                  <div class="form-group">
                    <label class="check-label">{{ 'datosForja.notas' | translate }}</label>
                    <div class="checkbox">
                      <textarea [(ngModel)]="notas_p5000" class="form-control" style="height: 370px;"></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab title="{{ 'datosForja.datosLaminador' | translate}}" [ngClass]="{ 'is-invalid': submitted && (porcenVelocidadCebollas1==null 
    || porcenVelocidadCebollas1==undefined ) || (cebollasSelected==null 
    || cebollasSelected==undefined || cebollasSelected.id==-1 ) || (porcenVelocidadCebollas2==null 
    || porcenVelocidadCebollas2==undefined ) || (poleaSelected==null 
    || poleaSelected==undefined || poleaSelected.id==-1 ) || (proyectoPDF == undefined)}">
      <ng-template kendoTabContent>
        <!-- Laminador -->
        <div class="row">
          <div class="col-lg-4 col-12">
            <div class="card">
              <div class="card-header">
                <h3>{{ 'datosForja.htaSuperior' | translate}}</h3>

              </div>
              <div class="card-body">
                <div class="caja-dentro-tab">
                  <div class="izq">
                    <kendo-label text="{{ 'datosForja.cebollas' | translate}}">
                      <span><i class="fas fa-image" (click)="abrirModalImagen('cebollas')"></i></span>
                      <span><i class="fas fa-file-pdf"
                           (click)="abrirPDF('pdfcebollas')"></i></span>
                      <div class="caja">
                        <kendo-dropdownlist [data]="datosCebollas" [(value)]="cebollasSelected" [textField]="'nombre'"
                                            [valueField]="'id'"
                                            [ngClass]="{ 'is-invalid': submitted && (cebollasSelected==null 
                                  || cebollasSelected==undefined || cebollasSelected.id==-1 )}">
                        </kendo-dropdownlist>
                        <kendo-label text="{{ 'datosForja.porcenVelocidadCebollas' | translate}}">
                          <div class="caja">
                            <div class="row">
                              <div class="caja-mediana col">
                                <kendo-numerictextbox [min]="0" [autoCorrect]="true" [step]="0.1" [decimals]="2"
                                                      [format]="'n'" [(value)]="porcenVelocidadCebollas1"
                                                      [ngClass]="{ 'is-invalid': submitted && (porcenVelocidadCebollas1==null 
                                  || porcenVelocidadCebollas1==undefined )}">
                                </kendo-numerictextbox>

                              </div>
                              <div class="caja-mediana col">
                                <kendo-numerictextbox [min]="0" [autoCorrect]="true" [step]="0.1" [decimals]="2"
                                                      [format]="'n'" [(value)]="porcenVelocidadCebollas2"
                                                      [ngClass]="{ 'is-invalid': submitted && (porcenVelocidadCebollas2==null 
                                  || porcenVelocidadCebollas2==undefined )}">
                                </kendo-numerictextbox>
                              </div>
                            </div>
                          </div>
                        </kendo-label>
                      </div>
                    </kendo-label>
                  </div>
                  <div class="dcha">
                    <img [src]="imagenCebollas" style="max-width:115px">
                  </div>
                </div>
                <div class="caja-dentro-tab">
                  <div class="izq">
                    <kendo-label text="{{ 'datosForja.poleas' | translate}}">
                      <span><i class="fas fa-image" (click)="abrirModalImagen('poleas')"></i></span>
                      <span><i class="fas fa-file-pdf"
                           (click)="abrirPDF('pdfpoleas')"></i></span>
                      <div class="caja">
                        <kendo-dropdownlist [data]="datosPoleas" [(value)]="poleaSelected" [textField]="'nombre'"
                                            [valueField]="'id'"
                                            [ngClass]="{ 'is-invalid': submitted && (poleaSelected==null 
                                  || poleaSelected==undefined || poleaSelected.id==-1 )}">
                        </kendo-dropdownlist>
                      </div>
                    </kendo-label>
                  </div>
                  <div class="dcha">
                    <img [src]="imagenPoleas" style="max-width:115px">
                  </div>
                </div>
                <div class="caja-dentro-tab">
                  <div class="izq">
                    <kendo-label text="{{ 'datosForja.proyectoPDF' | translate}}">
                      <div class="caja">
                        <kendo-fileselect [(ngModel)]="proyectoPDF" [restrictions]="restrictions"
                                          [multiple]="false" [(value)]="proyectoPDF" (select)="archivoSeleccionadoPDFLaminador($event)"
                                          (remove)="archivoEliminadoPDFLaminador($event)" [ngClass]="{ 'is-invalid': submitted && (proyectoPDF == undefined || proyectoPDF == null || proyectoPDF == '')}">
                          <kendo-upload-messages cancel="{{ 'uploads.cancelar' | translate }}"
                                                 clearSelectedFiles="{{ 'uploads.clearSelectedFiles' | translate }}"
                                                 dropFilesHere="{{ 'uploads.dropFilesHere' | translate }}"
                                                 externalDropFilesHere="{{ 'uploads.externalDropFilesHere' | translate }}"
                                                 fileStatusFailed="{{ 'uploads.fileStatusFailed' | translate }}"
                                                 fileStatusUploaded="{{ 'uploads.fileStatusUploaded' | translate }}"
                                                 filesBatchStatus="{{ 'uploads.filesBatchStatus' | translate }}"
                                                 filesBatchStatusFailed="{{ 'uploads.filesBatchStatusFailed' | translate }}"
                                                 filesBatchStatusUploaded="{{ 'uploads.filesBatchStatusUploaded' | translate }}"
                                                 headerStatusPaused="{{ 'uploads.headerStatusPaused' | translate }}"
                                                 headerStatusUploaded="{{ 'uploads.headerStatusUploaded' | translate }}"
                                                 headerStatusUploading="{{ 'uploads.headerStatusUploading' | translate }}"
                                                 invalidFileExtension="{{ 'uploads.invalidFileExtension' | translate }}"
                                                 invalidMaxFileSize="{{ 'uploads.invalidMaxFileSize' | translate }}"
                                                 invalidMinFileSize="{{ 'uploads.invalidMinFileSize' | translate }}"
                                                 pause="{{ 'uploads.pause' | translate }}" remove="{{ 'uploads.remove' | translate }}"
                                                 resume="{{ 'uploads.resume' | translate }}" retry="{{ 'uploads.retry' | translate }}"
                                                 select="{{ 'uploads.select' | translate }}"
                                                 uploadSelectedFiles="{{ 'uploads.uploadSelectedFiles' | translate }}">
                          </kendo-upload-messages>
                        </kendo-fileselect>
                        <div class="cont-archivo" *ngIf="mostrarPDFAux==true">
                          <div class="cont-archivo-inner">
                            <span class="k-file-group-wrapper">
                              <span class="k-file-group k-icon k-i-file-pdf"></span>
                            </span>
                            <span class="k-file-name-size-wrapper">
                              <span class="k-file-name">{{ proyectoPDF_nombre | json }}</span>
                            </span>
                            <button type="button" class="k-button k-button-icon k-flat k-upload-action" tabindex="-1" (click)="archivoEliminadoPDFLaminador($event)">
                              <span class="k-icon k-delete k-i-x"></span>
                            </button>
                          </div>
                        </div>
                        <button *ngIf="mostrarPDFAux==true"
                                kendoButton (click)="onClickVerPDF()" class="btn mt-1  btn-success btn-sm mr-1">{{ 'botones.verPDF' | translate}}</button>
                      </div>
                    </kendo-label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-4">
            <div class="card">
              <!--NOTAS-->
              <div class="card-body">
                <div class="caja-dentro-tab">
                  <div class="form-group">
                    <label class="check-label">{{ 'datosForja.notas' | translate }}</label>
                    <div class="checkbox">
                      <textarea [(ngModel)]="notas_laminador" class="form-control" style="height: 315px; width: 500px;"></textarea>
                      <!--<textarea [(ngModel)]="notas_p10000" class="form-control" style="height: 370px;"></textarea>-->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab title="{{ 'datosForja.montajeHtaNueva' | translate}}" *ngIf="!isAddMode">
      <ng-template kendoTabContent>
        <div class="">
          <table id="fichaImprimir">
            <tbody>
              <tr>
                <td>
                  <table class="no-vertical">
                    <tbody>
                      <tr>
                        <th colspan="5" rowspan="1">{{('hojaImpresion.fichaTecnica' | translate).toUpperCase()}}</th>
                        <th colspan="2" rowspan="1" class="header-negrita destacado">{{form.value.codigoPlano}}/{{form.value.figura}}</th>
                        <th colspan="1" rowspan="2" class="header-negrita" style="vertical-align: text-bottom;">Equipo</th>
                        <th colspan="1" rowspan="2">{{fecha | kendoDate}} {{fecha | kendoDate:'HH:mm'}}</th>
                      </tr>

                      <tr>
                        <th rowspan="2" style="vertical-align: text-bottom;">{{('hojaImpresion.ubicacion' | translate).toUpperCase()}}</th>
                        <th rowspan="1"></th>
                        <th colspan="5" rowspan="1">{{('hojaImpresion.herramientaPrensa10000NuevaGeneracion' |
                          translate).toUpperCase()}}</th>
                      </tr>
                      <tr>
                        <th colspan="5" class="header-negrita">{{('hojaImpresion.herramienta10000Superior' |
                          translate).toUpperCase()}}
                        </th>
                        <th colspan="2" class="header-negrita">{{('hojaImpresion.herramientaInferior1OP' |
                          translate).toUpperCase()}}
                        </th>
                        <th colspan="2" class="header-negrita">{{('hojaImpresion.htaInferior2OP' |
                          translate).toUpperCase()}}
                        </th>
                      </tr>
                      <tr>
                        <td>{{('hojaImpresion.placaSuperior' | translate).toUpperCase()}}</td>
                        <td>{{('hojaImpresion.postizoSuperior' | translate).toUpperCase()}}</td>
                        <td>{{('hojaImpresion.casquilloGuia' | translate).toUpperCase()}}</td>
                        <td>{{('hojaImpresion.aroForja' | translate).toUpperCase()}}</td>
                        <td>{{('hojaImpresion.medidasAro' | translate).toUpperCase()}}</td>
                        <td>{{('hojaImpresion.placaInferior' | translate).toUpperCase()}}</td>
                        <td>{{('datosForja.postizoInferior1aOP' | translate).toUpperCase()}}</td>
                        <td>{{('hojaImpresion.placaInferior' | translate).toUpperCase()}}</td>
                        <td>{{('datosForja.postizoInferior2aOP' | translate).toUpperCase()}}</td>

                      </tr>
                      <tr class="celdas-destacadas" style="font-weight: 700;">
                        <td>{{h15aMaestroSelected.nombre}}</td>
                        <td>{{htaSupH20a}}</td>
                        <td>{{htaSupH22a}}</td>
                        <td>{{htaSupH51a1}}</td>
                        <td>{{htaSupH51a2}}</td>
                        <td>{{h30aMaestroSelected1.nombre}}</td>
                        <td>{{htaInfH39a}}</td>
                        <td>{{h30aMaestroSelected2.nombre}}</td>
                        <td>{{htaInfH40a}}</td>
                      </tr>
                      <tr class="tabla-doble-linea" style="font-weight: 700;">
                        <td colspan="2" class="celda-negrita">{{('hojaImpresion.comentariop10000' | translate).toUpperCase()}}
                        </td>
                        <td colspan="7">{{notas_p10000}}</td>
                      </tr>
                      <tr>
                        <th colspan="2" class="header-negrita">{{('hojaImpresion.piecero10000Inferior1OP' | translate).toUpperCase()}}
                        </th>
                        <th colspan="5" class="header-negrita">{{('hojaImpresion.piecero10000Inferior2OP' | translate).toUpperCase()}}
                        </th>
                        <th colspan="2" class="header-negrita">{{('hojaImpresion.piecero10000Superior' | translate).toUpperCase()}}</th>
                      </tr>
                      <tr>
                        <td colspan="2">{{('hojaImpresion.medidasRepunzado' | translate).toUpperCase()}}</td>
                        <td colspan="2">{{('hojaImpresion.medidasYTipoExtractor' | translate).toUpperCase()}}</td>
                        <td colspan="2">{{('hojaImpresion.medidasPrepunzonado' | translate).toUpperCase()}}</td>
                        <td>{{('hojaImpresion.medidasArandelas' | translate).toUpperCase()}}</td>
                        <td colspan="2">{{('hojaImpresion.repunzonadoSuperior' | translate).toUpperCase()}}</td>
                      </tr>
                      <tr style="font-weight: 700;" class="celdas-destacadas">
                        <td colspan="2">{{piecerioHerramientaPrepunzado1aOP1}} x
                          {{piecerioHerramientaPrepunzado1aOP2}}</td>
                        <td colspan="2">{{piecerioHerramientaSubextractorSelected.nombre}}</td>
                        <td colspan="2">{{piecerioHerramientaPrepunzado2aOP1.nombre}} x {{piecerioHerramientaPrepunzado2aOP2}}
                          x {{piecerioHerramientaPrepunzado2aOP3}}</td>
                        <td> {{piecerioHerramientaArandela1}} x {{piecerioHerramientaArandela2.nombre}} x
                          {{piecerioHerramientaArandela3}}</td>
                        <td colspan="2">{{piecerioHerramientaPrepunzadoSuperior1.nombre}} x
                          {{piecerioHerramientaPrepunzadoSuperior2}} x
                          {{piecerioHerramientaPrepunzadoSuperior3}}</td>
                      </tr>
                      <tr>
                        <td colspan="2">{{'hojaImpresion.informacion' | translate}}</td>
                        <td colspan="2" rowspan="2">&nbsp;</td>
                        <td colspan="2">{{'hojaImpresion.informacion' | translate}}</td>
                        <td>{{'hojaImpresion.informacion' | translate}}</td>
                        <td colspan="2">{{'hojaImpresion.informacion' | translate}}</td>
                      </tr>
                      <tr class="tabla-doble-linea" style="font-weight: 700;">
                        <td colspan="2">{{piecerioHerramientaPrepunzado1aOP3}}</td>
                        <td colspan="2">{{piecerioHerramientaPrepunzado2aOP4}}</td>
                        <td>{{piecerioHerramientaArandelaResultado}}</td>
                        <td colspan="2">{{piecerioHerramientaPrepunzadoSuperior4}}</td>
                      </tr>
                    </tbody>
                  </table>
                  <table class="no-vertical">
                    <thead>
                      <tr>
                        <th rowspan="1">{{('hojaImpresion.ubicacion' | translate).toUpperCase()}}</th>
                        <th rowspan="1"></th>
                        <th colspan="6">{{('hojaImpresion.herramientaPrensa5000' | translate).toUpperCase()}}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{{('hojaImpresion.matrizSuperior' | translate).toUpperCase()}}</td>
                        <td>{{('hojaImpresion.matrizInferior' | translate).toUpperCase()}}</td>
                        <td>{{('hojaImpresion.distanciaCubo' | translate).toUpperCase()}}</td>
                        <td>{{('hojaImpresion.diametroSubextractor' | translate).toUpperCase()}}</td>
                        <td>{{('hojaImpresion.alturaExtractor' | translate).toUpperCase()}}</td>
                        <td>{{('hojaImpresion.medidasArandelas' | translate).toUpperCase()}}</td>
                        <td>{{('hojaImpresion.diametroMandrino' | translate).toUpperCase()}}</td>
                      </tr>
                      <tr style="font-weight: 700;" class="celdas-destacadas">
                        <td>{{h60h61Value}}</td>
                        <td>{{h70h71Value1}}</td>
                        <td>{{h70h71Value2}}</td>
                        <td>{{subextractor5000Selected.nombre}}</td>
                        <td>{{subextractor5000Selected2}}</td>
                        <td>{{arandelap50001}} x {{arandelap50002.nombre}} x {{arandelap50003}}</td>
                        <td>{{mandrino5000Selected.nombre}}</td>
                      </tr>
                      <tr class="tabla-doble-linea" style="font-weight: 700;">
                        <td colspan="2" class="celda-negrita">{{('hojaImpresion.comentariop5000' | translate).toUpperCase()}}
                        </td>
                        <td colspan="3">{{notas_p5000}}</td>
                        <!-- {{'hojaImpresion.informacion' | translate}} -->
                        <td colspan="2">{{arandelap50004}}</td>
                      </tr>
                      <tr class="tabla-doble-linea" style="font-weight: 700;">
                        <td colspan="2" class="celda-negrita">{{('hojaImpresion.comentarioGeneral' | translate).toUpperCase()}}
                        </td>
                        <td colspan="5">{{form.value.notas_general}}</td>
                      </tr>
                      <tr>
                        <td colspan="7" class="celda-negrita">{{('hojaImpresion.estadoHerramienta' | translate).toUpperCase()}}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table class="no-vertical celdas-estrechas">
                    <thead>
                      <tr>
                        <th>{{'hojaImpresion.nombre' | translate}}</th>
                        <th>{{('hojaImpresion.ok' | translate).toUpperCase()}}</th>
                        <th>{{('hojaImpresion.noOk' | translate).toUpperCase()}}</th>
                        <th>{{'hojaImpresion.esmerilar' | translate}}</th>
                        <th>{{'hojaImpresion.tornear' | translate}}</th>
                        <th>{{'hojaImpresion.nuevo' | translate}}</th>
                        <th>{{'hojaImpresion.realizadoPor' | translate}}</th>
                        <th>{{'hojaImpresion.fecha' | translate}}</th>
                      </tr>
                    </thead>
                    <tbody>

                      <tr>
                        <td class="text-left">PREPU 1ºOP</td>
                        <td><input type="checkbox" [checked]="checkPrepunzado1OPOk" [disabled]="true"></td>
                        <td><input type="checkbox" [checked]="checkPrepunzado1OPNoOk" [disabled]="true"></td>
                        <td><input type="checkbox" [checked]="checkPrepunzado1OPEsmerilar" [disabled]="true"></td>
                        <td><input type="checkbox" [checked]="checkPrepunzado1OPTornear" [disabled]="true"></td>
                        <td><input type="checkbox" [checked]="checkPrepunzado1OPNuevo" [disabled]="true"></td>
                        <td></td>
                        <td></td>
                      </tr>

                      <tr>
                        <td class="text-left">POSTIZO 1ºOP</td>
                        <td><input type="checkbox" [checked]="checkPostizo1OPOk" [disabled]="true"></td>
                        <td><input type="checkbox" [checked]="checkPostizo1OPNoOk" [disabled]="true"></td>
                        <td><input type="checkbox" [checked]="checkPostizo1OPEsmerilar" [disabled]="true"></td>
                        <td><input type="checkbox" [checked]="checkPostizo1OPTornear" [disabled]="true"></td>
                        <td><input type="checkbox" [checked]="checkPostizo1OPNuevo" [disabled]="true"></td>
                        <td></td>
                        <td></td>
                      </tr>

                      <tr>
                        <td class="text-left">MATRIZ SUPERIOR</td>
                        <td><input type="checkbox" [checked]="checkMatrizSuperiorOk" [disabled]="true"></td>
                        <td><input type="checkbox" [checked]="checkMatrizSuperiorNoOk" [disabled]="true"></td>
                        <td><input type="checkbox" [checked]="checkMatrizSuperiorEsmerilar" [disabled]="true"></td>
                        <td><input type="checkbox" [checked]="checkMatrizSuperiorTornear" [disabled]="true"></td>
                        <td><input type="checkbox" [checked]="checkMatrizSuperiorNuevo" [disabled]="true"></td>
                        <td></td>
                        <td></td>
                      </tr>

                      <tr>
                        <td class="text-left">MATRIZ INFERIOR</td>
                        <td><input type="checkbox" [checked]="checkMatrizInferiorOk" [disabled]="true"></td>
                        <td><input type="checkbox" [checked]="checkMatrizInferiorNoOk" [disabled]="true"></td>
                        <td><input type="checkbox" [checked]="checkMatrizInferiorEsmerilar" [disabled]="true"></td>
                        <td><input type="checkbox" [checked]="checkMatrizInferiorTornear" [disabled]="true"></td>
                        <td><input type="checkbox" [checked]="checkMatrizInferiorNuevo" [disabled]="true"></td>
                        <td></td>
                        <td></td>
                      </tr>

                      <tr>
                        <td class="text-left">POSTIZO SUPERIOR</td>
                        <td><input type="checkbox" [checked]="checkPostizoSuperiorOk" [disabled]="true"></td>
                        <td><input type="checkbox" [checked]="checkPostizoSuperiorNoOk" [disabled]="true"></td>
                        <td><input type="checkbox" [checked]="checkPostizoSuperiorEsmerilar" [disabled]="true"></td>
                        <td><input type="checkbox" [checked]="checkPostizoSuperiorTornear" [disabled]="true"></td>
                        <td><input type="checkbox" [checked]="checkPostizoSuperiorNuevo" [disabled]="true"></td>
                        <td></td>
                        <td></td>
                      </tr>

                      <tr>
                        <td class="text-left">PREPU SUPERIOR</td>
                        <td><input type="checkbox" [checked]="checkPrepunzadoSuperiorOk" [disabled]="true"></td>
                        <td><input type="checkbox" [checked]="checkPrepunzadoSuperiorNoOk" [disabled]="true"></td>
                        <td><input type="checkbox" [checked]="checkPrepunzadoSuperiorEsmerilar" [disabled]="true"></td>
                        <td><input type="checkbox" [checked]="checkPrepunzadoSuperiorTornear" [disabled]="true"></td>
                        <td><input type="checkbox" [checked]="checkPrepunzadoSuperiorNuevo" [disabled]="true"></td>
                        <td></td>
                        <td></td>
                      </tr>



                      <tr>
                        <td class="text-left">ARO DE FORJA</td>
                        <td><input type="checkbox" [checked]="checkAroForjaOk" [disabled]="true"></td>
                        <td><input type="checkbox" [checked]="checkAroForjaNoOk" [disabled]="true"></td>
                        <td><input type="checkbox" [checked]="checkAroForjaEsmerilar" [disabled]="true"></td>
                        <td><input type="checkbox" [checked]="checkAroForjaTornear" [disabled]="true"></td>
                        <td><input type="checkbox" [checked]="checkAroForjaNuevo" [disabled]="true"></td>
                        <td></td>
                        <td></td>
                      </tr>


                      <tr>
                        <td class="text-left">POSTIZO INFERIOR</td>
                        <td><input type="checkbox" [checked]="checkPostizo2OPOk" [disabled]="true"></td>
                        <td><input type="checkbox" [checked]="checkPostizo2OPNoOk" [disabled]="true"></td>
                        <td><input type="checkbox" [checked]="checkPostizo2OPEsmerilar" [disabled]="true"></td>
                        <td><input type="checkbox" [checked]="checkPostizo2OPTornear" [disabled]="true"></td>
                        <td><input type="checkbox" [checked]="checkPostizo2OPNuevo" [disabled]="true"></td>
                        <td></td>
                        <td></td>
                      </tr>

                      <tr>
                        <td class="text-left">PREPU INFERIOR</td>
                        <td><input type="checkbox" [checked]="checkPrepunzado2OPOk" [disabled]="true"></td>
                        <td><input type="checkbox" [checked]="checkPrepunzado2OPNoOk" [disabled]="true"></td>
                        <td><input type="checkbox" [checked]="checkPrepunzado2OPEsmerilar" [disabled]="true"></td>
                        <td><input type="checkbox" [checked]="checkPrepunzado2OPTornear" [disabled]="true"></td>
                        <td><input type="checkbox" [checked]="checkPrepunzado2OPNuevo" [disabled]="true"></td>
                        <td></td>
                        <td></td>
                      </tr>

                      <!-- aa -->

                    </tbody>
                  </table>
                </td>
                <td class="vertical"><span>{{form.value.codigoPlano}}/{{form.value.figura}}</span></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="clearfix">
          <button kendoButton class="btn mt-2  btn-success" (click)="imprimir()">
            {{ 'datosForja.imprimir' | translate}}
          </button>
        </div>
      </ng-template>
    </kendo-tabstrip-tab>
  </kendo-tabstrip>
</div>

<div class="">
  <div class="card-body">
    <button kendoButton class="btn mr-1  btn-success" (click)="onSubmit()">
      {{ 'botones.guardar' | translate}}
    </button>
    <button *ngIf="!isAddMode" kendoButton class="btn mr-1  btn-primary" (click)="onClickCopia()">
      {{ 'botones.guardarCopia' | translate}}
    </button>
    <a routerLink="/datosForja" class="btn mr-1  btn-danger">{{ 'botones.cancelar' | translate}}</a>
  </div>
</div>

<div *ngIf="loading" class="k-i-loading"></div>

<!-- MODAL IMAGENES -->
<ng-template #modalImagenes let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title"></h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body text-center">
    <img [src]="imagenModal" style="max-width:500px">
  </div>
</ng-template>

<!-- MODAL COPIA -->
<ng-template #modalCopia let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title"></h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="clearfix">
      <div class="float-left mr-2">
        <kendo-label text="{{ 'datosForja.codigoPlano' | translate}}">
          <kendo-textbox [(value)]="codigoPlanoModal" [ngClass]="{ 'is-invalid': aceptado && errorPlanoModal }">
          </kendo-textbox>
          <div *ngIf="aceptado && errorPlanoModal" class="invalid-feedback">
            <div *ngIf="errorPlanoModal">{{ 'datosForja.codigoPlanoObligatorio' | translate}}</div>
          </div>
        </kendo-label>
      </div>
      <div class="float-left mr-2">
        <kendo-label text="{{ 'datosForja.figura' | translate}}">
          <kendo-textbox [(value)]="figuraModal" [ngClass]="{ 'is-invalid': aceptado && errorFiguraModal }">
          </kendo-textbox>
          <div *ngIf="aceptado && errorFiguraModal" class="invalid-feedback">
            <div *ngIf="errorFiguraModal">{{ 'datosForja.figuraObligatoria' | translate}}</div>
          </div>
        </kendo-label>
      </div>
      <div class="float-left mr-2">
        <kendo-label text="{{ 'datosForja.equivalenciaP10000' | translate}}">
          <kendo-textbox [(value)]="equivalenciaP10000Modal"
                         [ngClass]="{ 'is-invalid': aceptado && errorEquivalenciaP10000Modal }">
          </kendo-textbox>
          <div *ngIf="aceptado && errorEquivalenciaP10000Modal" class="invalid-feedback">
            <div *ngIf="errorEquivalenciaP10000Modal">{{ 'datosForja.equivalenciaObligatoria' | translate}}</div>
          </div>
        </kendo-label>
      </div>
      <div class="float-left mr-2">
        <kendo-label text="{{ 'datosForja.equivalenciaP5000' | translate}}">
          <kendo-textbox [(value)]="equivalenciaP5000Modal"
                         [ngClass]="{ 'is-invalid': aceptado && errorEquivalenciaP5000Modal }">
          </kendo-textbox>
          <div *ngIf="aceptado && errorEquivalenciaP5000Modal" class="invalid-feedback">
            <div *ngIf="errorEquivalenciaP5000Modal">{{ 'datosForja.equivalenciaObligatoria' | translate}}</div>
          </div>
        </kendo-label>
      </div>
      <div class="float-left mr-2">
        <kendo-label text="{{ 'datosForja.equivalenciaLaminador' | translate}}">
          <kendo-textbox [(value)]="equivalenciaLaminadorModal"
                         [ngClass]="{ 'is-invalid': aceptado && errorEquivalenciaLaminadorModal }">
          </kendo-textbox>
          <div *ngIf="aceptado && errorEquivalenciaLaminadorModal" class="invalid-feedback">
            <div *ngIf="errorEquivalenciaLaminadorModal">{{ 'datosForja.equivalenciaObligatoria' | translate}}</div>
          </div>
        </kendo-label>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">{{ 'botones.cancelar' |
      translate
      }}</button>
    <button type="button" class="btn btn-primary" (click)="btnCrearCopiaAceptar()">{{ 'botones.aceptar' | translate
      }}</button>

  </div>
</ng-template>

<!-- POPUP: editar o insertar seguro? -->
<ng-template #editarInsertarSeguro let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title"></h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{ 'datosForja.preguntaSeguroPopUp' | translate }}</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">{{ 'botones.no' | translate }}</button>
    <button type="button" class="btn btn-danger" (click)="aceptarEditarInsertar()">{{ 'botones.si' | translate }}</button>
  </div>
</ng-template>


<!-- POPUP: editar o insertar seguro? despues de editar un campo -->
<ng-template #guardarDespuesDeEditar let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title"></h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>Has editado uno o mas valores, ¿estás seguro de querer guardar lo editado?</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">{{ 'botones.no' | translate }}</button>
    <button type="button" class="btn btn-danger" (click)="aceptarEditarInsertar()">{{ 'botones.si' | translate }}</button>
  </div>
</ng-template>