import { NgModule, APP_INITIALIZER, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { JwtInterceptor, ErrorInterceptor, appInitializer } from './_helpers';
import { CommonModule } from '@angular/common';
import { AppComponent } from './app.component';
import { AlertComponent } from './_components';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
// import ngx-translate and the http loader
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

//FontAwesome
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

/* SERVICES */
import {
  UsuariosService,
  MenuService,
  UsuariosTiposService,
  placaSuperiorH15AService,
  basePrepunzonadoInferior10000Service,
  cebollasService,
  mandrino5000Service,
  mandrino10000Service,
  subextractor10000Service,
  subextractor5000Service,
  poleasService,
  placaSuperiorH30AService,
  PlanosService,
  ManualesService,
  catalogoForjaRuedasService,
  prepunzonadoSuperiorService
} from '@app/_services';
import { IdiomasService } from "@app/_services/idioma.service";

//Angular
import '@progress/kendo-angular-intl/locales/es/all';
import '@progress/kendo-angular-intl/locales/eu/all';
import { IntlModule } from '@progress/kendo-angular-intl';
import {
  DropDownsModule,
  ComboBoxModule,
} from '@progress/kendo-angular-dropdowns';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { GridModule } from '@progress/kendo-angular-grid';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { IconsModule } from '@progress/kendo-angular-icons';
import { FormsModule } from '@angular/forms';
import { LabelModule } from '@progress/kendo-angular-label';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LayoutModule } from "@progress/kendo-angular-layout";
import { UploadsModule } from '@progress/kendo-angular-upload';

//Paginas
import { HomeComponent } from './home';
import { UsuariosComponent } from './usuarios/usuarios.component';
import { EditarCrearUsuarioComponent } from './usuarios/editar-crear-usuario/editar-crear-usuario.component';
import { UsuariosTiposComponent } from './usuarios-tipos/usuarios-tipos.component';
import { EditarCrearUsuarioTipoComponent } from './usuarios-tipos/editar-crear-usuario-tipo/editar-crear-usuario-tipo.component';
import { DetailsComponent } from './profile/details.component';
import { UpdateComponent } from './profile/update/update.component';
import { ZituDropdownComponent } from './zitu-components/zitu-dropdown/zitu-dropdown.component';
import { ZituNumericComponent } from './zitu-components/zitu-numeric/zitu-numeric.component';
import { ZituTextboxComponent } from './zitu-components/zitu-textbox/zitu-textbox.component';
import { ZituSelectorUsuariosComponent } from './zitu-components/zitu-selector-usuarios/zitu-selector-usuarios.component';

//Paginas propias
import { PlacaSuperiorH15AComponent } from './placaSuperiorH15A/placaSuperiorH15A.component';
import { EditarCrearPlacaSuperiorH15AComponent } from './placaSuperiorH15A/editar-crear-placaSuperiorH15A/editar-crear-placaSuperiorH15A.component';
import { PlacaSuperiorH30AComponent } from './placaSuperiorH30A/placaSuperiorH30A.component';
import { EditarCrearPlacaSuperiorH30AComponent } from './placaSuperiorH30A/editar-crear-placaSuperiorH30A/editar-crear-placaSuperiorH30A.component';
import { CebollasComponent } from './cebollas/cebollas.component';
import { EditarCrearCebollasComponent } from './cebollas/editar-crear-cebollas/editar-crear-cebollas.component';
import { Mandrino10000Component } from './mandrino10000/mandrino10000.component';
import { EditarCrearMandrino10000Component } from './mandrino10000/editar-crear-mandrino10000/editar-crear-mandrino10000.component';
import { Mandrino5000Component } from './mandrino5000/mandrino5000.component';
import { EditarCrearMandrino5000Component } from './mandrino5000/editar-crear-mandrino5000/editar-crear-mandrino5000.component';
import { PoleasComponent } from './poleas/poleas.component';
import { EditarCrearPoleasComponent } from './poleas/editar-crear-poleas/editar-crear-poleas.component';
import { Subextractor10000Component } from './subextractor10000/subextractor10000.component';
import { EditarCrearSubextractor10000Component } from './subextractor10000/editar-crear-subextractor10000/editar-crear-subextractor10000.component';
import { Subextractor5000Component } from './subextractor5000/subextractor5000.component';
import { EditarCrearSubextractor5000Component } from './subextractor5000/editar-crear-subextractor5000/editar-crear-subextractor5000.component';
import { BasePrepunzonadoInferior10000Component } from './basePrepunzonadoInferior10000/basePrepunzonadoInferior10000.component';
import { EditarCrearBasePrepunzonadoInferior10000Component } from './basePrepunzonadoInferior10000/editar-crear-basePrepunzonadoInferior10000/editar-crear-basePrepunzonadoInferior10000.component'
import { DatosForjaComponent } from './datosForja/datosForja.component';
import { DatosForjaDetalleComponent } from './datosForja/datosForjaDetalle/datosForjaDetalle.component';
import { HojaImpresionComponent } from './datosForja/hojaImpresion/hojaImpresion.component';
import { ConfiguracionComponent } from './configuracion/configuracion.component';
import { BuscadorComponent } from './buscador/buscador.component';
import { ManualesComponent } from './manuales/manuales.component';
import { CatalogoForjaRuedasComponent } from './catalogo-forja-ruedas/catalogo-forja-ruedas.component';
import { PrepunzonadoSuperiorComponent } from './prepunzonadoSuperior/prepunzonadoSuperior.component';
import { EditarCrearPrepunzonadoSuperiorComponent } from './prepunzonadoSuperior/editar-crear-prepunzonadoSuperior/editar-crear-prepunzonadoSuperior.component';

@NgModule({
  imports: [
    IntlModule,
    BrowserModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    CommonModule,
    //IDIOMAS
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    GridModule,
    ComboBoxModule,
    DateInputsModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    DropDownsModule,
    InputsModule,
    IconsModule,
    ButtonsModule,
    FormsModule,
    LabelModule,
    LayoutModule,
    UploadsModule
  ],
  declarations: [
    AppComponent,
    AlertComponent,
    HomeComponent,
    UsuariosComponent,
    EditarCrearUsuarioComponent,
    UsuariosTiposComponent,
    EditarCrearUsuarioTipoComponent,
    DetailsComponent,
    UpdateComponent,
    ZituDropdownComponent,
    ZituNumericComponent,
    ZituTextboxComponent,
    ZituSelectorUsuariosComponent,
    PlacaSuperiorH15AComponent,
    EditarCrearPlacaSuperiorH15AComponent,
    PlacaSuperiorH30AComponent,
    EditarCrearPlacaSuperiorH30AComponent,
    CebollasComponent,
    EditarCrearCebollasComponent,
    Mandrino10000Component,
    EditarCrearMandrino10000Component,
    Mandrino5000Component,
    EditarCrearMandrino5000Component,
    PoleasComponent,
    EditarCrearPoleasComponent,
    Subextractor10000Component,
    EditarCrearSubextractor10000Component,
    Subextractor5000Component,
    EditarCrearSubextractor5000Component,
    BasePrepunzonadoInferior10000Component,
    EditarCrearBasePrepunzonadoInferior10000Component,
    DatosForjaComponent,
    DatosForjaDetalleComponent,
    HojaImpresionComponent,
    ConfiguracionComponent,
    BuscadorComponent,
    ManualesComponent,
    CatalogoForjaRuedasComponent,
    PrepunzonadoSuperiorComponent,
    EditarCrearPrepunzonadoSuperiorComponent
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [UsuariosService],
    },
    { provide: LOCALE_ID, useValue: 'es' },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    MenuService,
    UsuariosTiposService,
    IdiomasService,
    placaSuperiorH15AService,
    basePrepunzonadoInferior10000Service,
    cebollasService,
    mandrino5000Service,
    mandrino10000Service,
    placaSuperiorH30AService,
    poleasService,
    subextractor10000Service,
    subextractor5000Service,
    PlanosService,
    ManualesService,
    catalogoForjaRuedasService,
    prepunzonadoSuperiorService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
