<div class="form-group">
  <kendo-label text="{{ label }}">
      <div *ngIf="labelArriba">
      </div>
    <kendo-numerictextbox #numeric
      format="n0"
      [step]="1"
      [min]="0"
      [(ngModel)]="valornumero"
      (valueChange)="valueChange($event, numeric)"
    ></kendo-numerictextbox>
  </kendo-label>
</div>
