<!-- P10000 -->
<div class="row">
  <div class="col-lg-3 col-12">
    <div class="card">
      <div class="card-header">
        <h3>{{ 'datosForja.htaSuperior' | translate}}</h3>
        <div class="plegarpanel"></div>
      </div>
      <div class="card-body">
        <div class="clearfix">
          <div class="caja-imagen">
            <!--IMAGEN VIEJA-->
            <div class="cont-izq">
              <kendo-label text="{{ 'datosForja.h15a' | translate}}">

              </kendo-label>

              <div class="form-group">
                <div class="caja">

                  <kendo-fileselect [(ngModel)]="archivoHTASuperiorH15AMaestro" [restrictions]="restrictionsImage"
                                    [multiple]="false" (select)="archivoSeleccionadoHTASuperiorH15AMaestro($event)"
                                    (remove)="archivoEliminadoHTASuperiorH15AMaestro($event)">
                    <kendo-upload-messages cancel="{{ 'uploads.cancelar' | translate }}"
                                           clearSelectedFiles="{{ 'uploads.clearSelectedFiles' | translate }}"
                                           dropFilesHere="{{ 'uploads.dropFilesHere' | translate }}"
                                           externalDropFilesHere="{{ 'uploads.externalDropFilesHere' | translate }}"
                                           fileStatusFailed="{{ 'uploads.fileStatusFailed' | translate }}"
                                           fileStatusUploaded="{{ 'uploads.fileStatusUploaded' | translate }}"
                                           filesBatchStatus="{{ 'uploads.filesBatchStatus' | translate }}"
                                           filesBatchStatusFailed="{{ 'uploads.filesBatchStatusFailed' | translate }}"
                                           filesBatchStatusUploaded="{{ 'uploads.filesBatchStatusUploaded' | translate }}"
                                           headerStatusPaused="{{ 'uploads.headerStatusPaused' | translate }}"
                                           headerStatusUploaded="{{ 'uploads.headerStatusUploaded' | translate }}"
                                           headerStatusUploading="{{ 'uploads.headerStatusUploading' | translate }}"
                                           invalidFileExtension="{{ 'uploads.invalidFileExtension' | translate }}"
                                           invalidMaxFileSize="{{ 'uploads.invalidMaxFileSize' | translate }}"
                                           invalidMinFileSize="{{ 'uploads.invalidMinFileSize' | translate }}"
                                           pause="{{ 'uploads.pause' | translate }}"
                                           remove="{{ 'uploads.remove' | translate }}"
                                           resume="{{ 'uploads.resume' | translate }}"
                                           retry="{{ 'uploads.retry' | translate }}"
                                           select="{{ 'uploads.selectImages' | translate }}"
                                           uploadSelectedFiles="{{ 'uploads.uploadSelectedFiles' | translate }}">
                    </kendo-upload-messages>
                  </kendo-fileselect>

                </div>
              </div>
              <div class="form-group">
                <div class="caja">

                  <kendo-fileselect [(ngModel)]="pdfHTASuperiorH15AMaestro" [restrictions]="restrictionsPDF"
                                    [multiple]="false" (select)="pdfSeleccionadoHTASuperiorH15AMaestro($event)"
                                    (remove)="pdfEliminadoHTASuperiorH15AMaestro($event)">
                    <kendo-upload-messages cancel="{{ 'uploads.cancelar' | translate }}"
                                           clearSelectedFiles="{{ 'uploads.clearSelectedFiles' | translate }}"
                                           dropFilesHere="{{ 'uploads.dropFilesHere' | translate }}"
                                           externalDropFilesHere="{{ 'uploads.externalDropFilesHere' | translate }}"
                                           fileStatusFailed="{{ 'uploads.fileStatusFailed' | translate }}"
                                           fileStatusUploaded="{{ 'uploads.fileStatusUploaded' | translate }}"
                                           filesBatchStatus="{{ 'uploads.filesBatchStatus' | translate }}"
                                           filesBatchStatusFailed="{{ 'uploads.filesBatchStatusFailed' | translate }}"
                                           filesBatchStatusUploaded="{{ 'uploads.filesBatchStatusUploaded' | translate }}"
                                           headerStatusPaused="{{ 'uploads.headerStatusPaused' | translate }}"
                                           headerStatusUploaded="{{ 'uploads.headerStatusUploaded' | translate }}"
                                           headerStatusUploading="{{ 'uploads.headerStatusUploading' | translate }}"
                                           invalidFileExtension="{{ 'uploads.invalidFileExtension' | translate }}"
                                           invalidMaxFileSize="{{ 'uploads.invalidMaxFileSize' | translate }}"
                                           invalidMinFileSize="{{ 'uploads.invalidMinFileSize' | translate }}"
                                           pause="{{ 'uploads.pause' | translate }}"
                                           remove="{{ 'uploads.remove' | translate }}"
                                           resume="{{ 'uploads.resume' | translate }}"
                                           retry="{{ 'uploads.retry' | translate }}"
                                           select="{{ 'uploads.select' | translate }}"
                                           uploadSelectedFiles="{{ 'uploads.uploadSelectedFiles' | translate }}">
                    </kendo-upload-messages>
                  </kendo-fileselect>
                  <div class="cont-archivo" *ngIf="mostrarHTASuperiorH15AMaestro==true">
                    <div class="cont-archivo-inner">
                      <span class="k-file-group-wrapper">
                        <span class="k-file-group k-icon k-i-file-pdf"></span>
                      </span>
                      <span class="k-file-name-size-wrapper">
                        <span class="k-file-name">{{ 'configuracion.pdfHTASuperiorH15AMaestro' | translate}}</span>
                      </span>
                      <button type="button" class="k-button k-button-icon k-flat k-upload-action" tabindex="-1" (click)="pdfEliminadoHTASuperiorH15AMaestro($event)">
                        <span class="k-icon k-delete k-i-x"></span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <img [src]="imageToShowHTASuperiorH15AMaestro" class="img-conf" style="max-height: 113px">
          </div>
          <div class="caja-imagen">
            <!--IMAGEN VIEJA-->
            <div class="cont-izq">
              <kendo-label text="{{ 'datosForja.h20a' | translate}}">

              </kendo-label>

              <div class="form-group">
                <div class="caja">

                  <kendo-fileselect [(ngModel)]="archivoHTASuperiorH20a" [restrictions]="restrictionsImage"
                                    [multiple]="false" (select)="archivoSeleccionadoHTASuperiorH20a($event)"
                                    (remove)="archivoEliminadoHTASuperiorH20a($event)">
                    <kendo-upload-messages cancel="{{ 'uploads.cancelar' | translate }}"
                                           clearSelectedFiles="{{ 'uploads.clearSelectedFiles' | translate }}"
                                           dropFilesHere="{{ 'uploads.dropFilesHere' | translate }}"
                                           externalDropFilesHere="{{ 'uploads.externalDropFilesHere' | translate }}"
                                           fileStatusFailed="{{ 'uploads.fileStatusFailed' | translate }}"
                                           fileStatusUploaded="{{ 'uploads.fileStatusUploaded' | translate }}"
                                           filesBatchStatus="{{ 'uploads.filesBatchStatus' | translate }}"
                                           filesBatchStatusFailed="{{ 'uploads.filesBatchStatusFailed' | translate }}"
                                           filesBatchStatusUploaded="{{ 'uploads.filesBatchStatusUploaded' | translate }}"
                                           headerStatusPaused="{{ 'uploads.headerStatusPaused' | translate }}"
                                           headerStatusUploaded="{{ 'uploads.headerStatusUploaded' | translate }}"
                                           headerStatusUploading="{{ 'uploads.headerStatusUploading' | translate }}"
                                           invalidFileExtension="{{ 'uploads.invalidFileExtension' | translate }}"
                                           invalidMaxFileSize="{{ 'uploads.invalidMaxFileSize' | translate }}"
                                           invalidMinFileSize="{{ 'uploads.invalidMinFileSize' | translate }}"
                                           pause="{{ 'uploads.pause' | translate }}"
                                           remove="{{ 'uploads.remove' | translate }}"
                                           resume="{{ 'uploads.resume' | translate }}"
                                           retry="{{ 'uploads.retry' | translate }}"
                                           select="{{ 'uploads.selectImages' | translate }}"
                                           uploadSelectedFiles="{{ 'uploads.uploadSelectedFiles' | translate }}">
                    </kendo-upload-messages>
                  </kendo-fileselect>

                </div>
              </div>
              <div class="form-group">
                <div class="caja">

                  <kendo-fileselect [(ngModel)]="pdfHTASuperiorH20a" [restrictions]="restrictionsPDF"
                                    [multiple]="false" (select)="pdfSeleccionadoHTASuperiorH20a($event)"
                                    (remove)="pdfEliminadoHTASuperiorH20a($event)">
                    <kendo-upload-messages cancel="{{ 'uploads.cancelar' | translate }}"
                                           clearSelectedFiles="{{ 'uploads.clearSelectedFiles' | translate }}"
                                           dropFilesHere="{{ 'uploads.dropFilesHere' | translate }}"
                                           externalDropFilesHere="{{ 'uploads.externalDropFilesHere' | translate }}"
                                           fileStatusFailed="{{ 'uploads.fileStatusFailed' | translate }}"
                                           fileStatusUploaded="{{ 'uploads.fileStatusUploaded' | translate }}"
                                           filesBatchStatus="{{ 'uploads.filesBatchStatus' | translate }}"
                                           filesBatchStatusFailed="{{ 'uploads.filesBatchStatusFailed' | translate }}"
                                           filesBatchStatusUploaded="{{ 'uploads.filesBatchStatusUploaded' | translate }}"
                                           headerStatusPaused="{{ 'uploads.headerStatusPaused' | translate }}"
                                           headerStatusUploaded="{{ 'uploads.headerStatusUploaded' | translate }}"
                                           headerStatusUploading="{{ 'uploads.headerStatusUploading' | translate }}"
                                           invalidFileExtension="{{ 'uploads.invalidFileExtension' | translate }}"
                                           invalidMaxFileSize="{{ 'uploads.invalidMaxFileSize' | translate }}"
                                           invalidMinFileSize="{{ 'uploads.invalidMinFileSize' | translate }}"
                                           pause="{{ 'uploads.pause' | translate }}"
                                           remove="{{ 'uploads.remove' | translate }}"
                                           resume="{{ 'uploads.resume' | translate }}"
                                           retry="{{ 'uploads.retry' | translate }}"
                                           select="{{ 'uploads.select' | translate }}"
                                           uploadSelectedFiles="{{ 'uploads.uploadSelectedFiles' | translate }}">
                    </kendo-upload-messages>
                  </kendo-fileselect>
                  <div class="cont-archivo" *ngIf="mostrarHTASuperiorH20a==true">
                    <div class="cont-archivo-inner">
                      <span class="k-file-group-wrapper">
                        <span class="k-file-group k-icon k-i-file-pdf"></span>
                      </span>
                      <span class="k-file-name-size-wrapper">
                        <span class="k-file-name">{{ 'configuracion.pdfHTASuperiorH20a' | translate}}</span>
                      </span>
                      <button type="button" class="k-button k-button-icon k-flat k-upload-action" tabindex="-1" (click)="pdfEliminadoHTASuperiorH15AMaestro($event)">
                        <span class="k-icon k-delete k-i-x"></span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <img [src]="imageToShowHTASuperiorH20a" class="img-conf" style="max-height: 113px">
          </div>
          <div class="caja-imagen">
            <!--IMAGEN VIEJA-->
            <div class="cont-izq">
              <kendo-label text="{{ 'datosForja.h22a' | translate}}">

              </kendo-label>

              <div class="form-group">
                <div class="caja">

                  <kendo-fileselect [(ngModel)]="archivoHTASuperiorH22a" [restrictions]="restrictionsImage"
                                    [multiple]="false" (select)="archivoSeleccionadoHTASuperiorH22a($event)"
                                    (remove)="archivoEliminadoHTASuperiorH22a($event)">
                    <kendo-upload-messages cancel="{{ 'uploads.cancelar' | translate }}"
                                           clearSelectedFiles="{{ 'uploads.clearSelectedFiles' | translate }}"
                                           dropFilesHere="{{ 'uploads.dropFilesHere' | translate }}"
                                           externalDropFilesHere="{{ 'uploads.externalDropFilesHere' | translate }}"
                                           fileStatusFailed="{{ 'uploads.fileStatusFailed' | translate }}"
                                           fileStatusUploaded="{{ 'uploads.fileStatusUploaded' | translate }}"
                                           filesBatchStatus="{{ 'uploads.filesBatchStatus' | translate }}"
                                           filesBatchStatusFailed="{{ 'uploads.filesBatchStatusFailed' | translate }}"
                                           filesBatchStatusUploaded="{{ 'uploads.filesBatchStatusUploaded' | translate }}"
                                           headerStatusPaused="{{ 'uploads.headerStatusPaused' | translate }}"
                                           headerStatusUploaded="{{ 'uploads.headerStatusUploaded' | translate }}"
                                           headerStatusUploading="{{ 'uploads.headerStatusUploading' | translate }}"
                                           invalidFileExtension="{{ 'uploads.invalidFileExtension' | translate }}"
                                           invalidMaxFileSize="{{ 'uploads.invalidMaxFileSize' | translate }}"
                                           invalidMinFileSize="{{ 'uploads.invalidMinFileSize' | translate }}"
                                           pause="{{ 'uploads.pause' | translate }}"
                                           remove="{{ 'uploads.remove' | translate }}"
                                           resume="{{ 'uploads.resume' | translate }}"
                                           retry="{{ 'uploads.retry' | translate }}"
                                           select="{{ 'uploads.selectImages' | translate }}"
                                           uploadSelectedFiles="{{ 'uploads.uploadSelectedFiles' | translate }}">
                    </kendo-upload-messages>
                  </kendo-fileselect>

                </div>
              </div>
              <div class="form-group">
                <div class="caja">

                  <kendo-fileselect [(ngModel)]="pdfHTASuperiorH22a" [restrictions]="restrictionsPDF"
                                    [multiple]="false" (select)="pdfSeleccionadoHTASuperiorH22a($event)"
                                    (remove)="pdfEliminadoHTASuperiorH22a($event)">
                    <kendo-upload-messages cancel="{{ 'uploads.cancelar' | translate }}"
                                           clearSelectedFiles="{{ 'uploads.clearSelectedFiles' | translate }}"
                                           dropFilesHere="{{ 'uploads.dropFilesHere' | translate }}"
                                           externalDropFilesHere="{{ 'uploads.externalDropFilesHere' | translate }}"
                                           fileStatusFailed="{{ 'uploads.fileStatusFailed' | translate }}"
                                           fileStatusUploaded="{{ 'uploads.fileStatusUploaded' | translate }}"
                                           filesBatchStatus="{{ 'uploads.filesBatchStatus' | translate }}"
                                           filesBatchStatusFailed="{{ 'uploads.filesBatchStatusFailed' | translate }}"
                                           filesBatchStatusUploaded="{{ 'uploads.filesBatchStatusUploaded' | translate }}"
                                           headerStatusPaused="{{ 'uploads.headerStatusPaused' | translate }}"
                                           headerStatusUploaded="{{ 'uploads.headerStatusUploaded' | translate }}"
                                           headerStatusUploading="{{ 'uploads.headerStatusUploading' | translate }}"
                                           invalidFileExtension="{{ 'uploads.invalidFileExtension' | translate }}"
                                           invalidMaxFileSize="{{ 'uploads.invalidMaxFileSize' | translate }}"
                                           invalidMinFileSize="{{ 'uploads.invalidMinFileSize' | translate }}"
                                           pause="{{ 'uploads.pause' | translate }}"
                                           remove="{{ 'uploads.remove' | translate }}"
                                           resume="{{ 'uploads.resume' | translate }}"
                                           retry="{{ 'uploads.retry' | translate }}"
                                           select="{{ 'uploads.select' | translate }}"
                                           uploadSelectedFiles="{{ 'uploads.uploadSelectedFiles' | translate }}">
                    </kendo-upload-messages>
                  </kendo-fileselect>
                  <div class="cont-archivo" *ngIf="mostrarHTASuperiorH22a==true">
                    <div class="cont-archivo-inner">
                      <span class="k-file-group-wrapper">
                        <span class="k-file-group k-icon k-i-file-pdf"></span>
                      </span>
                      <span class="k-file-name-size-wrapper">
                        <span class="k-file-name">{{ 'configuracion.pdfHTASuperiorH22a' | translate}}</span>
                      </span>
                      <button type="button" class="k-button k-button-icon k-flat k-upload-action" tabindex="-1" (click)="pdfEliminadoHTASuperiorH15AMaestro($event)">
                        <span class="k-icon k-delete k-i-x"></span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <img [src]="imageToShowHTASuperiorH22a" class="img-conf" style="max-height: 113px">
          </div>
          <div class="caja-imagen">
            <!--IMAGEN VIEJA-->
            <div class="cont-izq">
              <kendo-label text="{{ 'datosForja.h51a' | translate}}">

              </kendo-label>

              <div class="form-group">
                <div class="caja">

                  <kendo-fileselect [(ngModel)]="archivoHTASuperiorH51a" [restrictions]="restrictionsImage"
                                    [multiple]="false" (select)="archivoSeleccionadoHTASuperiorH51a($event)"
                                    (remove)="archivoEliminadoHTASuperiorH51a($event)">
                    <kendo-upload-messages cancel="{{ 'uploads.cancelar' | translate }}"
                                           clearSelectedFiles="{{ 'uploads.clearSelectedFiles' | translate }}"
                                           dropFilesHere="{{ 'uploads.dropFilesHere' | translate }}"
                                           externalDropFilesHere="{{ 'uploads.externalDropFilesHere' | translate }}"
                                           fileStatusFailed="{{ 'uploads.fileStatusFailed' | translate }}"
                                           fileStatusUploaded="{{ 'uploads.fileStatusUploaded' | translate }}"
                                           filesBatchStatus="{{ 'uploads.filesBatchStatus' | translate }}"
                                           filesBatchStatusFailed="{{ 'uploads.filesBatchStatusFailed' | translate }}"
                                           filesBatchStatusUploaded="{{ 'uploads.filesBatchStatusUploaded' | translate }}"
                                           headerStatusPaused="{{ 'uploads.headerStatusPaused' | translate }}"
                                           headerStatusUploaded="{{ 'uploads.headerStatusUploaded' | translate }}"
                                           headerStatusUploading="{{ 'uploads.headerStatusUploading' | translate }}"
                                           invalidFileExtension="{{ 'uploads.invalidFileExtension' | translate }}"
                                           invalidMaxFileSize="{{ 'uploads.invalidMaxFileSize' | translate }}"
                                           invalidMinFileSize="{{ 'uploads.invalidMinFileSize' | translate }}"
                                           pause="{{ 'uploads.pause' | translate }}"
                                           remove="{{ 'uploads.remove' | translate }}"
                                           resume="{{ 'uploads.resume' | translate }}"
                                           retry="{{ 'uploads.retry' | translate }}"
                                           select="{{ 'uploads.selectImages' | translate }}"
                                           uploadSelectedFiles="{{ 'uploads.uploadSelectedFiles' | translate }}">
                    </kendo-upload-messages>
                  </kendo-fileselect>

                </div>
              </div>
              <div class="form-group">
                <div class="caja">

                  <kendo-fileselect [(ngModel)]="pdfHTASuperiorH51a" [restrictions]="restrictionsPDF"
                                    [multiple]="false" (select)="pdfSeleccionadoHTASuperiorH51a($event)"
                                    (remove)="pdfEliminadoHTASuperiorH51a($event)">
                    <kendo-upload-messages cancel="{{ 'uploads.cancelar' | translate }}"
                                           clearSelectedFiles="{{ 'uploads.clearSelectedFiles' | translate }}"
                                           dropFilesHere="{{ 'uploads.dropFilesHere' | translate }}"
                                           externalDropFilesHere="{{ 'uploads.externalDropFilesHere' | translate }}"
                                           fileStatusFailed="{{ 'uploads.fileStatusFailed' | translate }}"
                                           fileStatusUploaded="{{ 'uploads.fileStatusUploaded' | translate }}"
                                           filesBatchStatus="{{ 'uploads.filesBatchStatus' | translate }}"
                                           filesBatchStatusFailed="{{ 'uploads.filesBatchStatusFailed' | translate }}"
                                           filesBatchStatusUploaded="{{ 'uploads.filesBatchStatusUploaded' | translate }}"
                                           headerStatusPaused="{{ 'uploads.headerStatusPaused' | translate }}"
                                           headerStatusUploaded="{{ 'uploads.headerStatusUploaded' | translate }}"
                                           headerStatusUploading="{{ 'uploads.headerStatusUploading' | translate }}"
                                           invalidFileExtension="{{ 'uploads.invalidFileExtension' | translate }}"
                                           invalidMaxFileSize="{{ 'uploads.invalidMaxFileSize' | translate }}"
                                           invalidMinFileSize="{{ 'uploads.invalidMinFileSize' | translate }}"
                                           pause="{{ 'uploads.pause' | translate }}"
                                           remove="{{ 'uploads.remove' | translate }}"
                                           resume="{{ 'uploads.resume' | translate }}"
                                           retry="{{ 'uploads.retry' | translate }}"
                                           select="{{ 'uploads.select' | translate }}"
                                           uploadSelectedFiles="{{ 'uploads.uploadSelectedFiles' | translate }}">
                    </kendo-upload-messages>
                  </kendo-fileselect>
                  <div class="cont-archivo" *ngIf="mostrarHTASuperiorH51a==true">
                    <div class="cont-archivo-inner">
                      <span class="k-file-group-wrapper">
                        <span class="k-file-group k-icon k-i-file-pdf"></span>
                      </span>
                      <span class="k-file-name-size-wrapper">
                        <span class="k-file-name">{{ 'configuracion.pdfHTASuperiorH51a' | translate}}</span>
                      </span>
                      <button type="button" class="k-button k-button-icon k-flat k-upload-action" tabindex="-1" (click)="pdfEliminadoHTASuperiorH15AMaestro($event)">
                        <span class="k-icon k-delete k-i-x"></span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <img [src]="imageToShowHTASuperiorH51a" class="img-conf" style="max-height: 113px">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-3 col-12">
    <div class="card">
      <div class="card-header">
        <h3>{{ 'datosForja.htaInferior' | translate}}</h3>
        <div class="plegarpanel"></div>
      </div>
      <div class="card-body">
        <div class="caja-imagen">
          <!--IMAGEN VIEJA-->
          <div class="cont-izq">
            <kendo-label text="{{ 'datosForja.h30a' | translate}}">

            </kendo-label>

            <div class="form-group">
              <div class="caja">

                <kendo-fileselect [(ngModel)]="archivoHTAInferiorH30AMaestro1" [restrictions]="restrictionsImage"
                                  [multiple]="false" (select)="archivoSeleccionadoHTAInferiorH30AMaestro1($event)"
                                  (remove)="archivoEliminadoHTAInferiorH30AMaestro1($event)">
                  <kendo-upload-messages cancel="{{ 'uploads.cancelar' | translate }}"
                                         clearSelectedFiles="{{ 'uploads.clearSelectedFiles' | translate }}"
                                         dropFilesHere="{{ 'uploads.dropFilesHere' | translate }}"
                                         externalDropFilesHere="{{ 'uploads.externalDropFilesHere' | translate }}"
                                         fileStatusFailed="{{ 'uploads.fileStatusFailed' | translate }}"
                                         fileStatusUploaded="{{ 'uploads.fileStatusUploaded' | translate }}"
                                         filesBatchStatus="{{ 'uploads.filesBatchStatus' | translate }}"
                                         filesBatchStatusFailed="{{ 'uploads.filesBatchStatusFailed' | translate }}"
                                         filesBatchStatusUploaded="{{ 'uploads.filesBatchStatusUploaded' | translate }}"
                                         headerStatusPaused="{{ 'uploads.headerStatusPaused' | translate }}"
                                         headerStatusUploaded="{{ 'uploads.headerStatusUploaded' | translate }}"
                                         headerStatusUploading="{{ 'uploads.headerStatusUploading' | translate }}"
                                         invalidFileExtension="{{ 'uploads.invalidFileExtension' | translate }}"
                                         invalidMaxFileSize="{{ 'uploads.invalidMaxFileSize' | translate }}"
                                         invalidMinFileSize="{{ 'uploads.invalidMinFileSize' | translate }}"
                                         pause="{{ 'uploads.pause' | translate }}"
                                         remove="{{ 'uploads.remove' | translate }}"
                                         resume="{{ 'uploads.resume' | translate }}"
                                         retry="{{ 'uploads.retry' | translate }}"
                                         select="{{ 'uploads.selectImages' | translate }}"
                                         uploadSelectedFiles="{{ 'uploads.uploadSelectedFiles' | translate }}">
                  </kendo-upload-messages>
                </kendo-fileselect>

              </div>
            </div>
            <div class="form-group">
              <div class="caja">

                <kendo-fileselect [(ngModel)]="pdfHTAInferiorH30AMaestro1" [restrictions]="restrictionsPDF"
                                  [multiple]="false" (select)="pdfSeleccionadoHTAInferiorH30AMaestro1($event)"
                                  (remove)="pdfEliminadoHTAInferiorH30AMaestro1($event)">
                  <kendo-upload-messages cancel="{{ 'uploads.cancelar' | translate }}"
                                         clearSelectedFiles="{{ 'uploads.clearSelectedFiles' | translate }}"
                                         dropFilesHere="{{ 'uploads.dropFilesHere' | translate }}"
                                         externalDropFilesHere="{{ 'uploads.externalDropFilesHere' | translate }}"
                                         fileStatusFailed="{{ 'uploads.fileStatusFailed' | translate }}"
                                         fileStatusUploaded="{{ 'uploads.fileStatusUploaded' | translate }}"
                                         filesBatchStatus="{{ 'uploads.filesBatchStatus' | translate }}"
                                         filesBatchStatusFailed="{{ 'uploads.filesBatchStatusFailed' | translate }}"
                                         filesBatchStatusUploaded="{{ 'uploads.filesBatchStatusUploaded' | translate }}"
                                         headerStatusPaused="{{ 'uploads.headerStatusPaused' | translate }}"
                                         headerStatusUploaded="{{ 'uploads.headerStatusUploaded' | translate }}"
                                         headerStatusUploading="{{ 'uploads.headerStatusUploading' | translate }}"
                                         invalidFileExtension="{{ 'uploads.invalidFileExtension' | translate }}"
                                         invalidMaxFileSize="{{ 'uploads.invalidMaxFileSize' | translate }}"
                                         invalidMinFileSize="{{ 'uploads.invalidMinFileSize' | translate }}"
                                         pause="{{ 'uploads.pause' | translate }}"
                                         remove="{{ 'uploads.remove' | translate }}"
                                         resume="{{ 'uploads.resume' | translate }}"
                                         retry="{{ 'uploads.retry' | translate }}"
                                         select="{{ 'uploads.select' | translate }}"
                                         uploadSelectedFiles="{{ 'uploads.uploadSelectedFiles' | translate }}">
                  </kendo-upload-messages>
                </kendo-fileselect>
                <div class="cont-archivo" *ngIf="mostrarHTAInferiorH30AMaestro1==true">
                  <div class="cont-archivo-inner">
                    <span class="k-file-group-wrapper">
                      <span class="k-file-group k-icon k-i-file-pdf"></span>
                    </span>
                    <span class="k-file-name-size-wrapper">
                      <span class="k-file-name">{{ 'configuracion.pdfHTAInferiorH30AMaestro1' | translate}}</span>
                    </span>
                    <button type="button" class="k-button k-button-icon k-flat k-upload-action" tabindex="-1" (click)="pdfEliminadoHTASuperiorH15AMaestro($event)">
                      <span class="k-icon k-delete k-i-x"></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img [src]="imageToShowHTAInferiorH30AMaestro1" class="img-conf" style="max-height: 113px">
        </div>
        <div class="caja-imagen">
          <!--IMAGEN VIEJA-->
          <div class="cont-izq">
            <kendo-label text="{{ 'datosForja.h39a' | translate}}">

            </kendo-label>

            <div class="form-group">
              <div class="caja">

                <kendo-fileselect [(ngModel)]="archivoHTAInferiorH39a" [restrictions]="restrictionsImage"
                                  [multiple]="false" (select)="archivoSeleccionadoHTAInferiorH39a($event)"
                                  (remove)="archivoEliminadoHTAInferiorH39a($event)">
                  <kendo-upload-messages cancel="{{ 'uploads.cancelar' | translate }}"
                                         clearSelectedFiles="{{ 'uploads.clearSelectedFiles' | translate }}"
                                         dropFilesHere="{{ 'uploads.dropFilesHere' | translate }}"
                                         externalDropFilesHere="{{ 'uploads.externalDropFilesHere' | translate }}"
                                         fileStatusFailed="{{ 'uploads.fileStatusFailed' | translate }}"
                                         fileStatusUploaded="{{ 'uploads.fileStatusUploaded' | translate }}"
                                         filesBatchStatus="{{ 'uploads.filesBatchStatus' | translate }}"
                                         filesBatchStatusFailed="{{ 'uploads.filesBatchStatusFailed' | translate }}"
                                         filesBatchStatusUploaded="{{ 'uploads.filesBatchStatusUploaded' | translate }}"
                                         headerStatusPaused="{{ 'uploads.headerStatusPaused' | translate }}"
                                         headerStatusUploaded="{{ 'uploads.headerStatusUploaded' | translate }}"
                                         headerStatusUploading="{{ 'uploads.headerStatusUploading' | translate }}"
                                         invalidFileExtension="{{ 'uploads.invalidFileExtension' | translate }}"
                                         invalidMaxFileSize="{{ 'uploads.invalidMaxFileSize' | translate }}"
                                         invalidMinFileSize="{{ 'uploads.invalidMinFileSize' | translate }}"
                                         pause="{{ 'uploads.pause' | translate }}"
                                         remove="{{ 'uploads.remove' | translate }}"
                                         resume="{{ 'uploads.resume' | translate }}"
                                         retry="{{ 'uploads.retry' | translate }}"
                                         select="{{ 'uploads.selectImages' | translate }}"
                                         uploadSelectedFiles="{{ 'uploads.uploadSelectedFiles' | translate }}">
                  </kendo-upload-messages>
                </kendo-fileselect>

              </div>
            </div>
            <div class="form-group">
              <div class="caja">

                <kendo-fileselect [(ngModel)]="pdfHTAInferiorH39a" [restrictions]="restrictionsPDF"
                                  [multiple]="false" (select)="pdfSeleccionadoHTAInferiorH39a($event)"
                                  (remove)="pdfEliminadoHTAInferiorH39a($event)">
                  <kendo-upload-messages cancel="{{ 'uploads.cancelar' | translate }}"
                                         clearSelectedFiles="{{ 'uploads.clearSelectedFiles' | translate }}"
                                         dropFilesHere="{{ 'uploads.dropFilesHere' | translate }}"
                                         externalDropFilesHere="{{ 'uploads.externalDropFilesHere' | translate }}"
                                         fileStatusFailed="{{ 'uploads.fileStatusFailed' | translate }}"
                                         fileStatusUploaded="{{ 'uploads.fileStatusUploaded' | translate }}"
                                         filesBatchStatus="{{ 'uploads.filesBatchStatus' | translate }}"
                                         filesBatchStatusFailed="{{ 'uploads.filesBatchStatusFailed' | translate }}"
                                         filesBatchStatusUploaded="{{ 'uploads.filesBatchStatusUploaded' | translate }}"
                                         headerStatusPaused="{{ 'uploads.headerStatusPaused' | translate }}"
                                         headerStatusUploaded="{{ 'uploads.headerStatusUploaded' | translate }}"
                                         headerStatusUploading="{{ 'uploads.headerStatusUploading' | translate }}"
                                         invalidFileExtension="{{ 'uploads.invalidFileExtension' | translate }}"
                                         invalidMaxFileSize="{{ 'uploads.invalidMaxFileSize' | translate }}"
                                         invalidMinFileSize="{{ 'uploads.invalidMinFileSize' | translate }}"
                                         pause="{{ 'uploads.pause' | translate }}"
                                         remove="{{ 'uploads.remove' | translate }}"
                                         resume="{{ 'uploads.resume' | translate }}"
                                         retry="{{ 'uploads.retry' | translate }}"
                                         select="{{ 'uploads.select' | translate }}"
                                         uploadSelectedFiles="{{ 'uploads.uploadSelectedFiles' | translate }}">
                  </kendo-upload-messages>
                </kendo-fileselect>
                <div class="cont-archivo" *ngIf="mostrarHTAInferiorH39a==true">
                  <div class="cont-archivo-inner">
                    <span class="k-file-group-wrapper">
                      <span class="k-file-group k-icon k-i-file-pdf"></span>
                    </span>
                    <span class="k-file-name-size-wrapper">
                      <span class="k-file-name">{{ 'configuracion.pdfHTAInferiorH39a' | translate}}</span>
                    </span>
                    <button type="button" class="k-button k-button-icon k-flat k-upload-action" tabindex="-1" (click)="pdfEliminadoHTASuperiorH15AMaestro($event)">
                      <span class="k-icon k-delete k-i-x"></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img [src]="imageToShowHTAInferiorH39a" class="img-conf" style="max-height: 113px">
        </div>
        <div class="caja-imagen">
          <!--IMAGEN VIEJA-->
          <div class="cont-izq">
            <kendo-label text="{{ 'datosForja.h30a' | translate}}">

            </kendo-label>

            <div class="form-group">
              <div class="caja">

                <kendo-fileselect [(ngModel)]="archivoHTAInferiorH30AMaestro2" [restrictions]="restrictionsImage"
                                  [multiple]="false" (select)="archivoSeleccionadoHTAInferiorH30AMaestro2($event)"
                                  (remove)="archivoEliminadoHTAInferiorH30AMaestro2($event)">
                  <kendo-upload-messages cancel="{{ 'uploads.cancelar' | translate }}"
                                         clearSelectedFiles="{{ 'uploads.clearSelectedFiles' | translate }}"
                                         dropFilesHere="{{ 'uploads.dropFilesHere' | translate }}"
                                         externalDropFilesHere="{{ 'uploads.externalDropFilesHere' | translate }}"
                                         fileStatusFailed="{{ 'uploads.fileStatusFailed' | translate }}"
                                         fileStatusUploaded="{{ 'uploads.fileStatusUploaded' | translate }}"
                                         filesBatchStatus="{{ 'uploads.filesBatchStatus' | translate }}"
                                         filesBatchStatusFailed="{{ 'uploads.filesBatchStatusFailed' | translate }}"
                                         filesBatchStatusUploaded="{{ 'uploads.filesBatchStatusUploaded' | translate }}"
                                         headerStatusPaused="{{ 'uploads.headerStatusPaused' | translate }}"
                                         headerStatusUploaded="{{ 'uploads.headerStatusUploaded' | translate }}"
                                         headerStatusUploading="{{ 'uploads.headerStatusUploading' | translate }}"
                                         invalidFileExtension="{{ 'uploads.invalidFileExtension' | translate }}"
                                         invalidMaxFileSize="{{ 'uploads.invalidMaxFileSize' | translate }}"
                                         invalidMinFileSize="{{ 'uploads.invalidMinFileSize' | translate }}"
                                         pause="{{ 'uploads.pause' | translate }}"
                                         remove="{{ 'uploads.remove' | translate }}"
                                         resume="{{ 'uploads.resume' | translate }}"
                                         retry="{{ 'uploads.retry' | translate }}"
                                         select="{{ 'uploads.selectImages' | translate }}"
                                         uploadSelectedFiles="{{ 'uploads.uploadSelectedFiles' | translate }}">
                  </kendo-upload-messages>
                </kendo-fileselect>

              </div>
            </div>
            <div class="form-group">
              <div class="caja">

                <kendo-fileselect [(ngModel)]="pdfHTAInferiorH30AMaestro2" [restrictions]="restrictionsPDF"
                                  [multiple]="false" (select)="pdfSeleccionadoHTAInferiorH30AMaestro2($event)"
                                  (remove)="pdfEliminadoHTAInferiorH30AMaestro2($event)">
                  <kendo-upload-messages cancel="{{ 'uploads.cancelar' | translate }}"
                                         clearSelectedFiles="{{ 'uploads.clearSelectedFiles' | translate }}"
                                         dropFilesHere="{{ 'uploads.dropFilesHere' | translate }}"
                                         externalDropFilesHere="{{ 'uploads.externalDropFilesHere' | translate }}"
                                         fileStatusFailed="{{ 'uploads.fileStatusFailed' | translate }}"
                                         fileStatusUploaded="{{ 'uploads.fileStatusUploaded' | translate }}"
                                         filesBatchStatus="{{ 'uploads.filesBatchStatus' | translate }}"
                                         filesBatchStatusFailed="{{ 'uploads.filesBatchStatusFailed' | translate }}"
                                         filesBatchStatusUploaded="{{ 'uploads.filesBatchStatusUploaded' | translate }}"
                                         headerStatusPaused="{{ 'uploads.headerStatusPaused' | translate }}"
                                         headerStatusUploaded="{{ 'uploads.headerStatusUploaded' | translate }}"
                                         headerStatusUploading="{{ 'uploads.headerStatusUploading' | translate }}"
                                         invalidFileExtension="{{ 'uploads.invalidFileExtension' | translate }}"
                                         invalidMaxFileSize="{{ 'uploads.invalidMaxFileSize' | translate }}"
                                         invalidMinFileSize="{{ 'uploads.invalidMinFileSize' | translate }}"
                                         pause="{{ 'uploads.pause' | translate }}"
                                         remove="{{ 'uploads.remove' | translate }}"
                                         resume="{{ 'uploads.resume' | translate }}"
                                         retry="{{ 'uploads.retry' | translate }}"
                                         select="{{ 'uploads.select' | translate }}"
                                         uploadSelectedFiles="{{ 'uploads.uploadSelectedFiles' | translate }}">
                  </kendo-upload-messages>
                </kendo-fileselect>
                <div class="cont-archivo" *ngIf="mostrarHTAInferiorH30AMaestro2==true">
                  <div class="cont-archivo-inner">
                    <span class="k-file-group-wrapper">
                      <span class="k-file-group k-icon k-i-file-pdf"></span>
                    </span>
                    <span class="k-file-name-size-wrapper">
                      <span class="k-file-name">{{ 'configuracion.pdfHTAInferiorH30AMaestro2' | translate}}</span>
                    </span>
                    <button type="button" class="k-button k-button-icon k-flat k-upload-action" tabindex="-1" (click)="pdfEliminadoHTASuperiorH15AMaestro($event)">
                      <span class="k-icon k-delete k-i-x"></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img [src]="imageToShowHTAInferiorH30AMaestro2" class="img-conf" style="max-height: 113px">
        </div>
        <div class="caja-imagen">
          <!--IMAGEN VIEJA-->
          <div class="cont-izq">
            <kendo-label text="{{ 'datosForja.h40a' | translate}}">

            </kendo-label>

            <div class="form-group">
              <div class="caja">

                <kendo-fileselect [(ngModel)]="archivoHTAInferiorH40a" [restrictions]="restrictionsImage"
                                  [multiple]="false" (select)="archivoSeleccionadoHTAInferiorH40a($event)"
                                  (remove)="archivoEliminadoHTAInferiorH40a($event)">
                  <kendo-upload-messages cancel="{{ 'uploads.cancelar' | translate }}"
                                         clearSelectedFiles="{{ 'uploads.clearSelectedFiles' | translate }}"
                                         dropFilesHere="{{ 'uploads.dropFilesHere' | translate }}"
                                         externalDropFilesHere="{{ 'uploads.externalDropFilesHere' | translate }}"
                                         fileStatusFailed="{{ 'uploads.fileStatusFailed' | translate }}"
                                         fileStatusUploaded="{{ 'uploads.fileStatusUploaded' | translate }}"
                                         filesBatchStatus="{{ 'uploads.filesBatchStatus' | translate }}"
                                         filesBatchStatusFailed="{{ 'uploads.filesBatchStatusFailed' | translate }}"
                                         filesBatchStatusUploaded="{{ 'uploads.filesBatchStatusUploaded' | translate }}"
                                         headerStatusPaused="{{ 'uploads.headerStatusPaused' | translate }}"
                                         headerStatusUploaded="{{ 'uploads.headerStatusUploaded' | translate }}"
                                         headerStatusUploading="{{ 'uploads.headerStatusUploading' | translate }}"
                                         invalidFileExtension="{{ 'uploads.invalidFileExtension' | translate }}"
                                         invalidMaxFileSize="{{ 'uploads.invalidMaxFileSize' | translate }}"
                                         invalidMinFileSize="{{ 'uploads.invalidMinFileSize' | translate }}"
                                         pause="{{ 'uploads.pause' | translate }}"
                                         remove="{{ 'uploads.remove' | translate }}"
                                         resume="{{ 'uploads.resume' | translate }}"
                                         retry="{{ 'uploads.retry' | translate }}"
                                         select="{{ 'uploads.selectImages' | translate }}"
                                         uploadSelectedFiles="{{ 'uploads.uploadSelectedFiles' | translate }}">
                  </kendo-upload-messages>
                </kendo-fileselect>

              </div>
            </div>
            <div class="form-group">
              <div class="caja">

                <kendo-fileselect [(ngModel)]="pdfHTAInferiorH40a" [restrictions]="restrictionsPDF"
                                  [multiple]="false" (select)="pdfSeleccionadoHTAInferiorH40a($event)"
                                  (remove)="pdfEliminadoHTAInferiorH40a($event)">
                  <kendo-upload-messages cancel="{{ 'uploads.cancelar' | translate }}"
                                         clearSelectedFiles="{{ 'uploads.clearSelectedFiles' | translate }}"
                                         dropFilesHere="{{ 'uploads.dropFilesHere' | translate }}"
                                         externalDropFilesHere="{{ 'uploads.externalDropFilesHere' | translate }}"
                                         fileStatusFailed="{{ 'uploads.fileStatusFailed' | translate }}"
                                         fileStatusUploaded="{{ 'uploads.fileStatusUploaded' | translate }}"
                                         filesBatchStatus="{{ 'uploads.filesBatchStatus' | translate }}"
                                         filesBatchStatusFailed="{{ 'uploads.filesBatchStatusFailed' | translate }}"
                                         filesBatchStatusUploaded="{{ 'uploads.filesBatchStatusUploaded' | translate }}"
                                         headerStatusPaused="{{ 'uploads.headerStatusPaused' | translate }}"
                                         headerStatusUploaded="{{ 'uploads.headerStatusUploaded' | translate }}"
                                         headerStatusUploading="{{ 'uploads.headerStatusUploading' | translate }}"
                                         invalidFileExtension="{{ 'uploads.invalidFileExtension' | translate }}"
                                         invalidMaxFileSize="{{ 'uploads.invalidMaxFileSize' | translate }}"
                                         invalidMinFileSize="{{ 'uploads.invalidMinFileSize' | translate }}"
                                         pause="{{ 'uploads.pause' | translate }}"
                                         remove="{{ 'uploads.remove' | translate }}"
                                         resume="{{ 'uploads.resume' | translate }}"
                                         retry="{{ 'uploads.retry' | translate }}"
                                         select="{{ 'uploads.select' | translate }}"
                                         uploadSelectedFiles="{{ 'uploads.uploadSelectedFiles' | translate }}">
                  </kendo-upload-messages>
                </kendo-fileselect>
                <div class="cont-archivo" *ngIf="mostrarHTAInferiorH40a==true">
                  <div class="cont-archivo-inner">
                    <span class="k-file-group-wrapper">
                      <span class="k-file-group k-icon k-i-file-pdf"></span>
                    </span>
                    <span class="k-file-name-size-wrapper">
                      <span class="k-file-name">{{ 'configuracion.pdfHTAInferiorH40a' | translate}}</span>
                    </span>
                    <button type="button" class="k-button k-button-icon k-flat k-upload-action" tabindex="-1" (click)="pdfEliminadoHTASuperiorH15AMaestro($event)">
                      <span class="k-icon k-delete k-i-x"></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img [src]="imageToShowHTAInferiorH40a" class="img-conf" style="max-height: 113px">
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-5 col-12">
    <div class="card">
      <div class="card-header">
        <h3>{{ 'datosForja.piecerioHerramientaP10000' | translate}}</h3>
        <div class="plegarpanel"></div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col">

            <h4 class="subtitulo">{{'datosForja.inferior' | translate}}</h4>
            <div class="clearfix">

              <div class="caja-imagen">
                <!--IMAGEN VIEJA-->
                <div class="cont-izq">
                  <kendo-label text="{{ 'datosForja.prepunzonadoInferior1aOP' | translate}}">

                  </kendo-label>

                  <div class="form-group">
                    <div class="caja">
                      <kendo-fileselect [(ngModel)]="archivoPiecerioPrepunzonadoInferior1OP"
                                        [restrictions]="restrictionsImage" [multiple]="false"
                                        (select)="archivoSeleccionadoPiecerioPrepunzonadoInferior1OP($event)"
                                        (remove)="archivoEliminadoPiecerioPrepunzonadoInferior1OP($event)">
                        <kendo-upload-messages cancel="{{ 'uploads.cancelar' | translate }}"
                                               clearSelectedFiles="{{ 'uploads.clearSelectedFiles' | translate }}"
                                               dropFilesHere="{{ 'uploads.dropFilesHere' | translate }}"
                                               externalDropFilesHere="{{ 'uploads.externalDropFilesHere' | translate }}"
                                               fileStatusFailed="{{ 'uploads.fileStatusFailed' | translate }}"
                                               fileStatusUploaded="{{ 'uploads.fileStatusUploaded' | translate }}"
                                               filesBatchStatus="{{ 'uploads.filesBatchStatus' | translate }}"
                                               filesBatchStatusFailed="{{ 'uploads.filesBatchStatusFailed' | translate }}"
                                               filesBatchStatusUploaded="{{ 'uploads.filesBatchStatusUploaded' | translate }}"
                                               headerStatusPaused="{{ 'uploads.headerStatusPaused' | translate }}"
                                               headerStatusUploaded="{{ 'uploads.headerStatusUploaded' | translate }}"
                                               headerStatusUploading="{{ 'uploads.headerStatusUploading' | translate }}"
                                               invalidFileExtension="{{ 'uploads.invalidFileExtension' | translate }}"
                                               invalidMaxFileSize="{{ 'uploads.invalidMaxFileSize' | translate }}"
                                               invalidMinFileSize="{{ 'uploads.invalidMinFileSize' | translate }}"
                                               pause="{{ 'uploads.pause' | translate }}"
                                               remove="{{ 'uploads.remove' | translate }}"
                                               resume="{{ 'uploads.resume' | translate }}"
                                               retry="{{ 'uploads.retry' | translate }}"
                                               select="{{ 'uploads.selectImages' | translate }}"
                                               uploadSelectedFiles="{{ 'uploads.uploadSelectedFiles' | translate }}">
                        </kendo-upload-messages>
                      </kendo-fileselect>

                    </div>
                  </div>
                  <div class="form-group">
                    <div class="caja">
                      <kendo-fileselect [(ngModel)]="pdfPiecerioPrepunzonadoInferior1OP"
                                        [restrictions]="restrictionsPDF" [multiple]="false"
                                        (select)="pdfSeleccionadoPiecerioPrepunzonadoInferior1OP($event)"
                                        (remove)="pdfEliminadoPiecerioPrepunzonadoInferior1OP($event)">
                        <kendo-upload-messages cancel="{{ 'uploads.cancelar' | translate }}"
                                               clearSelectedFiles="{{ 'uploads.clearSelectedFiles' | translate }}"
                                               dropFilesHere="{{ 'uploads.dropFilesHere' | translate }}"
                                               externalDropFilesHere="{{ 'uploads.externalDropFilesHere' | translate }}"
                                               fileStatusFailed="{{ 'uploads.fileStatusFailed' | translate }}"
                                               fileStatusUploaded="{{ 'uploads.fileStatusUploaded' | translate }}"
                                               filesBatchStatus="{{ 'uploads.filesBatchStatus' | translate }}"
                                               filesBatchStatusFailed="{{ 'uploads.filesBatchStatusFailed' | translate }}"
                                               filesBatchStatusUploaded="{{ 'uploads.filesBatchStatusUploaded' | translate }}"
                                               headerStatusPaused="{{ 'uploads.headerStatusPaused' | translate }}"
                                               headerStatusUploaded="{{ 'uploads.headerStatusUploaded' | translate }}"
                                               headerStatusUploading="{{ 'uploads.headerStatusUploading' | translate }}"
                                               invalidFileExtension="{{ 'uploads.invalidFileExtension' | translate }}"
                                               invalidMaxFileSize="{{ 'uploads.invalidMaxFileSize' | translate }}"
                                               invalidMinFileSize="{{ 'uploads.invalidMinFileSize' | translate }}"
                                               pause="{{ 'uploads.pause' | translate }}"
                                               remove="{{ 'uploads.remove' | translate }}"
                                               resume="{{ 'uploads.resume' | translate }}"
                                               retry="{{ 'uploads.retry' | translate }}"
                                               select="{{ 'uploads.select' | translate }}"
                                               uploadSelectedFiles="{{ 'uploads.uploadSelectedFiles' | translate }}">
                        </kendo-upload-messages>
                      </kendo-fileselect>
                      <div class="cont-archivo" *ngIf="mostrarPiecerioPrepunzonadoInferior1OP==true">
                        <div class="cont-archivo-inner">
                          <span class="k-file-group-wrapper">
                            <span class="k-file-group k-icon k-i-file-pdf"></span>
                          </span>
                          <span class="k-file-name-size-wrapper">
                            <span class="k-file-name">{{ 'configuracion.pdfPiecerioPrepunzonadoInferior1OP' | translate}}</span>
                          </span>
                          <button type="button" class="k-button k-button-icon k-flat k-upload-action" tabindex="-1" (click)="pdfEliminadoHTASuperiorH15AMaestro($event)">
                            <span class="k-icon k-delete k-i-x"></span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <img [src]="imageToShowPiecerioPrepunzonadoInferior1OP" class="img-conf" style="max-height: 113px">
              </div>
              <div class="caja-imagen">
                <!--IMAGEN VIEJA-->
                <div class="cont-izq">
                  <kendo-label text="{{ 'datosForja.subextractor' | translate}}">

                  </kendo-label>

                  <div class="form-group">
                    <div class="caja">

                      <kendo-fileselect [(ngModel)]="archivoPiecerioSubextractor" [restrictions]="restrictionsImage"
                                        [multiple]="false" (select)="archivoSeleccionadoPiecerioSubextractor($event)"
                                        (remove)="archivoEliminadoPiecerioSubextractor($event)">
                        <kendo-upload-messages cancel="{{ 'uploads.cancelar' | translate }}"
                                               clearSelectedFiles="{{ 'uploads.clearSelectedFiles' | translate }}"
                                               dropFilesHere="{{ 'uploads.dropFilesHere' | translate }}"
                                               externalDropFilesHere="{{ 'uploads.externalDropFilesHere' | translate }}"
                                               fileStatusFailed="{{ 'uploads.fileStatusFailed' | translate }}"
                                               fileStatusUploaded="{{ 'uploads.fileStatusUploaded' | translate }}"
                                               filesBatchStatus="{{ 'uploads.filesBatchStatus' | translate }}"
                                               filesBatchStatusFailed="{{ 'uploads.filesBatchStatusFailed' | translate }}"
                                               filesBatchStatusUploaded="{{ 'uploads.filesBatchStatusUploaded' | translate }}"
                                               headerStatusPaused="{{ 'uploads.headerStatusPaused' | translate }}"
                                               headerStatusUploaded="{{ 'uploads.headerStatusUploaded' | translate }}"
                                               headerStatusUploading="{{ 'uploads.headerStatusUploading' | translate }}"
                                               invalidFileExtension="{{ 'uploads.invalidFileExtension' | translate }}"
                                               invalidMaxFileSize="{{ 'uploads.invalidMaxFileSize' | translate }}"
                                               invalidMinFileSize="{{ 'uploads.invalidMinFileSize' | translate }}"
                                               pause="{{ 'uploads.pause' | translate }}"
                                               remove="{{ 'uploads.remove' | translate }}"
                                               resume="{{ 'uploads.resume' | translate }}"
                                               retry="{{ 'uploads.retry' | translate }}"
                                               select="{{ 'uploads.selectImages' | translate }}"
                                               uploadSelectedFiles="{{ 'uploads.uploadSelectedFiles' | translate }}">
                        </kendo-upload-messages>
                      </kendo-fileselect>

                    </div>
                  </div>
                  <div class="form-group">
                    <div class="caja">

                      <kendo-fileselect [(ngModel)]="pdfPiecerioSubextractor" [restrictions]="restrictionsPDF"
                                        [multiple]="false" (select)="pdfSeleccionadoPiecerioSubextractor($event)"
                                        (remove)="pdfEliminadoPiecerioSubextractor($event)">
                        <kendo-upload-messages cancel="{{ 'uploads.cancelar' | translate }}"
                                               clearSelectedFiles="{{ 'uploads.clearSelectedFiles' | translate }}"
                                               dropFilesHere="{{ 'uploads.dropFilesHere' | translate }}"
                                               externalDropFilesHere="{{ 'uploads.externalDropFilesHere' | translate }}"
                                               fileStatusFailed="{{ 'uploads.fileStatusFailed' | translate }}"
                                               fileStatusUploaded="{{ 'uploads.fileStatusUploaded' | translate }}"
                                               filesBatchStatus="{{ 'uploads.filesBatchStatus' | translate }}"
                                               filesBatchStatusFailed="{{ 'uploads.filesBatchStatusFailed' | translate }}"
                                               filesBatchStatusUploaded="{{ 'uploads.filesBatchStatusUploaded' | translate }}"
                                               headerStatusPaused="{{ 'uploads.headerStatusPaused' | translate }}"
                                               headerStatusUploaded="{{ 'uploads.headerStatusUploaded' | translate }}"
                                               headerStatusUploading="{{ 'uploads.headerStatusUploading' | translate }}"
                                               invalidFileExtension="{{ 'uploads.invalidFileExtension' | translate }}"
                                               invalidMaxFileSize="{{ 'uploads.invalidMaxFileSize' | translate }}"
                                               invalidMinFileSize="{{ 'uploads.invalidMinFileSize' | translate }}"
                                               pause="{{ 'uploads.pause' | translate }}"
                                               remove="{{ 'uploads.remove' | translate }}"
                                               resume="{{ 'uploads.resume' | translate }}"
                                               retry="{{ 'uploads.retry' | translate }}"
                                               select="{{ 'uploads.select' | translate }}"
                                               uploadSelectedFiles="{{ 'uploads.uploadSelectedFiles' | translate }}">
                        </kendo-upload-messages>
                      </kendo-fileselect>
                      <div class="cont-archivo" *ngIf="mostrarPiecerioSubextractor==true">
                        <div class="cont-archivo-inner">
                          <span class="k-file-group-wrapper">
                            <span class="k-file-group k-icon k-i-file-pdf"></span>
                          </span>
                          <span class="k-file-name-size-wrapper">
                            <span class="k-file-name">{{ 'configuracion.pdfPiecerioSubextractor' | translate}}</span>
                          </span>
                          <button type="button" class="k-button k-button-icon k-flat k-upload-action" tabindex="-1" (click)="pdfEliminadoHTASuperiorH15AMaestro($event)">
                            <span class="k-icon k-delete k-i-x"></span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <img [src]="imageToShowPiecerioSubextractor" class="img-conf" style="max-height: 113px">
              </div>
              <div class="caja-imagen">
                <!--IMAGEN VIEJA-->
                <div class="cont-izq">
                  <kendo-label text="{{ 'datosForja.basePrepunzonadoInferior' | translate}}">

                  </kendo-label>

                  <div class="form-group">
                    <div class="caja">

                      <kendo-fileselect [(ngModel)]="archivoPiecerioBasePrepunzonadoInferior"
                                        [restrictions]="restrictionsImage" [multiple]="false"
                                        (select)="archivoSeleccionadoPiecerioBasePrepunzonadoInferior($event)"
                                        (remove)="archivoEliminadoPiecerioBasePrepunzonadoInferior($event)">
                        <kendo-upload-messages cancel="{{ 'uploads.cancelar' | translate }}"
                                               clearSelectedFiles="{{ 'uploads.clearSelectedFiles' | translate }}"
                                               dropFilesHere="{{ 'uploads.dropFilesHere' | translate }}"
                                               externalDropFilesHere="{{ 'uploads.externalDropFilesHere' | translate }}"
                                               fileStatusFailed="{{ 'uploads.fileStatusFailed' | translate }}"
                                               fileStatusUploaded="{{ 'uploads.fileStatusUploaded' | translate }}"
                                               filesBatchStatus="{{ 'uploads.filesBatchStatus' | translate }}"
                                               filesBatchStatusFailed="{{ 'uploads.filesBatchStatusFailed' | translate }}"
                                               filesBatchStatusUploaded="{{ 'uploads.filesBatchStatusUploaded' | translate }}"
                                               headerStatusPaused="{{ 'uploads.headerStatusPaused' | translate }}"
                                               headerStatusUploaded="{{ 'uploads.headerStatusUploaded' | translate }}"
                                               headerStatusUploading="{{ 'uploads.headerStatusUploading' | translate }}"
                                               invalidFileExtension="{{ 'uploads.invalidFileExtension' | translate }}"
                                               invalidMaxFileSize="{{ 'uploads.invalidMaxFileSize' | translate }}"
                                               invalidMinFileSize="{{ 'uploads.invalidMinFileSize' | translate }}"
                                               pause="{{ 'uploads.pause' | translate }}"
                                               remove="{{ 'uploads.remove' | translate }}"
                                               resume="{{ 'uploads.resume' | translate }}"
                                               retry="{{ 'uploads.retry' | translate }}"
                                               select="{{ 'uploads.selectImages' | translate }}"
                                               uploadSelectedFiles="{{ 'uploads.uploadSelectedFiles' | translate }}">
                        </kendo-upload-messages>
                      </kendo-fileselect>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="caja">

                      <kendo-fileselect [(ngModel)]="pdfPiecerioBasePrepunzonadoInferior"
                                        [restrictions]="restrictionsPDF" [multiple]="false"
                                        (select)="pdfSeleccionadoPiecerioBasePrepunzonadoInferior($event)"
                                        (remove)="pdfEliminadoPiecerioBasePrepunzonadoInferior($event)">
                        <kendo-upload-messages cancel="{{ 'uploads.cancelar' | translate }}"
                                               clearSelectedFiles="{{ 'uploads.clearSelectedFiles' | translate }}"
                                               dropFilesHere="{{ 'uploads.dropFilesHere' | translate }}"
                                               externalDropFilesHere="{{ 'uploads.externalDropFilesHere' | translate }}"
                                               fileStatusFailed="{{ 'uploads.fileStatusFailed' | translate }}"
                                               fileStatusUploaded="{{ 'uploads.fileStatusUploaded' | translate }}"
                                               filesBatchStatus="{{ 'uploads.filesBatchStatus' | translate }}"
                                               filesBatchStatusFailed="{{ 'uploads.filesBatchStatusFailed' | translate }}"
                                               filesBatchStatusUploaded="{{ 'uploads.filesBatchStatusUploaded' | translate }}"
                                               headerStatusPaused="{{ 'uploads.headerStatusPaused' | translate }}"
                                               headerStatusUploaded="{{ 'uploads.headerStatusUploaded' | translate }}"
                                               headerStatusUploading="{{ 'uploads.headerStatusUploading' | translate }}"
                                               invalidFileExtension="{{ 'uploads.invalidFileExtension' | translate }}"
                                               invalidMaxFileSize="{{ 'uploads.invalidMaxFileSize' | translate }}"
                                               invalidMinFileSize="{{ 'uploads.invalidMinFileSize' | translate }}"
                                               pause="{{ 'uploads.pause' | translate }}"
                                               remove="{{ 'uploads.remove' | translate }}"
                                               resume="{{ 'uploads.resume' | translate }}"
                                               retry="{{ 'uploads.retry' | translate }}"
                                               select="{{ 'uploads.select' | translate }}"
                                               uploadSelectedFiles="{{ 'uploads.uploadSelectedFiles' | translate }}">
                        </kendo-upload-messages>
                      </kendo-fileselect>
                      <div class="cont-archivo" *ngIf="mostrarPiecerioBasePrepunzonadoInferior==true">
                        <div class="cont-archivo-inner">
                          <span class="k-file-group-wrapper">
                            <span class="k-file-group k-icon k-i-file-pdf"></span>
                          </span>
                          <span class="k-file-name-size-wrapper">
                            <span class="k-file-name">{{ 'configuracion.pdfPiecerioBasePrepunzonadoInferior' | translate}}</span>
                          </span>
                          <button type="button" class="k-button k-button-icon k-flat k-upload-action" tabindex="-1" (click)="pdfEliminadoHTASuperiorH15AMaestro($event)">
                            <span class="k-icon k-delete k-i-x"></span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <img [src]="imageToShowPiecerioBasePrepunzonadoInferior" class="img-conf" style="max-height: 113px">
              </div>

              <div class="col">
                <div class="caja-imagen">
                  <!--IMAGEN VIEJA-->
                  <div class="cont-izq">
                    <kendo-label text="{{ 'datosForja.prepunzonadoInferior2aOP' | translate}}">

                    </kendo-label>

                    <div class="form-group">
                      <div class="caja">
                        <kendo-fileselect [(ngModel)]="archivoPiecerioPrepunzonadoInferior2OP"
                                          [restrictions]="restrictionsImage" [multiple]="false"
                                          (select)="archivoSeleccionadoPiecerioPrepunzonadoInferior2OP($event)"
                                          (remove)="archivoEliminadoPiecerioPrepunzonadoInferior2OP($event)">
                          <kendo-upload-messages cancel="{{ 'uploads.cancelar' | translate }}"
                                                 clearSelectedFiles="{{ 'uploads.clearSelectedFiles' | translate }}"
                                                 dropFilesHere="{{ 'uploads.dropFilesHere' | translate }}"
                                                 externalDropFilesHere="{{ 'uploads.externalDropFilesHere' | translate }}"
                                                 fileStatusFailed="{{ 'uploads.fileStatusFailed' | translate }}"
                                                 fileStatusUploaded="{{ 'uploads.fileStatusUploaded' | translate }}"
                                                 filesBatchStatus="{{ 'uploads.filesBatchStatus' | translate }}"
                                                 filesBatchStatusFailed="{{ 'uploads.filesBatchStatusFailed' | translate }}"
                                                 filesBatchStatusUploaded="{{ 'uploads.filesBatchStatusUploaded' | translate }}"
                                                 headerStatusPaused="{{ 'uploads.headerStatusPaused' | translate }}"
                                                 headerStatusUploaded="{{ 'uploads.headerStatusUploaded' | translate }}"
                                                 headerStatusUploading="{{ 'uploads.headerStatusUploading' | translate }}"
                                                 invalidFileExtension="{{ 'uploads.invalidFileExtension' | translate }}"
                                                 invalidMaxFileSize="{{ 'uploads.invalidMaxFileSize' | translate }}"
                                                 invalidMinFileSize="{{ 'uploads.invalidMinFileSize' | translate }}"
                                                 pause="{{ 'uploads.pause' | translate }}"
                                                 remove="{{ 'uploads.remove' | translate }}"
                                                 resume="{{ 'uploads.resume' | translate }}"
                                                 retry="{{ 'uploads.retry' | translate }}"
                                                 select="{{ 'uploads.selectImages' | translate }}"
                                                 uploadSelectedFiles="{{ 'uploads.uploadSelectedFiles' | translate }}">
                          </kendo-upload-messages>
                        </kendo-fileselect>

                      </div>
                    </div>
                    <div class="form-group">
                      <div class="caja">
                        <kendo-fileselect [(ngModel)]="pdfPiecerioPrepunzonadoInferior2OP"
                                          [restrictions]="restrictionsPDF" [multiple]="false"
                                          (select)="pdfSeleccionadoPiecerioPrepunzonadoInferior2OP($event)"
                                          (remove)="pdfEliminadoPiecerioPrepunzonadoInferior2OP($event)">
                          <kendo-upload-messages cancel="{{ 'uploads.cancelar' | translate }}"
                                                 clearSelectedFiles="{{ 'uploads.clearSelectedFiles' | translate }}"
                                                 dropFilesHere="{{ 'uploads.dropFilesHere' | translate }}"
                                                 externalDropFilesHere="{{ 'uploads.externalDropFilesHere' | translate }}"
                                                 fileStatusFailed="{{ 'uploads.fileStatusFailed' | translate }}"
                                                 fileStatusUploaded="{{ 'uploads.fileStatusUploaded' | translate }}"
                                                 filesBatchStatus="{{ 'uploads.filesBatchStatus' | translate }}"
                                                 filesBatchStatusFailed="{{ 'uploads.filesBatchStatusFailed' | translate }}"
                                                 filesBatchStatusUploaded="{{ 'uploads.filesBatchStatusUploaded' | translate }}"
                                                 headerStatusPaused="{{ 'uploads.headerStatusPaused' | translate }}"
                                                 headerStatusUploaded="{{ 'uploads.headerStatusUploaded' | translate }}"
                                                 headerStatusUploading="{{ 'uploads.headerStatusUploading' | translate }}"
                                                 invalidFileExtension="{{ 'uploads.invalidFileExtension' | translate }}"
                                                 invalidMaxFileSize="{{ 'uploads.invalidMaxFileSize' | translate }}"
                                                 invalidMinFileSize="{{ 'uploads.invalidMinFileSize' | translate }}"
                                                 pause="{{ 'uploads.pause' | translate }}"
                                                 remove="{{ 'uploads.remove' | translate }}"
                                                 resume="{{ 'uploads.resume' | translate }}"
                                                 retry="{{ 'uploads.retry' | translate }}"
                                                 select="{{ 'uploads.select' | translate }}"
                                                 uploadSelectedFiles="{{ 'uploads.uploadSelectedFiles' | translate }}">
                          </kendo-upload-messages>
                        </kendo-fileselect>
                        <div class="cont-archivo" *ngIf="mostrarPiecerioPrepunzonadoInferior2OP==true">
                          <div class="cont-archivo-inner">
                            <span class="k-file-group-wrapper">
                              <span class="k-file-group k-icon k-i-file-pdf"></span>
                            </span>
                            <span class="k-file-name-size-wrapper">
                              <span class="k-file-name">{{ 'configuracion.pdfPiecerioPrepunzonadoInferior2OP' | translate}}</span>
                            </span>
                            <button type="button" class="k-button k-button-icon k-flat k-upload-action" tabindex="-1" (click)="pdfEliminadoHTASuperiorH15AMaestro($event)">
                              <span class="k-icon k-delete k-i-x"></span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <img [src]="imageToShowPiecerioPrepunzonadoInferior2OP" class="img-conf" style="max-height: 113px">
                </div>
                <div class="caja-imagen">
                  <!--IMAGEN VIEJA-->
                  <div class="cont-izq">
                    <kendo-label text="{{ 'datosForja.arandela' | translate}}">

                    </kendo-label>

                    <div class="form-group">
                      <div class="caja">
                        <kendo-fileselect [(ngModel)]="archivoP10000Arandela" [restrictions]="restrictionsImage"
                                          [multiple]="false" (select)="archivoSeleccionadoP10000Arandela($event)"
                                          (remove)="archivoEliminadoP10000Arandela($event)">
                          <kendo-upload-messages cancel="{{ 'uploads.cancelar' | translate }}"
                                                 clearSelectedFiles="{{ 'uploads.clearSelectedFiles' | translate }}"
                                                 dropFilesHere="{{ 'uploads.dropFilesHere' | translate }}"
                                                 externalDropFilesHere="{{ 'uploads.externalDropFilesHere' | translate }}"
                                                 fileStatusFailed="{{ 'uploads.fileStatusFailed' | translate }}"
                                                 fileStatusUploaded="{{ 'uploads.fileStatusUploaded' | translate }}"
                                                 filesBatchStatus="{{ 'uploads.filesBatchStatus' | translate }}"
                                                 filesBatchStatusFailed="{{ 'uploads.filesBatchStatusFailed' | translate }}"
                                                 filesBatchStatusUploaded="{{ 'uploads.filesBatchStatusUploaded' | translate }}"
                                                 headerStatusPaused="{{ 'uploads.headerStatusPaused' | translate }}"
                                                 headerStatusUploaded="{{ 'uploads.headerStatusUploaded' | translate }}"
                                                 headerStatusUploading="{{ 'uploads.headerStatusUploading' | translate }}"
                                                 invalidFileExtension="{{ 'uploads.invalidFileExtension' | translate }}"
                                                 invalidMaxFileSize="{{ 'uploads.invalidMaxFileSize' | translate }}"
                                                 invalidMinFileSize="{{ 'uploads.invalidMinFileSize' | translate }}"
                                                 pause="{{ 'uploads.pause' | translate }}"
                                                 remove="{{ 'uploads.remove' | translate }}"
                                                 resume="{{ 'uploads.resume' | translate }}"
                                                 retry="{{ 'uploads.retry' | translate }}"
                                                 select="{{ 'uploads.selectImages' | translate }}"
                                                 uploadSelectedFiles="{{ 'uploads.uploadSelectedFiles' | translate }}">
                          </kendo-upload-messages>
                        </kendo-fileselect>

                      </div>
                    </div>
                    <div class="form-group">
                      <div class="caja">
                        <kendo-fileselect [(ngModel)]="pdfP10000Arandela" [restrictions]="restrictionsPDF"
                                          [multiple]="false" (select)="pdfSeleccionadoP10000Arandela($event)"
                                          (remove)="pdfEliminadoP10000Arandela($event)">
                          <kendo-upload-messages cancel="{{ 'uploads.cancelar' | translate }}"
                                                 clearSelectedFiles="{{ 'uploads.clearSelectedFiles' | translate }}"
                                                 dropFilesHere="{{ 'uploads.dropFilesHere' | translate }}"
                                                 externalDropFilesHere="{{ 'uploads.externalDropFilesHere' | translate }}"
                                                 fileStatusFailed="{{ 'uploads.fileStatusFailed' | translate }}"
                                                 fileStatusUploaded="{{ 'uploads.fileStatusUploaded' | translate }}"
                                                 filesBatchStatus="{{ 'uploads.filesBatchStatus' | translate }}"
                                                 filesBatchStatusFailed="{{ 'uploads.filesBatchStatusFailed' | translate }}"
                                                 filesBatchStatusUploaded="{{ 'uploads.filesBatchStatusUploaded' | translate }}"
                                                 headerStatusPaused="{{ 'uploads.headerStatusPaused' | translate }}"
                                                 headerStatusUploaded="{{ 'uploads.headerStatusUploaded' | translate }}"
                                                 headerStatusUploading="{{ 'uploads.headerStatusUploading' | translate }}"
                                                 invalidFileExtension="{{ 'uploads.invalidFileExtension' | translate }}"
                                                 invalidMaxFileSize="{{ 'uploads.invalidMaxFileSize' | translate }}"
                                                 invalidMinFileSize="{{ 'uploads.invalidMinFileSize' | translate }}"
                                                 pause="{{ 'uploads.pause' | translate }}"
                                                 remove="{{ 'uploads.remove' | translate }}"
                                                 resume="{{ 'uploads.resume' | translate }}"
                                                 retry="{{ 'uploads.retry' | translate }}"
                                                 select="{{ 'uploads.select' | translate }}"
                                                 uploadSelectedFiles="{{ 'uploads.uploadSelectedFiles' | translate }}">
                          </kendo-upload-messages>
                        </kendo-fileselect>
                        <div class="cont-archivo" *ngIf="mostrarP10000Arandela==true">
                          <div class="cont-archivo-inner">
                            <span class="k-file-group-wrapper">
                              <span class="k-file-group k-icon k-i-file-pdf"></span>
                            </span>
                            <span class="k-file-name-size-wrapper">
                              <span class="k-file-name">{{ 'configuracion.pdfP10000Arandela' | translate}}</span>
                            </span>
                            <button type="button" class="k-button k-button-icon k-flat k-upload-action" tabindex="-1" (click)="pdfEliminadoHTASuperiorH15AMaestro($event)">
                              <span class="k-icon k-delete k-i-x"></span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <img [src]="imageToShowP10000Arandela" class="img-conf" style="max-height: 113px">
                </div>
              </div>
            </div>

          </div>
          <div class="col">

            <h4 class="subtitulo">{{'datosForja.htaSuperior' | translate}}</h4>
            <div class="clearfix">

              <div class="caja-imagen">
                <!--IMAGEN VIEJA-->
                <div class="cont-izq">
                  <kendo-label text="{{ 'datosForja.prepunzonadoSuperior' | translate}}">

                  </kendo-label>

                  <div class="form-group">
                    <div class="caja">

                      <kendo-fileselect [(ngModel)]="archivoPiecerioPrepunzonadoSuperior"
                                        [restrictions]="restrictionsImage"
                                        [multiple]="false"
                                        (select)="archivoSeleccionadoPiecerioPrepunzonadoSuperior($event)"
                                        (remove)="archivoEliminadoPiecerioPrepunzonadoSuperior($event)">
                        <kendo-upload-messages cancel="{{ 'uploads.cancelar' | translate }}"
                                               clearSelectedFiles="{{ 'uploads.clearSelectedFiles' | translate }}"
                                               dropFilesHere="{{ 'uploads.dropFilesHere' | translate }}"
                                               externalDropFilesHere="{{ 'uploads.externalDropFilesHere' | translate }}"
                                               fileStatusFailed="{{ 'uploads.fileStatusFailed' | translate }}"
                                               fileStatusUploaded="{{ 'uploads.fileStatusUploaded' | translate }}"
                                               filesBatchStatus="{{ 'uploads.filesBatchStatus' | translate }}"
                                               filesBatchStatusFailed="{{ 'uploads.filesBatchStatusFailed' | translate }}"
                                               filesBatchStatusUploaded="{{ 'uploads.filesBatchStatusUploaded' | translate }}"
                                               headerStatusPaused="{{ 'uploads.headerStatusPaused' | translate }}"
                                               headerStatusUploaded="{{ 'uploads.headerStatusUploaded' | translate }}"
                                               headerStatusUploading="{{ 'uploads.headerStatusUploading' | translate }}"
                                               invalidFileExtension="{{ 'uploads.invalidFileExtension' | translate }}"
                                               invalidMaxFileSize="{{ 'uploads.invalidMaxFileSize' | translate }}"
                                               invalidMinFileSize="{{ 'uploads.invalidMinFileSize' | translate }}"
                                               pause="{{ 'uploads.pause' | translate }}"
                                               remove="{{ 'uploads.remove' | translate }}"
                                               resume="{{ 'uploads.resume' | translate }}"
                                               retry="{{ 'uploads.retry' | translate }}"
                                               select="{{ 'uploads.selectImages' | translate }}"
                                               uploadSelectedFiles="{{ 'uploads.uploadSelectedFiles' | translate }}">
                        </kendo-upload-messages>
                      </kendo-fileselect>

                    </div>
                  </div>
                  <div class="form-group">
                    <div class="caja">

                      <kendo-fileselect [(ngModel)]="pdfPiecerioPrepunzonadoSuperior"
                                        [restrictions]="restrictionsPDF"
                                        [multiple]="false"
                                        (select)="pdfSeleccionadoPiecerioPrepunzonadoSuperior($event)"
                                        (remove)="pdfEliminadoPiecerioPrepunzonadoSuperior($event)">
                        <kendo-upload-messages cancel="{{ 'uploads.cancelar' | translate }}"
                                               clearSelectedFiles="{{ 'uploads.clearSelectedFiles' | translate }}"
                                               dropFilesHere="{{ 'uploads.dropFilesHere' | translate }}"
                                               externalDropFilesHere="{{ 'uploads.externalDropFilesHere' | translate }}"
                                               fileStatusFailed="{{ 'uploads.fileStatusFailed' | translate }}"
                                               fileStatusUploaded="{{ 'uploads.fileStatusUploaded' | translate }}"
                                               filesBatchStatus="{{ 'uploads.filesBatchStatus' | translate }}"
                                               filesBatchStatusFailed="{{ 'uploads.filesBatchStatusFailed' | translate }}"
                                               filesBatchStatusUploaded="{{ 'uploads.filesBatchStatusUploaded' | translate }}"
                                               headerStatusPaused="{{ 'uploads.headerStatusPaused' | translate }}"
                                               headerStatusUploaded="{{ 'uploads.headerStatusUploaded' | translate }}"
                                               headerStatusUploading="{{ 'uploads.headerStatusUploading' | translate }}"
                                               invalidFileExtension="{{ 'uploads.invalidFileExtension' | translate }}"
                                               invalidMaxFileSize="{{ 'uploads.invalidMaxFileSize' | translate }}"
                                               invalidMinFileSize="{{ 'uploads.invalidMinFileSize' | translate }}"
                                               pause="{{ 'uploads.pause' | translate }}"
                                               remove="{{ 'uploads.remove' | translate }}"
                                               resume="{{ 'uploads.resume' | translate }}"
                                               retry="{{ 'uploads.retry' | translate }}"
                                               select="{{ 'uploads.select' | translate }}"
                                               uploadSelectedFiles="{{ 'uploads.uploadSelectedFiles' | translate }}">
                        </kendo-upload-messages>
                      </kendo-fileselect>
                      <div class="cont-archivo" *ngIf="mostrarPiecerioPrepunzonadoSuperior==true">
                        <div class="cont-archivo-inner">
                          <span class="k-file-group-wrapper">
                            <span class="k-file-group k-icon k-i-file-pdf"></span>
                          </span>
                          <span class="k-file-name-size-wrapper">
                            <span class="k-file-name">{{ 'configuracion.pdfPiecerioPrepunzonadoSuperior' | translate}}</span>
                          </span>
                          <button type="button" class="k-button k-button-icon k-flat k-upload-action" tabindex="-1" (click)="pdfEliminadoHTASuperiorH15AMaestro($event)">
                            <span class="k-icon k-delete k-i-x"></span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <img [src]="imageToShowPiecerioPrepunzonadoSuperior" class="img-conf" style="max-height: 113px">
              </div>


              <div class="caja-imagen">
                <!--IMAGEN VIEJA-->
                <div class="cont-izq">
                  <kendo-label text="{{ 'datosForja.mandrinoMasAdaptadorMandrino' | translate}}">

                  </kendo-label>

                  <div class="form-group">
                    <div class="caja">

                      <kendo-fileselect [(ngModel)]="archivoPiecerioMandrino10000" [restrictions]="restrictionsImage"
                                        [multiple]="false" (select)="archivoSeleccionadoPiecerioMandrino10000($event)"
                                        (remove)="archivoEliminadoPiecerioMandrino10000($event)">
                        <kendo-upload-messages cancel="{{ 'uploads.cancelar' | translate }}"
                                               clearSelectedFiles="{{ 'uploads.clearSelectedFiles' | translate }}"
                                               dropFilesHere="{{ 'uploads.dropFilesHere' | translate }}"
                                               externalDropFilesHere="{{ 'uploads.externalDropFilesHere' | translate }}"
                                               fileStatusFailed="{{ 'uploads.fileStatusFailed' | translate }}"
                                               fileStatusUploaded="{{ 'uploads.fileStatusUploaded' | translate }}"
                                               filesBatchStatus="{{ 'uploads.filesBatchStatus' | translate }}"
                                               filesBatchStatusFailed="{{ 'uploads.filesBatchStatusFailed' | translate }}"
                                               filesBatchStatusUploaded="{{ 'uploads.filesBatchStatusUploaded' | translate }}"
                                               headerStatusPaused="{{ 'uploads.headerStatusPaused' | translate }}"
                                               headerStatusUploaded="{{ 'uploads.headerStatusUploaded' | translate }}"
                                               headerStatusUploading="{{ 'uploads.headerStatusUploading' | translate }}"
                                               invalidFileExtension="{{ 'uploads.invalidFileExtension' | translate }}"
                                               invalidMaxFileSize="{{ 'uploads.invalidMaxFileSize' | translate }}"
                                               invalidMinFileSize="{{ 'uploads.invalidMinFileSize' | translate }}"
                                               pause="{{ 'uploads.pause' | translate }}"
                                               remove="{{ 'uploads.remove' | translate }}"
                                               resume="{{ 'uploads.resume' | translate }}"
                                               retry="{{ 'uploads.retry' | translate }}"
                                               select="{{ 'uploads.selectImages' | translate }}"
                                               uploadSelectedFiles="{{ 'uploads.uploadSelectedFiles' | translate }}">
                        </kendo-upload-messages>
                      </kendo-fileselect>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="caja">

                      <kendo-fileselect [(ngModel)]="pdfPiecerioMandrino10000" [restrictions]="restrictionsPDF"
                                        [multiple]="false" (select)="pdfSeleccionadoPiecerioMandrino10000($event)"
                                        (remove)="pdfEliminadoPiecerioMandrino10000($event)">
                        <kendo-upload-messages cancel="{{ 'uploads.cancelar' | translate }}"
                                               clearSelectedFiles="{{ 'uploads.clearSelectedFiles' | translate }}"
                                               dropFilesHere="{{ 'uploads.dropFilesHere' | translate }}"
                                               externalDropFilesHere="{{ 'uploads.externalDropFilesHere' | translate }}"
                                               fileStatusFailed="{{ 'uploads.fileStatusFailed' | translate }}"
                                               fileStatusUploaded="{{ 'uploads.fileStatusUploaded' | translate }}"
                                               filesBatchStatus="{{ 'uploads.filesBatchStatus' | translate }}"
                                               filesBatchStatusFailed="{{ 'uploads.filesBatchStatusFailed' | translate }}"
                                               filesBatchStatusUploaded="{{ 'uploads.filesBatchStatusUploaded' | translate }}"
                                               headerStatusPaused="{{ 'uploads.headerStatusPaused' | translate }}"
                                               headerStatusUploaded="{{ 'uploads.headerStatusUploaded' | translate }}"
                                               headerStatusUploading="{{ 'uploads.headerStatusUploading' | translate }}"
                                               invalidFileExtension="{{ 'uploads.invalidFileExtension' | translate }}"
                                               invalidMaxFileSize="{{ 'uploads.invalidMaxFileSize' | translate }}"
                                               invalidMinFileSize="{{ 'uploads.invalidMinFileSize' | translate }}"
                                               pause="{{ 'uploads.pause' | translate }}"
                                               remove="{{ 'uploads.remove' | translate }}"
                                               resume="{{ 'uploads.resume' | translate }}"
                                               retry="{{ 'uploads.retry' | translate }}"
                                               select="{{ 'uploads.select' | translate }}"
                                               uploadSelectedFiles="{{ 'uploads.uploadSelectedFiles' | translate }}">
                        </kendo-upload-messages>
                      </kendo-fileselect>
                      <div class="cont-archivo" *ngIf="mostrarPiecerioMandrino10000==true">
                        <div class="cont-archivo-inner">
                          <span class="k-file-group-wrapper">
                            <span class="k-file-group k-icon k-i-file-pdf"></span>
                          </span>
                          <span class="k-file-name-size-wrapper">
                            <span class="k-file-name">{{ 'configuracion.pdfPiecerioMandrino10000' | translate}}</span>
                          </span>
                          <button type="button" class="k-button k-button-icon k-flat k-upload-action" tabindex="-1" (click)="pdfEliminadoHTASuperiorH15AMaestro($event)">
                            <span class="k-icon k-delete k-i-x"></span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <img [src]="imageToShowPiecerioMandrino10000" class="img-conf" style="max-height: 113px">
              </div>

            </div>
          </div>

        </div>


      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-lg-3 col-12">
    <div class="card">
      <div class="card-header">
        <h3>{{ 'datosForja.datosTecnicosLaminador' | translate}}</h3>
        <div class="plegarpanel"></div>
      </div>
      <div class="card-body">
        <div class="clearfix">
          <div class="caja-imagen">
            <!--IMAGEN VIEJA-->
            <div class="cont-izq">
              <kendo-label text="{{ 'datosForja.cebollas' | translate}}">

              </kendo-label>

              <div class="form-group">
                <div class="caja">
                  <kendo-fileselect [(ngModel)]="archivoCebollas" [restrictions]="restrictionsImage"
                                    [multiple]="false" (select)="archivoSeleccionadoCebollas($event)"
                                    (remove)="archivoEliminadoCebollas($event)">
                    <kendo-upload-messages cancel="{{ 'uploads.cancelar' | translate }}"
                                           clearSelectedFiles="{{ 'uploads.clearSelectedFiles' | translate }}"
                                           dropFilesHere="{{ 'uploads.dropFilesHere' | translate }}"
                                           externalDropFilesHere="{{ 'uploads.externalDropFilesHere' | translate }}"
                                           fileStatusFailed="{{ 'uploads.fileStatusFailed' | translate }}"
                                           fileStatusUploaded="{{ 'uploads.fileStatusUploaded' | translate }}"
                                           filesBatchStatus="{{ 'uploads.filesBatchStatus' | translate }}"
                                           filesBatchStatusFailed="{{ 'uploads.filesBatchStatusFailed' | translate }}"
                                           filesBatchStatusUploaded="{{ 'uploads.filesBatchStatusUploaded' | translate }}"
                                           headerStatusPaused="{{ 'uploads.headerStatusPaused' | translate }}"
                                           headerStatusUploaded="{{ 'uploads.headerStatusUploaded' | translate }}"
                                           headerStatusUploading="{{ 'uploads.headerStatusUploading' | translate }}"
                                           invalidFileExtension="{{ 'uploads.invalidFileExtension' | translate }}"
                                           invalidMaxFileSize="{{ 'uploads.invalidMaxFileSize' | translate }}"
                                           invalidMinFileSize="{{ 'uploads.invalidMinFileSize' | translate }}"
                                           pause="{{ 'uploads.pause' | translate }}"
                                           remove="{{ 'uploads.remove' | translate }}"
                                           resume="{{ 'uploads.resume' | translate }}"
                                           retry="{{ 'uploads.retry' | translate }}"
                                           select="{{ 'uploads.selectImages' | translate }}"
                                           uploadSelectedFiles="{{ 'uploads.uploadSelectedFiles' | translate }}">
                    </kendo-upload-messages>
                  </kendo-fileselect>
                </div>
              </div>
              <div class="form-group">
                <div class="caja">
                  <kendo-fileselect [(ngModel)]="pdfCebollas" [restrictions]="restrictionsPDF"
                                    [multiple]="false" (select)="pdfSeleccionadoCebollas($event)"
                                    (remove)="pdfEliminadoCebollas($event)">
                    <kendo-upload-messages cancel="{{ 'uploads.cancelar' | translate }}"
                                           clearSelectedFiles="{{ 'uploads.clearSelectedFiles' | translate }}"
                                           dropFilesHere="{{ 'uploads.dropFilesHere' | translate }}"
                                           externalDropFilesHere="{{ 'uploads.externalDropFilesHere' | translate }}"
                                           fileStatusFailed="{{ 'uploads.fileStatusFailed' | translate }}"
                                           fileStatusUploaded="{{ 'uploads.fileStatusUploaded' | translate }}"
                                           filesBatchStatus="{{ 'uploads.filesBatchStatus' | translate }}"
                                           filesBatchStatusFailed="{{ 'uploads.filesBatchStatusFailed' | translate }}"
                                           filesBatchStatusUploaded="{{ 'uploads.filesBatchStatusUploaded' | translate }}"
                                           headerStatusPaused="{{ 'uploads.headerStatusPaused' | translate }}"
                                           headerStatusUploaded="{{ 'uploads.headerStatusUploaded' | translate }}"
                                           headerStatusUploading="{{ 'uploads.headerStatusUploading' | translate }}"
                                           invalidFileExtension="{{ 'uploads.invalidFileExtension' | translate }}"
                                           invalidMaxFileSize="{{ 'uploads.invalidMaxFileSize' | translate }}"
                                           invalidMinFileSize="{{ 'uploads.invalidMinFileSize' | translate }}"
                                           pause="{{ 'uploads.pause' | translate }}"
                                           remove="{{ 'uploads.remove' | translate }}"
                                           resume="{{ 'uploads.resume' | translate }}"
                                           retry="{{ 'uploads.retry' | translate }}"
                                           select="{{ 'uploads.select' | translate }}"
                                           uploadSelectedFiles="{{ 'uploads.uploadSelectedFiles' | translate }}">
                    </kendo-upload-messages>
                  </kendo-fileselect>
                  <div class="cont-archivo" *ngIf="mostrarCebollas==true">
                    <div class="cont-archivo-inner">
                      <span class="k-file-group-wrapper">
                        <span class="k-file-group k-icon k-i-file-pdf"></span>
                      </span>
                      <span class="k-file-name-size-wrapper">
                        <span class="k-file-name">{{ 'configuracion.pdfCebollas' | translate}}</span>
                      </span>
                      <button type="button" class="k-button k-button-icon k-flat k-upload-action" tabindex="-1" (click)="pdfEliminadoHTASuperiorH15AMaestro($event)">
                        <span class="k-icon k-delete k-i-x"></span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <img [src]="imageToShowCebollas" class="img-conf" style="max-height: 113px">
          </div>
          <div class="caja-imagen">
            <!--IMAGEN VIEJA-->
            <div class="cont-izq">
              <kendo-label text="{{ 'datosForja.poleas' | translate}}">

              </kendo-label>

              <div class="form-group">
                <div class="caja">
                  <kendo-fileselect [(ngModel)]="archivoPoleas" [restrictions]="restrictionsImage"
                                    [multiple]="false" (select)="archivoSeleccionadoPoleas($event)"
                                    (remove)="archivoEliminadoPoleas($event)">
                    <kendo-upload-messages cancel="{{ 'uploads.cancelar' | translate }}"
                                           clearSelectedFiles="{{ 'uploads.clearSelectedFiles' | translate }}"
                                           dropFilesHere="{{ 'uploads.dropFilesHere' | translate }}"
                                           externalDropFilesHere="{{ 'uploads.externalDropFilesHere' | translate }}"
                                           fileStatusFailed="{{ 'uploads.fileStatusFailed' | translate }}"
                                           fileStatusUploaded="{{ 'uploads.fileStatusUploaded' | translate }}"
                                           filesBatchStatus="{{ 'uploads.filesBatchStatus' | translate }}"
                                           filesBatchStatusFailed="{{ 'uploads.filesBatchStatusFailed' | translate }}"
                                           filesBatchStatusUploaded="{{ 'uploads.filesBatchStatusUploaded' | translate }}"
                                           headerStatusPaused="{{ 'uploads.headerStatusPaused' | translate }}"
                                           headerStatusUploaded="{{ 'uploads.headerStatusUploaded' | translate }}"
                                           headerStatusUploading="{{ 'uploads.headerStatusUploading' | translate }}"
                                           invalidFileExtension="{{ 'uploads.invalidFileExtension' | translate }}"
                                           invalidMaxFileSize="{{ 'uploads.invalidMaxFileSize' | translate }}"
                                           invalidMinFileSize="{{ 'uploads.invalidMinFileSize' | translate }}"
                                           pause="{{ 'uploads.pause' | translate }}"
                                           remove="{{ 'uploads.remove' | translate }}"
                                           resume="{{ 'uploads.resume' | translate }}"
                                           retry="{{ 'uploads.retry' | translate }}"
                                           select="{{ 'uploads.selectImages' | translate }}"
                                           uploadSelectedFiles="{{ 'uploads.uploadSelectedFiles' | translate }}">
                    </kendo-upload-messages>
                  </kendo-fileselect>
                </div>
              </div>
              <div class="form-group">
                <div class="caja">
                  <kendo-fileselect [(ngModel)]="pdfPoleas" [restrictions]="restrictionsPDF"
                                    [multiple]="false" (select)="pdfSeleccionadoPoleas($event)"
                                    (remove)="pdfEliminadoPoleas($event)">
                    <kendo-upload-messages cancel="{{ 'uploads.cancelar' | translate }}"
                                           clearSelectedFiles="{{ 'uploads.clearSelectedFiles' | translate }}"
                                           dropFilesHere="{{ 'uploads.dropFilesHere' | translate }}"
                                           externalDropFilesHere="{{ 'uploads.externalDropFilesHere' | translate }}"
                                           fileStatusFailed="{{ 'uploads.fileStatusFailed' | translate }}"
                                           fileStatusUploaded="{{ 'uploads.fileStatusUploaded' | translate }}"
                                           filesBatchStatus="{{ 'uploads.filesBatchStatus' | translate }}"
                                           filesBatchStatusFailed="{{ 'uploads.filesBatchStatusFailed' | translate }}"
                                           filesBatchStatusUploaded="{{ 'uploads.filesBatchStatusUploaded' | translate }}"
                                           headerStatusPaused="{{ 'uploads.headerStatusPaused' | translate }}"
                                           headerStatusUploaded="{{ 'uploads.headerStatusUploaded' | translate }}"
                                           headerStatusUploading="{{ 'uploads.headerStatusUploading' | translate }}"
                                           invalidFileExtension="{{ 'uploads.invalidFileExtension' | translate }}"
                                           invalidMaxFileSize="{{ 'uploads.invalidMaxFileSize' | translate }}"
                                           invalidMinFileSize="{{ 'uploads.invalidMinFileSize' | translate }}"
                                           pause="{{ 'uploads.pause' | translate }}"
                                           remove="{{ 'uploads.remove' | translate }}"
                                           resume="{{ 'uploads.resume' | translate }}"
                                           retry="{{ 'uploads.retry' | translate }}"
                                           select="{{ 'uploads.select' | translate }}"
                                           uploadSelectedFiles="{{ 'uploads.uploadSelectedFiles' | translate }}">
                    </kendo-upload-messages>
                  </kendo-fileselect>
                  <div class="cont-archivo" *ngIf="mostrarPoleas==true">
                    <div class="cont-archivo-inner">
                      <span class="k-file-group-wrapper">
                        <span class="k-file-group k-icon k-i-file-pdf"></span>
                      </span>
                      <span class="k-file-name-size-wrapper">
                        <span class="k-file-name">{{ 'configuracion.pdfPoleas' | translate}}</span>
                      </span>
                      <button type="button" class="k-button k-button-icon k-flat k-upload-action" tabindex="-1" (click)="pdfEliminadoHTASuperiorH15AMaestro($event)">
                        <span class="k-icon k-delete k-i-x"></span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <img [src]="imageToShowPoleas" class="img-conf" style="max-height: 113px">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-3 col-12">
    <div class="card">
      <div class="card-header">
        <h3>{{ 'datosForja.htaP5000' | translate}}</h3>
        <div class="plegarpanel"></div>
      </div>
      <div class="card-body">
        <div class="clearfix">
          <div class="caja-imagen">
            <!--IMAGEN VIEJA-->
            <div class="cont-izq">
              <kendo-label text="{{ 'datosForja.h60h61' | translate}}">

              </kendo-label>

              <div class="form-group">
                <div class="caja">
                  <kendo-fileselect [(ngModel)]="archivoh60h61" [restrictions]="restrictionsImage"
                                    [multiple]="false" (select)="archivoSeleccionadoh60h61($event)"
                                    (remove)="archivoEliminadoh60h61($event)">
                    <kendo-upload-messages cancel="{{ 'uploads.cancelar' | translate }}"
                                           clearSelectedFiles="{{ 'uploads.clearSelectedFiles' | translate }}"
                                           dropFilesHere="{{ 'uploads.dropFilesHere' | translate }}"
                                           externalDropFilesHere="{{ 'uploads.externalDropFilesHere' | translate }}"
                                           fileStatusFailed="{{ 'uploads.fileStatusFailed' | translate }}"
                                           fileStatusUploaded="{{ 'uploads.fileStatusUploaded' | translate }}"
                                           filesBatchStatus="{{ 'uploads.filesBatchStatus' | translate }}"
                                           filesBatchStatusFailed="{{ 'uploads.filesBatchStatusFailed' | translate }}"
                                           filesBatchStatusUploaded="{{ 'uploads.filesBatchStatusUploaded' | translate }}"
                                           headerStatusPaused="{{ 'uploads.headerStatusPaused' | translate }}"
                                           headerStatusUploaded="{{ 'uploads.headerStatusUploaded' | translate }}"
                                           headerStatusUploading="{{ 'uploads.headerStatusUploading' | translate }}"
                                           invalidFileExtension="{{ 'uploads.invalidFileExtension' | translate }}"
                                           invalidMaxFileSize="{{ 'uploads.invalidMaxFileSize' | translate }}"
                                           invalidMinFileSize="{{ 'uploads.invalidMinFileSize' | translate }}"
                                           pause="{{ 'uploads.pause' | translate }}"
                                           remove="{{ 'uploads.remove' | translate }}"
                                           resume="{{ 'uploads.resume' | translate }}"
                                           retry="{{ 'uploads.retry' | translate }}"
                                           select="{{ 'uploads.selectImages' | translate }}"
                                           uploadSelectedFiles="{{ 'uploads.uploadSelectedFiles' | translate }}">
                    </kendo-upload-messages>
                  </kendo-fileselect>
                </div>
              </div>
              <div class="form-group">
                <div class="caja">
                  <kendo-fileselect [(ngModel)]="pdfh60h61" [restrictions]="restrictionsPDF"
                                    [multiple]="false" (select)="pdfSeleccionadoh60h61($event)"
                                    (remove)="pdfEliminadoh60h61($event)">
                    <kendo-upload-messages cancel="{{ 'uploads.cancelar' | translate }}"
                                           clearSelectedFiles="{{ 'uploads.clearSelectedFiles' | translate }}"
                                           dropFilesHere="{{ 'uploads.dropFilesHere' | translate }}"
                                           externalDropFilesHere="{{ 'uploads.externalDropFilesHere' | translate }}"
                                           fileStatusFailed="{{ 'uploads.fileStatusFailed' | translate }}"
                                           fileStatusUploaded="{{ 'uploads.fileStatusUploaded' | translate }}"
                                           filesBatchStatus="{{ 'uploads.filesBatchStatus' | translate }}"
                                           filesBatchStatusFailed="{{ 'uploads.filesBatchStatusFailed' | translate }}"
                                           filesBatchStatusUploaded="{{ 'uploads.filesBatchStatusUploaded' | translate }}"
                                           headerStatusPaused="{{ 'uploads.headerStatusPaused' | translate }}"
                                           headerStatusUploaded="{{ 'uploads.headerStatusUploaded' | translate }}"
                                           headerStatusUploading="{{ 'uploads.headerStatusUploading' | translate }}"
                                           invalidFileExtension="{{ 'uploads.invalidFileExtension' | translate }}"
                                           invalidMaxFileSize="{{ 'uploads.invalidMaxFileSize' | translate }}"
                                           invalidMinFileSize="{{ 'uploads.invalidMinFileSize' | translate }}"
                                           pause="{{ 'uploads.pause' | translate }}"
                                           remove="{{ 'uploads.remove' | translate }}"
                                           resume="{{ 'uploads.resume' | translate }}"
                                           retry="{{ 'uploads.retry' | translate }}"
                                           select="{{ 'uploads.select' | translate }}"
                                           uploadSelectedFiles="{{ 'uploads.uploadSelectedFiles' | translate }}">
                    </kendo-upload-messages>
                  </kendo-fileselect>
                  <div class="cont-archivo" *ngIf="mostrarh60h61==true">
                    <div class="cont-archivo-inner">
                      <span class="k-file-group-wrapper">
                        <span class="k-file-group k-icon k-i-file-pdf"></span>
                      </span>
                      <span class="k-file-name-size-wrapper">
                        <span class="k-file-name">{{ 'configuracion.pdfh60h61' | translate}}</span>
                      </span>
                      <button type="button" class="k-button k-button-icon k-flat k-upload-action" tabindex="-1" (click)="pdfEliminadoHTASuperiorH15AMaestro($event)">
                        <span class="k-icon k-delete k-i-x"></span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <img [src]="imageToShowh60h61" class="img-conf" style="max-height: 113px">

          </div>
          <div class="caja-imagen">
            <!--IMAGEN VIEJA-->
            <div class="cont-izq">
              <kendo-label text="{{ 'datosForja.h70h71' | translate}}">

              </kendo-label>

              <div class="form-group">
                <div class="caja">
                  <kendo-fileselect [(ngModel)]="archivoh70h71" [restrictions]="restrictionsImage"
                                    [multiple]="false" (select)="archivoSeleccionadoh70h71($event)"
                                    (remove)="archivoEliminadoh70h71($event)">
                    <kendo-upload-messages cancel="{{ 'uploads.cancelar' | translate }}"
                                           clearSelectedFiles="{{ 'uploads.clearSelectedFiles' | translate }}"
                                           dropFilesHere="{{ 'uploads.dropFilesHere' | translate }}"
                                           externalDropFilesHere="{{ 'uploads.externalDropFilesHere' | translate }}"
                                           fileStatusFailed="{{ 'uploads.fileStatusFailed' | translate }}"
                                           fileStatusUploaded="{{ 'uploads.fileStatusUploaded' | translate }}"
                                           filesBatchStatus="{{ 'uploads.filesBatchStatus' | translate }}"
                                           filesBatchStatusFailed="{{ 'uploads.filesBatchStatusFailed' | translate }}"
                                           filesBatchStatusUploaded="{{ 'uploads.filesBatchStatusUploaded' | translate }}"
                                           headerStatusPaused="{{ 'uploads.headerStatusPaused' | translate }}"
                                           headerStatusUploaded="{{ 'uploads.headerStatusUploaded' | translate }}"
                                           headerStatusUploading="{{ 'uploads.headerStatusUploading' | translate }}"
                                           invalidFileExtension="{{ 'uploads.invalidFileExtension' | translate }}"
                                           invalidMaxFileSize="{{ 'uploads.invalidMaxFileSize' | translate }}"
                                           invalidMinFileSize="{{ 'uploads.invalidMinFileSize' | translate }}"
                                           pause="{{ 'uploads.pause' | translate }}"
                                           remove="{{ 'uploads.remove' | translate }}"
                                           resume="{{ 'uploads.resume' | translate }}"
                                           retry="{{ 'uploads.retry' | translate }}"
                                           select="{{ 'uploads.selectImages' | translate }}"
                                           uploadSelectedFiles="{{ 'uploads.uploadSelectedFiles' | translate }}">
                    </kendo-upload-messages>
                  </kendo-fileselect>
                </div>
              </div>
              <div class="form-group">
                <div class="caja">
                  <kendo-fileselect [(ngModel)]="pdfh70h71" [restrictions]="restrictionsPDF"
                                    [multiple]="false" (select)="pdfSeleccionadoh70h71($event)"
                                    (remove)="pdfEliminadoh70h71($event)">
                    <kendo-upload-messages cancel="{{ 'uploads.cancelar' | translate }}"
                                           clearSelectedFiles="{{ 'uploads.clearSelectedFiles' | translate }}"
                                           dropFilesHere="{{ 'uploads.dropFilesHere' | translate }}"
                                           externalDropFilesHere="{{ 'uploads.externalDropFilesHere' | translate }}"
                                           fileStatusFailed="{{ 'uploads.fileStatusFailed' | translate }}"
                                           fileStatusUploaded="{{ 'uploads.fileStatusUploaded' | translate }}"
                                           filesBatchStatus="{{ 'uploads.filesBatchStatus' | translate }}"
                                           filesBatchStatusFailed="{{ 'uploads.filesBatchStatusFailed' | translate }}"
                                           filesBatchStatusUploaded="{{ 'uploads.filesBatchStatusUploaded' | translate }}"
                                           headerStatusPaused="{{ 'uploads.headerStatusPaused' | translate }}"
                                           headerStatusUploaded="{{ 'uploads.headerStatusUploaded' | translate }}"
                                           headerStatusUploading="{{ 'uploads.headerStatusUploading' | translate }}"
                                           invalidFileExtension="{{ 'uploads.invalidFileExtension' | translate }}"
                                           invalidMaxFileSize="{{ 'uploads.invalidMaxFileSize' | translate }}"
                                           invalidMinFileSize="{{ 'uploads.invalidMinFileSize' | translate }}"
                                           pause="{{ 'uploads.pause' | translate }}"
                                           remove="{{ 'uploads.remove' | translate }}"
                                           resume="{{ 'uploads.resume' | translate }}"
                                           retry="{{ 'uploads.retry' | translate }}"
                                           select="{{ 'uploads.select' | translate }}"
                                           uploadSelectedFiles="{{ 'uploads.uploadSelectedFiles' | translate }}">
                    </kendo-upload-messages>
                  </kendo-fileselect>
                  <div class="cont-archivo" *ngIf="mostrarh70h71==true">
                    <div class="cont-archivo-inner">
                      <span class="k-file-group-wrapper">
                        <span class="k-file-group k-icon k-i-file-pdf"></span>
                      </span>
                      <span class="k-file-name-size-wrapper">
                        <span class="k-file-name">{{ 'configuracion.pdfh70h71' | translate}}</span>
                      </span>
                      <button type="button" class="k-button k-button-icon k-flat k-upload-action" tabindex="-1" (click)="pdfEliminadoHTASuperiorH15AMaestro($event)">
                        <span class="k-icon k-delete k-i-x"></span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <img [src]="imageToShowh70h71" class="img-conf" style="max-height: 113px">

          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-3 col-12">
    <div class="card">
      <div class="card-header">
        <h3>{{ 'datosForja.pieceriohta5000' | translate}}</h3>
        <div class="plegarpanel"></div>
      </div>
      <div class="card-body">
        <div class="clearfix">
          <div class="caja-imagen">
            <!--IMAGEN VIEJA-->
            <div class="cont-izq">
              <kendo-label text="{{ 'datosForja.subextractorExtractor' | translate}}">

              </kendo-label>

              <div class="form-group">
                <div class="caja">
                  <kendo-fileselect [(ngModel)]="archivosubextractorExtractor" [restrictions]="restrictionsImage"
                                    [multiple]="false" (select)="archivoSeleccionadosubextractorExtractor($event)"
                                    (remove)="archivoEliminadosubextractorExtractor($event)">
                    <kendo-upload-messages cancel="{{ 'uploads.cancelar' | translate }}"
                                           clearSelectedFiles="{{ 'uploads.clearSelectedFiles' | translate }}"
                                           dropFilesHere="{{ 'uploads.dropFilesHere' | translate }}"
                                           externalDropFilesHere="{{ 'uploads.externalDropFilesHere' | translate }}"
                                           fileStatusFailed="{{ 'uploads.fileStatusFailed' | translate }}"
                                           fileStatusUploaded="{{ 'uploads.fileStatusUploaded' | translate }}"
                                           filesBatchStatus="{{ 'uploads.filesBatchStatus' | translate }}"
                                           filesBatchStatusFailed="{{ 'uploads.filesBatchStatusFailed' | translate }}"
                                           filesBatchStatusUploaded="{{ 'uploads.filesBatchStatusUploaded' | translate }}"
                                           headerStatusPaused="{{ 'uploads.headerStatusPaused' | translate }}"
                                           headerStatusUploaded="{{ 'uploads.headerStatusUploaded' | translate }}"
                                           headerStatusUploading="{{ 'uploads.headerStatusUploading' | translate }}"
                                           invalidFileExtension="{{ 'uploads.invalidFileExtension' | translate }}"
                                           invalidMaxFileSize="{{ 'uploads.invalidMaxFileSize' | translate }}"
                                           invalidMinFileSize="{{ 'uploads.invalidMinFileSize' | translate }}"
                                           pause="{{ 'uploads.pause' | translate }}"
                                           remove="{{ 'uploads.remove' | translate }}"
                                           resume="{{ 'uploads.resume' | translate }}"
                                           retry="{{ 'uploads.retry' | translate }}"
                                           select="{{ 'uploads.selectImages' | translate }}"
                                           uploadSelectedFiles="{{ 'uploads.uploadSelectedFiles' | translate }}">
                    </kendo-upload-messages>
                  </kendo-fileselect>
                </div>
              </div>
              <div class="form-group">
                <div class="caja">
                  <kendo-fileselect [(ngModel)]="pdfsubextractorExtractor" [restrictions]="restrictionsPDF"
                                    [multiple]="false" (select)="pdfSeleccionadosubextractorExtractor($event)"
                                    (remove)="pdfEliminadosubextractorExtractor($event)">
                    <kendo-upload-messages cancel="{{ 'uploads.cancelar' | translate }}"
                                           clearSelectedFiles="{{ 'uploads.clearSelectedFiles' | translate }}"
                                           dropFilesHere="{{ 'uploads.dropFilesHere' | translate }}"
                                           externalDropFilesHere="{{ 'uploads.externalDropFilesHere' | translate }}"
                                           fileStatusFailed="{{ 'uploads.fileStatusFailed' | translate }}"
                                           fileStatusUploaded="{{ 'uploads.fileStatusUploaded' | translate }}"
                                           filesBatchStatus="{{ 'uploads.filesBatchStatus' | translate }}"
                                           filesBatchStatusFailed="{{ 'uploads.filesBatchStatusFailed' | translate }}"
                                           filesBatchStatusUploaded="{{ 'uploads.filesBatchStatusUploaded' | translate }}"
                                           headerStatusPaused="{{ 'uploads.headerStatusPaused' | translate }}"
                                           headerStatusUploaded="{{ 'uploads.headerStatusUploaded' | translate }}"
                                           headerStatusUploading="{{ 'uploads.headerStatusUploading' | translate }}"
                                           invalidFileExtension="{{ 'uploads.invalidFileExtension' | translate }}"
                                           invalidMaxFileSize="{{ 'uploads.invalidMaxFileSize' | translate }}"
                                           invalidMinFileSize="{{ 'uploads.invalidMinFileSize' | translate }}"
                                           pause="{{ 'uploads.pause' | translate }}"
                                           remove="{{ 'uploads.remove' | translate }}"
                                           resume="{{ 'uploads.resume' | translate }}"
                                           retry="{{ 'uploads.retry' | translate }}"
                                           select="{{ 'uploads.select' | translate }}"
                                           uploadSelectedFiles="{{ 'uploads.uploadSelectedFiles' | translate }}">
                    </kendo-upload-messages>
                  </kendo-fileselect>
                  <div class="cont-archivo" *ngIf="mostrarsubextractorExtractor==true">
                    <div class="cont-archivo-inner">
                      <span class="k-file-group-wrapper">
                        <span class="k-file-group k-icon k-i-file-pdf"></span>
                      </span>
                      <span class="k-file-name-size-wrapper">
                        <span class="k-file-name">{{ 'configuracion.pdfsubextractorExtractor' | translate}}</span>
                      </span>
                      <button type="button" class="k-button k-button-icon k-flat k-upload-action" tabindex="-1" (click)="pdfEliminadoHTASuperiorH15AMaestro($event)">
                        <span class="k-icon k-delete k-i-x"></span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <img [src]="imageToShowsubextractorExtractor" class="img-conf" style="max-height: 113px">
          </div>
          <div class="caja-imagen">
            <!--IMAGEN VIEJA-->
            <div class="cont-izq">
              <kendo-label text="{{ 'datosForja.arandela' | translate}}">

              </kendo-label>

              <div class="form-group">
                <div class="caja">
                  <kendo-fileselect [(ngModel)]="archivoP5000Arandela" [restrictions]="restrictionsImage"
                                    [multiple]="false" (select)="archivoSeleccionadoP5000Arandela($event)"
                                    (remove)="archivoEliminadoP5000Arandela($event)">
                    <kendo-upload-messages cancel="{{ 'uploads.cancelar' | translate }}"
                                           clearSelectedFiles="{{ 'uploads.clearSelectedFiles' | translate }}"
                                           dropFilesHere="{{ 'uploads.dropFilesHere' | translate }}"
                                           externalDropFilesHere="{{ 'uploads.externalDropFilesHere' | translate }}"
                                           fileStatusFailed="{{ 'uploads.fileStatusFailed' | translate }}"
                                           fileStatusUploaded="{{ 'uploads.fileStatusUploaded' | translate }}"
                                           filesBatchStatus="{{ 'uploads.filesBatchStatus' | translate }}"
                                           filesBatchStatusFailed="{{ 'uploads.filesBatchStatusFailed' | translate }}"
                                           filesBatchStatusUploaded="{{ 'uploads.filesBatchStatusUploaded' | translate }}"
                                           headerStatusPaused="{{ 'uploads.headerStatusPaused' | translate }}"
                                           headerStatusUploaded="{{ 'uploads.headerStatusUploaded' | translate }}"
                                           headerStatusUploading="{{ 'uploads.headerStatusUploading' | translate }}"
                                           invalidFileExtension="{{ 'uploads.invalidFileExtension' | translate }}"
                                           invalidMaxFileSize="{{ 'uploads.invalidMaxFileSize' | translate }}"
                                           invalidMinFileSize="{{ 'uploads.invalidMinFileSize' | translate }}"
                                           pause="{{ 'uploads.pause' | translate }}"
                                           remove="{{ 'uploads.remove' | translate }}"
                                           resume="{{ 'uploads.resume' | translate }}"
                                           retry="{{ 'uploads.retry' | translate }}"
                                           select="{{ 'uploads.selectImages' | translate }}"
                                           uploadSelectedFiles="{{ 'uploads.uploadSelectedFiles' | translate }}">
                    </kendo-upload-messages>
                  </kendo-fileselect>

                </div>
              </div>
              <div class="form-group">
                <div class="caja">
                  <kendo-fileselect [(ngModel)]="pdfP5000Arandela" [restrictions]="restrictionsPDF"
                                    [multiple]="false" (select)="pdfSeleccionadoP5000Arandela($event)"
                                    (remove)="pdfEliminadoP5000Arandela($event)">
                    <kendo-upload-messages cancel="{{ 'uploads.cancelar' | translate }}"
                                           clearSelectedFiles="{{ 'uploads.clearSelectedFiles' | translate }}"
                                           dropFilesHere="{{ 'uploads.dropFilesHere' | translate }}"
                                           externalDropFilesHere="{{ 'uploads.externalDropFilesHere' | translate }}"
                                           fileStatusFailed="{{ 'uploads.fileStatusFailed' | translate }}"
                                           fileStatusUploaded="{{ 'uploads.fileStatusUploaded' | translate }}"
                                           filesBatchStatus="{{ 'uploads.filesBatchStatus' | translate }}"
                                           filesBatchStatusFailed="{{ 'uploads.filesBatchStatusFailed' | translate }}"
                                           filesBatchStatusUploaded="{{ 'uploads.filesBatchStatusUploaded' | translate }}"
                                           headerStatusPaused="{{ 'uploads.headerStatusPaused' | translate }}"
                                           headerStatusUploaded="{{ 'uploads.headerStatusUploaded' | translate }}"
                                           headerStatusUploading="{{ 'uploads.headerStatusUploading' | translate }}"
                                           invalidFileExtension="{{ 'uploads.invalidFileExtension' | translate }}"
                                           invalidMaxFileSize="{{ 'uploads.invalidMaxFileSize' | translate }}"
                                           invalidMinFileSize="{{ 'uploads.invalidMinFileSize' | translate }}"
                                           pause="{{ 'uploads.pause' | translate }}"
                                           remove="{{ 'uploads.remove' | translate }}"
                                           resume="{{ 'uploads.resume' | translate }}"
                                           retry="{{ 'uploads.retry' | translate }}"
                                           select="{{ 'uploads.select' | translate }}"
                                           uploadSelectedFiles="{{ 'uploads.uploadSelectedFiles' | translate }}">
                    </kendo-upload-messages>
                  </kendo-fileselect>
                  <div class="cont-archivo" *ngIf="mostrarP5000Arandela==true">
                    <div class="cont-archivo-inner">
                      <span class="k-file-group-wrapper">
                        <span class="k-file-group k-icon k-i-file-pdf"></span>
                      </span>
                      <span class="k-file-name-size-wrapper">
                        <span class="k-file-name">{{ 'configuracion.pdfP5000Arandela' | translate}}</span>
                      </span>
                      <button type="button" class="k-button k-button-icon k-flat k-upload-action" tabindex="-1" (click)="pdfEliminadoHTASuperiorH15AMaestro($event)">
                        <span class="k-icon k-delete k-i-x"></span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <img [src]="imageToShowP5000Arandela" class="img-conf" style="max-height: 113px">
          </div>
          <div class="caja-imagen">
            <!--IMAGEN VIEJA-->
            <div class="cont-izq">
              <kendo-label text="{{ 'datosForja.mandrinoMasBulonCorte' | translate}}">

              </kendo-label>

              <div class="form-group">
                <div class="caja">

                  <kendo-fileselect [(ngModel)]="archivoPiecerioMandrino5000" [restrictions]="restrictionsImage"
                                    [multiple]="false" (select)="archivoSeleccionadoPiecerioMandrino5000($event)"
                                    (remove)="archivoEliminadoPiecerioMandrino5000($event)">
                    <kendo-upload-messages cancel="{{ 'uploads.cancelar' | translate }}"
                                           clearSelectedFiles="{{ 'uploads.clearSelectedFiles' | translate }}"
                                           dropFilesHere="{{ 'uploads.dropFilesHere' | translate }}"
                                           externalDropFilesHere="{{ 'uploads.externalDropFilesHere' | translate }}"
                                           fileStatusFailed="{{ 'uploads.fileStatusFailed' | translate }}"
                                           fileStatusUploaded="{{ 'uploads.fileStatusUploaded' | translate }}"
                                           filesBatchStatus="{{ 'uploads.filesBatchStatus' | translate }}"
                                           filesBatchStatusFailed="{{ 'uploads.filesBatchStatusFailed' | translate }}"
                                           filesBatchStatusUploaded="{{ 'uploads.filesBatchStatusUploaded' | translate }}"
                                           headerStatusPaused="{{ 'uploads.headerStatusPaused' | translate }}"
                                           headerStatusUploaded="{{ 'uploads.headerStatusUploaded' | translate }}"
                                           headerStatusUploading="{{ 'uploads.headerStatusUploading' | translate }}"
                                           invalidFileExtension="{{ 'uploads.invalidFileExtension' | translate }}"
                                           invalidMaxFileSize="{{ 'uploads.invalidMaxFileSize' | translate }}"
                                           invalidMinFileSize="{{ 'uploads.invalidMinFileSize' | translate }}"
                                           pause="{{ 'uploads.pause' | translate }}"
                                           remove="{{ 'uploads.remove' | translate }}"
                                           resume="{{ 'uploads.resume' | translate }}"
                                           retry="{{ 'uploads.retry' | translate }}"
                                           select="{{ 'uploads.selectImages' | translate }}"
                                           uploadSelectedFiles="{{ 'uploads.uploadSelectedFiles' | translate }}">
                    </kendo-upload-messages>
                  </kendo-fileselect>
                </div>
              </div>
              <div class="form-group">
                <div class="caja">

                  <kendo-fileselect [(ngModel)]="pdfPiecerioMandrino5000" [restrictions]="restrictionsPDF"
                                    [multiple]="false" (select)="pdfSeleccionadoPiecerioMandrino5000($event)"
                                    (remove)="pdfEliminadoPiecerioMandrino5000($event)">
                    <kendo-upload-messages cancel="{{ 'uploads.cancelar' | translate }}"
                                           clearSelectedFiles="{{ 'uploads.clearSelectedFiles' | translate }}"
                                           dropFilesHere="{{ 'uploads.dropFilesHere' | translate }}"
                                           externalDropFilesHere="{{ 'uploads.externalDropFilesHere' | translate }}"
                                           fileStatusFailed="{{ 'uploads.fileStatusFailed' | translate }}"
                                           fileStatusUploaded="{{ 'uploads.fileStatusUploaded' | translate }}"
                                           filesBatchStatus="{{ 'uploads.filesBatchStatus' | translate }}"
                                           filesBatchStatusFailed="{{ 'uploads.filesBatchStatusFailed' | translate }}"
                                           filesBatchStatusUploaded="{{ 'uploads.filesBatchStatusUploaded' | translate }}"
                                           headerStatusPaused="{{ 'uploads.headerStatusPaused' | translate }}"
                                           headerStatusUploaded="{{ 'uploads.headerStatusUploaded' | translate }}"
                                           headerStatusUploading="{{ 'uploads.headerStatusUploading' | translate }}"
                                           invalidFileExtension="{{ 'uploads.invalidFileExtension' | translate }}"
                                           invalidMaxFileSize="{{ 'uploads.invalidMaxFileSize' | translate }}"
                                           invalidMinFileSize="{{ 'uploads.invalidMinFileSize' | translate }}"
                                           pause="{{ 'uploads.pause' | translate }}"
                                           remove="{{ 'uploads.remove' | translate }}"
                                           resume="{{ 'uploads.resume' | translate }}"
                                           retry="{{ 'uploads.retry' | translate }}"
                                           select="{{ 'uploads.select' | translate }}"
                                           uploadSelectedFiles="{{ 'uploads.uploadSelectedFiles' | translate }}">
                    </kendo-upload-messages>
                  </kendo-fileselect>
                  <div class="cont-archivo" *ngIf="mostrarPiecerioMandrino5000==true">
                    <div class="cont-archivo-inner">
                      <span class="k-file-group-wrapper">
                        <span class="k-file-group k-icon k-i-file-pdf"></span>
                      </span>
                      <span class="k-file-name-size-wrapper">
                        <span class="k-file-name">{{ 'configuracion.pdfPiecerioMandrino5000' | translate}}</span>
                      </span>
                      <button type="button" class="k-button k-button-icon k-flat k-upload-action" tabindex="-1" (click)="pdfEliminadoHTASuperiorH15AMaestro($event)">
                        <span class="k-icon k-delete k-i-x"></span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <img [src]="imageToShowPiecerioMandrino5000" class="img-conf" style="max-height: 113px">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<button kendoButton class="btn mr-1  btn-primary" (click)="onSubmit($event)">
  {{ 'botones.guardar' | translate}}
</button>


<router-outlet></router-outlet>