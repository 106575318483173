import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './_helpers';

import { HomeComponent } from './home';
import { UsuariosComponent } from './usuarios/usuarios.component';
import { EditarCrearUsuarioComponent } from './usuarios/editar-crear-usuario/editar-crear-usuario.component';
import { UsuariosTiposComponent } from './usuarios-tipos/usuarios-tipos.component';
import { EditarCrearUsuarioTipoComponent } from './usuarios-tipos/editar-crear-usuario-tipo/editar-crear-usuario-tipo.component';
import { DetailsComponent } from './profile/details.component';
import { UpdateComponent } from './profile/update/update.component';
import { PlacaSuperiorH15AComponent } from './placaSuperiorH15A/placaSuperiorH15A.component';
import { EditarCrearPlacaSuperiorH15AComponent } from './placaSuperiorH15A/editar-crear-placaSuperiorH15A/editar-crear-placaSuperiorH15A.component';
import { PlacaSuperiorH30AComponent } from './placaSuperiorH30A/placaSuperiorH30A.component';
import { EditarCrearPlacaSuperiorH30AComponent } from './placaSuperiorH30A/editar-crear-placaSuperiorH30A/editar-crear-placaSuperiorH30A.component';
import { CebollasComponent } from './cebollas/cebollas.component';
import { EditarCrearCebollasComponent } from './cebollas/editar-crear-cebollas/editar-crear-cebollas.component';
import { Mandrino10000Component } from './mandrino10000/mandrino10000.component';
import { EditarCrearMandrino10000Component } from './mandrino10000/editar-crear-mandrino10000/editar-crear-mandrino10000.component';
import { Mandrino5000Component } from './mandrino5000/mandrino5000.component';
import { EditarCrearMandrino5000Component } from './mandrino5000/editar-crear-mandrino5000/editar-crear-mandrino5000.component';
import { PoleasComponent } from './poleas/poleas.component';
import { EditarCrearPoleasComponent } from './poleas/editar-crear-poleas/editar-crear-poleas.component';
import { Subextractor10000Component } from './subextractor10000/subextractor10000.component';
import { EditarCrearSubextractor10000Component } from './subextractor10000/editar-crear-subextractor10000/editar-crear-subextractor10000.component';
import { Subextractor5000Component } from './subextractor5000/subextractor5000.component';
import { EditarCrearSubextractor5000Component } from './subextractor5000/editar-crear-subextractor5000/editar-crear-subextractor5000.component';
import { BasePrepunzonadoInferior10000Component } from './basePrepunzonadoInferior10000/basePrepunzonadoInferior10000.component';
import { EditarCrearBasePrepunzonadoInferior10000Component } from './basePrepunzonadoInferior10000//editar-crear-basePrepunzonadoInferior10000/editar-crear-basePrepunzonadoInferior10000.component';
import { DatosForjaComponent } from './datosForja/datosForja.component';
import { DatosForjaDetalleComponent } from './datosForja/datosForjaDetalle/datosForjaDetalle.component';
import { HojaImpresionComponent } from './datosForja/hojaImpresion/hojaImpresion.component';
import { ConfiguracionComponent } from './configuracion/configuracion.component';
import { BuscadorComponent } from './buscador/buscador.component';
import { ManualesComponent } from './manuales/manuales.component';
import { CatalogoForjaRuedasComponent } from './catalogo-forja-ruedas/catalogo-forja-ruedas.component';
import { PrepunzonadoSuperiorComponent } from './prepunzonadoSuperior/prepunzonadoSuperior.component';
import { EditarCrearPrepunzonadoSuperiorComponent } from './prepunzonadoSuperior/editar-crear-prepunzonadoSuperior/editar-crear-prepunzonadoSuperior.component';

const loginModule = () =>
  import('./login/login.module').then((x) => x.LoginModule);

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    loadChildren: loginModule,
    canActivate: [AuthGuard],
  },
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'login', loadChildren: loginModule },
  {
    path: 'profile',
    component: DetailsComponent,
    canActivate: [AuthGuard],
    children: [
      { path: 'update', component: UpdateComponent, canActivate: [AuthGuard] },
    ],
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'usuarios',
    component: UsuariosComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    children: [
      {
        path: 'editar/:id',
        component: EditarCrearUsuarioComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'crear',
        component: EditarCrearUsuarioComponent,
        canActivate: [AuthGuard],
      },
    ],
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'usuarios-tipos',
    component: UsuariosTiposComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    children: [
      {
        path: 'editar/:id',
        component: EditarCrearUsuarioTipoComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'crear',
        component: EditarCrearUsuarioTipoComponent,
        canActivate: [AuthGuard],
      },
    ],
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },

  //My Routes
  {
    path: 'placaSuperiorH15A',
    component: PlacaSuperiorH15AComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    children: [
      {
        path: 'editar/:id',
        component: EditarCrearPlacaSuperiorH15AComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'crear',
        component: EditarCrearPlacaSuperiorH15AComponent,
        canActivate: [AuthGuard],
      },
    ],
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'placaSuperiorH30A',
    component: PlacaSuperiorH30AComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    children: [
      {
        path: 'editar/:id',
        component: EditarCrearPlacaSuperiorH30AComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'crear',
        component: EditarCrearPlacaSuperiorH30AComponent,
        canActivate: [AuthGuard],
      },
    ],
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'cebollas',
    component: CebollasComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    children: [
      {
        path: 'editar/:id',
        component: EditarCrearCebollasComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'crear',
        component: EditarCrearCebollasComponent,
        canActivate: [AuthGuard],
      },
    ],
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'mandrino10000',
    component: Mandrino10000Component,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    children: [
      {
        path: 'editar/:id',
        component: EditarCrearMandrino10000Component,
        canActivate: [AuthGuard],
      },
      {
        path: 'crear',
        component: EditarCrearMandrino10000Component,
        canActivate: [AuthGuard],
      },
    ],
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'mandrino5000',
    component: Mandrino5000Component,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    children: [
      {
        path: 'editar/:id',
        component: EditarCrearMandrino5000Component,
        canActivate: [AuthGuard],
      },
      {
        path: 'crear',
        component: EditarCrearMandrino5000Component,
        canActivate: [AuthGuard],
      },
    ],
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'poleas',
    component: PoleasComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    children: [
      {
        path: 'editar/:id',
        component: EditarCrearPoleasComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'crear',
        component: EditarCrearPoleasComponent,
        canActivate: [AuthGuard],
      },
    ],
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'subextractor10000',
    component: Subextractor10000Component,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    children: [
      {
        path: 'editar/:id',
        component: EditarCrearSubextractor10000Component,
        canActivate: [AuthGuard],
      },
      {
        path: 'crear',
        component: EditarCrearSubextractor10000Component,
        canActivate: [AuthGuard],
      },
    ],
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'subextractor5000',
    component: Subextractor5000Component,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    children: [
      {
        path: 'editar/:id',
        component: EditarCrearSubextractor5000Component,
        canActivate: [AuthGuard],
      },
      {
        path: 'crear',
        component: EditarCrearSubextractor5000Component,
        canActivate: [AuthGuard],
      },
    ],
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'basePrepunzonadoInferior10000',
    component: BasePrepunzonadoInferior10000Component,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    children: [
      {
        path: 'editar/:id',
        component: EditarCrearBasePrepunzonadoInferior10000Component,
        canActivate: [AuthGuard],
      },
      {
        path: 'crear',
        component: EditarCrearBasePrepunzonadoInferior10000Component,
        canActivate: [AuthGuard],
      },
    ],
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'prepunzonadoSuperior',
    component: PrepunzonadoSuperiorComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    children: [
      {
        path: 'editar/:id',
        component: EditarCrearPrepunzonadoSuperiorComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'crear',
        component: EditarCrearPrepunzonadoSuperiorComponent,
        canActivate: [AuthGuard],
      },
    ],
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'datosForja',
    component: DatosForjaComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'datosForja/editar/:id',
    component: DatosForjaDetalleComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==2'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'hojaImpresion/:id/:desdeId',
    component: HojaImpresionComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'configuracion',
    component: ConfiguracionComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==2'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'buscador',
    component: BuscadorComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'manuales',
    component: ManualesComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'catalogoForjaRuedas',
    component: CatalogoForjaRuedasComponent,
    canActivate: [AuthGuard],
    data: { permisos: ['GestionUsuarios==1'] },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
 
  // otherwise redirect to home
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
