import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TipoUsuario, Usuario } from '@app/_models';
import { AlertService, MenuService, ManualesService, UsuariosTiposService } from '@app/_services';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { FileRestrictions } from '@progress/kendo-angular-upload';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-manuales',
  templateUrl: './manuales.component.html'
})
export class ManualesComponent implements OnInit {

  navigationSubscription;

  public datosLaminador: any;
  public datosHorno: any;
  public datosPrensaForjar: any;
  public datosMedidor: any;
  public datosSierras: any;
  public datosDocumentos: any;
  public datosPrensaCurvar: any;
  public datosVolteador: any;
  public datosGranalladora: any;

  public datosLaminadorSelected: any = [];
  public datosHornoSelected: any = [];
  public datosPrensaForjarSelected: any = [];
  public datosMedidorSelected: any = [];
  public datosSierrasSelected: any = [];
  public datosDocumentosSelected: any = [];
  public datosPrensaCurvarSelected: any = [];
  public datosVolteadorSelected: any = [];
  public datosGranalladoraSelected: any = [];

  public tipoDatoSeleccionadoModal: any = [];
  public submit: any;
  public errorNombre: any;
  public nombreModal: any;
  public idModalSeleccionado: any;
  public archivos: any = [];

  public archivosModal: any = [];
  public archivosModal2: any = [];
  public nombresArchivosModal: any = [];

  modalReference: NgbModalRef;
  @ViewChild('modalNuevo') modalNuevo: NgbModalRef;
  @ViewChild('modalEditar') modalEditar: NgbModalRef;
  @ViewChild('modalEliminar') modalEliminar: NgbModalRef;
  @ViewChild('modalArchivos') modalArchivos: NgbModalRef;
  @ViewChild('modalImagenesVideos') modalImagenesVideos: NgbModalRef;

  public loading: any;

  public modalArchivos_archivos: any;
  public modalArchivos_nombres: any;
  public modalArchivos_tipos: any;

  public archivoAMostrar: any;
  public tipoAMostrar: any;

  public restrictions: FileRestrictions = {
      allowedExtensions: ['.jpg', '.jpeg', '.png', '.gif', '.pdf', '.doc', '.docx', '.rtf', 'xlsx', '.mp4', '.mkv', '.mov', '.avi', '.flv'],
      maxFileSize: 10000000
    }; 

  public show: any;

  public settings = {
    prevButtonIcon: "fa fa-arrow-circle-left",
    nextButtonIcon: "fa fa-arrow-circle-right",
  };

  constructor(private manualesService: ManualesService, private modalService: NgbModal,
    private alertService: AlertService, 
    private menuService: MenuService, 
    public router: Router, 
    private route: ActivatedRoute, 
    private formBuilder: FormBuilder, 
    private translateService: TranslateService, private cdref: ChangeDetectorRef, private sanitizer: DomSanitizer) {
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        if (this.router.url == '/manuales') {
          this.menuService.titulo = this.translateService.instant('manuales.titulo');
          this.cdref.detectChanges();
          this.cargarDatos();
        }
      }else{
        if (this.router.url == '/manuales') {
          this.menuService.titulo = this.translateService.instant('manuales.titulo');
          this.cdref.detectChanges();
        }
      }
    });
  }

  cargarDatos() {
    this.loading=true;
    this.datosLaminadorSelected = [];
    this.datosHornoSelected = [];
    this.datosPrensaForjarSelected = [];
    this.datosMedidorSelected = [];
    this.datosSierrasSelected = [];
    this.datosDocumentosSelected = [];
    this.datosPrensaCurvarSelected = [];
    this.datosVolteadorSelected = [];
    this.datosGranalladoraSelected = [];
    this.tipoDatoSeleccionadoModal = undefined;
    this.idModalSeleccionado = undefined;
    this.archivos=[];
    this.manualesService.getAll()
    .pipe(first())
    .subscribe((result: any) =>{
      this.datosLaminador = result.laminador;
      this.datosHorno = result.horno;
      this.datosPrensaForjar = result.prensaForjar;
      this.datosMedidor = result.medidor;
      this.datosSierras = result.sierras;
      this.datosDocumentos = result.documentos;
      this.datosPrensaCurvar = result.prensaCurvar;
      this.datosVolteador = result.volteador;
      this.datosGranalladora = result.granalladora;
      this.loading=false;
    });
  }

  ngOnInit(): void {
    this.menuService.titulo = this.translateService.instant('manuales.titulo');
  }

  onClickEditar(tipoDato){
    this.show = true;
    this.loading=true;
    switch(tipoDato) { 
      case 'laminador': { 
        if(this.datosLaminadorSelected.length>0){
          this.manualesService.getById(this.datosLaminadorSelected[0])
          .pipe(first())
          .subscribe((result: any) =>{
            this.nombreModal = result.data[0].nombre;
            this.idModalSeleccionado = result.data[0].id;
            this.archivosModal = result.data[0].archivosBase64.split(";and;");
            this.nombresArchivosModal = result.data[0].archivos.split(";and;");
            this.archivosModal2=[];
            this.archivosModal.forEach((element,i) => {
              var auxiDict = {id: -1, archivo: element, nombre: this.nombresArchivosModal[i]};
              this.archivosModal2.push(auxiDict);
            });
            this.submit= false;
            this.errorNombre = false;
            this.modalReference = this.modalService.open(this.modalEditar, { backdrop: 'static', size: 's', keyboard: false, centered: true });
            this.loading=false;
          });
        }
        break; 
      } 
      case 'horno': { 
        if(this.datosHornoSelected.length>0){
          this.manualesService.getById(this.datosHornoSelected[0])
          .pipe(first())
          .subscribe((result: any) =>{
            this.nombreModal = result[0].nombre;
            this.idModalSeleccionado = result[0].id;
            this.archivosModal = result.data[0].archivosBase64.split(";and;");
            this.nombresArchivosModal = result.data[0].archivos.split(";and;");
            this.archivosModal2=[];
            this.archivosModal.forEach((element,i) => {
              var auxiDict = {id: -1, archivo: element, nombre: this.nombresArchivosModal[i]};
              this.archivosModal2.push(auxiDict);
            });
            this.submit= false;
            this.errorNombre = false;
            this.modalReference = this.modalService.open(this.modalEditar, { backdrop: 'static', size: 's', keyboard: false, centered: true });
            this.loading=false;
          });
        }
        break; 
      } 
      case 'prensaForjar': { 
        if(this.datosPrensaForjarSelected.length>0){
          this.manualesService.getById(this.datosPrensaForjarSelected[0])
          .pipe(first())
          .subscribe((result: any) =>{
            this.nombreModal = result[0].nombre;
            this.idModalSeleccionado = result[0].id;
            this.archivosModal = result.data[0].archivosBase64.split(";and;");
            this.nombresArchivosModal = result.data[0].archivos.split(";and;");
            this.archivosModal2=[];
            this.archivosModal.forEach((element,i) => {
              var auxiDict = {id: -1, archivo: element, nombre: this.nombresArchivosModal[i]};
              this.archivosModal2.push(auxiDict);
            });
            this.submit= false;
            this.errorNombre = false;
            this.modalReference = this.modalService.open(this.modalEditar, { backdrop: 'static', size: 's', keyboard: false, centered: true });
            this.loading=false;
          });
        }
        break; 
      } 
      case 'medidor': { 
        if(this.datosMedidorSelected.length>0){
          this.manualesService.getById(this.datosMedidorSelected[0])
          .pipe(first())
          .subscribe((result: any) =>{
            this.nombreModal = result[0].nombre;
            this.idModalSeleccionado = result[0].id;
            this.archivosModal = result.data[0].archivosBase64.split(";and;");
            this.nombresArchivosModal = result.data[0].archivos.split(";and;");
            this.archivosModal2=[];
            this.archivosModal.forEach((element,i) => {
              var auxiDict = {id: -1, archivo: element, nombre: this.nombresArchivosModal[i]};
              this.archivosModal2.push(auxiDict);
            });
            this.submit= false;
            this.errorNombre = false;
            this.modalReference = this.modalService.open(this.modalEditar, { backdrop: 'static', size: 's', keyboard: false, centered: true });
            this.loading=false;
          });
        }
        break; 
      } 
      case 'sierras': { 
        if(this.datosSierrasSelected.length>0){
          this.manualesService.getById(this.datosSierrasSelected[0])
          .pipe(first())
          .subscribe((result: any) =>{
            this.nombreModal = result[0].nombre;
            this.idModalSeleccionado = result[0].id;
            this.archivosModal = result.data[0].archivosBase64.split(";and;");
            this.nombresArchivosModal = result.data[0].archivos.split(";and;");
            this.archivosModal2=[];
            this.archivosModal.forEach((element,i) => {
              var auxiDict = {id: -1, archivo: element, nombre: this.nombresArchivosModal[i]};
              this.archivosModal2.push(auxiDict);
            });
            this.submit= false;
            this.errorNombre = false;
            this.modalReference = this.modalService.open(this.modalEditar, { backdrop: 'static', size: 's', keyboard: false, centered: true });
            this.loading=false;
          });
        } 
        break; 
      }
      case 'documentos': { 
        if(this.datosDocumentosSelected.length>0){
          this.manualesService.getById(this.datosDocumentosSelected[0])
          .pipe(first())
          .subscribe((result: any) =>{
            this.nombreModal = result[0].nombre;
            this.idModalSeleccionado = result[0].id;
            this.archivosModal = result.data[0].archivosBase64.split(";and;");
            this.nombresArchivosModal = result.data[0].archivos.split(";and;");
            this.archivosModal2=[];
            this.archivosModal.forEach((element,i) => {
              var auxiDict = {id: -1, archivo: element, nombre: this.nombresArchivosModal[i]};
              this.archivosModal2.push(auxiDict);
            });
            this.submit= false;
            this.errorNombre = false;
            this.modalReference = this.modalService.open(this.modalEditar, { backdrop: 'static', size: 's', keyboard: false, centered: true });
            this.loading=false;
          });
        }
        break; 
      }
      case 'prensaCurvar': { 
        if(this.datosPrensaCurvarSelected.length>0){
          this.manualesService.getById(this.datosPrensaCurvarSelected[0])
          .pipe(first())
          .subscribe((result: any) =>{
            this.nombreModal = result[0].nombre;
            this.idModalSeleccionado = result[0].id;
            this.archivosModal = result.data[0].archivosBase64.split(";and;");
            this.nombresArchivosModal = result.data[0].archivos.split(";and;");
            this.archivosModal2=[];
            this.archivosModal.forEach((element,i) => {
              var auxiDict = {id: -1, archivo: element, nombre: this.nombresArchivosModal[i]};
              this.archivosModal2.push(auxiDict);
            });
            this.submit= false;
            this.errorNombre = false;
            this.modalReference = this.modalService.open(this.modalEditar, { backdrop: 'static', size: 's', keyboard: false, centered: true });
            this.loading=false;
          });
        }
        break; 
      }
      case 'volteador': { 
        if(this.datosVolteadorSelected.length>0){
          this.manualesService.getById(this.datosVolteadorSelected[0])
          .pipe(first())
          .subscribe((result: any) =>{
            this.nombreModal = result[0].nombre;
            this.idModalSeleccionado = result[0].id;
            this.archivosModal = result.data[0].archivosBase64.split(";and;");
            this.nombresArchivosModal = result.data[0].archivos.split(";and;");
            this.archivosModal2=[];
            this.archivosModal.forEach((element,i) => {
              var auxiDict = {id: -1, archivo: element, nombre: this.nombresArchivosModal[i]};
              this.archivosModal2.push(auxiDict);
            });
            this.submit= false;
            this.errorNombre = false;
            this.modalReference = this.modalService.open(this.modalEditar, { backdrop: 'static', size: 's', keyboard: false, centered: true });
            this.loading=false;
          });
        }
        break; 
      }
      case 'granalladora': { 
        if(this.datosGranalladoraSelected.length>0){
          this.manualesService.getById(this.datosGranalladoraSelected[0])
          .pipe(first())
          .subscribe((result: any) =>{
            this.nombreModal = result[0].nombre;
            this.idModalSeleccionado = result[0].id;
            this.archivosModal = result.data[0].archivosBase64.split(";and;");
            this.nombresArchivosModal = result.data[0].archivos.split(";and;");
            this.archivosModal2=[];
            this.archivosModal.forEach((element,i) => {
              var auxiDict = {id: -1, archivo: element, nombre: this.nombresArchivosModal[i]};
              this.archivosModal2.push(auxiDict);
            });
            this.submit= false;
            this.errorNombre = false;
            this.modalReference = this.modalService.open(this.modalEditar, { backdrop: 'static', size: 's', keyboard: false, centered: true });
            this.loading=false;
          });
        }
        break; 
      }
    }
  }

  onClickNuevo(tipoDato){
    this.loading=true;
    this.tipoDatoSeleccionadoModal = tipoDato;
    this.archivos=[];
    this.submit= false;
    this.errorNombre = false;
    this.nombreModal = "";
    this.modalReference = this.modalService.open(this.modalNuevo, { backdrop: 'static', size: 's', keyboard: false, centered: true });
    this.loading=false;
  }

  onClickAceptarNuevo(){
    this.loading=true;
    this.submit = true;
    if(this.nombreModal == "" || this.nombreModal == undefined || this.nombreModal == null){
      this.errorNombre = true;
    }else{
      this.errorNombre = false;
    }

    if(this.errorNombre){
      return;
    }

    this.manualesService.create(this.nombreModal, this.tipoDatoSeleccionadoModal, this.archivos)
      .pipe(first())
      .subscribe(result =>{
        if(result>0){
          this.alertService.success(this.translateService.instant('botones.creadocorrectamente'), { keepAfterRouteChange: true });
          this.cargarDatos();
        }else{
          this.alertService.error(this.translateService.instant('botones.errorcrear'), { keepAfterRouteChange: true });
        }
        this.modalReference.close();
        this.loading=false;
      });
  }

  onClickAceptarEditar(){
    this.loading=true;
    this.submit = true;
    if(this.nombreModal == "" || this.nombreModal == undefined || this.nombreModal == null){
      this.errorNombre = true;
    }else{
      this.errorNombre = false;
    }

    if(this.errorNombre){
      return;
    }
    this.manualesService.update(this.nombreModal, this.idModalSeleccionado, this.archivosModal2)
      .pipe(first())
      .subscribe(result =>{
        if(result>0){
          this.alertService.success(this.translateService.instant('botones.editadocorrectamente'), { keepAfterRouteChange: true });
          this.cargarDatos();
        }else{
          this.alertService.error(this.translateService.instant('botones.erroreditar'), { keepAfterRouteChange: true });
        }
        this.modalReference.close();
        this.loading=false;
      });
    
  }

  onClickEliminar(tipoDato){
    this.tipoDatoSeleccionadoModal = tipoDato;
    this.modalReference = this.modalService.open(this.modalEliminar, { backdrop: 'static', size: 's', keyboard: false, centered: true });
  }

  btnBorrarAceptar(){
    switch(this.tipoDatoSeleccionadoModal) { 
      case 'laminador': { 
        if(this.datosLaminadorSelected.length>0){
          this.datosLaminadorSelected.forEach(element => {
            this.manualesService.delete(element)
            .pipe(first())
            .subscribe((result: any) =>{
              if(result>0){
                this.alertService.success(this.translateService.instant('botones.eliminadocorrectamente'), { keepAfterRouteChange: true });
                this.cargarDatos();
              }else{
                this.alertService.error(this.translateService.instant('botones.erroreliminar'), { keepAfterRouteChange: true });
              }
            });
          });
          this.modalReference.close();
        }
        break; 
      } 
      case 'horno': { 
        if(this.datosHornoSelected.length>0){
          this.datosHornoSelected.forEach(element => {
            this.manualesService.delete(element)
            .pipe(first())
            .subscribe((result: any) =>{
              if(result>0){
                this.alertService.success(this.translateService.instant('botones.eliminadocorrectamente'), { keepAfterRouteChange: true });
                this.cargarDatos();
              }else{
                this.alertService.error(this.translateService.instant('botones.erroreliminar'), { keepAfterRouteChange: true });
              }
            });
          });
          this.modalReference.close();
        }
        break; 
      } 
      case 'prensaForjar': { 
        if(this.datosPrensaForjarSelected.length>0){
          this.datosPrensaForjarSelected.forEach(element => {
            this.manualesService.delete(element)
            .pipe(first())
            .subscribe((result: any) =>{
              if(result>0){
                this.alertService.success(this.translateService.instant('botones.eliminadocorrectamente'), { keepAfterRouteChange: true });
                this.cargarDatos();
              }else{
                this.alertService.error(this.translateService.instant('botones.erroreliminar'), { keepAfterRouteChange: true });
              }
            });
          });
          this.modalReference.close();
        }
        break; 
      } 
      case 'medidor': { 
        if(this.datosSierrasSelected.length>0){
          this.datosSierrasSelected.forEach(element => {
            this.manualesService.delete(element)
            .pipe(first())
            .subscribe((result: any) =>{
              if(result>0){
                this.alertService.success(this.translateService.instant('botones.eliminadocorrectamente'), { keepAfterRouteChange: true });
                this.cargarDatos();
              }else{
                this.alertService.error(this.translateService.instant('botones.erroreliminar'), { keepAfterRouteChange: true });
              }
            });
          });
          this.modalReference.close();
        }
        break; 
      } 
      case 'sierras': { 
        if(this.datosSierrasSelected.length>0){
          this.datosSierrasSelected.forEach(element => {
            this.manualesService.delete(element)
            .pipe(first())
            .subscribe((result: any) =>{
              if(result>0){
                this.alertService.success(this.translateService.instant('botones.eliminadocorrectamente'), { keepAfterRouteChange: true });
                this.cargarDatos();
              }else{
                this.alertService.error(this.translateService.instant('botones.erroreliminar'), { keepAfterRouteChange: true });
              }
            });
          });
          this.modalReference.close();
        }
        break; 
      }
      case 'documentos': { 
        if(this.datosDocumentosSelected.length>0){
          this.datosDocumentosSelected.forEach(element => {
            this.manualesService.delete(element)
            .pipe(first())
            .subscribe((result: any) =>{
              if(result>0){
                this.alertService.success(this.translateService.instant('botones.eliminadocorrectamente'), { keepAfterRouteChange: true });
                this.cargarDatos();
              }else{
                this.alertService.error(this.translateService.instant('botones.erroreliminar'), { keepAfterRouteChange: true });
              }
            });
          });
          this.modalReference.close();
        }
        break; 
      }
      case 'prensaCurvar': { 
        if(this.datosPrensaCurvarSelected.length>0){
          this.datosPrensaCurvarSelected.forEach(element => {
            this.manualesService.delete(element)
            .pipe(first())
            .subscribe((result: any) =>{
              if(result>0){
                this.alertService.success(this.translateService.instant('botones.eliminadocorrectamente'), { keepAfterRouteChange: true });
                this.cargarDatos();
              }else{
                this.alertService.error(this.translateService.instant('botones.erroreliminar'), { keepAfterRouteChange: true });
              }
            });
          });
          this.modalReference.close();
        }
        break; 
      }
      case 'volteador': { 
        if(this.datosVolteadorSelected.length>0){
          this.datosVolteadorSelected.forEach(element => {
            this.manualesService.delete(element)
            .pipe(first())
            .subscribe((result: any) =>{
              if(result>0){
                this.alertService.success(this.translateService.instant('botones.eliminadocorrectamente'), { keepAfterRouteChange: true });
                this.cargarDatos();
              }else{
                this.alertService.error(this.translateService.instant('botones.erroreliminar'), { keepAfterRouteChange: true });
              }
            });
          });
          this.modalReference.close();
        }
        break; 
      }
      case 'granalladora': { 
        if(this.datosGranalladoraSelected.length>0){
          this.datosGranalladoraSelected.forEach(element => {
            this.manualesService.delete(element)
            .pipe(first())
            .subscribe((result: any) =>{
              if(result>0){
                this.alertService.success(this.translateService.instant('botones.eliminadocorrectamente'), { keepAfterRouteChange: true });
                this.cargarDatos();
              }else{
                this.alertService.error(this.translateService.instant('botones.erroreliminar'), { keepAfterRouteChange: true });
              }
            });
          });
          this.modalReference.close();
        }
        break; 
      }
    }
  }

  //Funciones de las imagenes
  archivoSeleccionado(e){
    var th = this;
    if(e.files.length+this.archivos.length>5){
      this.alertService.error(this.translateService.instant('manuales.errorarchivos'), { keepAfterRouteChange: true });
      e.preventDefault();
    }else{
      e.files.forEach(element => {
        setTimeout(async function () {
          var archivoBase64: any = "";
          archivoBase64 = await th.toBase64(element.rawFile);
          var auxiDict = {id: element.uid, archivo: archivoBase64, nombre: element.name}
          th.archivos.push(auxiDict);
        }, 500);
      });
    }
  }
  archivoEliminado(e){
    this.archivos = this.archivos.filter(x=>x.id != e.files[0].uid);
  }

  archivoSeleccionado2(e){
    var th = this;
    if(e.files.length+this.archivosModal2.length>5){
      this.alertService.error(this.translateService.instant('manuales.errorarchivos'), { keepAfterRouteChange: true });
      e.preventDefault();
    }else{
      e.files.forEach(element => {
        setTimeout(async function () {
          var archivoBase64: any = "";
          archivoBase64 = await th.toBase64(element.rawFile);
          var auxiDict = {id: element.uid, archivo: archivoBase64, nombre: element.name}
          th.archivosModal2.push(auxiDict);
        }, 500);
      });
    }
  }
  archivoEliminado2(e, aux){
    if(aux==1)
      this.archivosModal2 = this.archivosModal2.filter(x=>x.id != e.files[0].uid);
    else if(aux==2){
      var auxi = this.archivosModal2.filter(x=>x.nombre == e);
      if(auxi!=undefined){
        auxi= auxi[0];
      }
      this.archivosModal2 = this.archivosModal2.filter(x=>x.nombre != e);
      this.archivosModal = this.archivosModal.filter(x=>x != auxi.archivo);
      this.nombresArchivosModal = this.nombresArchivosModal.filter(x=>x != e);
    }
  }

  toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  cellClick(e){
    if(e.columnIndex==2){
      this.modalArchivos_archivos= e.dataItem.archivosBase64.split(";and;");
      this.modalArchivos_nombres= e.dataItem.archivos.split(";and;");
      this.modalArchivos_tipos= e.dataItem.tipos.split(";and;");
      this.modalReference = this.modalService.open(this.modalArchivos, { backdrop: 'static', size: 's', keyboard: false, centered: true });
    }
  }

  clickArchivo(archivo, tipo, nombre){
    if(tipo==1 || tipo==2){
      this.archivoAMostrar = archivo;
      this.tipoAMostrar = tipo;
      this.modalReference.close();
      this.modalReference = this.modalService.open(this.modalImagenesVideos, { backdrop: 'static', size: 'lg', keyboard: false, centered: true });
    }else if(tipo==3){
      var auxi = nombre;
      console.log(archivo);
      if(auxi.split(".")[1].toUpperCase()=="PDF"){
        const win = window.open("about:blank", "_blank");
        let html = '';

        html += '<html>';
        html += '<body style="margin:0!important">';
        html += '<embed width="100%" height="100%" src="' + archivo + '" type="application/pdf" />';
        html += '</body>';
        html += '</html>';

        setTimeout(() => {
          win.document.write(html);
          win.document.title = nombre;
        }, 0);
      }else{
        const downloadLink = document.createElement("a");

        downloadLink.href = archivo;
        downloadLink.download = nombre;
        downloadLink.click();
      }
    }
  }

  cancelarImagenVideo(){
    this.modalReference.close();
    this.modalReference = this.modalService.open(this.modalArchivos, { backdrop: 'static', size: 's', keyboard: false, centered: true });
  }
}
