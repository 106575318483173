<!-- Selección Enviar App-->
<kendo-multiselect
  [autoClose]="true"
  [filterable]="true"
  (filterChange)="handleFilter($event)"
  (valueChange)="valueChange($event)"
  (removeTag)="removeTag($event)"
  [data]="listaFiltrados"
  textField="nombre"
  valueField="id"
  [(ngModel)]="listaSeleccionados"
  placeholder="Búsca los usuarios que quieras seleccionar"
>
<ng-template kendoMultiSelectItemTemplate let-dataItem>
  <span class="template">{{ dataItem.value }}</span>  <pre> <b>{{ dataItem.nombreUsuario }}</b>    Email:{{ dataItem.email }} </pre>
</ng-template>
<ng-template kendoMultiSelectNoDataTemplate>
  <h4>
    <span class="k-icon k-i-warning"></span><br /><br />
    {{ "zitucomponentes.lbl_emptyusers" | translate }}
  </h4>
</ng-template>
</kendo-multiselect>