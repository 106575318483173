import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TipoUsuario, Usuario } from '@app/_models';
import { AlertService, MenuService, cebollasService, UsuariosTiposService, PlanosService } from '@app/_services';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { FileRestrictions } from '@progress/kendo-angular-upload';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-configuracion',
  templateUrl: './configuracion.component.html'
})
export class ConfiguracionComponent implements OnInit {
  public restrictionsImage: FileRestrictions = {
    allowedExtensions: ['.jpg', '.jpeg', '.png', '.gif'],
    maxFileSize: 10000000
  };

  public restrictionsPDF: FileRestrictions = {
    allowedExtensions: ['.pdf'],
    maxFileSize: 10000000
  };

  //P10000
  //HTA SUPERIOR
  public imageToShowHTASuperiorH15AMaestro: any = "";
  public imageToShowHTASuperiorH20a: any = "";
  public imageToShowHTASuperiorH22a: any = "";
  public imageToShowHTASuperiorH51a: any = "";

  public archivoHTASuperiorH15AMaestro: any = "";
  public pdfHTASuperiorH15AMaestro: any = "";
  public mostrarHTASuperiorH15AMaestro: any = false;
  public archivoHTASuperiorH20a: any = "";
  public pdfHTASuperiorH20a: any = "";
  public mostrarHTASuperiorH20a: any = false;
  public archivoHTASuperiorH22a: any = "";
  public pdfHTASuperiorH22a: any = "";
  public mostrarHTASuperiorH22a: any = false;
  public archivoHTASuperiorH51a: any = "";
  public pdfHTASuperiorH51a: any = "";
  public mostrarHTASuperiorH51a: any = false;

  //HTA INFERIOR
  public imageToShowHTAInferiorH30AMaestro1: any = "";
  public imageToShowHTAInferiorH39a: any = "";
  public imageToShowHTAInferiorH30AMaestro2: any = "";
  public imageToShowHTAInferiorH40a: any = "";

  public archivoHTAInferiorH30AMaestro1: any = "";
  public pdfHTAInferiorH30AMaestro1: any = "";
  public mostrarHTAInferiorH30AMaestro1: any = false;
  public archivoHTAInferiorH39a: any = "";
  public pdfHTAInferiorH39a: any = "";
  public mostrarHTAInferiorH39a: any = false;
  public archivoHTAInferiorH30AMaestro2: any = "";
  public pdfHTAInferiorH30AMaestro2: any = "";
  public mostrarHTAInferiorH30AMaestro2: any = false;
  public archivoHTAInferiorH40a: any = "";
  public pdfHTAInferiorH40a: any = "";
  public mostrarHTAInferiorH40a: any = false;

  //PIECERIO
  public imageToShowPiecerioPrepunzonadoInferior1OP: any = "";
  public imageToShowPiecerioSubextractor: any = "";
  public imageToShowPiecerioBasePrepunzonadoInferior: any = "";
  public imageToShowPiecerioMandrino10000: any = "";
  public imageToShowPiecerioPrepunzonadoSuperior: any = "";
  public imageToShowPiecerioPrepunzonadoInferior2OP: any = "";
  public imageToShowP10000Arandela: any = "";

  public archivoPiecerioPrepunzonadoInferior1OP: any = "";
  public pdfPiecerioPrepunzonadoInferior1OP: any = "";
  public mostrarPiecerioPrepunzonadoInferior1OP: any = false;
  public archivoPiecerioSubextractor: any = "";
  public pdfPiecerioSubextractor: any = "";
  public mostrarPiecerioSubextractor: any = false;
  public archivoPiecerioBasePrepunzonadoInferior: any = "";
  public pdfPiecerioBasePrepunzonadoInferior: any = "";
  public mostrarPiecerioBasePrepunzonadoInferior: any = false;
  public archivoPiecerioMandrino10000: any = "";
  public pdfPiecerioMandrino10000: any = "";
  public mostrarPiecerioMandrino10000: any = false;
  public archivoPiecerioPrepunzonadoSuperior: any = "";
  public pdfPiecerioPrepunzonadoSuperior: any = "";
  public mostrarPiecerioPrepunzonadoSuperior: any = false;
  public archivoPiecerioPrepunzonadoInferior2OP: any = "";
  public pdfPiecerioPrepunzonadoInferior2OP: any = "";
  public mostrarPiecerioPrepunzonadoInferior2OP: any = false;
  public archivoP10000Arandela: any = "";
  public pdfP10000Arandela: any = "";
  public mostrarP10000Arandela: any = false;

  //Laminador
  public imageToShowCebollas: any = "";
  public imageToShowPoleas: any = "";

  public archivoCebollas: any = "";
  public pdfCebollas: any = "";
  public mostrarCebollas: any = false;
  public archivoPoleas: any = "";
  public pdfPoleas: any = "";
  public mostrarPoleas: any = false;

  //P5000
  public imageToShowh60h61: any = "";
  public imageToShowh70h71: any = "";
  public imageToShowsubextractorExtractor: any = "";
  public imageToShowP5000Arandela: any = "";
  public imageToShowPiecerioMandrino5000: any = "";
  

  public archivoh60h61: any = "";
  public pdfh60h61: any = "";
  public mostrarh60h61: any = false;
  public archivoh70h71: any = "";
  public pdfh70h71: any = "";
  public mostrarh70h71: any = false;
  public archivosubextractorExtractor: any = "";
  public pdfsubextractorExtractor: any = "";
  public mostrarsubextractorExtractor: any = false;
  public archivoP5000Arandela: any = "";
  public pdfP5000Arandela: any = "";
  public mostrarP5000Arandela: any = false;
  public archivoPiecerioMandrino5000: any = "";
  public pdfPiecerioMandrino5000: any = "";
  public mostrarPiecerioMandrino5000: any = false;

  constructor(private planosService: PlanosService,
    private alertService: AlertService, 
    private menuService: MenuService, 
    public router: Router, 
    private route: ActivatedRoute, 
    private formBuilder: FormBuilder,
    private translateService: TranslateService) {
    this.menuService.titulo = this.translateService.instant('configuracion.titulo');
  }

  ngOnInit(): void {
    this.cargarDatos();
  }

  cargarDatos(){
    this.planosService.getImagenesAuxiliares()
      .pipe(first())
      .subscribe((result: any) =>{
        if(result){
          console.log(result);
          //P10000
          //HTA SUPERIOR
          this.imageToShowHTASuperiorH15AMaestro = result.htaSuperiorH15AMaestro;
          this.imageToShowHTASuperiorH20a = result.htaSuperiorH20a;
          this.imageToShowHTASuperiorH22a = result.htaSuperiorH22a;
          this.imageToShowHTASuperiorH51a = result.htaSuperiorH51a;

          this.pdfHTASuperiorH15AMaestro = result.pdfHTASuperiorH15AMaestro;
          if(this.pdfHTASuperiorH15AMaestro!="" && this.pdfHTASuperiorH15AMaestro!=null && this.pdfHTASuperiorH15AMaestro!=undefined){
            this.mostrarHTASuperiorH15AMaestro=true;
          }else{
            this.mostrarHTASuperiorH15AMaestro=false;
          }
          this.pdfHTASuperiorH20a = result.pdfHTASuperiorH20a;
          if(this.pdfHTASuperiorH20a!="" && this.pdfHTASuperiorH20a!=null && this.pdfHTASuperiorH20a!=undefined){
            this.mostrarHTASuperiorH20a=true;
          }else{
            this.mostrarHTASuperiorH20a=false;
          }
          this.pdfHTASuperiorH22a = result.pdfHTASuperiorH22a;
          if(this.pdfHTASuperiorH22a!="" && this.pdfHTASuperiorH22a!=null && this.pdfHTASuperiorH22a!=undefined){
            this.mostrarHTASuperiorH22a=true;
          }else{
            this.mostrarHTASuperiorH22a=false;
          }
          this.pdfHTASuperiorH51a = result.pdfHTASuperiorH51a;
          if(this.pdfHTASuperiorH51a!="" && this.pdfHTASuperiorH51a!=null && this.pdfHTASuperiorH51a!=undefined){
            this.mostrarHTASuperiorH51a=true;
          }else{
            this.mostrarHTASuperiorH51a=false;
          }
        
          this.archivoHTASuperiorH15AMaestro = "";
          this.archivoHTASuperiorH20a = "";
          this.archivoHTASuperiorH22a = "";
          this.archivoHTASuperiorH51a = "";
        
          //HTA INFERIOR
          this.imageToShowHTAInferiorH30AMaestro1 = result.htaInferiorH30AMaestro1;
          this.imageToShowHTAInferiorH39a = result.htaInferiorH39a;
          this.imageToShowHTAInferiorH30AMaestro2 = result.htaInferiorH30AMaestro2;
          this.imageToShowHTAInferiorH40a = result.htaInferiorH40a;

          this.pdfHTAInferiorH30AMaestro1 = result.pdfHTAInferiorH30AMaestro1;
          if(this.pdfHTAInferiorH30AMaestro1!="" && this.pdfHTAInferiorH30AMaestro1!=null && this.pdfHTAInferiorH30AMaestro1!=undefined){
            this.mostrarHTAInferiorH30AMaestro1=true;
          }else{
            this.mostrarHTAInferiorH30AMaestro1=false;
          }
          this.pdfHTAInferiorH39a = result.pdfHTAInferiorH39a;
          if(this.pdfHTAInferiorH39a!="" && this.pdfHTAInferiorH39a!=null && this.pdfHTAInferiorH39a!=undefined){
            this.mostrarHTAInferiorH39a=true;
          }else{
            this.mostrarHTAInferiorH39a=false;
          }
          this.pdfHTAInferiorH30AMaestro2 = result.pdfHTAInferiorH30AMaestro2;
          if(this.pdfHTAInferiorH30AMaestro2!="" && this.pdfHTAInferiorH30AMaestro2!=null && this.pdfHTAInferiorH30AMaestro2!=undefined){
            this.mostrarHTAInferiorH30AMaestro2=true;
          }else{
            this.mostrarHTAInferiorH30AMaestro2=false;
          }
          this.pdfHTAInferiorH40a = result.pdfHTAInferiorH40a;
          if(this.pdfHTAInferiorH40a!="" && this.pdfHTAInferiorH40a!=null && this.pdfHTAInferiorH40a!=undefined){
            this.mostrarHTAInferiorH40a=true;
          }else{
            this.mostrarHTAInferiorH40a=false;
          }
        
          this.archivoHTAInferiorH30AMaestro1 = "";
          this.archivoHTAInferiorH39a = "";
          this.archivoHTAInferiorH30AMaestro2 = "";
          this.archivoHTAInferiorH40a = "";
        
          //PIECERIO
          this.imageToShowPiecerioPrepunzonadoInferior1OP = result.piecerioPrepunzonadoInferior1OP;
          this.imageToShowPiecerioSubextractor = result.piecerioSubextractor;
          this.imageToShowPiecerioBasePrepunzonadoInferior = result.piecerioBasePrepunzonadoInferior;
          this.imageToShowPiecerioMandrino10000 = result.piecerioMandrino10000;
          this.imageToShowPiecerioPrepunzonadoSuperior = result.piecerioPrepunzonadoSuperior;
          this.imageToShowPiecerioPrepunzonadoInferior2OP = result.piecerioPrepunzonadoInferior2OP;
          this.imageToShowP10000Arandela = result.p10000Arandela;

          this.pdfPiecerioPrepunzonadoInferior1OP = result.pdfPiecerioPrepunzonadoInferior1OP;
          if(this.pdfPiecerioPrepunzonadoInferior1OP!="" && this.pdfPiecerioPrepunzonadoInferior1OP!=null && this.pdfPiecerioPrepunzonadoInferior1OP!=undefined){
            this.mostrarPiecerioPrepunzonadoInferior1OP=true;
          }else{
            this.mostrarPiecerioPrepunzonadoInferior1OP=false;
          }
          this.pdfPiecerioSubextractor = result.pdfPiecerioSubextractor;
          if(this.pdfPiecerioSubextractor!="" && this.pdfPiecerioSubextractor!=null && this.pdfPiecerioSubextractor!=undefined){
            this.mostrarPiecerioSubextractor=true;
          }else{
            this.mostrarPiecerioSubextractor=false;
          }
          this.pdfPiecerioBasePrepunzonadoInferior = result.pdfPiecerioBasePrepunzonadoInferior;
          if(this.pdfPiecerioBasePrepunzonadoInferior!="" && this.pdfPiecerioBasePrepunzonadoInferior!=null && this.pdfPiecerioBasePrepunzonadoInferior!=undefined){
            this.mostrarPiecerioBasePrepunzonadoInferior=true;
          }else{
            this.mostrarPiecerioBasePrepunzonadoInferior=false;
          }
          this.pdfPiecerioMandrino10000 = result.pdfPiecerioMandrino10000;
          if(this.pdfPiecerioMandrino10000!="" && this.pdfPiecerioMandrino10000!=null && this.pdfPiecerioMandrino10000!=undefined){
            this.mostrarPiecerioMandrino10000=true;
          }else{
            this.mostrarPiecerioMandrino10000=false;
          }
          this.pdfPiecerioPrepunzonadoSuperior = result.pdfPiecerioPrepunzonadoSuperior;
          if(this.pdfPiecerioPrepunzonadoSuperior!="" && this.pdfPiecerioPrepunzonadoSuperior!=null && this.pdfPiecerioPrepunzonadoSuperior!=undefined){
            this.mostrarPiecerioPrepunzonadoSuperior=true;
          }else{
            this.mostrarPiecerioPrepunzonadoSuperior=false;
          }
          this.pdfPiecerioPrepunzonadoInferior2OP = result.pdfPiecerioPrepunzonadoInferior2OP;
          if(this.pdfPiecerioPrepunzonadoInferior2OP!="" && this.pdfPiecerioPrepunzonadoInferior2OP!=null && this.pdfPiecerioPrepunzonadoInferior2OP!=undefined){
            this.mostrarPiecerioPrepunzonadoInferior2OP=true;
          }else{
            this.mostrarPiecerioPrepunzonadoInferior2OP=false;
          }
          this.pdfP10000Arandela = result.pdfP10000Arandela;
          if(this.pdfP10000Arandela!="" && this.pdfP10000Arandela!=null && this.pdfP10000Arandela!=undefined){
            this.mostrarP10000Arandela=true;
          }else{
            this.mostrarP10000Arandela=false;
          }
        
          this.archivoPiecerioPrepunzonadoInferior1OP = "";
          this.archivoPiecerioSubextractor = "";
          this.archivoPiecerioBasePrepunzonadoInferior = "";
          this.archivoPiecerioMandrino10000 = "";
          this.archivoPiecerioPrepunzonadoSuperior = "";
          this.archivoPiecerioPrepunzonadoInferior2OP = "";
          this.archivoP10000Arandela = "";
        
          //Laminador
          this.imageToShowCebollas = result.cebollas;
          this.imageToShowPoleas = result.poleas;

          this.pdfCebollas = result.pdfCebollas;
          if(this.pdfCebollas!="" && this.pdfCebollas!=null && this.pdfCebollas!=undefined){
            this.mostrarCebollas=true;
          }else{
            this.mostrarCebollas=false;
          }
          this.pdfPoleas = result.pdfPoleas;
          if(this.pdfPoleas!="" && this.pdfPoleas!=null && this.pdfPoleas!=undefined){
            this.mostrarPoleas=true;
          }else{
            this.mostrarPoleas=false;
          }
        
          this.archivoCebollas = "";
          this.archivoPoleas = "";
        
          //P5000
          this.imageToShowh60h61 = result.h60h61;
          this.imageToShowh70h71 = result.h70h71;
          this.imageToShowsubextractorExtractor = result.subextractorExtractor;
          this.imageToShowP5000Arandela = result.p5000Arandela;
          this.imageToShowPiecerioMandrino5000 = result.piecerioMandrino5000;

          this.pdfh60h61 = result.pdfh60h61;
          if(this.pdfh60h61!="" && this.pdfh60h61!=null && this.pdfh60h61!=undefined){
            this.mostrarh60h61=true;
          }else{
            this.mostrarh60h61=false;
          }
          this.pdfh70h71 = result.pdfh70h71;
          if(this.pdfh70h71!="" && this.pdfh70h71!=null && this.pdfh70h71!=undefined){
            this.mostrarh70h71=true;
          }else{
            this.mostrarh70h71=false;
          }
          this.pdfsubextractorExtractor = result.pdfsubextractorExtractor;
          if(this.pdfsubextractorExtractor!="" && this.pdfsubextractorExtractor!=null && this.pdfsubextractorExtractor!=undefined){
            this.mostrarsubextractorExtractor=true;
          }else{
            this.mostrarsubextractorExtractor=false;
          }
          this.pdfP5000Arandela = result.pdfP5000Arandela;
          if(this.pdfP5000Arandela!="" && this.pdfP5000Arandela!=null && this.pdfP5000Arandela!=undefined){
            this.mostrarP5000Arandela=true;
          }else{
            this.mostrarP5000Arandela=false;
          }
          this.pdfPiecerioMandrino5000 = result.pdfPiecerioMandrino5000;
          if(this.pdfPiecerioMandrino5000!="" && this.pdfPiecerioMandrino5000!=null && this.pdfPiecerioMandrino5000!=undefined){
            this.mostrarPiecerioMandrino5000=true;
          }else{
            this.mostrarPiecerioMandrino5000=false;
          }

          this.archivoh60h61 = "";
          this.archivoh70h71 = "";
          this.archivosubextractorExtractor = "";
          this.archivoP5000Arandela = "";
          this.archivoPiecerioMandrino5000 = "";
        }
      });
  }

  //P10000
  //HTA SUPERIOR
  archivoSeleccionadoHTASuperiorH15AMaestro(e){
    var th = this;
    setTimeout(async function () {
      var archivoBase64: any = "";
      if (th.archivoHTASuperiorH15AMaestro !== "" && th.archivoHTASuperiorH15AMaestro !== null) 
        archivoBase64 = await th.toBase64(th.archivoHTASuperiorH15AMaestro[0]);
      else archivoBase64 = "";
      th.archivoHTASuperiorH15AMaestro = archivoBase64;
      th.imageToShowHTASuperiorH15AMaestro = archivoBase64;
    }, 500);
  }
  archivoEliminadoHTASuperiorH15AMaestro(e){
    this.archivoHTASuperiorH15AMaestro = "";
    this.imageToShowHTASuperiorH15AMaestro = "";
  }

  pdfSeleccionadoHTASuperiorH15AMaestro(e){
    var th = this;
    this.mostrarHTASuperiorH15AMaestro=false;
    setTimeout(async function () {
      var archivoBase64: any = "";
      if (th.pdfHTASuperiorH15AMaestro !== "" && th.pdfHTASuperiorH15AMaestro !== null) 
        archivoBase64 = await th.toBase64(th.pdfHTASuperiorH15AMaestro[0]);
      else archivoBase64 = "";
      th.pdfHTASuperiorH15AMaestro = archivoBase64;
    }, 500);
  }
  pdfEliminadoHTASuperiorH15AMaestro(e){
    this.pdfHTASuperiorH15AMaestro = "";
    this.mostrarHTASuperiorH15AMaestro=false;
  }

  archivoSeleccionadoHTASuperiorH20a(e){
    var th = this;
    setTimeout(async function () {
      var archivoBase64: any = "";
      if (th.archivoHTASuperiorH20a !== "" && th.archivoHTASuperiorH20a !== null) 
        archivoBase64 = await th.toBase64(th.archivoHTASuperiorH20a[0]);
      else archivoBase64 = "";
      th.archivoHTASuperiorH20a = archivoBase64;
      th.imageToShowHTASuperiorH20a = archivoBase64;
    }, 500);
  }
  archivoEliminadoHTASuperiorH20a(e){
    this.archivoHTASuperiorH20a = "";
    this.imageToShowHTASuperiorH20a = "";
  }

  pdfSeleccionadoHTASuperiorH20a(e){
    var th = this;
    this.mostrarHTASuperiorH20a=false;
    setTimeout(async function () {
      var archivoBase64: any = "";
      if (th.pdfHTASuperiorH20a !== "" && th.pdfHTASuperiorH20a !== null) 
        archivoBase64 = await th.toBase64(th.pdfHTASuperiorH20a[0]);
      else archivoBase64 = "";
      th.pdfHTASuperiorH20a = archivoBase64;
    }, 500);
  }
  pdfEliminadoHTASuperiorH20a(e){
    this.pdfHTASuperiorH20a = "";
    this.mostrarHTASuperiorH20a=false;
  }


  archivoSeleccionadoHTASuperiorH22a(e){
    var th = this;
    setTimeout(async function () {
      var archivoBase64: any = "";
      if (th.archivoHTASuperiorH22a !== "" && th.archivoHTASuperiorH22a !== null) 
        archivoBase64 = await th.toBase64(th.archivoHTASuperiorH22a[0]);
      else archivoBase64 = "";
      th.archivoHTASuperiorH22a = archivoBase64;
      th.imageToShowHTASuperiorH22a = archivoBase64;
    }, 500);
  }
  archivoEliminadoHTASuperiorH22a(e){
    this.archivoHTASuperiorH22a = "";
    this.imageToShowHTASuperiorH22a = "";
  }

  pdfSeleccionadoHTASuperiorH22a(e){
    var th = this;
    this.mostrarHTASuperiorH22a=false;
    setTimeout(async function () {
      var archivoBase64: any = "";
      if (th.pdfHTASuperiorH22a !== "" && th.pdfHTASuperiorH22a !== null) 
        archivoBase64 = await th.toBase64(th.pdfHTASuperiorH22a[0]);
      else archivoBase64 = "";
      th.pdfHTASuperiorH22a = archivoBase64;
    }, 500);
  }
  pdfEliminadoHTASuperiorH22a(e){
    this.pdfHTASuperiorH22a = "";
    this.mostrarHTASuperiorH22a=false;
  }

  archivoSeleccionadoHTASuperiorH51a(e){
    var th = this;
    setTimeout(async function () {
      var archivoBase64: any = "";
      if (th.archivoHTASuperiorH51a !== "" && th.archivoHTASuperiorH51a !== null) 
        archivoBase64 = await th.toBase64(th.archivoHTASuperiorH51a[0]);
      else archivoBase64 = "";
      th.archivoHTASuperiorH51a = archivoBase64;
      th.imageToShowHTASuperiorH51a = archivoBase64;
    }, 500);
  }
  archivoEliminadoHTASuperiorH51a(e){
    this.archivoHTASuperiorH51a = "";
    this.imageToShowHTASuperiorH51a = "";
  }

  pdfSeleccionadoHTASuperiorH51a(e){
    var th = this;
    this.mostrarHTASuperiorH51a = false;
    setTimeout(async function () {
      var archivoBase64: any = "";
      if (th.pdfHTASuperiorH51a !== "" && th.pdfHTASuperiorH51a !== null) 
        archivoBase64 = await th.toBase64(th.pdfHTASuperiorH51a[0]);
      else archivoBase64 = "";
      th.pdfHTASuperiorH51a = archivoBase64;
    }, 500);
  }
  pdfEliminadoHTASuperiorH51a(e){
    this.pdfHTASuperiorH51a = "";
    this.mostrarHTASuperiorH51a = false;
  }

  //HTA INFERIOR

  archivoSeleccionadoHTAInferiorH30AMaestro1(e){
    var th = this;
    setTimeout(async function () {
      var archivoBase64: any = "";
      if (th.archivoHTAInferiorH30AMaestro1 !== "" && th.archivoHTAInferiorH30AMaestro1 !== null) 
        archivoBase64 = await th.toBase64(th.archivoHTAInferiorH30AMaestro1[0]);
      else archivoBase64 = "";
      th.archivoHTAInferiorH30AMaestro1 = archivoBase64;
      th.imageToShowHTAInferiorH30AMaestro1 = archivoBase64;
    }, 500);
  }
  archivoEliminadoHTAInferiorH30AMaestro1(e){
    this.archivoHTAInferiorH30AMaestro1 = "";
    this.imageToShowHTAInferiorH30AMaestro1 = "";
  }

  pdfSeleccionadoHTAInferiorH30AMaestro1(e){
    var th = this;
    this.mostrarHTAInferiorH30AMaestro1 = false;
    setTimeout(async function () {
      var archivoBase64: any = "";
      if (th.pdfHTAInferiorH30AMaestro1 !== "" && th.pdfHTAInferiorH30AMaestro1 !== null) 
        archivoBase64 = await th.toBase64(th.pdfHTAInferiorH30AMaestro1[0]);
      else archivoBase64 = "";
      th.pdfHTAInferiorH30AMaestro1 = archivoBase64;
    }, 500);
  }
  pdfEliminadoHTAInferiorH30AMaestro1(e){
    this.pdfHTAInferiorH30AMaestro1 = "";
    this.mostrarHTAInferiorH30AMaestro1 = false;
  }

  archivoSeleccionadoHTAInferiorH30AMaestro2(e){
    var th = this;
    setTimeout(async function () {
      var archivoBase64: any = "";
      if (th.archivoHTAInferiorH30AMaestro2 !== "" && th.archivoHTAInferiorH30AMaestro2 !== null) 
        archivoBase64 = await th.toBase64(th.archivoHTAInferiorH30AMaestro2[0]);
      else archivoBase64 = "";
      th.archivoHTAInferiorH30AMaestro2 = archivoBase64;
      th.imageToShowHTAInferiorH30AMaestro2 = archivoBase64;
    }, 500);
  }
  archivoEliminadoHTAInferiorH30AMaestro2(e){
    this.archivoHTAInferiorH30AMaestro2 = "";
    this.imageToShowHTAInferiorH30AMaestro2 = "";
  }

  pdfSeleccionadoHTAInferiorH30AMaestro2(e){
    var th = this;
    this.mostrarHTAInferiorH30AMaestro2 = false;
    setTimeout(async function () {
      var archivoBase64: any = "";
      if (th.pdfHTAInferiorH30AMaestro2 !== "" && th.pdfHTAInferiorH30AMaestro2 !== null) 
        archivoBase64 = await th.toBase64(th.pdfHTAInferiorH30AMaestro2[0]);
      else archivoBase64 = "";
      th.pdfHTAInferiorH30AMaestro2 = archivoBase64;
    }, 500);
  }
  pdfEliminadoHTAInferiorH30AMaestro2(e){
    this.pdfHTAInferiorH30AMaestro2 = "";
    this.mostrarHTAInferiorH30AMaestro2 = false;
  }


  archivoSeleccionadoHTAInferiorH39a(e){
    var th = this;
    setTimeout(async function () {
      var archivoBase64: any = "";
      if (th.archivoHTAInferiorH39a !== "" && th.archivoHTAInferiorH39a !== null) 
        archivoBase64 = await th.toBase64(th.archivoHTAInferiorH39a[0]);
      else archivoBase64 = "";
      th.archivoHTAInferiorH39a = archivoBase64;
      th.imageToShowHTAInferiorH39a = archivoBase64;
    }, 500);
  }
  archivoEliminadoHTAInferiorH39a(e){
    this.archivoHTAInferiorH39a = "";
    this.imageToShowHTAInferiorH39a = "";
  }

  pdfSeleccionadoHTAInferiorH39a(e){
    var th = this;
    this.mostrarHTAInferiorH39a = false;
    setTimeout(async function () {
      var archivoBase64: any = "";
      if (th.pdfHTAInferiorH39a !== "" && th.pdfHTAInferiorH39a !== null) 
        archivoBase64 = await th.toBase64(th.pdfHTAInferiorH39a[0]);
      else archivoBase64 = "";
      th.pdfHTAInferiorH39a = archivoBase64;
    }, 500);
  }
  pdfEliminadoHTAInferiorH39a(e){
    this.pdfHTAInferiorH39a = "";
    this.mostrarHTAInferiorH39a = false;
  }

  archivoSeleccionadoHTAInferiorH40a(e){
    var th = this;
    setTimeout(async function () {
      var archivoBase64: any = "";
      if (th.archivoHTAInferiorH40a !== "" && th.archivoHTAInferiorH40a !== null) 
        archivoBase64 = await th.toBase64(th.archivoHTAInferiorH40a[0]);
      else archivoBase64 = "";
      th.archivoHTAInferiorH40a = archivoBase64;
      th.imageToShowHTAInferiorH40a = archivoBase64;
    }, 500);
  }
  archivoEliminadoHTAInferiorH40a(e){
    this.archivoHTAInferiorH40a = "";
    this.imageToShowHTAInferiorH40a = "";
  }

  pdfSeleccionadoHTAInferiorH40a(e){
    var th = this;
    this.mostrarHTAInferiorH40a = false;
    setTimeout(async function () {
      var archivoBase64: any = "";
      if (th.pdfHTAInferiorH40a !== "" && th.pdfHTAInferiorH40a !== null) 
        archivoBase64 = await th.toBase64(th.pdfHTAInferiorH40a[0]);
      else archivoBase64 = "";
      th.pdfHTAInferiorH40a = archivoBase64;
    }, 500);
  }
  pdfEliminadoHTAInferiorH40a(e){
    this.pdfHTAInferiorH40a = "";
    this.mostrarHTAInferiorH40a = false;
  }

  //PIECERIO

  archivoSeleccionadoPiecerioPrepunzonadoInferior1OP(e){
    var th = this;
    setTimeout(async function () {
      var archivoBase64: any = "";
      if (th.archivoPiecerioPrepunzonadoInferior1OP !== "" && th.archivoPiecerioPrepunzonadoInferior1OP !== null) 
        archivoBase64 = await th.toBase64(th.archivoPiecerioPrepunzonadoInferior1OP[0]);
      else archivoBase64 = "";
      th.archivoPiecerioPrepunzonadoInferior1OP = archivoBase64;
      th.imageToShowPiecerioPrepunzonadoInferior1OP = archivoBase64;
    }, 500);
  }
  archivoEliminadoPiecerioPrepunzonadoInferior1OP(e){
    this.archivoPiecerioPrepunzonadoInferior1OP = "";
    this.imageToShowPiecerioPrepunzonadoInferior1OP = "";
  }

  pdfSeleccionadoPiecerioPrepunzonadoInferior1OP(e){
    var th = this;
    this.mostrarPiecerioPrepunzonadoInferior1OP = false;
    setTimeout(async function () {
      var archivoBase64: any = "";
      if (th.pdfPiecerioPrepunzonadoInferior1OP !== "" && th.pdfPiecerioPrepunzonadoInferior1OP !== null) 
        archivoBase64 = await th.toBase64(th.pdfPiecerioPrepunzonadoInferior1OP[0]);
      else archivoBase64 = "";
      th.pdfPiecerioPrepunzonadoInferior1OP = archivoBase64;
    }, 500);
  }
  pdfEliminadoPiecerioPrepunzonadoInferior1OP(e){
    this.pdfPiecerioPrepunzonadoInferior1OP = "";
    this.mostrarPiecerioPrepunzonadoInferior1OP = false;
  }

  archivoSeleccionadoPiecerioSubextractor(e){
    var th = this;
    setTimeout(async function () {
      var archivoBase64: any = "";
      if (th.archivoPiecerioSubextractor !== "" && th.archivoPiecerioSubextractor !== null) 
        archivoBase64 = await th.toBase64(th.archivoPiecerioSubextractor[0]);
      else archivoBase64 = "";
      th.archivoPiecerioSubextractor = archivoBase64;
      th.imageToShowPiecerioSubextractor = archivoBase64;
    }, 500);
  }
  archivoEliminadoPiecerioSubextractor(e){
    this.archivoPiecerioSubextractor = "";
    this.imageToShowPiecerioSubextractor = "";
  }

  pdfSeleccionadoPiecerioSubextractor(e){
    var th = this;
    this.mostrarPiecerioSubextractor = false;
    setTimeout(async function () {
      var archivoBase64: any = "";
      if (th.pdfPiecerioSubextractor !== "" && th.pdfPiecerioSubextractor !== null) 
        archivoBase64 = await th.toBase64(th.pdfPiecerioSubextractor[0]);
      else archivoBase64 = "";
      th.pdfPiecerioSubextractor = archivoBase64;
    }, 500);
  }
  pdfEliminadoPiecerioSubextractor(e){
    this.pdfPiecerioSubextractor = "";
    this.mostrarPiecerioSubextractor = false;
  }
  
  archivoSeleccionadoPiecerioBasePrepunzonadoInferior(e){
    var th = this;
    setTimeout(async function () {
      var archivoBase64: any = "";
      if (th.archivoPiecerioBasePrepunzonadoInferior !== "" && th.archivoPiecerioBasePrepunzonadoInferior !== null) 
        archivoBase64 = await th.toBase64(th.archivoPiecerioBasePrepunzonadoInferior[0]);
      else archivoBase64 = "";
      th.archivoPiecerioBasePrepunzonadoInferior = archivoBase64;
      th.imageToShowPiecerioBasePrepunzonadoInferior = archivoBase64;
    }, 500);
  }
  archivoEliminadoPiecerioBasePrepunzonadoInferior(e){
    this.archivoPiecerioBasePrepunzonadoInferior = "";
    this.imageToShowPiecerioBasePrepunzonadoInferior = "";
  }

  pdfSeleccionadoPiecerioBasePrepunzonadoInferior(e){
    var th = this;
    this.mostrarPiecerioBasePrepunzonadoInferior= false;
    setTimeout(async function () {
      var archivoBase64: any = "";
      if (th.pdfPiecerioBasePrepunzonadoInferior !== "" && th.pdfPiecerioBasePrepunzonadoInferior !== null) 
        archivoBase64 = await th.toBase64(th.pdfPiecerioBasePrepunzonadoInferior[0]);
      else archivoBase64 = "";
      th.pdfPiecerioBasePrepunzonadoInferior = archivoBase64;
    }, 500);
  }
  pdfEliminadoPiecerioBasePrepunzonadoInferior(e){
    this.pdfPiecerioBasePrepunzonadoInferior = "";
    this.mostrarPiecerioBasePrepunzonadoInferior= false;
  }
  
  archivoSeleccionadoPiecerioMandrino10000(e){
    var th = this;
    setTimeout(async function () {
      var archivoBase64: any = "";
      if (th.archivoPiecerioMandrino10000 !== "" && th.archivoPiecerioMandrino10000 !== null) 
        archivoBase64 = await th.toBase64(th.archivoPiecerioMandrino10000[0]);
      else archivoBase64 = "";
      th.archivoPiecerioMandrino10000 = archivoBase64;
      th.imageToShowPiecerioMandrino10000 = archivoBase64;
    }, 500);
  }
  archivoEliminadoPiecerioMandrino10000(e){
    this.archivoPiecerioMandrino10000 = "";
    this.imageToShowPiecerioMandrino10000 = "";
  }

  pdfSeleccionadoPiecerioMandrino10000(e){
    var th = this;
    this.mostrarPiecerioMandrino10000 = false;
    setTimeout(async function () {
      var archivoBase64: any = "";
      if (th.pdfPiecerioMandrino10000 !== "" && th.pdfPiecerioMandrino10000 !== null) 
        archivoBase64 = await th.toBase64(th.pdfPiecerioMandrino10000[0]);
      else archivoBase64 = "";
      th.pdfPiecerioMandrino10000 = archivoBase64;
    }, 500);
  }
  pdfEliminadoPiecerioMandrino10000(e){
    this.pdfPiecerioMandrino10000 = "";
    this.mostrarPiecerioMandrino10000 = false;
  }

  archivoSeleccionadoPiecerioPrepunzonadoSuperior(e){
    var th = this;
    setTimeout(async function () {
      var archivoBase64: any = "";
      if (th.archivoPiecerioPrepunzonadoSuperior !== "" && th.archivoPiecerioPrepunzonadoSuperior !== null) 
        archivoBase64 = await th.toBase64(th.archivoPiecerioPrepunzonadoSuperior[0]);
      else archivoBase64 = "";
      th.archivoPiecerioPrepunzonadoSuperior = archivoBase64;
      th.imageToShowPiecerioPrepunzonadoSuperior = archivoBase64;
    }, 500);
  }
  archivoEliminadoPiecerioPrepunzonadoSuperior(e){
    this.archivoPiecerioPrepunzonadoSuperior = "";
    this.imageToShowPiecerioPrepunzonadoSuperior = "";
  }

  pdfSeleccionadoPiecerioPrepunzonadoSuperior(e){
    var th = this;
    this.mostrarPiecerioPrepunzonadoSuperior = false;
    setTimeout(async function () {
      var archivoBase64: any = "";
      if (th.pdfPiecerioPrepunzonadoSuperior !== "" && th.pdfPiecerioPrepunzonadoSuperior !== null) 
        archivoBase64 = await th.toBase64(th.pdfPiecerioPrepunzonadoSuperior[0]);
      else archivoBase64 = "";
      th.pdfPiecerioPrepunzonadoSuperior = archivoBase64;
    }, 500);
  }
  pdfEliminadoPiecerioPrepunzonadoSuperior(e){
    this.pdfPiecerioPrepunzonadoSuperior = "";
    this.mostrarPiecerioPrepunzonadoSuperior = false;
  }

  archivoSeleccionadoPiecerioPrepunzonadoInferior2OP(e){
    var th = this;
    setTimeout(async function () {
      var archivoBase64: any = "";
      if (th.archivoPiecerioPrepunzonadoInferior2OP !== "" && th.archivoPiecerioPrepunzonadoInferior2OP !== null) 
        archivoBase64 = await th.toBase64(th.archivoPiecerioPrepunzonadoInferior2OP[0]);
      else archivoBase64 = "";
      th.archivoPiecerioPrepunzonadoInferior2OP = archivoBase64;
      th.imageToShowPiecerioPrepunzonadoInferior2OP = archivoBase64;
    }, 500);
  }
  archivoEliminadoPiecerioPrepunzonadoInferior2OP(e){
    this.archivoPiecerioPrepunzonadoInferior2OP = "";
    this.imageToShowPiecerioPrepunzonadoInferior2OP = "";
  }

  pdfSeleccionadoPiecerioPrepunzonadoInferior2OP(e){
    var th = this;
    this.mostrarPiecerioPrepunzonadoInferior2OP = false;
    setTimeout(async function () {
      var archivoBase64: any = "";
      if (th.pdfPiecerioPrepunzonadoInferior2OP !== "" && th.pdfPiecerioPrepunzonadoInferior2OP !== null) 
        archivoBase64 = await th.toBase64(th.pdfPiecerioPrepunzonadoInferior2OP[0]);
      else archivoBase64 = "";
      th.pdfPiecerioPrepunzonadoInferior2OP = archivoBase64;
    }, 500);
  }
  pdfEliminadoPiecerioPrepunzonadoInferior2OP(e){
    this.pdfPiecerioPrepunzonadoInferior2OP = "";
    this.mostrarPiecerioPrepunzonadoInferior2OP = false;
  }

  archivoSeleccionadoP10000Arandela(e){
    var th = this;
    setTimeout(async function () {
      var archivoBase64: any = "";
      if (th.archivoP10000Arandela !== "" && th.archivoP10000Arandela !== null) 
        archivoBase64 = await th.toBase64(th.archivoP10000Arandela[0]);
      else archivoBase64 = "";
      th.archivoP10000Arandela = archivoBase64;
      th.imageToShowP10000Arandela = archivoBase64;
    }, 500);
  }
  archivoEliminadoP10000Arandela(e){
    this.archivoP10000Arandela = "";
    this.imageToShowP10000Arandela = "";
  }

  pdfSeleccionadoP10000Arandela(e){
    var th = this;
    this.mostrarP10000Arandela = false;
    setTimeout(async function () {
      var archivoBase64: any = "";
      if (th.pdfP10000Arandela !== "" && th.pdfP10000Arandela !== null) 
        archivoBase64 = await th.toBase64(th.pdfP10000Arandela[0]);
      else archivoBase64 = "";
      th.pdfP10000Arandela = archivoBase64;
    }, 500);
  }
  pdfEliminadoP10000Arandela(e){
    this.pdfP10000Arandela = "";
    this.mostrarP10000Arandela = false;
  }

  //Laminador

  archivoSeleccionadoCebollas(e){
    var th = this;
    setTimeout(async function () {
      var archivoBase64: any = "";
      if (th.archivoCebollas !== "" && th.archivoCebollas !== null) 
        archivoBase64 = await th.toBase64(th.archivoCebollas[0]);
      else archivoBase64 = "";
      th.archivoCebollas = archivoBase64;
      th.imageToShowCebollas = archivoBase64;
    }, 500);
  }
  archivoEliminadoCebollas(e){
    this.archivoCebollas = "";
    this.imageToShowCebollas = "";
  }

  pdfSeleccionadoCebollas(e){
    var th = this;
    this.mostrarCebollas = false;
    setTimeout(async function () {
      var archivoBase64: any = "";
      if (th.pdfCebollas !== "" && th.pdfCebollas !== null) 
        archivoBase64 = await th.toBase64(th.pdfCebollas[0]);
      else archivoBase64 = "";
      th.pdfCebollas = archivoBase64;
    }, 500);
  }
  pdfEliminadoCebollas(e){
    this.pdfCebollas = "";
    this.mostrarCebollas = false;
  }

  archivoSeleccionadoPoleas(e){
    var th = this;
    setTimeout(async function () {
      var archivoBase64: any = "";
      if (th.archivoPoleas !== "" && th.archivoPoleas !== null) 
        archivoBase64 = await th.toBase64(th.archivoPoleas[0]);
      else archivoBase64 = "";
      th.archivoPoleas = archivoBase64;
      th.imageToShowPoleas = archivoBase64;
    }, 500);
  }
  archivoEliminadoPoleas(e){
    this.archivoPoleas = "";
    this.imageToShowPoleas = "";
  }

  pdfSeleccionadoPoleas(e){
    var th = this;
    this.mostrarPoleas = false;
    setTimeout(async function () {
      var archivoBase64: any = "";
      if (th.pdfPoleas !== "" && th.pdfPoleas !== null) 
        archivoBase64 = await th.toBase64(th.pdfPoleas[0]);
      else archivoBase64 = "";
      th.pdfPoleas = archivoBase64;
    }, 500);
  }
  pdfEliminadoPoleas(e){
    this.pdfPoleas = "";
    this.mostrarPoleas = false;
  }

  //P5000

  archivoSeleccionadoh60h61(e){
    var th = this;
    setTimeout(async function () {
      var archivoBase64: any = "";
      if (th.archivoh60h61 !== "" && th.archivoh60h61 !== null) 
        archivoBase64 = await th.toBase64(th.archivoh60h61[0]);
      else archivoBase64 = "";
      th.archivoh60h61 = archivoBase64;
      th.imageToShowh60h61 = archivoBase64;
    }, 500);
  }
  archivoEliminadoh60h61(e){
    this.archivoh60h61 = "";
    this.imageToShowh60h61 = "";
  }

  pdfSeleccionadoh60h61(e){
    var th = this;
    this.mostrarh60h61 = false;
    setTimeout(async function () {
      var archivoBase64: any = "";
      if (th.pdfh60h61 !== "" && th.pdfh60h61 !== null) 
        archivoBase64 = await th.toBase64(th.pdfh60h61[0]);
      else archivoBase64 = "";
      th.pdfh60h61 = archivoBase64;
    }, 500);
  }
  pdfEliminadoh60h61(e){
    this.pdfh60h61 = "";
    this.mostrarh60h61 = false;
  }

  archivoSeleccionadoh70h71(e){
    var th = this;
    setTimeout(async function () {
      var archivoBase64: any = "";
      if (th.archivoh70h71 !== "" && th.archivoh70h71 !== null) 
        archivoBase64 = await th.toBase64(th.archivoh70h71[0]);
      else archivoBase64 = "";
      th.archivoh70h71 = archivoBase64;
      th.imageToShowh70h71 = archivoBase64;
    }, 500);
  }
  archivoEliminadoh70h71(e){
    this.archivoh70h71 = "";
    this.imageToShowh70h71 = "";
  }

  pdfSeleccionadoh70h71(e){
    var th = this;
    this.mostrarh70h71 = false;
    setTimeout(async function () {
      var archivoBase64: any = "";
      if (th.pdfh70h71 !== "" && th.pdfh70h71 !== null) 
        archivoBase64 = await th.toBase64(th.pdfh70h71[0]);
      else archivoBase64 = "";
      th.pdfh70h71 = archivoBase64;
    }, 500);
  }
  pdfEliminadoh70h71(e){
    this.pdfh70h71 = "";
    this.mostrarh70h71 = false;
  }

  archivoSeleccionadosubextractorExtractor(e){
    var th = this;
    setTimeout(async function () {
      var archivoBase64: any = "";
      if (th.archivosubextractorExtractor !== "" && th.archivosubextractorExtractor !== null) 
        archivoBase64 = await th.toBase64(th.archivosubextractorExtractor[0]);
      else archivoBase64 = "";
      th.archivosubextractorExtractor = archivoBase64;
      th.imageToShowsubextractorExtractor = archivoBase64;
    }, 500);
  }
  archivoEliminadosubextractorExtractor(e){
    this.archivosubextractorExtractor = "";
    this.imageToShowsubextractorExtractor = "";
  }

  pdfSeleccionadosubextractorExtractor(e){
    var th = this;
    this.mostrarsubextractorExtractor = false;
    setTimeout(async function () {
      var archivoBase64: any = "";
      if (th.pdfsubextractorExtractor !== "" && th.pdfsubextractorExtractor !== null) 
        archivoBase64 = await th.toBase64(th.pdfsubextractorExtractor[0]);
      else archivoBase64 = "";
      th.pdfsubextractorExtractor = archivoBase64;
    }, 500);
  }
  pdfEliminadosubextractorExtractor(e){
    this.pdfsubextractorExtractor = "";
    this.mostrarsubextractorExtractor = false;
  }

  archivoSeleccionadoP5000Arandela(e){
    var th = this;
    setTimeout(async function () {
      var archivoBase64: any = "";
      if (th.archivoP5000Arandela !== "" && th.archivoP5000Arandela !== null) 
        archivoBase64 = await th.toBase64(th.archivoP5000Arandela[0]);
      else archivoBase64 = "";
      th.archivoP5000Arandela = archivoBase64;
      th.imageToShowP5000Arandela = archivoBase64;
    }, 500);
  }
  archivoEliminadoP5000Arandela(e){
    this.archivoP5000Arandela = "";
    this.imageToShowP5000Arandela = "";
  }

  pdfSeleccionadoP5000Arandela(e){
    var th = this;
    this.mostrarP5000Arandela = false;
    setTimeout(async function () {
      var archivoBase64: any = "";
      if (th.pdfP5000Arandela !== "" && th.pdfP5000Arandela !== null) 
        archivoBase64 = await th.toBase64(th.pdfP5000Arandela[0]);
      else archivoBase64 = "";
      th.pdfP5000Arandela = archivoBase64;
    }, 500);
  }
  pdfEliminadoP5000Arandela(e){
    this.pdfP5000Arandela = "";
    this.mostrarP5000Arandela = false;
  }

  archivoSeleccionadoPiecerioMandrino5000(e){
    var th = this;
    setTimeout(async function () {
      var archivoBase64: any = "";
      if (th.archivoPiecerioMandrino5000 !== "" && th.archivoPiecerioMandrino5000 !== null) 
        archivoBase64 = await th.toBase64(th.archivoPiecerioMandrino5000[0]);
      else archivoBase64 = "";
      th.archivoPiecerioMandrino5000 = archivoBase64;
      th.imageToShowPiecerioMandrino5000 = archivoBase64;
    }, 500);
  }
  archivoEliminadoPiecerioMandrino5000(e){
    this.archivoPiecerioMandrino5000 = "";
    this.imageToShowPiecerioMandrino5000 = "";
  }

  pdfSeleccionadoPiecerioMandrino5000(e){
    var th = this;
    this.mostrarPiecerioMandrino5000 = false;
    setTimeout(async function () {
      var archivoBase64: any = "";
      if (th.pdfPiecerioMandrino5000 !== "" && th.pdfPiecerioMandrino5000 !== null) 
        archivoBase64 = await th.toBase64(th.pdfPiecerioMandrino5000[0]);
      else archivoBase64 = "";
      th.pdfPiecerioMandrino5000 = archivoBase64;
    }, 500);
  }
  pdfEliminadoPiecerioMandrino5000(e){
    this.pdfPiecerioMandrino5000 = "";
    this.mostrarPiecerioMandrino5000 = false;
  }

  toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  onSubmit(e){
    this.planosService.insertImagenesAuxiliares(this.archivoHTASuperiorH15AMaestro,this.archivoHTASuperiorH20a,
      this.archivoHTASuperiorH22a,this.archivoHTASuperiorH51a,
      this.archivoHTAInferiorH30AMaestro1,this.archivoHTAInferiorH39a,this.archivoHTAInferiorH30AMaestro2, this.archivoHTAInferiorH40a,
      this.archivoPiecerioPrepunzonadoInferior1OP, this.archivoPiecerioSubextractor,
      this.archivoPiecerioBasePrepunzonadoInferior, this.archivoPiecerioMandrino10000, this.archivoPiecerioMandrino5000,
      this.archivoPiecerioPrepunzonadoSuperior, this.archivoPiecerioPrepunzonadoInferior2OP,
      this.archivoP10000Arandela, this.archivoCebollas, this.archivoPoleas,
      this.archivoh60h61, this.archivoh70h71,this.archivosubextractorExtractor, this.archivoP5000Arandela,
      this.pdfHTASuperiorH15AMaestro,this.pdfHTASuperiorH20a,
      this.pdfHTASuperiorH22a,this.pdfHTASuperiorH51a,
      this.pdfHTAInferiorH30AMaestro1,this.pdfHTAInferiorH39a,this.pdfHTAInferiorH30AMaestro2, this.pdfHTAInferiorH40a,
      this.pdfPiecerioPrepunzonadoInferior1OP, this.pdfPiecerioSubextractor,
      this.pdfPiecerioBasePrepunzonadoInferior, this.pdfPiecerioMandrino10000, this.pdfPiecerioMandrino5000,
      this.pdfPiecerioPrepunzonadoSuperior, this.pdfPiecerioPrepunzonadoInferior2OP,
      this.pdfP10000Arandela, this.pdfCebollas, this.pdfPoleas,
      this.pdfh60h61, this.pdfh70h71,this.pdfsubextractorExtractor, this.pdfP5000Arandela)
      .pipe(first())
      .subscribe((result: any) =>{
        if(result==0){
          this.alertService.success(this.translateService.instant('botones.editadocorrectamente'), { keepAfterRouteChange: true });
          window.location.reload();
          
        }else{
            this.alertService.error(this.translateService.instant('botones.erroreditar'), { keepAfterRouteChange: true });
        }
      });
  }
}
