import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NumericTextBoxComponent } from '@progress/kendo-angular-inputs';

@Component({
  selector: 'zitu-numeric',
  templateUrl: './zitu-numeric.component.html',
})
export class ZituNumericComponent implements OnInit {

  @Input() label:string;
  @Input() labelArriba:boolean=false;
  @Output() selectedNumberOut = new EventEmitter<number>();

  valornumero:number=0;
  constructor() { }

  ngOnInit(): void {
  }


  valueChange(value, numericControl: NumericTextBoxComponent) {
    this.selectedNumberOut.emit(value);
  }
  
}
