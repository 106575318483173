import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthGuard } from '@app/_helpers';
import { TipoUsuario } from '@app/_models';
import { environment } from '@environments/environment';

const baseUrl = `${environment.apiUrl}/catalogoForjaRuedas`;

@Injectable({
  providedIn: 'root'
})
export class catalogoForjaRuedasService {

  constructor(private http: HttpClient) {
  }

  acoplarArchivo(archivo) {
    return this.http.post(baseUrl + "/acoplarPDF", {archivo});
  }

  actualizarArchivo(archivo) {
    return this.http.post(baseUrl + "/actualizarPDF", {archivo});
  }

  obtenerManual() {
    return this.http.get(baseUrl + "/obtenerManual");
  }
  

}
