import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { UsuariosService, AlertService, MenuService, UsuariosTiposService } from '@app/_services';
import { MustMatch } from '@app/_helpers';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { TipoUsuario, Usuario } from '@app/_models';
@Component({
    selector: 'app-editar-crear-usuario',
    templateUrl: './editar-crear-usuario.component.html'
})
export class EditarCrearUsuarioComponent implements OnInit {
    public tiposUsuarios_DAT: TipoUsuario[];
    public selectedItem: TipoUsuario;
    private usuarioTipo: Usuario;
    form: FormGroup;
    id: string;
    isAddMode: boolean;
    loading = false;
    submitted = false;
    user = this.usuariosService.userValue;
    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private translateService: TranslateService,
        private usuariosService: UsuariosService,
        private usuariosTiposService: UsuariosTiposService,
        private alertService: AlertService,
        private menuService: MenuService
    ) { }

    ngOnInit() {
        this.menuService.titulo = this.translateService.instant('usuarios.usuario');
        this.id = this.route.snapshot.params['id'];
        this.isAddMode = !this.id;

        

        this.form = this.formBuilder.group({
            id: this.route.snapshot.params['id'],
            idDb: this.user.idDb,
            nombreUsuario: new FormControl(''),
            nombre: new FormControl('', [Validators.required]),
            apellido1: new FormControl('', [Validators.required]),
            apellido2: new FormControl(''),
            email: new FormControl('', [Validators.email]),
            usuarios_TiposId: 1,
            UsuarioTipo: new FormControl(''),
            password: new FormControl('', [Validators.minLength(6), this.isAddMode ? Validators.required : Validators.nullValidator]),
            confirmPassword: new FormControl(''),
            idioma: this.user.idioma,
            tema: this.user.tema,
            menuExpandido: this.user.menuExpandido,
            activo: true,
            gestionUsuarios: 1,
            condicionesAceptadas: true
        }, {
            validator: MustMatch('password', 'confirmPassword')
        });
        if (!this.isAddMode) {
            this.usuariosTiposService.getAll().pipe(first()).subscribe(
              (result: any) => {
                this.tiposUsuarios_DAT = result;
                this.usuariosService.getById(this.id).pipe().subscribe(
                  (result: any) => {
                      console.log(result);
                    this.usuarioTipo = result;
                    this.selectedItem = new TipoUsuario(this.usuarioTipo.usuarios_TiposId, this.usuarioTipo.usuarioTipo, this.usuarioTipo.gestionUsuarios)
                    this.form.patchValue(this.usuarioTipo);
                    console.log(this.selectedItem);
                  }
                );
              }
            );      
          }else{
            this.usuariosTiposService.getAll().pipe(first()).subscribe(
              (result: any) => {
                this.tiposUsuarios_DAT = result;
                this.selectedItem = result[1];
                console.log(this.selectedItem);
              }
            );
          }

        this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
            // TODO This as a workaround.
            this.menuService.titulo = this.translateService.instant('usuarios.usuario');
        });
    }


    public valueChange(value: any): void {
        this.selectedItem = value
    }

    // convenience getter for easy access to form fields
    get f() { return this.form.controls; }

    onSubmit() {
        this.submitted = true;

        // reset alerts on submit
        this.alertService.clear();

        // stop here if form is invalid
        if (this.form.invalid) {
            return;
        }

        if((this.form.value.email == null || this.form.value.email== undefined) && 
        (this.form.value.nombreUsuario == null || this.form.value.nombreUsuario== undefined))
            return;

        this.form.controls['UsuarioTipo'].setValue(this.selectedItem.nombre);
        this.form.controls['gestionUsuarios'].setValue(this.selectedItem.gestionUsuarios);
        this.loading = true;
        if (this.isAddMode) {
            this.createUser();
        } else {
            this.updateUser();
        }

    }

    private createUser() {
        this.usuariosService.create(this.form.value)
            .pipe(first())
            .subscribe({
                next: () => {

                    this.alertService.success(this.translateService.instant('botones.creadocorrectamente'), { keepAfterRouteChange: true });
                    this.router.navigate(['../'], { relativeTo: this.route });

                },
                error: error => {
                    this.alertService.error(error);
                    this.loading = false;
                }
            });
    }

    private updateUser() {
        this.usuariosService.update(this.form.value)
            .pipe(first())
            .subscribe({
                next: () => {
                    this.alertService.success(this.translateService.instant('botones.editadocorrectamente'), { keepAfterRouteChange: true });
                    this.router.navigate(['../../'], { relativeTo: this.route });
                },
                error: error => {
                    this.alertService.error(error);
                    this.loading = false;
                }
            });
    }


}
