<kendo-tabstrip [scrollable]="settings">
  <kendo-tabstrip-tab title="{{ 'manuales.laminador' | translate}}" [selected]="true">
    <ng-template kendoTabContent>
      <kendo-grid [kendoGridBinding]="datosLaminador" [sortable]="true" kendoGridSelectBy="id" [navigable]="true"
        filterable="menu" [selectedKeys]="datosLaminadorSelected" [selectable]="true" (cellClick)="cellClick($event)">
        <ng-template kendoGridToolbarTemplate position="top">
          <button kendoButton (click)="onClickEditar('laminador')" class="btn mr-1  btn-success btn-sm mr-1">{{
            'botones.editar' | translate}}</button>
          <button kendoButton (click)="onClickNuevo('laminador')" class="btn mr-1  btn-primary btn-sm mr-1">{{
            'botones.nuevo' | translate}}</button>
          <button kendoButton (click)="onClickEliminar('laminador')" class="btn mr-1  btn-danger btn-sm mr-1"
            [disabled]="isDeleting">{{ 'botones.eliminar' | translate}}</button>
        </ng-template>
        <kendo-grid-checkbox-column width="5%" showSelectAll="true"></kendo-grid-checkbox-column>
        <kendo-grid-column width="10%" field="nombre" title="{{ 'manuales.nombre' | translate}}"
          [style]="{'text-align': 'left'}">
        </kendo-grid-column>
        <kendo-grid-column width="10%" field="personalizado" title="{{ 'manuales.ficheros' | translate}}"
          [style]="{'text-align': 'left'}">
          <ng-template kendoGridCellTemplate let-dataItem>
            <span *ngIf="dataItem.tipos.includes('1')" class="mr-2"><i class="fa fa-camera"></i></span>
            <span *ngIf="dataItem.tipos.includes('3')" class="mr-2"><i class="fa fa-file"></i></span>
            <span *ngIf="dataItem.tipos.includes('2')" class=""><i class="fa fa-play"></i></span>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-messages filter="{{'grids.filter' | translate}}"
          filterAfterOperator="{{'grids.filterAfterOperator' | translate}}"
          filterAfterOrEqualOperator="{{'grids.filterAfterOrEqualOperator' | translate}}"
          filterAndLogic="{{'grids.filterAndLogic' | translate}}"
          filterBeforeOperator="{{'grids.filterBeforeOperator' | translate}}"
          filterBeforeOrEqualOperator="{{'grids.filterBeforeOrEqualOperator' | translate}}"
          filterBooleanAll="{{'grids.filterBooleanAll' | translate}}"
          filterClearButton="{{'filterClearButton' | translate}}"
          filterContainsOperator="{{'grids.filterContainsOperator' | translate}}"
          filterDateToday="{{'grids.filterDateToday' | translate}}"
          filterDateToggle="{{'grids.filterDateToggle' | translate}}"
          filterEndsWithOperator="{{'grids.filterEndsWithOperator' | translate}}"
          filterEqOperator="{{'grids.filterEqOperator' | translate}}"
          filterFilterButton="{{'grids.filterFilterButton' | translate}}"
          filterGtOperator="{{'grids.filterGtOperator' | translate}}"
          filterGteOperator="{{'grids.filterGteOperator' | translate}}"
          filterIsEmptyOperator="{{'grids.filterIsEmptyOperator' | translate}}"
          filterIsFalse="{{'grids.filterIsFalse' | translate}}"
          filterIsNotEmptyOperator="{{'grids.filterIsNotEmptyOperator' | translate}}"
          filterIsNotNullOperator="{{'grids.filterIsNotNullOperator' | translate}}"
          filterIsNullOperator="{{'grids.filterIsNullOperator' | translate}}"
          filterIsTrue="{{'grids.filterIsTrue' | translate}}"
          filterLtOperator="{{'grids.filterLtOperator' | translate}}"
          filterLteOperator="{{'grids.filterLteOperator' | translate}}"
          filterNotContainsOperator="{{'grids.filterNotContainsOperator' | translate}}"
          filterNotEqOperator="{{'grids.filterNotEqOperator' | translate}}"
          filterNumericDecrement="{{'grids.filterNumericDecrement' | translate}}"
          filterNumericIncrement="{{'grids.filterNumericIncrement' | translate}}"
          filterOrLogic="{{'grids.filterOrLogic' | translate}}"
          filterStartsWithOperator="{{'grids.filterStartsWithOperator' | translate}}"
          loading="{{'grids.loading' | translate}}" noRecords="{{'grids.noRecords' | translate}}"
          unlock="{{'unlock' | translate}}">
        </kendo-grid-messages>
      </kendo-grid>
    </ng-template>
  </kendo-tabstrip-tab>
  <kendo-tabstrip-tab title="{{ 'manuales.horno' | translate}}">
    <ng-template kendoTabContent>
      <kendo-grid [kendoGridBinding]="datosHorno" [sortable]="true" kendoGridSelectBy="id" [navigable]="true"
        filterable="menu" [selectedKeys]="datosHornoSelected" [selectable]="true" (cellClick)="cellClick($event)">
        <ng-template kendoGridToolbarTemplate position="top">
          <button kendoButton (click)="onClickEditar('horno')" class="btn mr-1  btn-success btn-sm mr-1">{{
            'botones.editar' | translate}}</button>
          <button kendoButton (click)="onClickNuevo('horno')" class="btn mr-1  btn-primary btn-sm mr-1">{{
            'botones.nuevo'
            | translate}}</button>
          <button kendoButton (click)="onClickEliminar('horno')" class="btn mr-1  btn-danger btn-sm mr-1"
            [disabled]="isDeleting">{{ 'botones.eliminar' | translate}}</button>
        </ng-template>
        <kendo-grid-checkbox-column width="5%" showSelectAll="true"></kendo-grid-checkbox-column>
        <kendo-grid-column width="10%" field="nombre" title="{{ 'manuales.nombre' | translate}}"
          [style]="{'text-align': 'left'}">
        </kendo-grid-column>
        <kendo-grid-column width="10%" field="personalizado" title="{{ 'manuales.ficheros' | translate}}"
          [style]="{'text-align': 'left'}">
          <ng-template kendoGridCellTemplate let-dataItem>
            <span *ngIf="dataItem.tipos.includes('1')" class="mr-2"><i class="fa fa-camera"></i></span>
            <span *ngIf="dataItem.tipos.includes('3')" class="mr-2"><i class="fa fa-file"></i></span>
            <span *ngIf="dataItem.tipos.includes('2')" class=""><i class="fa fa-play"></i></span>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-messages filter="{{'grids.filter' | translate}}"
          filterAfterOperator="{{'grids.filterAfterOperator' | translate}}"
          filterAfterOrEqualOperator="{{'grids.filterAfterOrEqualOperator' | translate}}"
          filterAndLogic="{{'grids.filterAndLogic' | translate}}"
          filterBeforeOperator="{{'grids.filterBeforeOperator' | translate}}"
          filterBeforeOrEqualOperator="{{'grids.filterBeforeOrEqualOperator' | translate}}"
          filterBooleanAll="{{'grids.filterBooleanAll' | translate}}"
          filterClearButton="{{'filterClearButton' | translate}}"
          filterContainsOperator="{{'grids.filterContainsOperator' | translate}}"
          filterDateToday="{{'grids.filterDateToday' | translate}}"
          filterDateToggle="{{'grids.filterDateToggle' | translate}}"
          filterEndsWithOperator="{{'grids.filterEndsWithOperator' | translate}}"
          filterEqOperator="{{'grids.filterEqOperator' | translate}}"
          filterFilterButton="{{'grids.filterFilterButton' | translate}}"
          filterGtOperator="{{'grids.filterGtOperator' | translate}}"
          filterGteOperator="{{'grids.filterGteOperator' | translate}}"
          filterIsEmptyOperator="{{'grids.filterIsEmptyOperator' | translate}}"
          filterIsFalse="{{'grids.filterIsFalse' | translate}}"
          filterIsNotEmptyOperator="{{'grids.filterIsNotEmptyOperator' | translate}}"
          filterIsNotNullOperator="{{'grids.filterIsNotNullOperator' | translate}}"
          filterIsNullOperator="{{'grids.filterIsNullOperator' | translate}}"
          filterIsTrue="{{'grids.filterIsTrue' | translate}}"
          filterLtOperator="{{'grids.filterLtOperator' | translate}}"
          filterLteOperator="{{'grids.filterLteOperator' | translate}}"
          filterNotContainsOperator="{{'grids.filterNotContainsOperator' | translate}}"
          filterNotEqOperator="{{'grids.filterNotEqOperator' | translate}}"
          filterNumericDecrement="{{'grids.filterNumericDecrement' | translate}}"
          filterNumericIncrement="{{'grids.filterNumericIncrement' | translate}}"
          filterOrLogic="{{'grids.filterOrLogic' | translate}}"
          filterStartsWithOperator="{{'grids.filterStartsWithOperator' | translate}}"
          loading="{{'grids.loading' | translate}}" noRecords="{{'grids.noRecords' | translate}}"
          unlock="{{'unlock' | translate}}">
        </kendo-grid-messages>
      </kendo-grid>
    </ng-template>
  </kendo-tabstrip-tab>
  <kendo-tabstrip-tab title="{{ 'manuales.prensaForjar' | translate}}">
    <ng-template kendoTabContent>
      <kendo-grid [kendoGridBinding]="datosPrensaForjar" [sortable]="true" kendoGridSelectBy="id" [navigable]="true"
        filterable="menu" [selectedKeys]="datosPrensaForjarSelected" [selectable]="true" (cellClick)="cellClick($event)">
        <ng-template kendoGridToolbarTemplate position="top">
          <button kendoButton (click)="onClickEditar('prensaForjar')" class="btn mr-1  btn-success btn-sm mr-1">{{
            'botones.editar' | translate}}</button>
          <button kendoButton (click)="onClickNuevo('prensaForjar')" class="btn mr-1  btn-primary btn-sm mr-1">{{
            'botones.nuevo' | translate}}</button>
          <button kendoButton (click)="onClickEliminar('prensaForjar')" class="btn mr-1  btn-danger btn-sm mr-1"
            [disabled]="isDeleting">{{ 'botones.eliminar' | translate}}</button>
        </ng-template>
        <kendo-grid-checkbox-column width="5%" showSelectAll="true"></kendo-grid-checkbox-column>
        <kendo-grid-column width="10%" field="nombre" title="{{ 'manuales.nombre' | translate}}"
          [style]="{'text-align': 'left'}">
        </kendo-grid-column>
        <kendo-grid-column width="10%" field="personalizado" title="{{ 'manuales.ficheros' | translate}}"
          [style]="{'text-align': 'left'}">
          <ng-template kendoGridCellTemplate let-dataItem>
            <span *ngIf="dataItem.tipos.includes('1')" class="mr-2"><i class="fa fa-camera"></i></span>
            <span *ngIf="dataItem.tipos.includes('3')" class="mr-2"><i class="fa fa-file"></i></span>
            <span *ngIf="dataItem.tipos.includes('2')" class=""><i class="fa fa-play"></i></span>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-messages filter="{{'grids.filter' | translate}}"
          filterAfterOperator="{{'grids.filterAfterOperator' | translate}}"
          filterAfterOrEqualOperator="{{'grids.filterAfterOrEqualOperator' | translate}}"
          filterAndLogic="{{'grids.filterAndLogic' | translate}}"
          filterBeforeOperator="{{'grids.filterBeforeOperator' | translate}}"
          filterBeforeOrEqualOperator="{{'grids.filterBeforeOrEqualOperator' | translate}}"
          filterBooleanAll="{{'grids.filterBooleanAll' | translate}}"
          filterClearButton="{{'filterClearButton' | translate}}"
          filterContainsOperator="{{'grids.filterContainsOperator' | translate}}"
          filterDateToday="{{'grids.filterDateToday' | translate}}"
          filterDateToggle="{{'grids.filterDateToggle' | translate}}"
          filterEndsWithOperator="{{'grids.filterEndsWithOperator' | translate}}"
          filterEqOperator="{{'grids.filterEqOperator' | translate}}"
          filterFilterButton="{{'grids.filterFilterButton' | translate}}"
          filterGtOperator="{{'grids.filterGtOperator' | translate}}"
          filterGteOperator="{{'grids.filterGteOperator' | translate}}"
          filterIsEmptyOperator="{{'grids.filterIsEmptyOperator' | translate}}"
          filterIsFalse="{{'grids.filterIsFalse' | translate}}"
          filterIsNotEmptyOperator="{{'grids.filterIsNotEmptyOperator' | translate}}"
          filterIsNotNullOperator="{{'grids.filterIsNotNullOperator' | translate}}"
          filterIsNullOperator="{{'grids.filterIsNullOperator' | translate}}"
          filterIsTrue="{{'grids.filterIsTrue' | translate}}"
          filterLtOperator="{{'grids.filterLtOperator' | translate}}"
          filterLteOperator="{{'grids.filterLteOperator' | translate}}"
          filterNotContainsOperator="{{'grids.filterNotContainsOperator' | translate}}"
          filterNotEqOperator="{{'grids.filterNotEqOperator' | translate}}"
          filterNumericDecrement="{{'grids.filterNumericDecrement' | translate}}"
          filterNumericIncrement="{{'grids.filterNumericIncrement' | translate}}"
          filterOrLogic="{{'grids.filterOrLogic' | translate}}"
          filterStartsWithOperator="{{'grids.filterStartsWithOperator' | translate}}"
          loading="{{'grids.loading' | translate}}" noRecords="{{'grids.noRecords' | translate}}"
          unlock="{{'unlock' | translate}}">
        </kendo-grid-messages>
      </kendo-grid>
    </ng-template>
  </kendo-tabstrip-tab>
  <kendo-tabstrip-tab title="{{ 'manuales.medidor' | translate}}">
    <ng-template kendoTabContent>
      <kendo-grid [kendoGridBinding]="datosMedidor" [sortable]="true" kendoGridSelectBy="id" [navigable]="true"
        filterable="menu" [selectedKeys]="datosMedidorSelected" [selectable]="true" (cellClick)="cellClick($event)">
        <ng-template kendoGridToolbarTemplate position="top">
          <button kendoButton (click)="onClickEditar('medidor')" class="btn mr-1  btn-success btn-sm mr-1">{{
            'botones.editar' | translate}}</button>
          <button kendoButton (click)="onClickNuevo('medidor')" class="btn mr-1  btn-primary btn-sm mr-1">{{
            'botones.nuevo' | translate}}</button>
          <button kendoButton (click)="onClickEliminar('medidor')" class="btn mr-1  btn-danger btn-sm mr-1"
            [disabled]="isDeleting">{{ 'botones.eliminar' | translate}}</button>
        </ng-template>
        <kendo-grid-checkbox-column width="5%" showSelectAll="true"></kendo-grid-checkbox-column>
        <kendo-grid-column width="10%" field="nombre" title="{{ 'manuales.nombre' | translate}}"
          [style]="{'text-align': 'left'}">
        </kendo-grid-column>
        <kendo-grid-column width="10%" field="personalizado" title="{{ 'manuales.ficheros' | translate}}"
          [style]="{'text-align': 'left'}">
          <ng-template kendoGridCellTemplate let-dataItem>
            <span *ngIf="dataItem.tipos.includes('1')" class="mr-2"><i class="fa fa-camera"></i></span>
            <span *ngIf="dataItem.tipos.includes('3')" class="mr-2"><i class="fa fa-file"></i></span>
            <span *ngIf="dataItem.tipos.includes('2')" class=""><i class="fa fa-play"></i></span>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-messages filter="{{'grids.filter' | translate}}"
          filterAfterOperator="{{'grids.filterAfterOperator' | translate}}"
          filterAfterOrEqualOperator="{{'grids.filterAfterOrEqualOperator' | translate}}"
          filterAndLogic="{{'grids.filterAndLogic' | translate}}"
          filterBeforeOperator="{{'grids.filterBeforeOperator' | translate}}"
          filterBeforeOrEqualOperator="{{'grids.filterBeforeOrEqualOperator' | translate}}"
          filterBooleanAll="{{'grids.filterBooleanAll' | translate}}"
          filterClearButton="{{'filterClearButton' | translate}}"
          filterContainsOperator="{{'grids.filterContainsOperator' | translate}}"
          filterDateToday="{{'grids.filterDateToday' | translate}}"
          filterDateToggle="{{'grids.filterDateToggle' | translate}}"
          filterEndsWithOperator="{{'grids.filterEndsWithOperator' | translate}}"
          filterEqOperator="{{'grids.filterEqOperator' | translate}}"
          filterFilterButton="{{'grids.filterFilterButton' | translate}}"
          filterGtOperator="{{'grids.filterGtOperator' | translate}}"
          filterGteOperator="{{'grids.filterGteOperator' | translate}}"
          filterIsEmptyOperator="{{'grids.filterIsEmptyOperator' | translate}}"
          filterIsFalse="{{'grids.filterIsFalse' | translate}}"
          filterIsNotEmptyOperator="{{'grids.filterIsNotEmptyOperator' | translate}}"
          filterIsNotNullOperator="{{'grids.filterIsNotNullOperator' | translate}}"
          filterIsNullOperator="{{'grids.filterIsNullOperator' | translate}}"
          filterIsTrue="{{'grids.filterIsTrue' | translate}}"
          filterLtOperator="{{'grids.filterLtOperator' | translate}}"
          filterLteOperator="{{'grids.filterLteOperator' | translate}}"
          filterNotContainsOperator="{{'grids.filterNotContainsOperator' | translate}}"
          filterNotEqOperator="{{'grids.filterNotEqOperator' | translate}}"
          filterNumericDecrement="{{'grids.filterNumericDecrement' | translate}}"
          filterNumericIncrement="{{'grids.filterNumericIncrement' | translate}}"
          filterOrLogic="{{'grids.filterOrLogic' | translate}}"
          filterStartsWithOperator="{{'grids.filterStartsWithOperator' | translate}}"
          loading="{{'grids.loading' | translate}}" noRecords="{{'grids.noRecords' | translate}}"
          unlock="{{'unlock' | translate}}">
        </kendo-grid-messages>
      </kendo-grid>
    </ng-template>
  </kendo-tabstrip-tab>
  <kendo-tabstrip-tab title="{{ 'manuales.sierras' | translate}}">
    <ng-template kendoTabContent>
      <kendo-grid [kendoGridBinding]="datosSierras" [sortable]="true" kendoGridSelectBy="id" [navigable]="true"
        filterable="menu" [selectedKeys]="datosSierrasSelected" [selectable]="true" (cellClick)="cellClick($event)">
        <ng-template kendoGridToolbarTemplate position="top">
          <button kendoButton (click)="onClickEditar('sierras')" class="btn mr-1  btn-success btn-sm mr-1">{{
            'botones.editar' | translate}}</button>
          <button kendoButton (click)="onClickNuevo('sierras')" class="btn mr-1  btn-primary btn-sm mr-1">{{
            'botones.nuevo' | translate}}</button>
          <button kendoButton (click)="onClickEliminar('sierras')" class="btn mr-1  btn-danger btn-sm mr-1"
            [disabled]="isDeleting">{{ 'botones.eliminar' | translate}}</button>
        </ng-template>
        <kendo-grid-checkbox-column width="5%" showSelectAll="true"></kendo-grid-checkbox-column>
        <kendo-grid-column width="10%" field="nombre" title="{{ 'manuales.nombre' | translate}}"
          [style]="{'text-align': 'left'}">
        </kendo-grid-column>
        <kendo-grid-column width="10%" field="personalizado" title="{{ 'manuales.ficheros' | translate}}"
          [style]="{'text-align': 'left'}">
          <ng-template kendoGridCellTemplate let-dataItem>
            <span *ngIf="dataItem.tipos.includes('1')" class="mr-2"><i class="fa fa-camera"></i></span>
            <span *ngIf="dataItem.tipos.includes('3')" class="mr-2"><i class="fa fa-file"></i></span>
            <span *ngIf="dataItem.tipos.includes('2')" class=""><i class="fa fa-play"></i></span>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-messages filter="{{'grids.filter' | translate}}"
          filterAfterOperator="{{'grids.filterAfterOperator' | translate}}"
          filterAfterOrEqualOperator="{{'grids.filterAfterOrEqualOperator' | translate}}"
          filterAndLogic="{{'grids.filterAndLogic' | translate}}"
          filterBeforeOperator="{{'grids.filterBeforeOperator' | translate}}"
          filterBeforeOrEqualOperator="{{'grids.filterBeforeOrEqualOperator' | translate}}"
          filterBooleanAll="{{'grids.filterBooleanAll' | translate}}"
          filterClearButton="{{'filterClearButton' | translate}}"
          filterContainsOperator="{{'grids.filterContainsOperator' | translate}}"
          filterDateToday="{{'grids.filterDateToday' | translate}}"
          filterDateToggle="{{'grids.filterDateToggle' | translate}}"
          filterEndsWithOperator="{{'grids.filterEndsWithOperator' | translate}}"
          filterEqOperator="{{'grids.filterEqOperator' | translate}}"
          filterFilterButton="{{'grids.filterFilterButton' | translate}}"
          filterGtOperator="{{'grids.filterGtOperator' | translate}}"
          filterGteOperator="{{'grids.filterGteOperator' | translate}}"
          filterIsEmptyOperator="{{'grids.filterIsEmptyOperator' | translate}}"
          filterIsFalse="{{'grids.filterIsFalse' | translate}}"
          filterIsNotEmptyOperator="{{'grids.filterIsNotEmptyOperator' | translate}}"
          filterIsNotNullOperator="{{'grids.filterIsNotNullOperator' | translate}}"
          filterIsNullOperator="{{'grids.filterIsNullOperator' | translate}}"
          filterIsTrue="{{'grids.filterIsTrue' | translate}}"
          filterLtOperator="{{'grids.filterLtOperator' | translate}}"
          filterLteOperator="{{'grids.filterLteOperator' | translate}}"
          filterNotContainsOperator="{{'grids.filterNotContainsOperator' | translate}}"
          filterNotEqOperator="{{'grids.filterNotEqOperator' | translate}}"
          filterNumericDecrement="{{'grids.filterNumericDecrement' | translate}}"
          filterNumericIncrement="{{'grids.filterNumericIncrement' | translate}}"
          filterOrLogic="{{'grids.filterOrLogic' | translate}}"
          filterStartsWithOperator="{{'grids.filterStartsWithOperator' | translate}}"
          loading="{{'grids.loading' | translate}}" noRecords="{{'grids.noRecords' | translate}}"
          unlock="{{'unlock' | translate}}">
        </kendo-grid-messages>
      </kendo-grid>
    </ng-template>
  </kendo-tabstrip-tab>
  <kendo-tabstrip-tab title="{{ 'manuales.documentos' | translate}}">
    <ng-template kendoTabContent>
      <kendo-grid [kendoGridBinding]="datosDocumentos" [sortable]="true" kendoGridSelectBy="id" [navigable]="true"
        filterable="menu" [selectedKeys]="datosDocumentosSelected" [selectable]="true" (cellClick)="cellClick($event)">
        <ng-template kendoGridToolbarTemplate position="top">
          <button kendoButton (click)="onClickEditar('documentos')" class="btn mr-1  btn-success btn-sm mr-1">{{
            'botones.editar' | translate}}</button>
          <button kendoButton (click)="onClickNuevo('documentos')" class="btn mr-1  btn-primary btn-sm mr-1">{{
            'botones.nuevo' | translate}}</button>
          <button kendoButton (click)="onClickEliminar('documentos')" class="btn mr-1  btn-danger btn-sm mr-1"
            [disabled]="isDeleting">{{ 'botones.eliminar' | translate}}</button>
        </ng-template>
        <kendo-grid-checkbox-column width="5%" showSelectAll="true"></kendo-grid-checkbox-column>
        <kendo-grid-column width="10%" field="nombre" title="{{ 'manuales.nombre' | translate}}"
          [style]="{'text-align': 'left'}">
        </kendo-grid-column>
        <kendo-grid-column width="10%" field="personalizado" title="{{ 'manuales.ficheros' | translate}}"
          [style]="{'text-align': 'left'}">
          <ng-template kendoGridCellTemplate let-dataItem>
            <span *ngIf="dataItem.tipos.includes('1')" class="mr-2"><i class="fa fa-camera"></i></span>
            <span *ngIf="dataItem.tipos.includes('3')" class="mr-2"><i class="fa fa-file"></i></span>
            <span *ngIf="dataItem.tipos.includes('2')" class=""><i class="fa fa-play"></i></span>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-messages filter="{{'grids.filter' | translate}}"
          filterAfterOperator="{{'grids.filterAfterOperator' | translate}}"
          filterAfterOrEqualOperator="{{'grids.filterAfterOrEqualOperator' | translate}}"
          filterAndLogic="{{'grids.filterAndLogic' | translate}}"
          filterBeforeOperator="{{'grids.filterBeforeOperator' | translate}}"
          filterBeforeOrEqualOperator="{{'grids.filterBeforeOrEqualOperator' | translate}}"
          filterBooleanAll="{{'grids.filterBooleanAll' | translate}}"
          filterClearButton="{{'filterClearButton' | translate}}"
          filterContainsOperator="{{'grids.filterContainsOperator' | translate}}"
          filterDateToday="{{'grids.filterDateToday' | translate}}"
          filterDateToggle="{{'grids.filterDateToggle' | translate}}"
          filterEndsWithOperator="{{'grids.filterEndsWithOperator' | translate}}"
          filterEqOperator="{{'grids.filterEqOperator' | translate}}"
          filterFilterButton="{{'grids.filterFilterButton' | translate}}"
          filterGtOperator="{{'grids.filterGtOperator' | translate}}"
          filterGteOperator="{{'grids.filterGteOperator' | translate}}"
          filterIsEmptyOperator="{{'grids.filterIsEmptyOperator' | translate}}"
          filterIsFalse="{{'grids.filterIsFalse' | translate}}"
          filterIsNotEmptyOperator="{{'grids.filterIsNotEmptyOperator' | translate}}"
          filterIsNotNullOperator="{{'grids.filterIsNotNullOperator' | translate}}"
          filterIsNullOperator="{{'grids.filterIsNullOperator' | translate}}"
          filterIsTrue="{{'grids.filterIsTrue' | translate}}"
          filterLtOperator="{{'grids.filterLtOperator' | translate}}"
          filterLteOperator="{{'grids.filterLteOperator' | translate}}"
          filterNotContainsOperator="{{'grids.filterNotContainsOperator' | translate}}"
          filterNotEqOperator="{{'grids.filterNotEqOperator' | translate}}"
          filterNumericDecrement="{{'grids.filterNumericDecrement' | translate}}"
          filterNumericIncrement="{{'grids.filterNumericIncrement' | translate}}"
          filterOrLogic="{{'grids.filterOrLogic' | translate}}"
          filterStartsWithOperator="{{'grids.filterStartsWithOperator' | translate}}"
          loading="{{'grids.loading' | translate}}" noRecords="{{'grids.noRecords' | translate}}"
          unlock="{{'unlock' | translate}}">
        </kendo-grid-messages>
      </kendo-grid>
    </ng-template>
  </kendo-tabstrip-tab>
  <kendo-tabstrip-tab title="{{ 'manuales.prensaCurvar' | translate}}">
    <ng-template kendoTabContent>
      <kendo-grid [kendoGridBinding]="datosPrensaCurvar" [sortable]="true" kendoGridSelectBy="id" [navigable]="true"
        filterable="menu" [selectedKeys]="datosPrensaCurvarSelected" [selectable]="true" (cellClick)="cellClick($event)">
        <ng-template kendoGridToolbarTemplate position="top">
          <button kendoButton (click)="onClickEditar('prensaCurvar')" class="btn mr-1  btn-success btn-sm mr-1">{{
            'botones.editar' | translate}}</button>
          <button kendoButton (click)="onClickNuevo('prensaCurvar')" class="btn mr-1  btn-primary btn-sm mr-1">{{
            'botones.nuevo' | translate}}</button>
          <button kendoButton (click)="onClickEliminar('prensaCurvar')" class="btn mr-1  btn-danger btn-sm mr-1"
            [disabled]="isDeleting">{{ 'botones.eliminar' | translate}}</button>
        </ng-template>
        <kendo-grid-checkbox-column width="5%" showSelectAll="true"></kendo-grid-checkbox-column>
        <kendo-grid-column width="10%" field="nombre" title="{{ 'manuales.nombre' | translate}}"
          [style]="{'text-align': 'left'}">
        </kendo-grid-column>
        <kendo-grid-column width="10%" field="personalizado" title="{{ 'manuales.ficheros' | translate}}"
          [style]="{'text-align': 'left'}">
          <ng-template kendoGridCellTemplate let-dataItem>
            <span *ngIf="dataItem.tipos.includes('1')" class="mr-2"><i class="fa fa-camera"></i></span>
            <span *ngIf="dataItem.tipos.includes('3')" class="mr-2"><i class="fa fa-file"></i></span>
            <span *ngIf="dataItem.tipos.includes('2')" class=""><i class="fa fa-play"></i></span>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-messages filter="{{'grids.filter' | translate}}"
          filterAfterOperator="{{'grids.filterAfterOperator' | translate}}"
          filterAfterOrEqualOperator="{{'grids.filterAfterOrEqualOperator' | translate}}"
          filterAndLogic="{{'grids.filterAndLogic' | translate}}"
          filterBeforeOperator="{{'grids.filterBeforeOperator' | translate}}"
          filterBeforeOrEqualOperator="{{'grids.filterBeforeOrEqualOperator' | translate}}"
          filterBooleanAll="{{'grids.filterBooleanAll' | translate}}"
          filterClearButton="{{'filterClearButton' | translate}}"
          filterContainsOperator="{{'grids.filterContainsOperator' | translate}}"
          filterDateToday="{{'grids.filterDateToday' | translate}}"
          filterDateToggle="{{'grids.filterDateToggle' | translate}}"
          filterEndsWithOperator="{{'grids.filterEndsWithOperator' | translate}}"
          filterEqOperator="{{'grids.filterEqOperator' | translate}}"
          filterFilterButton="{{'grids.filterFilterButton' | translate}}"
          filterGtOperator="{{'grids.filterGtOperator' | translate}}"
          filterGteOperator="{{'grids.filterGteOperator' | translate}}"
          filterIsEmptyOperator="{{'grids.filterIsEmptyOperator' | translate}}"
          filterIsFalse="{{'grids.filterIsFalse' | translate}}"
          filterIsNotEmptyOperator="{{'grids.filterIsNotEmptyOperator' | translate}}"
          filterIsNotNullOperator="{{'grids.filterIsNotNullOperator' | translate}}"
          filterIsNullOperator="{{'grids.filterIsNullOperator' | translate}}"
          filterIsTrue="{{'grids.filterIsTrue' | translate}}"
          filterLtOperator="{{'grids.filterLtOperator' | translate}}"
          filterLteOperator="{{'grids.filterLteOperator' | translate}}"
          filterNotContainsOperator="{{'grids.filterNotContainsOperator' | translate}}"
          filterNotEqOperator="{{'grids.filterNotEqOperator' | translate}}"
          filterNumericDecrement="{{'grids.filterNumericDecrement' | translate}}"
          filterNumericIncrement="{{'grids.filterNumericIncrement' | translate}}"
          filterOrLogic="{{'grids.filterOrLogic' | translate}}"
          filterStartsWithOperator="{{'grids.filterStartsWithOperator' | translate}}"
          loading="{{'grids.loading' | translate}}" noRecords="{{'grids.noRecords' | translate}}"
          unlock="{{'unlock' | translate}}">
        </kendo-grid-messages>
      </kendo-grid>
    </ng-template>
  </kendo-tabstrip-tab>
  <kendo-tabstrip-tab title="{{ 'manuales.volteador' | translate}}">
    <ng-template kendoTabContent>
      <kendo-grid [kendoGridBinding]="datosVolteador" [sortable]="true" kendoGridSelectBy="id" [navigable]="true"
        filterable="menu" [selectedKeys]="datosVolteadorSelected" [selectable]="true" (cellClick)="cellClick($event)">
        <ng-template kendoGridToolbarTemplate position="top">
          <button kendoButton (click)="onClickEditar('volteador')" class="btn mr-1  btn-success btn-sm mr-1">{{
            'botones.editar' | translate}}</button>
          <button kendoButton (click)="onClickNuevo('volteador')" class="btn mr-1  btn-primary btn-sm mr-1">{{
            'botones.nuevo' | translate}}</button>
          <button kendoButton (click)="onClickEliminar('volteador')" class="btn mr-1  btn-danger btn-sm mr-1"
            [disabled]="isDeleting">{{ 'botones.eliminar' | translate}}</button>
        </ng-template>
        <kendo-grid-checkbox-column width="5%" showSelectAll="true"></kendo-grid-checkbox-column>
        <kendo-grid-column width="10%" field="nombre" title="{{ 'manuales.nombre' | translate}}"
          [style]="{'text-align': 'left'}">
        </kendo-grid-column>
        <kendo-grid-column width="10%" field="personalizado" title="{{ 'manuales.ficheros' | translate}}"
          [style]="{'text-align': 'left'}">
          <ng-template kendoGridCellTemplate let-dataItem>
            <span *ngIf="dataItem.tipos.includes('1')" class="mr-2"><i class="fa fa-camera"></i></span>
            <span *ngIf="dataItem.tipos.includes('3')" class="mr-2"><i class="fa fa-file"></i></span>
            <span *ngIf="dataItem.tipos.includes('2')" class=""><i class="fa fa-play"></i></span>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-messages filter="{{'grids.filter' | translate}}"
          filterAfterOperator="{{'grids.filterAfterOperator' | translate}}"
          filterAfterOrEqualOperator="{{'grids.filterAfterOrEqualOperator' | translate}}"
          filterAndLogic="{{'grids.filterAndLogic' | translate}}"
          filterBeforeOperator="{{'grids.filterBeforeOperator' | translate}}"
          filterBeforeOrEqualOperator="{{'grids.filterBeforeOrEqualOperator' | translate}}"
          filterBooleanAll="{{'grids.filterBooleanAll' | translate}}"
          filterClearButton="{{'filterClearButton' | translate}}"
          filterContainsOperator="{{'grids.filterContainsOperator' | translate}}"
          filterDateToday="{{'grids.filterDateToday' | translate}}"
          filterDateToggle="{{'grids.filterDateToggle' | translate}}"
          filterEndsWithOperator="{{'grids.filterEndsWithOperator' | translate}}"
          filterEqOperator="{{'grids.filterEqOperator' | translate}}"
          filterFilterButton="{{'grids.filterFilterButton' | translate}}"
          filterGtOperator="{{'grids.filterGtOperator' | translate}}"
          filterGteOperator="{{'grids.filterGteOperator' | translate}}"
          filterIsEmptyOperator="{{'grids.filterIsEmptyOperator' | translate}}"
          filterIsFalse="{{'grids.filterIsFalse' | translate}}"
          filterIsNotEmptyOperator="{{'grids.filterIsNotEmptyOperator' | translate}}"
          filterIsNotNullOperator="{{'grids.filterIsNotNullOperator' | translate}}"
          filterIsNullOperator="{{'grids.filterIsNullOperator' | translate}}"
          filterIsTrue="{{'grids.filterIsTrue' | translate}}"
          filterLtOperator="{{'grids.filterLtOperator' | translate}}"
          filterLteOperator="{{'grids.filterLteOperator' | translate}}"
          filterNotContainsOperator="{{'grids.filterNotContainsOperator' | translate}}"
          filterNotEqOperator="{{'grids.filterNotEqOperator' | translate}}"
          filterNumericDecrement="{{'grids.filterNumericDecrement' | translate}}"
          filterNumericIncrement="{{'grids.filterNumericIncrement' | translate}}"
          filterOrLogic="{{'grids.filterOrLogic' | translate}}"
          filterStartsWithOperator="{{'grids.filterStartsWithOperator' | translate}}"
          loading="{{'grids.loading' | translate}}" noRecords="{{'grids.noRecords' | translate}}"
          unlock="{{'unlock' | translate}}">
        </kendo-grid-messages>
      </kendo-grid>
    </ng-template>
  </kendo-tabstrip-tab>
  <kendo-tabstrip-tab title="{{ 'manuales.granalladora' | translate}}">
    <ng-template kendoTabContent>
      <kendo-grid [kendoGridBinding]="datosGranalladora" [sortable]="true" kendoGridSelectBy="id" [navigable]="true"
        filterable="menu" [selectedKeys]="datosGranalladoraSelected" [selectable]="true">
        <ng-template kendoGridToolbarTemplate position="top">
          <button kendoButton (click)="onClickEditar('granalladora')" class="btn mr-1  btn-success btn-sm mr-1">{{
            'botones.editar' | translate}}</button>
          <button kendoButton (click)="onClickNuevo('granalladora')" class="btn mr-1  btn-primary btn-sm mr-1">{{
            'botones.nuevo' | translate}}</button>
          <button kendoButton (click)="onClickEliminar('granalladora')" class="btn mr-1  btn-danger btn-sm mr-1"
            [disabled]="isDeleting">{{ 'botones.eliminar' | translate}}</button>
        </ng-template>
        <kendo-grid-checkbox-column width="5%" showSelectAll="true"></kendo-grid-checkbox-column>
        <kendo-grid-column width="10%" field="nombre" title="{{ 'manuales.nombre' | translate}}"
          [style]="{'text-align': 'left'}">
        </kendo-grid-column>
        <kendo-grid-column width="10%" field="personalizado" title="{{ 'manuales.ficheros' | translate}}"
          [style]="{'text-align': 'left'}">
          <ng-template kendoGridCellTemplate let-dataItem>
            <span *ngIf="dataItem.tipos.includes('1')" class="mr-2"><i class="fa fa-camera"></i></span>
            <span *ngIf="dataItem.tipos.includes('3')" class="mr-2"><i class="fa fa-file"></i></span>
            <span *ngIf="dataItem.tipos.includes('2')" class=""><i class="fa fa-play"></i></span>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-messages filter="{{'grids.filter' | translate}}"
          filterAfterOperator="{{'grids.filterAfterOperator' | translate}}"
          filterAfterOrEqualOperator="{{'grids.filterAfterOrEqualOperator' | translate}}"
          filterAndLogic="{{'grids.filterAndLogic' | translate}}"
          filterBeforeOperator="{{'grids.filterBeforeOperator' | translate}}"
          filterBeforeOrEqualOperator="{{'grids.filterBeforeOrEqualOperator' | translate}}"
          filterBooleanAll="{{'grids.filterBooleanAll' | translate}}"
          filterClearButton="{{'filterClearButton' | translate}}"
          filterContainsOperator="{{'grids.filterContainsOperator' | translate}}"
          filterDateToday="{{'grids.filterDateToday' | translate}}"
          filterDateToggle="{{'grids.filterDateToggle' | translate}}"
          filterEndsWithOperator="{{'grids.filterEndsWithOperator' | translate}}"
          filterEqOperator="{{'grids.filterEqOperator' | translate}}"
          filterFilterButton="{{'grids.filterFilterButton' | translate}}"
          filterGtOperator="{{'grids.filterGtOperator' | translate}}"
          filterGteOperator="{{'grids.filterGteOperator' | translate}}"
          filterIsEmptyOperator="{{'grids.filterIsEmptyOperator' | translate}}"
          filterIsFalse="{{'grids.filterIsFalse' | translate}}"
          filterIsNotEmptyOperator="{{'grids.filterIsNotEmptyOperator' | translate}}"
          filterIsNotNullOperator="{{'grids.filterIsNotNullOperator' | translate}}"
          filterIsNullOperator="{{'grids.filterIsNullOperator' | translate}}"
          filterIsTrue="{{'grids.filterIsTrue' | translate}}"
          filterLtOperator="{{'grids.filterLtOperator' | translate}}"
          filterLteOperator="{{'grids.filterLteOperator' | translate}}"
          filterNotContainsOperator="{{'grids.filterNotContainsOperator' | translate}}"
          filterNotEqOperator="{{'grids.filterNotEqOperator' | translate}}"
          filterNumericDecrement="{{'grids.filterNumericDecrement' | translate}}"
          filterNumericIncrement="{{'grids.filterNumericIncrement' | translate}}"
          filterOrLogic="{{'grids.filterOrLogic' | translate}}"
          filterStartsWithOperator="{{'grids.filterStartsWithOperator' | translate}}"
          loading="{{'grids.loading' | translate}}" noRecords="{{'grids.noRecords' | translate}}"
          unlock="{{'unlock' | translate}}">
        </kendo-grid-messages>
      </kendo-grid>
    </ng-template>
  </kendo-tabstrip-tab>
</kendo-tabstrip>

<div *ngIf="loading" class="k-i-loading"></div>

<!-- MODAL Nuevo -->
<ng-template #modalNuevo let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title"></h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="clearfix">
      <div class="col float-left mr-2">
        <kendo-label text="{{ 'manuales.nombre' | translate}}">
          <kendo-textbox [(value)]="nombreModal" [ngClass]="{ 'is-invalid': submit && errorNombre }">
          </kendo-textbox>
          <div *ngIf="submit && errorNombre" class="invalid-feedback">
            <div *ngIf="errorNombre">{{ 'manuales.nombreObligatorio' | translate}}</div>
          </div>
        </kendo-label>
      </div>
      <div class="float-left mr-2">
        <div class="form-group">
          <kendo-label text="{{ 'manuales.archivos' | translate}}">
            <div class="caja">
              <kendo-fileselect [ngModel]="archivos" [restrictions]="restrictions" [multiple]="true" autoUpload="false"
                (select)="archivoSeleccionado($event)" (remove)="archivoEliminado($event)">
                <kendo-upload-messages cancel="{{ 'uploads.cancelar' | translate }}"
                  clearSelectedFiles="{{ 'uploads.clearSelectedFiles' | translate }}"
                  dropFilesHere="{{ 'uploads.dropFilesHere' | translate }}"
                  externalDropFilesHere="{{ 'uploads.externalDropFilesHere' | translate }}"
                  fileStatusFailed="{{ 'uploads.fileStatusFailed' | translate }}"
                  fileStatusUploaded="{{ 'uploads.fileStatusUploaded' | translate }}"
                  filesBatchStatus="{{ 'uploads.filesBatchStatus' | translate }}"
                  filesBatchStatusFailed="{{ 'uploads.filesBatchStatusFailed' | translate }}"
                  filesBatchStatusUploaded="{{ 'uploads.filesBatchStatusUploaded' | translate }}"
                  headerStatusPaused="{{ 'uploads.headerStatusPaused' | translate }}"
                  headerStatusUploaded="{{ 'uploads.headerStatusUploaded' | translate }}"
                  headerStatusUploading="{{ 'uploads.headerStatusUploading' | translate }}"
                  invalidFileExtension="{{ 'uploads.invalidFileExtension' | translate }}"
                  invalidMaxFileSize="{{ 'uploads.invalidMaxFileSize' | translate }}"
                  invalidMinFileSize="{{ 'uploads.invalidMinFileSize' | translate }}"
                  pause="{{ 'uploads.pause' | translate }}" remove="{{ 'uploads.remove' | translate }}"
                  resume="{{ 'uploads.resume' | translate }}" retry="{{ 'uploads.retry' | translate }}"
                  select="{{ 'uploads.select' | translate }}"
                  uploadSelectedFiles="{{ 'uploads.uploadSelectedFiles' | translate }}">
                </kendo-upload-messages>
              </kendo-fileselect>
            </div>
          </kendo-label>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">{{ 'botones.cancelar' |
      translate
      }}</button>
    <button type="button" class="btn btn-primary" (click)="onClickAceptarNuevo()">{{ 'botones.aceptar' | translate
      }}</button>
  </div>
</ng-template>

<!-- MODAL Editar -->
<ng-template #modalEditar let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title"></h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <kendo-label text="{{ 'manuales.nombre' | translate}}">
      <kendo-textbox [(value)]="nombreModal" [ngClass]="{ 'is-invalid': submit && errorNombre }">
      </kendo-textbox>
      <div *ngIf="submit && errorNombre" class="invalid-feedback">
        <div *ngIf="errorNombre">{{ 'manuales.nombreObligatorio' | translate}}</div>
      </div>
    </kendo-label>
    <div class="form-group">
      <kendo-label text="{{ 'manuales.archivos' | translate}}">
        <div class="caja">
          <kendo-fileselect [ngModel]="archivosModal2" [restrictions]="restrictions" [multiple]="true"
            (select)="archivoSeleccionado2($event)" (remove)="archivoEliminado2($event, 1)">
            <kendo-upload-messages cancel="{{ 'uploads.cancelar' | translate }}"
              clearSelectedFiles="{{ 'uploads.clearSelectedFiles' | translate }}"
              dropFilesHere="{{ 'uploads.dropFilesHere' | translate }}"
              externalDropFilesHere="{{ 'uploads.externalDropFilesHere' | translate }}"
              fileStatusFailed="{{ 'uploads.fileStatusFailed' | translate }}"
              fileStatusUploaded="{{ 'uploads.fileStatusUploaded' | translate }}"
              filesBatchStatus="{{ 'uploads.filesBatchStatus' | translate }}"
              filesBatchStatusFailed="{{ 'uploads.filesBatchStatusFailed' | translate }}"
              filesBatchStatusUploaded="{{ 'uploads.filesBatchStatusUploaded' | translate }}"
              headerStatusPaused="{{ 'uploads.headerStatusPaused' | translate }}"
              headerStatusUploaded="{{ 'uploads.headerStatusUploaded' | translate }}"
              headerStatusUploading="{{ 'uploads.headerStatusUploading' | translate }}"
              invalidFileExtension="{{ 'uploads.invalidFileExtension' | translate }}"
              invalidMaxFileSize="{{ 'uploads.invalidMaxFileSize' | translate }}"
              invalidMinFileSize="{{ 'uploads.invalidMinFileSize' | translate }}"
              pause="{{ 'uploads.pause' | translate }}" remove="{{ 'uploads.remove' | translate }}"
              resume="{{ 'uploads.resume' | translate }}" retry="{{ 'uploads.retry' | translate }}"
              select="{{ 'uploads.select' | translate }}"
              uploadSelectedFiles="{{ 'uploads.uploadSelectedFiles' | translate }}">
            </kendo-upload-messages>
          </kendo-fileselect>
        </div>
      </kendo-label>

    </div>

    <ng-container *ngFor="let archivo of archivosModal; let i = index">
      <div class="cont-archivo">
        <div class="cont-archivo-inner">
          <span class="k-file-group-wrapper">
            <span class="k-file-group k-icon k-i-file-image"></span>
          </span>
          <span class="k-file-name-size-wrapper">
            <span class="k-file-name">{{nombresArchivosModal[i]}}</span>
          </span>
          <button type="button" class="k-button k-button-icon k-flat k-upload-action" tabindex="-1" (click)="archivoEliminado2(nombresArchivosModal[i],2)">
            <span class="k-icon k-delete k-i-x"></span>
          </button>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">{{ 'botones.cancelar' |
      translate
      }}</button>
    <button type="button" class="btn btn-primary" (click)="onClickAceptarEditar()">{{ 'botones.aceptar' | translate
      }}</button>
  </div>
</ng-template>

<!-- POPUP: Borrar -->
<ng-template #modalEliminar let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title"></h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <label>{{'botones.preguntaeliminarpopup' | translate }}</label>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="btnBorrarAceptar()">{{ 'botones.aceptar' | translate
      }}</button>
    <button type="button" class="btn btn-danger" (click)="modal.dismiss('cancel click')">{{ 'botones.cancelar' |
      translate
      }}</button>
  </div>
</ng-template>

<!-- MODAL Archivos -->
<ng-template #modalArchivos let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title"></h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <ng-container *ngFor="let archivo of modalArchivos_archivos; let i = index">
      <div class="caja-manuales" (click)="clickArchivo(archivo, modalArchivos_tipos[i], modalArchivos_nombres[i])">
        <span *ngIf="modalArchivos_tipos[i].includes('1')"><i class="fa fa-camera"></i></span>
        <span *ngIf="modalArchivos_tipos[i].includes('2')"><i class="fa fa-play"></i></span>
        <span *ngIf="modalArchivos_tipos[i].includes('3')"><i class="fa fa-file"></i></span>
        <label>{{modalArchivos_nombres[i]}}</label>
      </div>
    </ng-container>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')">{{ 'botones.cancelar' |
      translate
      }}</button>
  </div>
</ng-template>

<!-- MODAL Imagenes/Videos -->
<ng-template #modalImagenesVideos let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title"></h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="text-center" *ngIf="tipoAMostrar==1">
      <img [src]="archivoAMostrar">
    </div>
    <div class="text-center" *ngIf="tipoAMostrar==2">
      <video controls style="width:720px; height: 480px;">
        <source [src]="archivoAMostrar" type="video/mp4">
      Your browser does not support the video tag.
      </video>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="cancelarImagenVideo()">{{ 'botones.cancelar' |
      translate
      }}</button>
  </div>
</ng-template>

<router-outlet></router-outlet>