<div class="card">
  <div class="card-body">
    <div class="float-left mr-2 nolabel">
      <div class="caja">
        <div class="btn mr-1" [class.btn-primary]="es5000" [class.btn-outline-primary]="!es5000">
          <input type="radio" name="informesGroup" id="btnEstimado" class="k-radio" (click)="btn5000()"
            [checked]="es5000" kendoRadioButton>
          <label class="k-radio-label" for="btnEstimado">5000</label>
        </div>
        <div class="btn mr-1" [class.btn-primary]="!es5000" [class.btn-outline-primary]="es5000">
          <input type="radio" name="informesGroup" id="btnPredictivo" class="k-radio" (click)="btn10000()"
            [checked]="!es5000" kendoRadioButton>
          <label class="k-radio-label" for="btnPredictivo">10000</label>
        </div>
      </div>
    </div>
    <div class="float left mr-2">
      <label>{{ 'buscador.seleccionBusqueda' | translate }}</label>
      <div class="caja">
        <kendo-dropdownlist (valueChange)="cambioCombo()" [data]="listaCombo" style="width: 295px;"
          [(ngModel)]="comboSeleccionado" [textField]="'nombre'" [valueField]="'value'">
        </kendo-dropdownlist>
      </div>
    </div>
    <div class="float left mr-2 buscador-forja caja-dentro-tab" *ngIf="comboYaSeleccionado">
      <label>{{ 'buscador.valores' | translate }}</label>
      <div class="caja grupodetres">
        <div class="clearfix">
          <div class="cajaNumero">
            <kendo-numerictextbox [min]="0.0" [max]="999.0" [autoCorrect]="true" [step]="0.1" [format]="'n2'"
              [(value)]="valorGenerico1">
            </kendo-numerictextbox>
          </div>
          <div class="cajaX">x</div>
          <div class="cajaNumero">
            <kendo-numerictextbox [min]="0.0" [max]="999.0" [autoCorrect]="true" [step]="0.1" [format]="'n2'"
              [(value)]="valorGenerico2">
            </kendo-numerictextbox>
          </div>
          <div class="cajaX" *ngIf="mostrarTercerValor">x</div>
          <div class="cajaNumero" *ngIf="mostrarTercerValor">
            <kendo-numerictextbox [min]="0.0" [max]="999.0" [autoCorrect]="true" [step]="0.1" [format]="'n2'"
              [(value)]="valorGenerico3">
            </kendo-numerictextbox>
          </div>
        </div>
      </div>
    </div>
    <div class="nolabel" *ngIf="comboYaSeleccionado">
      <button kendoButton class="btn mr-1  btn-primary" (click)="onClickBuscar()">
        {{ 'buscador.buscar' | translate}}
      </button>
    </div>
  </div>
</div>

<div class="row">
  <div class="col">
    <div class="card">
      <div class="card-header">
        <h3>{{ 'buscador.equivalentes' | translate}}</h3>
        <div class="plegarpanel"></div>
      </div>
      <div class="card-body">
        <kendo-grid [kendoGridBinding]="datosFiltradosIguales" [resizable]="true" [sortable]="true" kendoGridSelectBy="id" [navigable]="true"
          filterable="menu" [selectedKeys]="datosFiltradosIgualesSelected" [selectable]="true" (cellClick)="cellClick($event)">
          <kendo-grid-checkbox-column width="5%" showSelectAll="true"></kendo-grid-checkbox-column>
          <kendo-grid-column width="10%" field="codigoPlano" title="{{ 'datosForja.codigoPlano' | translate}}"
            [style]="{'text-align': 'left'}">
          </kendo-grid-column>
          <kendo-grid-column width="10%" field="figura" title="{{ 'datosForja.figura' | translate}}"
            [style]="{'text-align': 'left'}"> </kendo-grid-column>
          <kendo-grid-column width="10%" field="equivalenciaP10000" title="{{ 'datosForja.equivalenciaP10000' | translate}}"
            [style]="{'text-align': 'left'}">
          </kendo-grid-column>
          <kendo-grid-column width="10%" field="equivalenciaP5000" title="{{ 'datosForja.equivalenciaP5000' | translate}}"
            [style]="{'text-align': 'left'}">
          </kendo-grid-column>
          <kendo-grid-column width="10%" field="equivalenciaLaminador" title="{{ 'datosForja.equivalenciaLaminador' | translate}}"
            [style]="{'text-align': 'left'}">
          </kendo-grid-column>
          <kendo-grid-column width="10%" field="personalizado" title="{{ 'buscador.medidas' | translate}}"
            [style]="{'text-align': 'left'}">
            <ng-template kendoGridCellTemplate let-dataItem>
              <p *ngIf="comboSeleccionadoFiltro == 'arandela' && es5000SeleccionadoFiltro">
                {{dataItem.arandelap5000_diametro}}x{{dataItem.arandelap5000_l}}x{{dataItem.arandelap5000_l1}}
              </p>
              <p *ngIf="comboSeleccionadoFiltro == 'arandela' && !es5000SeleccionadoFiltro">
                {{dataItem.arandelap10000_diametro}}x{{dataItem.arandelap10000_l}}x{{dataItem.arandelap10000_l1}}
              </p>
              <p *ngIf="comboSeleccionadoFiltro == 'prepunzonadoInferior1aOP'">
                {{dataItem.prepunzonadoInferior1OP_diametro}}x{{dataItem.prepunzonadoInferior1OP_altura}}
              </p>
              <p *ngIf="comboSeleccionadoFiltro == 'prepunzonadoInferior2aOP'">
                {{dataItem.prepunzonadoInferior2OP_diametro}}x{{dataItem.prepunzonadoInferior2OP_l}}
                x{{dataItem.prepunzonadoInferior2OP_l1}}
              </p>
              <p *ngIf="comboSeleccionadoFiltro == 'prepunzonadoSuperior'">
                {{dataItem.prepunzonadoSuperior_diametro}}x{{dataItem.prepunzonadoSuperior_l}}
                x{{dataItem.prepunzonadoSuperior_l1}}
              </p>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-messages filter="{{'grids.filter' | translate}}"
            filterAfterOperator="{{'grids.filterAfterOperator' | translate}}"
            filterAfterOrEqualOperator="{{'grids.filterAfterOrEqualOperator' | translate}}"
            filterAndLogic="{{'grids.filterAndLogic' | translate}}"
            filterBeforeOperator="{{'grids.filterBeforeOperator' | translate}}"
            filterBeforeOrEqualOperator="{{'grids.filterBeforeOrEqualOperator' | translate}}"
            filterBooleanAll="{{'grids.filterBooleanAll' | translate}}"
            filterClearButton="{{'filterClearButton' | translate}}"
            filterContainsOperator="{{'grids.filterContainsOperator' | translate}}"
            filterDateToday="{{'grids.filterDateToday' | translate}}"
            filterDateToggle="{{'grids.filterDateToggle' | translate}}"
            filterEndsWithOperator="{{'grids.filterEndsWithOperator' | translate}}"
            filterEqOperator="{{'grids.filterEqOperator' | translate}}"
            filterFilterButton="{{'grids.filterFilterButton' | translate}}"
            filterGtOperator="{{'grids.filterGtOperator' | translate}}"
            filterGteOperator="{{'grids.filterGteOperator' | translate}}"
            filterIsEmptyOperator="{{'grids.filterIsEmptyOperator' | translate}}"
            filterIsFalse="{{'grids.filterIsFalse' | translate}}"
            filterIsNotEmptyOperator="{{'grids.filterIsNotEmptyOperator' | translate}}"
            filterIsNotNullOperator="{{'grids.filterIsNotNullOperator' | translate}}"
            filterIsNullOperator="{{'grids.filterIsNullOperator' | translate}}"
            filterIsTrue="{{'grids.filterIsTrue' | translate}}"
            filterLtOperator="{{'grids.filterLtOperator' | translate}}"
            filterLteOperator="{{'grids.filterLteOperator' | translate}}"
            filterNotContainsOperator="{{'grids.filterNotContainsOperator' | translate}}"
            filterNotEqOperator="{{'grids.filterNotEqOperator' | translate}}"
            filterNumericDecrement="{{'grids.filterNumericDecrement' | translate}}"
            filterNumericIncrement="{{'grids.filterNumericIncrement' | translate}}"
            filterOrLogic="{{'grids.filterOrLogic' | translate}}"
            filterStartsWithOperator="{{'grids.filterStartsWithOperator' | translate}}"
            loading="{{'grids.loading' | translate}}" noRecords="{{'grids.noRecords' | translate}}"
            unlock="{{'unlock' | translate}}">
          </kendo-grid-messages>

        </kendo-grid>
      </div>
    </div>
  </div>
  <div class="col">
    <div class="card">
      <div class="card-header">
        <h3>{{ 'buscador.piezasSimilares' | translate}}</h3>
        <div class="plegarpanel"></div>
      </div>
      <div class="card-body">
        <kendo-grid [kendoGridBinding]="datosFiltradosParecidos" [resizable]="true" [sortable]="true" kendoGridSelectBy="id" [navigable]="true"
          filterable="menu" [selectedKeys]="datosFiltradosParecidosSelected" [selectable]="true" (cellClick)="cellClick($event)">
          <kendo-grid-checkbox-column width="5%" showSelectAll="true"></kendo-grid-checkbox-column>
          <kendo-grid-column width="10%" field="codigoPlano" title="{{ 'datosForja.codigoPlano' | translate}}"
            [style]="{'text-align': 'left'}">
          </kendo-grid-column>
          <kendo-grid-column width="10%" field="figura" title="{{ 'datosForja.figura' | translate}}"
            [style]="{'text-align': 'left'}"> </kendo-grid-column>
            <kendo-grid-column width="10%" field="equivalenciaP10000" title="{{ 'datosForja.equivalenciaP10000' | translate}}"
            [style]="{'text-align': 'left'}">
          </kendo-grid-column>
          <kendo-grid-column width="10%" field="equivalenciaP5000" title="{{ 'datosForja.equivalenciaP5000' | translate}}"
            [style]="{'text-align': 'left'}">
          </kendo-grid-column>
          <kendo-grid-column width="10%" field="equivalenciaLaminador" title="{{ 'datosForja.equivalenciaLaminador' | translate}}"
            [style]="{'text-align': 'left'}">
          </kendo-grid-column>
          <kendo-grid-column width="10%" field="personalizado" title="{{ 'buscador.medidas' | translate}}"
            [style]="{'text-align': 'left'}">
            <ng-template kendoGridCellTemplate let-dataItem>
              <p *ngIf="comboSeleccionadoFiltro == 'arandela' && es5000SeleccionadoFiltro">
                {{dataItem.arandelap5000_diametro}}x{{dataItem.arandelap5000_l}}x{{dataItem.arandelap5000_l1}}
              </p>
              <p *ngIf="comboSeleccionadoFiltro == 'arandela' && !es5000SeleccionadoFiltro">
                {{dataItem.arandelap10000_diametro}}x{{dataItem.arandelap10000_l}}x{{dataItem.arandelap10000_l1}}
              </p>
              <p *ngIf="comboSeleccionadoFiltro == 'prepunzonadoInferior1aOP'">
                {{dataItem.prepunzonadoInferior1OP_diametro}}x{{dataItem.prepunzonadoInferior1OP_altura}}
              </p>
              <p *ngIf="comboSeleccionadoFiltro == 'prepunzonadoInferior2aOP'">
                {{dataItem.prepunzonadoInferior2OP_diametro}}x{{dataItem.prepunzonadoInferior2OP_l}}
                x{{dataItem.prepunzonadoInferior2OP_l1}}
              </p>
              <p *ngIf="comboSeleccionadoFiltro == 'prepunzonadoSuperior'">
                {{dataItem.prepunzonadoSuperior_diametro}}x{{dataItem.prepunzonadoSuperior_l}}
                x{{dataItem.prepunzonadoSuperior_l1}}
              </p>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-messages filter="{{'grids.filter' | translate}}"
            filterAfterOperator="{{'grids.filterAfterOperator' | translate}}"
            filterAfterOrEqualOperator="{{'grids.filterAfterOrEqualOperator' | translate}}"
            filterAndLogic="{{'grids.filterAndLogic' | translate}}"
            filterBeforeOperator="{{'grids.filterBeforeOperator' | translate}}"
            filterBeforeOrEqualOperator="{{'grids.filterBeforeOrEqualOperator' | translate}}"
            filterBooleanAll="{{'grids.filterBooleanAll' | translate}}"
            filterClearButton="{{'filterClearButton' | translate}}"
            filterContainsOperator="{{'grids.filterContainsOperator' | translate}}"
            filterDateToday="{{'grids.filterDateToday' | translate}}"
            filterDateToggle="{{'grids.filterDateToggle' | translate}}"
            filterEndsWithOperator="{{'grids.filterEndsWithOperator' | translate}}"
            filterEqOperator="{{'grids.filterEqOperator' | translate}}"
            filterFilterButton="{{'grids.filterFilterButton' | translate}}"
            filterGtOperator="{{'grids.filterGtOperator' | translate}}"
            filterGteOperator="{{'grids.filterGteOperator' | translate}}"
            filterIsEmptyOperator="{{'grids.filterIsEmptyOperator' | translate}}"
            filterIsFalse="{{'grids.filterIsFalse' | translate}}"
            filterIsNotEmptyOperator="{{'grids.filterIsNotEmptyOperator' | translate}}"
            filterIsNotNullOperator="{{'grids.filterIsNotNullOperator' | translate}}"
            filterIsNullOperator="{{'grids.filterIsNullOperator' | translate}}"
            filterIsTrue="{{'grids.filterIsTrue' | translate}}"
            filterLtOperator="{{'grids.filterLtOperator' | translate}}"
            filterLteOperator="{{'grids.filterLteOperator' | translate}}"
            filterNotContainsOperator="{{'grids.filterNotContainsOperator' | translate}}"
            filterNotEqOperator="{{'grids.filterNotEqOperator' | translate}}"
            filterNumericDecrement="{{'grids.filterNumericDecrement' | translate}}"
            filterNumericIncrement="{{'grids.filterNumericIncrement' | translate}}"
            filterOrLogic="{{'grids.filterOrLogic' | translate}}"
            filterStartsWithOperator="{{'grids.filterStartsWithOperator' | translate}}"
            loading="{{'grids.loading' | translate}}" noRecords="{{'grids.noRecords' | translate}}"
            unlock="{{'unlock' | translate}}">
          </kendo-grid-messages>
        </kendo-grid>
      </div>
    </div>
  </div>
</div>

<div *ngIf="loading" class="k-i-loading"></div>