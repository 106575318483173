<header id="goiMenua" class="barratop navbar" [ngClass]="{'menu-zabalduta':isExpanded, '':isExpanded}" *ngIf="user">
  <nav class="navbar navbar-expand-sm w-100 p-0">
    <h2><span>{{menuService.titulo}}</span></h2>

    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#usermenu" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"><i class="fas fa-sliders-h"></i></span>
    </button>

    <div class="navbar-collapse collapse d-sm-inline-flex flex-sm-row-reverse" id="usermenu" [ngClass]="{ show: isExpanded }">
      <ul class="navbar-nav navbar-nav-user usermenu">

        <!--IDIOMA -->
        <li id="cidioma" class="dropdown selector-idioma">
          <span id="LidiomaActual" class="idioma-seleccionado"
                data-toggle="dropdown">{{ 'idiomaSeleccionado' | translate}}</span>
          <ul class="dropdown-menu">
            <li id="menuIdiomasES" class="">
              <a id="HLes" (click)="useLanguage('es')">ES</a>
            </li>
            <li id="menuIdiomasEU" class="">
              <a id="HLeu" (click)="useLanguage('eu')">EU</a>
            </li>
            <li id="menuIdiomasEN" class="">
              <a id="HLen" (click)="useLanguage('en')">EN</a>
            </li>
            <li id="menuIdiomasDE" class="">
              <a id="HLde" (click)="useLanguage('de')">DE</a>
            </li>
            <li id="menuIdiomasFR" class="">
              <a id="HLfr" (click)="useLanguage('fr')">FR</a>
            </li>
            <li id="menuIdiomasIT" class="">
              <a id="HLit" (click)="useLanguage('it')">IT</a>
            </li>
            <li id="menuIdiomasPT" class="">
              <a id="HLpt" (click)="useLanguage('pt')">PT</a>
            </li>
          </ul>
        </li>

        <!--TUTORIALES -->

        <!-- <li>
          <a id="HLtutoriales" title="Tutorial" class="tutoriales-nav" href="/Tutorial"> </a>
        </li> -->

        <!--MENÚ USUARIO -->

        <li class="dropdown">
          <a data-toggle="dropdown" class="nombre-usuario" *ngIf="iniciales"><span class="circulo-usuario">{{iniciales}}</span></a>

          <div class="dropdown-menu dropdown-usuario" *ngIf="iniciales">
            <div class="inner-usuario-nombre-cont clearfix" [routerLink]="['/profile']">
              <span class="inner-usuario-circulo-usuario" >{{iniciales}}</span>
              <span id="Lnombre" class="inner-usuario-nombre"> {{ user.nombre }} {{ user.apellido1 }}</span>
            </div>

            <div class="clearfix">
              <div class="inner-usuario-tipo-cont">
                <span id="" class="inner-usuario-titulo">{{ 'usuarios.tipousuario' | translate}}</span>
                <span id="Ltipo" class="inner-usuario-tipo">{{ user.usuarioTipo }}</span>
              </div>

              <div class="inner-usuario-num-cont">
                <span id="" class="inner-usuario-titulo">ID:</span>
                <span id="Lnum" class="inner-usuario-num">{{ user.id }}</span>
              </div>
            </div>

            <div class="clearfix">
              <div class="clearfix"><span class="inner-usuario-titulo">Skin</span></div>
              <div class="inner-usuario-itxura-cont">
                <div (click)="temaArgia()" class="itxura-aldaketa-argia"
                     [ngClass]="{'aukeratuta':tema == 1, '':tema != 1}"></div>
              </div>
              <div class="inner-usuario-itxura-cont">
                <div (click)="temaIluna()" class="itxura-aldaketa-iluna"
                     [ngClass]="{'aukeratuta':tema == 2, '':tema != 2}"></div>
              </div>
            </div>

            <div class="cont-linea">
              <div class="inner-usuario-logout-cont">
                <a (click)="logout()" class="btn btn-danger w-100 salir">{{ 'menu.cerrarsesion' | translate}}</a>
              </div>
            </div>

          </div>
        </li>

      </ul>
    </div>
  </nav>
</header>


<!-- Vertical navbar -->
<div id="ezkerMenua" class="barramenu" [ngClass]="{'menu-zabalduta':isExpanded, '':isExpanded}" *ngIf="user">

  <div class="logotipoa"></div>

  <div id="botonExpandir" (click)="expandir()" class="menu-zabalera-blokeatu"></div>


  <h3>{{ 'menu.principal' | translate}}</h3>

  <ul class="nav flex-column mb-0">
    <!-- <li class="nav-item" [routerLinkActive]="['link-active']">
      <a [routerLink]="['/home']" class="nav-link home-nav">
        {{ 'menu.inicio' | translate}}
      </a>
    </li> -->

    <li class="dropdown" *ngIf="user.gestionUsuarios==2">
      <a id="" class="nav-link usuarios-nav" data-toggle="dropdown">{{ 'usuarios.gestionusuarios' | translate}}</a>

      <ul class="dropdown-menu">

        <!-- <li class="nav-item" [routerLinkActive]="['link-active']" [routerLinkActiveOptions]="{ exact: true }">
          <a class="nav-link " [routerLink]="['/usuarios-tipos']">{{ 'usuarios.tiposusuario' | translate}}</a>
        </li> -->

        <li class="nav-item" [routerLinkActive]="['link-active']">
          <a class="nav-link " [routerLink]="['/usuarios']">{{ 'usuarios.usuarios' | translate}}</a>
        </li>

      </ul>
    </li>

    <li class="dropdown">
      <a id="" class="nav-link tablas-maestras-nav" data-toggle="dropdown">{{ 'tablasMaestras.titulo' | translate}}</a>

      <ul class="dropdown-menu">

        <li class="nav-item" [routerLinkActive]="['link-active']" [routerLinkActiveOptions]="{ exact: true }">
          <a class="nav-link " [routerLink]="['/placaSuperiorH15A']">{{ 'tablasMaestras.placaSuperiorH15A' | translate}}</a>
        </li>

        <li class="nav-item" [routerLinkActive]="['link-active']">
          <a class="nav-link " [routerLink]="['/placaSuperiorH30A']">{{ 'tablasMaestras.placaSuperiorH30A' | translate}}</a>
        </li>

        <li class="nav-item" [routerLinkActive]="['link-active']">
          <a class="nav-link " [routerLink]="['/subextractor10000']">{{ 'tablasMaestras.subextractor10000' | translate}}</a>
        </li>

        <li class="nav-item" [routerLinkActive]="['link-active']">
          <a class="nav-link " [routerLink]="['/basePrepunzonadoInferior10000']">{{ 'tablasMaestras.basePrepunzonadoInferior10000' | translate}}</a>
        </li>

        <li class="nav-item" [routerLinkActive]="['link-active']">
          <a class="nav-link " [routerLink]="['/mandrino10000']">{{ 'tablasMaestras.mandrino10000' | translate}}</a>
        </li>

        <li class="nav-item" [routerLinkActive]="['link-active']">
          <a class="nav-link " [routerLink]="['/poleas']">{{ 'tablasMaestras.poleas' | translate}}</a>
        </li>

        <li class="nav-item" [routerLinkActive]="['link-active']">
          <a class="nav-link " [routerLink]="['/cebollas']">{{ 'tablasMaestras.cebollas' | translate}}</a>
        </li>

        <li class="nav-item" [routerLinkActive]="['link-active']">
          <a class="nav-link " [routerLink]="['/subextractor5000']">{{ 'tablasMaestras.subextractor5000' | translate}}</a>
        </li>

        <li class="nav-item" [routerLinkActive]="['link-active']">
          <a class="nav-link " [routerLink]="['/mandrino5000']">{{ 'tablasMaestras.mandrino5000' | translate}}</a>
        </li>

        <li class="nav-item" [routerLinkActive]="['link-active']">
          <a class="nav-link " [routerLink]="['/prepunzonadoSuperior']">{{ 'tablasMaestras.prepunzonadoSuperior' | translate}}</a>
        </li>

      </ul>
    </li>

    <li class="nav-item" [routerLinkActive]="['link-active']">
      <a [routerLink]="['/datosForja']" class="nav-link datos-forja-nav">
        {{ 'datosForja.titulo' | translate}}
      </a>
    </li>

    <li class="nav-item" [routerLinkActive]="['link-active']">
      <a [routerLink]="['/buscador']" class="nav-link buscador-nav">
        {{ 'buscador.titulo' | translate}}
      </a>
    </li>

    <li class="nav-item" [routerLinkActive]="['link-active']" *ngIf="user.gestionUsuarios==2">
      <a [routerLink]="['/configuracion']" class="nav-link configuracion-nav">
        {{ 'configuracion.titulo' | translate}}
      </a>
    </li>

    <li class="nav-item" [routerLinkActive]="['link-active']" *ngIf="user.gestionUsuarios==1 || user.gestionUsuarios==2">
      <a [routerLink]="['/manuales']" class="nav-link manuales-nav">
        {{ 'manuales.titulo' | translate}}
      </a>
    </li>

    <li class="nav-item" [routerLinkActive]="['link-active']" *ngIf="user.gestionUsuarios==1 || user.gestionUsuarios==2">
      <a [routerLink]="['/catalogoForjaRuedas']" class="nav-link catalogo-forja-ruedas-nav">
        {{ 'catalogoForjaRuedas.tituloAcortado' | translate}}
      </a>
    </li>


  </ul>







  <h3 class="titulo-menu-configuracion" *ngIf="configDatos && configDatos[0].sogestIndependiente && user.gestionUsuarios==2">{{ 'gestion' | translate}}</h3>

  <ul class="nav flex-column mb-0 conf-menu" *ngIf="configDatos && configDatos[0].sogestIndependiente && user.gestionUsuarios==2">

  </ul>


  <ul class="beheko-minimenu" *ngIf="configDatos && configDatos[0].sogestIndependiente && user && user.gestionUsuarios==2">

    <li class="">
      <a id="LoginView1_HLconfiguracion" class="conf desplegar-menuconf">{{ 'configuracion' | translate}}</a>
    </li>
  </ul>

</div>




<div class="contenedor body-content" [ngClass]="{'menu-zabalduta':isExpanded, '':isExpanded}">



  <!-- subnav router outlet -->
  <router-outlet name="subnav"></router-outlet>

  <!-- global alert -->
  <alert></alert>

  <!-- main router outlet -->
  <router-outlet></router-outlet>

</div>


<div class="text-center footer" [ngClass]="{'copyright-danobat': user.idDb == idDbDanobat}" *ngIf="user">
  <p>
 
    <span class="copy-zitu"><a href="https://www.zitu.net" target="_blank">© 2022 ZITU</a></span>


  </p>

</div>
