import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AlertService, MenuService, subextractor5000Service, UsuariosTiposService } from '@app/_services';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';
import {ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-editar-crear-subextractor5000',
  templateUrl: './editar-crear-subextractor5000.component.html'
})
export class EditarCrearSubextractor5000Component implements OnInit {

  form: FormGroup;
  id: string;
  isAddMode: boolean;
  loading = false;
  submitted = false;
  navigationSubscription: any;

  constructor(
      private formBuilder: FormBuilder,
      private route: ActivatedRoute,
      private router: Router,
      private translateService: TranslateService,
      private subextractor5000Service: subextractor5000Service,
      private alertService: AlertService,
      private menuService: MenuService, private cdref: ChangeDetectorRef
  ) {
    
  }
  
  ngOnInit() { 
      this.id = this.route.snapshot.params['id'];
      this.isAddMode = !this.id;

      this.form = this.formBuilder.group({
          id: this.route.snapshot.params['id'],
          nombre: new FormControl('', [Validators.required])           
      });

      if (!this.isAddMode) {
          this.subextractor5000Service.getById(this.id)
              .pipe(first())
              .subscribe(x => this.form.patchValue(x));
      }
  }

    
  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  onSubmit() {
      this.submitted = true;

      // reset alerts on submit
      this.alertService.clear();

      // stop here if form is invalid
      if (this.form.invalid) {
          return;
      }

      this.loading = true;
      if (this.isAddMode) { 
          this.crearTipoUsuario();
      } else {
          this.updateTipoUsuario();
      }
      
  }

  private crearTipoUsuario() {
      this.subextractor5000Service.create(this.form.value.nombre)
          .pipe(first())
          .subscribe((response: any) => {
            if(response>0){
                this.alertService.success(this.translateService.instant('botones.creadocorrectamente'), { keepAfterRouteChange: true });
                this.atras();
            }else{
                this.alertService.error(this.translateService.instant('botones.errorcrear'), { keepAfterRouteChange: true });
            }
                  
              });
  }

  private updateTipoUsuario() {
      this.subextractor5000Service.update(this.form.value.nombre, this.form.value.id)
          .pipe(first())
          .subscribe((response: any) => {
            if(response>0){
                this.alertService.success(this.translateService.instant('botones.editadocorrectamente'), { keepAfterRouteChange: true });
                this.atras();
            }else{
                this.alertService.error(this.translateService.instant('botones.erroreditar'), { keepAfterRouteChange: true });
            }
        });
  }

  atras() {
    this.router.navigate(['/subextractor5000']);
  }

}
