import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Usuario } from '@app/_models';
import { Idioma } from '@app/_models/idioma';
import { AlertService, basePrepunzonadoInferior10000Service, cebollasService, mandrino10000Service, mandrino5000Service, MenuService, placaSuperiorH15AService, placaSuperiorH30AService, PlanosService, poleasService, subextractor10000Service, subextractor5000Service, UsuariosService } from '@app/_services';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { FileRestrictions } from '@progress/kendo-angular-upload';

@Component({ templateUrl: 'datosForjaDetalle.component.html' })
export class DatosForjaDetalleComponent {
  dataUsuarios: Usuario[];
  interval: number;
  selectedIdioma: Idioma;
  selectedNumber: number;
  inputTextBox: string;
  idiomaSeleccionado: string;
  id: any;
  isAddMode: any = true;

  form: FormGroup;

  submitted: any;

  dataEquivalenciasP10000: any;
  equivalenciaP10000Seleccionada: any = [];

  dataEquivalenciasP5000: any;
  equivalenciaP5000Seleccionada: any = [];

  dataEquivalenciasLaminador: any;
  equivalenciaLaminadorSeleccionada: any = [];

  errorEquivalenciaP10000 = false;

  errorEquivalenciaP5000 = false;

  errorEquivalenciaLaminador = false;

  //Datos para los tabs inferiores
  //geometria Tab
  public geometriaValueA: any;
  public geometriaValueB: any;
  public geometriaValueC: any;
  public geometriaValueD: any;
  public geometriaValueDVieja: any;
  public geometriaValueE: any;
  public geometriaValueF: any;
  public geometriaValueG: any;
  public geometriaValueH: any;
  public geometriaValueI: any;
  public geometriaValueJ: any;
  public geometriaValueL: any;
  public imagenGeometria: any;


  //p10000 tab
  //hta superior
  public dataH15AMaestro: any = [];
  public h15aMaestroSelected: any;
  public htaSupH20a: any;
  public htaSupH22a: any;
  public htaSupH51a1: any;
  public htaSupH51a2: any;

  //hta inferior
  public dataH30AMaestro: any = [];
  public h30aMaestroSelected1: any;
  public h30aMaestroSelected2: any;
  public htaInfH39a: any;
  public htaInfH40a: any;

  //Piecerio herramienta
  public piecerioHerramientaPrepunzado1aOP1: any;
  public piecerioHerramientaPrepunzado1aOP2: any;
  public piecerioHerramientaPrepunzado1aOP3: any;
  public piecerioHerramientaPrepunzado2aOP1: any;
  public piecerioHerramientaPrepunzado2aOP2: any;
  public piecerioHerramientaPrepunzado2aOP3: any;
  public piecerioHerramientaPrepunzado2aOP4: any;
  public piecerioHerramientaArandela1: any;
  public piecerioHerramientaArandela2: any;
  public piecerioHerramientaArandela3: any;
  public piecerioHerramientaArandelaResultado: any;
  public piecerioHerramientaPrepunzadoSuperior1: any;
  public piecerioHerramientaPrepunzadoSuperior2: any;
  public piecerioHerramientaPrepunzadoSuperior3: any;
  public piecerioHerramientaPrepunzadoSuperior4: any;
  public datosSubextractor10000: any;
  public piecerioHerramientaSubextractorSelected: any;
  public datosBasePrepunzonado: any;
  public piecerioHerramientaBasePrepunzonadoSelected: any;
  public dataMandrino10000: any;
  public mandrino10000Selected: any;

  public notas_p10000: any = "";
  public notas_laminador: string = "";

  //DATOS LAMINADOR TAB
  public datosCebollas: any = [];
  public cebollasSelected: any;
  public porcenVelocidadCebollas1: number;
  public porcenVelocidadCebollas2: number;
  public datosPoleas: any = [];
  public poleaSelected: any;
  public proyectoPDF_nombre: any;
  public proyectoPDF: any;
  public mostrarPDFAux = false;

  public restrictions: FileRestrictions = {
    allowedExtensions: ['.pdf'],
    maxFileSize: 10000000
  };

  //DATOS P5000
  public h60h61Value: any;
  public h70h71Value1: any;
  public h70h71Value2: any;
  public subextractorextractorValue: any;
  public arandelap50001: any;
  public arandelap50002: any;
  public arandelap50003: any;
  public arandelap50004: any;
  public notas_p5000: any = "";
  public datosSubextractor5000: any;
  public subextractor5000Selected: any;
  public subextractor5000Selected2: any;
  public dataMandrino5000: any;
  public mandrino5000Selected: any;
  public dataPrepunzonadoSuperior: any;

  //ModalImagenes
  //P10000
  public imagenModal: any;
  public imagenH15aMaestra: any;
  public imagenH20a: any;
  public imagenH22a: any;
  public imagenH51a: any;
  public imagenH30aMaestro1: any;
  public imagenH39a: any;
  public imagenH30aMaestro2: any;
  public imagenH40a: any;
  public imagenP10000PrepunzonadoInferior1OP: any;
  public imagenP10000PrepunzonadoInferior2OP: any;
  public imagenP10000Subextractor: any;
  public imagenP10000Arandela: any;
  public imagenP10000BasePrepunzonadoInferior: any;
  public imagenP10000PrepunzonadoSuperior: any;
  public imagenP10000Mandrino: any;
  public imagenP5000Mandrino: any;

  //Laminador
  public imagenCebollas: any;
  public imagenPoleas: any;

  //p5000
  public imagenp5000h60h61: any;
  public imagenp5000h70h71: any;
  public imagenp5000subextractor: any;
  public imagenp5000Arandela: any;


  //PDFS
  //P10000
  public pdfH15aMaestra: any;
  public pdfH20a: any;
  public pdfH22a: any;
  public pdfH51a: any;
  public pdfH30aMaestro1: any;
  public pdfH39a: any;
  public pdfH30aMaestro2: any;
  public pdfH40a: any;
  public pdfP10000PrepunzonadoInferior1OP: any;
  public pdfP10000PrepunzonadoInferior2OP: any;
  public pdfP10000Subextractor: any;
  public pdfP10000Arandela: any;
  public pdfP10000BasePrepunzonadoInferior: any;
  public pdfP10000PrepunzonadoSuperior: any;
  public pdfP10000Mandrino: any;
  public pdfP5000Mandrino: any;

  //Laminador
  public pdfCebollas: any;
  public pdfPoleas: any;

  //p5000
  public pdfp5000h60h61: any;
  public pdfp5000h70h71: any;
  public pdfp5000subextractor: any;
  public pdfp5000Arandela: any;

  //Modal copia
  public codigoPlanoModal: any;
  public aceptado: any;
  public errorPlanoModal: any;
  public figuraModal: any;
  public equivalenciaP10000Modal: any;
  public equivalenciaP5000Modal: any;
  public equivalenciaLaminadorModal: any;
  public errorFiguraModal: any;
  public errorEquivalenciaP10000Modal: any;
  public errorEquivalenciaP5000Modal: any;
  public errorEquivalenciaLaminadorModal: any;


  modalReference: NgbModalRef;
  @ViewChild('modalImagenes') modalImagenes: NgbModalRef;
  @ViewChild('modalCopia') modalCopia: NgbModalRef;
  @ViewChild('editarInsertarSeguro') editarInsertarSeguro: NgbModalRef;
  @ViewChild('guardarDespuesDeEditar') guardarDespuesDeEditar: NgbModalRef;


  public cambioDelSistema: any;

  public loading;

  //HOJA IMPRESION
  public checkAroForjaOk: any;
  public checkAroForjaNoOk: any;
  public checkAroForjaEsmerilar: any;
  public checkAroForjaTornear: any;
  public checkAroForjaNuevo: any;

  public checkPostizoSuperiorOk: any;
  public checkPostizoSuperiorNoOk: any;
  public checkPostizoSuperiorEsmerilar: any;
  public checkPostizoSuperiorTornear: any;
  public checkPostizoSuperiorNuevo: any;

  public checkPostizo1OPOk: any;
  public checkPostizo1OPNoOk: any;
  public checkPostizo1OPEsmerilar: any;
  public checkPostizo1OPTornear: any;
  public checkPostizo1OPNuevo: any;

  public checkPostizo2OPOk: any;
  public checkPostizo2OPNoOk: any;
  public checkPostizo2OPEsmerilar: any;
  public checkPostizo2OPTornear: any;
  public checkPostizo2OPNuevo: any;

  public checkMatrizSuperiorOk: any;
  public checkMatrizSuperiorNoOk: any;
  public checkMatrizSuperiorEsmerilar: any;
  public checkMatrizSuperiorTornear: any;
  public checkMatrizSuperiorNuevo: any;

  public checkMatrizInferiorOk: any;
  public checkMatrizInferiorNoOk: any;
  public checkMatrizInferiorEsmerilar: any;
  public checkMatrizInferiorTornear: any;
  public checkMatrizInferiorNuevo: any;

  public checkPrepunzadoSuperiorOk: any;
  public checkPrepunzadoSuperiorNoOk: any;
  public checkPrepunzadoSuperiorEsmerilar: any;
  public checkPrepunzadoSuperiorTornear: any;
  public checkPrepunzadoSuperiorNuevo: any;

  public checkPrepunzado1OPOk: any;
  public checkPrepunzado1OPNoOk: any;
  public checkPrepunzado1OPEsmerilar: any;
  public checkPrepunzado1OPTornear: any;
  public checkPrepunzado1OPNuevo: any;

  public checkPrepunzado2OPOk: any;
  public checkPrepunzado2OPNoOk: any;
  public checkPrepunzado2OPEsmerilar: any;
  public checkPrepunzado2OPTornear: any;
  public checkPrepunzado2OPNuevo: any;

  public fecha: any;

  public isCopia: any;

  constructor(private usuariosService: UsuariosService, private alertService: AlertService, private modalService: NgbModal,
    private route: ActivatedRoute, public planosService: PlanosService, private formBuilder: FormBuilder,
    private menuService: MenuService, public router: Router, private translateService: TranslateService,
    private placaSuperiorH15AService: placaSuperiorH15AService, private placaSuperiorH30Service: placaSuperiorH30AService,
    private basePrepunzonadoInferior10000Service: basePrepunzonadoInferior10000Service,
    private cebollasService: cebollasService, private mandrino5000Service: mandrino5000Service,
    private mandrino10000Service: mandrino10000Service, private poleasService: poleasService,
    private subextractor5000Service: subextractor5000Service, private subextractor10000Service: subextractor10000Service,
    private cdRef: ChangeDetectorRef) {
    this.id = this.route.snapshot.params['id'];
    this.isAddMode = (this.id == -1);
  }

  ngOnInit(): void {
    console.log(this.proyectoPDF);
    this.form = this.formBuilder.group({
      id: this.route.snapshot.params['id'],
      codigoPlano: new FormControl('', [Validators.required]),
      figura: new FormControl('', [Validators.required]),
      equivalenciaP10000: new FormControl(''),
      equivalenciaP5000: new FormControl(''),
      equivalenciaLaminador: new FormControl(''),
      notas_general: new FormControl('')
    });
    this.loading = true;
    this.isCopia = false;

    this.cargarDatosIniciales();
    this.cambioDelSistema = false;
  }

  cargarDatos(copia) {
    this.isCopia = copia;
    if (!copia) {
      if (!this.isAddMode) {
        this.planosService.getById(this.id)
          .pipe(first())
          .subscribe((result: any) => {
            var datos = result[0];
            this.form = this.formBuilder.group({
              id: this.route.snapshot.params['id'],
              codigoPlano: datos.codigoPlano,
              figura: datos.figura,
              equivalenciaP10000: datos.equivalenciaP10000,
              equivalenciaP5000: datos.equivalenciaP5000,
              equivalenciaLaminador: datos.equivalenciaLaminador,
              notas_general: datos.notas_general
            });
            this.equivalenciaP10000Seleccionada = this.dataEquivalenciasP10000.filter(
              x => x.id == datos.idEquivalenciasP10000 && x.nombre == datos.equivalenciaP10000)[0];

            this.equivalenciaP5000Seleccionada = this.dataEquivalenciasP5000.filter(
              x => x.id == datos.idEquivalenciasP5000 && x.nombre == datos.equivalenciaP5000)[0];

            this.equivalenciaLaminadorSeleccionada = this.dataEquivalenciasLaminador.filter(
              x => x.id == datos.idEquivalenciasLaminador && x.nombre == datos.equivalenciaLaminador)[0];
            //Datos tab geometria
            this.geometriaValueC = datos.c_value;
            this.geometriaValueD = datos.d_value;
            this.geometriaValueDVieja = datos.d_value_vieja;
            this.geometriaValueI = datos.i_value;
            this.geometriaValueL = datos.l_value;
            this.geometriaValueJ = datos.j_value;
            this.geometriaValueG = datos.g_value;
            this.geometriaValueF = datos.f_value;
            this.cambioDelSistema = datos.cambioDelSistema;

            //Datos tab p10000
            //hta superior
            var auxi = this.dataH15AMaestro.filter(x => x.id == datos.placah15AMaestro_id)[0];
            if (auxi == undefined)
              this.h15aMaestroSelected = { nombre: '', id: -1 };
            else {
              this.h15aMaestroSelected = auxi;
            }
            this.htaSupH20a = datos.h20a_value;
            this.htaSupH22a = datos.h22a_value;
            this.htaSupH51a1 = datos.h51a_value1;
            this.htaSupH51a2 = datos.h51a_value2;

            //hta inferior
            auxi = this.dataH30AMaestro.filter(x => x.id == datos.placah30AMaestro1_id)[0];
            if (auxi == undefined)
              this.h30aMaestroSelected1 = { nombre: '', id: -1 };
            else {
              this.h30aMaestroSelected1 = auxi;
            }

            auxi = this.dataH30AMaestro.filter(x => x.id == datos.placah30AMaestro2_id)[0];
            if (auxi == undefined)
              this.h30aMaestroSelected2 = { nombre: '', id: -1 };
            else {
              this.h30aMaestroSelected2 = auxi;
            }
            this.htaInfH39a = datos.h39a_value;
            this.htaInfH40a = datos.h40a_value;

            //Piecerio
            this.piecerioHerramientaPrepunzado1aOP1 = datos.prepunzonadoInferior1OP_diametro;
            this.piecerioHerramientaPrepunzado1aOP2 = datos.prepunzonadoInferior1OP_altura;
            this.piecerioHerramientaPrepunzado1aOP3 = datos.prepunzonadoInferior1OP_inferior;

            auxi = this.dataMandrino5000.filter(x => x.nombre == datos.prepunzonadoInferior2OP_diametro)[0];
            if (auxi != undefined)
              this.piecerioHerramientaPrepunzado2aOP1 = { nombre: datos.prepunzonadoInferior2OP_diametro, id: auxi.id };
            else
              this.piecerioHerramientaPrepunzado2aOP1 = { nombre: datos.prepunzonadoInferior2OP_diametro, id: -1 };

            this.piecerioHerramientaPrepunzado2aOP2 = datos.prepunzonadoInferior2OP_l;

            this.piecerioHerramientaPrepunzado2aOP3 = datos.prepunzonadoInferior2OP_l1;
            this.piecerioHerramientaPrepunzado2aOP4 = datos.prepunzonadoInferior2OP_inferior;

            auxi = this.datosSubextractor10000.filter(x => x.id == datos.subextractorP10000Maestro_id)[0];
            if (auxi == undefined)
              this.piecerioHerramientaSubextractorSelected = { nombre: '', id: -1 };
            else {
              this.piecerioHerramientaSubextractorSelected = auxi;
            }

            auxi = this.datosBasePrepunzonado.filter(x => x.id == datos.basePrepunzonadoInferiorMaestro_id)[0];
            if (auxi == undefined)
              this.piecerioHerramientaBasePrepunzonadoSelected = { nombre: '', id: -1 };
            else {
              this.piecerioHerramientaBasePrepunzonadoSelected = auxi;
            }

            this.piecerioHerramientaArandela1 = datos.arandelap10000_diametro;
            auxi = this.dataMandrino5000.filter(x => x.nombre == datos.arandelap10000_l)[0];
            if (auxi != undefined)
              this.piecerioHerramientaArandela2 = { nombre: datos.arandelap10000_l, id: auxi.id };
            else
              this.piecerioHerramientaArandela2 = { nombre: datos.arandelap10000_l, id: -1 };
            this.piecerioHerramientaArandela3 = datos.arandelap10000_l1;
            this.piecerioHerramientaArandelaResultado = datos.arandelap10000_inferior;

            auxi = this.dataPrepunzonadoSuperior.filter(x => x.nombre == datos.prepunzonadoSuperior_diametro)[0];
            if (auxi != undefined)
              this.piecerioHerramientaPrepunzadoSuperior1 = { nombre: datos.prepunzonadoSuperior_diametro, id: auxi.id };
            else
              this.piecerioHerramientaPrepunzadoSuperior1 = { nombre: datos.prepunzonadoSuperior_diametro, id: -1 };
            this.piecerioHerramientaPrepunzadoSuperior2 = datos.prepunzonadoSuperior_l;
            this.piecerioHerramientaPrepunzadoSuperior3 = datos.prepunzonadoSuperior_l1;
            this.piecerioHerramientaPrepunzadoSuperior4 = datos.prepunzonadoSuperior_inferior;

            auxi = this.dataMandrino10000.filter(x => x.id == datos.mandrinoMaestro10000_id)[0];
            if (auxi == undefined)
              this.mandrino10000Selected = { nombre: '', id: -1 };
            else {
              this.mandrino10000Selected = auxi;
            }

            this.notas_p10000 = datos.notas_p10000;

            //Laminador
            auxi = this.datosCebollas.filter(x => x.id == datos.cebollasMaestro_id)[0];
            if (auxi == undefined)
              this.cebollasSelected = { nombre: '', id: -1 };
            else {
              this.cebollasSelected = auxi;
            }
            this.porcenVelocidadCebollas1 = Number(datos.porcenVelocidadCebollasMin);
            this.porcenVelocidadCebollas2 = Number(datos.porcenVelocidadCebollasMax);
            auxi = this.datosPoleas.filter(x => x.id == datos.poleaMaestro_id)[0];
            if (auxi == undefined)
              this.poleaSelected = { nombre: '', id: -1 };
            else {
              this.poleaSelected = auxi;
            }
            this.proyectoPDF_nombre = datos.proyectoPDF_nombre;
            this.proyectoPDF = datos.proyectoPDF;
            if (this.proyectoPDF != "" && this.proyectoPDF != null && this.proyectoPDF != undefined)
              this.mostrarPDFAux = true;
            else
              this.mostrarPDFAux = false;

            this.notas_laminador = datos.notas_laminador;


            //p5000
            this.h60h61Value = datos.h60H61;
            this.h70h71Value1 = datos.h70H71_value1;
            this.h70h71Value2 = datos.h70H71_value2;
            this.arandelap50001 = datos.arandelap5000_diametro;
            auxi = this.dataMandrino5000.filter(x => x.nombre == datos.arandelap5000_l)[0];
            if (auxi != undefined)
              this.arandelap50002 = { nombre: datos.arandelap5000_l, id: auxi.id };
            else
              this.arandelap50002 = { nombre: datos.arandelap5000_l, id: -1 };
            this.arandelap50003 = datos.arandelap5000_l1;
            this.arandelap50004 = datos.arandelap5000_inferior;
            auxi = this.datosSubextractor5000.filter(x => x.id == datos.subextractorMaestroP5000_id)[0];
            if (auxi == undefined)
              this.subextractor5000Selected = { nombre: '', id: -1 };
            else {
              this.subextractor5000Selected = auxi;
            }
            this.subextractor5000Selected2 = datos.subextractorP5000_datos;
            auxi = this.dataMandrino5000.filter(x => x.id == datos.mandrinoMaestro5000_id)[0];
            if (auxi == undefined)
              this.mandrino5000Selected = { nombre: '', id: -1 };
            else {
              this.mandrino5000Selected = auxi;
            }
            this.notas_p5000 = datos.notas_p5000;

            //Datos para impresion
            this.fecha = new Date();

            this.loading = false;
          });
      } else {
        this.loading = false;
        this.htaSupH20a = "";
        this.htaSupH22a = "";
        this.htaSupH51a1 = "";
        this.htaSupH51a2 = "";
        this.htaInfH39a = "";
        this.htaInfH40a = "";
        this.piecerioHerramientaPrepunzado1aOP3 = "";
        this.piecerioHerramientaPrepunzado2aOP4 = "";
        this.piecerioHerramientaArandelaResultado = "";
        this.piecerioHerramientaPrepunzadoSuperior4 = "";
        this.h60h61Value = "";
        this.h70h71Value1 = "";
        this.h70h71Value2 = "";
        this.arandelap50004 = "";
        this.subextractor5000Selected2 = "";
        this.mostrarPDFAux = false;
      }
    } else { //AQUI
      this.loading = true;
      this.planosService.getById(this.id)
        .pipe(first())
        .subscribe((result: any) => {
          this.id = -1;
          var datos = result[0];
          this.form = this.formBuilder.group({
            id: this.route.snapshot.params['id'],
            codigoPlano: this.codigoPlanoModal, //datos.codigoPlano,
            figura: this.figuraModal, //datos.figura,
            equivalenciaP10000: this.equivalenciaP10000Modal,
            equivalenciaP5000: this.equivalenciaP5000Modal,
            equivalenciaLaminador: this.equivalenciaLaminadorModal,
            notas_general: ""
          });
          var auxiliar = this.dataEquivalenciasP10000.filter(x => x.nombre == this.equivalenciaP10000Modal)[0];
          if (auxiliar == undefined) {
            this.equivalenciaP10000Seleccionada = { id: -1, nombre: this.equivalenciaP10000Modal };
          } else {
            this.equivalenciaP10000Seleccionada = auxiliar;
          }

          auxiliar = this.dataEquivalenciasP5000.filter(x => x.nombre == this.equivalenciaP5000Modal)[0];
          if (auxiliar == undefined) {
            this.equivalenciaP5000Seleccionada = { id: -1, nombre: this.equivalenciaP5000Modal };
          } else {
            this.equivalenciaP5000Seleccionada = auxiliar;
          }

          auxiliar = this.dataEquivalenciasLaminador.filter(x => x.nombre == this.equivalenciaLaminadorModal)[0];
          if (auxiliar == undefined) {
            this.equivalenciaLaminadorSeleccionada = { id: -1, nombre: this.equivalenciaLaminadorModal };
          } else {
            this.equivalenciaLaminadorSeleccionada = auxiliar;
          }

          //Datos tab geometria
          console.log(datos);
          this.geometriaValueC = datos.c_value;
          this.geometriaValueD = datos.d_value;
          this.geometriaValueDVieja = datos.d_value_vieja;
          this.geometriaValueI = datos.i_value;
          this.geometriaValueL = datos.l_value;
          this.geometriaValueJ = datos.j_value;
          this.geometriaValueG = datos.g_value;
          this.geometriaValueF = datos.f_value;
          this.cambioDelSistema = datos.cambioDelSistema;

          //Datos tab p10000
          //hta superior
          var auxi = this.dataH15AMaestro.filter(x => x.id == datos.placah15AMaestro_id)[0];
          if (auxi == undefined)
            this.h15aMaestroSelected = { nombre: '', id: -1 };
          else {
            this.h15aMaestroSelected = auxi;
          }
          this.htaSupH20a = datos.h20a_value;
          this.htaSupH22a = datos.h22a_value;
          this.htaSupH51a1 = datos.h51a_value1;
          this.htaSupH51a2 = datos.h51a_value2;

          //hta inferior
          auxi = this.dataH30AMaestro.filter(x => x.id == datos.placah30AMaestro1_id)[0];
          if (auxi == undefined)
            this.h30aMaestroSelected1 = { nombre: '', id: -1 };
          else {
            this.h30aMaestroSelected1 = auxi;
          }

          auxi = this.dataH30AMaestro.filter(x => x.id == datos.placah30AMaestro2_id)[0];
          if (auxi == undefined)
            this.h30aMaestroSelected2 = { nombre: '', id: -1 };
          else {
            this.h30aMaestroSelected2 = auxi;
          }
          this.htaInfH39a = datos.h39a_value;
          this.htaInfH40a = datos.h40a_value;

          //Piecerio
          this.piecerioHerramientaPrepunzado1aOP1 = datos.prepunzonadoInferior1OP_diametro;
          this.piecerioHerramientaPrepunzado1aOP2 = datos.prepunzonadoInferior1OP_altura;
          this.piecerioHerramientaPrepunzado1aOP3 = datos.prepunzonadoInferior1OP_inferior;

          auxi = this.dataMandrino5000.filter(x => x.nombre == datos.prepunzonadoInferior2OP_diametro)[0];
          if (auxi != undefined)
            this.piecerioHerramientaPrepunzado2aOP1 = { nombre: datos.prepunzonadoInferior2OP_diametro, id: auxi.id };
          else
            this.piecerioHerramientaPrepunzado2aOP1 = { nombre: datos.prepunzonadoInferior2OP_diametro, id: -1 };

          this.piecerioHerramientaPrepunzado2aOP2 = datos.prepunzonadoInferior2OP_l;

          this.piecerioHerramientaPrepunzado2aOP3 = datos.prepunzonadoInferior2OP_l1;
          this.piecerioHerramientaPrepunzado2aOP4 = datos.prepunzonadoInferior2OP_inferior;

          auxi = this.datosSubextractor10000.filter(x => x.id == datos.subextractorP10000Maestro_id)[0];
          if (auxi == undefined)
            this.piecerioHerramientaSubextractorSelected = { nombre: '', id: -1 };
          else {
            this.piecerioHerramientaSubextractorSelected = auxi;
          }

          auxi = this.datosBasePrepunzonado.filter(x => x.id == datos.basePrepunzonadoInferiorMaestro_id)[0];
          if (auxi == undefined)
            this.piecerioHerramientaBasePrepunzonadoSelected = { nombre: '', id: -1 };
          else {
            this.piecerioHerramientaBasePrepunzonadoSelected = auxi;
          }

          this.piecerioHerramientaArandela1 = datos.arandelap10000_diametro;
          auxi = this.dataMandrino5000.filter(x => x.nombre == datos.arandelap10000_l)[0];
          if (auxi != undefined)
            this.piecerioHerramientaArandela2 = { nombre: datos.arandelap10000_l, id: auxi.id };
          else
            this.piecerioHerramientaArandela2 = { nombre: datos.arandelap10000_l, id: -1 };
          this.piecerioHerramientaArandela3 = datos.arandelap10000_l1;
          this.piecerioHerramientaArandelaResultado = datos.arandelap10000_inferior;

          auxi = this.dataPrepunzonadoSuperior.filter(x => x.nombre == datos.prepunzonadoSuperior_diametro)[0];
          if (auxi != undefined)
            this.piecerioHerramientaPrepunzadoSuperior1 = { nombre: datos.prepunzonadoSuperior_diametro, id: auxi.id };
          else
            this.piecerioHerramientaPrepunzadoSuperior1 = { nombre: datos.prepunzonadoSuperior_diametro, id: -1 };
          this.piecerioHerramientaPrepunzadoSuperior2 = datos.prepunzonadoSuperior_l;
          this.piecerioHerramientaPrepunzadoSuperior3 = datos.prepunzonadoSuperior_l1;
          this.piecerioHerramientaPrepunzadoSuperior4 = datos.prepunzonadoSuperior_inferior;

          auxi = this.dataMandrino10000.filter(x => x.id == datos.mandrinoMaestro10000_id)[0];
          if (auxi == undefined)
            this.mandrino10000Selected = { nombre: '', id: -1 };
          else {
            this.mandrino10000Selected = auxi;
          }

          this.notas_p10000 = datos.notas_p10000;

          //Laminador
          auxi = this.datosCebollas.filter(x => x.id == datos.cebollasMaestro_id)[0];
          if (auxi == undefined)
            this.cebollasSelected = { nombre: '', id: -1 };
          else {
            this.cebollasSelected = auxi;
          }
          this.porcenVelocidadCebollas1 = Number(datos.porcenVelocidadCebollasMin);
          this.porcenVelocidadCebollas2 = Number(datos.porcenVelocidadCebollasMax);
          auxi = this.datosPoleas.filter(x => x.id == datos.poleaMaestro_id)[0];
          if (auxi == undefined)
            this.poleaSelected = { nombre: '', id: -1 };
          else {
            this.poleaSelected = auxi;
          }
          this.proyectoPDF = datos.proyectoPDF;
          if (this.proyectoPDF != "" && this.proyectoPDF != null && this.proyectoPDF != undefined)
            this.mostrarPDFAux = true;
          else
            this.mostrarPDFAux = false;

          this.notas_laminador = datos.notas_laminador;
          //p5000
          this.h60h61Value = datos.h60H61;
          this.h70h71Value1 = datos.h70H71_value1;
          this.h70h71Value2 = datos.h70H71_value2;
          this.arandelap50001 = datos.arandelap5000_diametro;
          auxi = this.dataMandrino5000.filter(x => x.nombre == datos.arandelap5000_l)[0];
          if (auxi != undefined)
            this.arandelap50002 = { nombre: datos.arandelap5000_l, id: auxi.id };
          else
            this.arandelap50002 = { nombre: datos.arandelap5000_l, id: -1 };
          this.arandelap50003 = datos.arandelap5000_l1;
          this.arandelap50004 = datos.arandelap5000_inferior;
          auxi = this.datosSubextractor5000.filter(x => x.id == datos.subextractorMaestroP5000_id)[0];
          if (auxi == undefined)
            this.subextractor5000Selected = { nombre: '', id: -1 };
          else {
            this.subextractor5000Selected = auxi;
          }
          this.subextractor5000Selected2 = datos.subextractorP5000_datos;
          auxi = this.dataMandrino5000.filter(x => x.id == datos.mandrinoMaestro5000_id)[0];
          if (auxi == undefined)
            this.mandrino5000Selected = { nombre: '', id: -1 };
          else {
            this.mandrino5000Selected = auxi;
          }
          this.notas_p5000 = datos.notas_p5000;

          //Datos para impresion
          this.fecha = new Date();

          this.loading = false;
        });
    }
  }

  cargarDatosIniciales() {
    var r1, r2, r3, r4;

    this.planosService.getAllEquivalencias().pipe(first()).subscribe((result: any) => {
      this.dataEquivalenciasP10000 = result.equivalenciasP10000;
      this.dataEquivalenciasP5000 = result.equivalenciasP5000;
      this.dataEquivalenciasLaminador = result.equivalenciasLaminador;
      r1 = true;
      if (r1 && r2 && r3 && r4) {
        this.cargarDatos(false);
      }
    });

    this.planosService.getMaestrasGeometria()
      .pipe(first())
      .subscribe((result: any) => {
        this.imagenGeometria = result[0].imagenBase64;
        this.geometriaValueA = result[0].a;
        this.geometriaValueB = result[0].b;
        this.geometriaValueE = result[0].e;
        this.geometriaValueH = result[0].h;
        r2 = true;
        if (r1 && r2 && r3 && r4) {
          this.cargarDatos(false);
        }
      });

    this.planosService.getImagenesAuxiliares()
      .pipe(first())
      .subscribe((result: any) => {
        console.log(result);
        //TAB P10000
        this.imagenH15aMaestra = result.htaSuperiorH15AMaestro;
        this.pdfH15aMaestra = result.pdfHTASuperiorH15AMaestro;
        this.imagenH20a = result.htaSuperiorH20a;
        this.pdfH20a = result.pdfHTASuperiorH20a;
        this.imagenH22a = result.htaSuperiorH22a;
        this.pdfH22a = result.pdfHTASuperiorH22a;
        this.imagenH51a = result.htaSuperiorH51a;
        this.pdfH51a = result.pdfHTASuperiorH51a;

        this.imagenH30aMaestro1 = result.htaInferiorH30AMaestro1;
        this.pdfH30aMaestro1 = result.pdfHTAInferiorH30AMaestro1;
        this.imagenH39a = result.htaInferiorH39a;
        this.pdfH39a = result.pdfHTAInferiorH39a;
        this.imagenH30aMaestro2 = result.htaInferiorH30AMaestro2;
        this.pdfH30aMaestro2 = result.pdfHTAInferiorH30AMaestro2;
        this.imagenH40a = result.htaInferiorH40a;
        this.pdfH40a = result.pdfHTAInferiorH40a;

        this.imagenP10000PrepunzonadoInferior1OP = result.piecerioPrepunzonadoInferior1OP;
        this.pdfP10000PrepunzonadoInferior1OP = result.pdfPiecerioPrepunzonadoInferior1OP;
        this.imagenP10000PrepunzonadoInferior2OP = result.piecerioPrepunzonadoInferior2OP;
        this.pdfP10000PrepunzonadoInferior2OP = result.pdfPiecerioPrepunzonadoInferior2OP;
        this.imagenP10000Subextractor = result.piecerioSubextractor;
        this.pdfP10000Subextractor = result.pdfPiecerioSubextractor;
        this.imagenP10000Arandela = result.p10000Arandela;
        this.pdfP10000Arandela = result.pdfP10000Arandela;
        this.imagenP10000BasePrepunzonadoInferior = result.piecerioBasePrepunzonadoInferior;
        this.pdfP10000BasePrepunzonadoInferior = result.pdfPiecerioBasePrepunzonadoInferior;
        this.imagenP10000PrepunzonadoSuperior = result.piecerioPrepunzonadoSuperior;
        this.pdfP10000PrepunzonadoSuperior = result.pdfPiecerioPrepunzonadoSuperior;
        this.imagenP10000Mandrino = result.piecerioMandrino10000;
        this.pdfP10000Mandrino = result.pdfPiecerioMandrino10000;

        this.imagenCebollas = result.cebollas;
        this.pdfCebollas = result.pdfCebollas;
        this.imagenPoleas = result.poleas;
        this.pdfPoleas = result.pdfPoleas;

        this.imagenp5000h60h61 = result.h60h61;
        this.pdfp5000h60h61 = result.pdfh60h61;
        this.imagenp5000h70h71 = result.h70h71;
        this.pdfp5000h70h71 = result.pdfh70h71;
        this.imagenp5000subextractor = result.subextractorExtractor;
        this.pdfp5000subextractor = result.pdfsubextractorExtractor;
        this.imagenp5000Arandela = result.p5000Arandela;
        this.pdfp5000Arandela = result.pdfP5000Arandela;
        this.imagenP5000Mandrino = result.piecerioMandrino5000;
        this.pdfP5000Mandrino = result.pdfPiecerioMandrino5000;

        r3 = true;
        if (r1 && r2 && r3 && r4) {
          this.cargarDatos(false);
        }
      });


    this.planosService.getAllMaestras().pipe(first()).subscribe((result: any) => {

      this.dataH15AMaestro = result.dataH15AMaestro;
      this.dataH15AMaestro = this.dataH15AMaestro.sort((a, b) => parseInt(a.nombre) - parseInt(b.nombre)); // SORT ARRAY objeto.nombre

      this.dataH30AMaestro = result.dataH30AMaestro;
      this.dataH30AMaestro = this.dataH30AMaestro.sort((a, b) => parseInt(a.nombre) - parseInt(b.nombre));

      this.datosSubextractor10000 = result.datosSubextractor10000;

      this.datosBasePrepunzonado = result.datosBasePrepunzonado;
      this.datosBasePrepunzonado = this.datosBasePrepunzonado.sort((a, b) => parseInt(a.nombre) - parseInt(b.nombre));

      this.dataMandrino10000 = result.dataMandrino10000;
      this.dataMandrino10000 = this.dataMandrino10000.sort((a, b) => parseInt(a.nombre) - parseInt(b.nombre));

      this.datosCebollas = result.datosCebollas;
      this.datosCebollas = this.datosCebollas.sort((a, b) => parseInt(a.nombre) - parseInt(b.nombre));

      this.datosPoleas = result.datosPoleas;
      this.datosPoleas = this.datosPoleas.sort((a, b) => parseInt(a.nombre) - parseInt(b.nombre));

      this.datosSubextractor5000 = result.datosSubextractor5000;
      this.datosSubextractor5000 = this.datosSubextractor5000.sort((a, b) => parseInt(a.nombre) - parseInt(b.nombre));

      this.dataMandrino5000 = result.dataMandrino5000;
      this.dataMandrino5000 = this.dataMandrino5000.sort((a, b) => parseInt(a.nombre) - parseInt(b.nombre));

      this.dataPrepunzonadoSuperior = result.dataPrepunzonadoSuperior;
      this.dataPrepunzonadoSuperior = this.dataPrepunzonadoSuperior.sort((a, b) => parseInt(a.nombre) - parseInt(b.nombre));

      r4 = true;
      if (r1 && r2 && r3 && r4) {
        this.cargarDatos(false);
      }
    });
  }

  cambioArandelaP5000(cambio) {
    if (!this.dataMandrino5000.includes(cambio))
      this.arandelap50002 = { id: -1, nombre: cambio };
    else {
      this.arandelap50002 = cambio;
    }
  }

  cambiopiecerioHerramientaPrepunzadoSuperior1(cambio) {
    if (!this.dataPrepunzonadoSuperior.includes(cambio))
      this.piecerioHerramientaPrepunzadoSuperior1 = { id: -1, nombre: cambio };
    else {
      this.piecerioHerramientaPrepunzadoSuperior1 = cambio;
    }
  }

  cambiopiecerioHerramientaPrepunzado2aOP1(cambio) {
    if (!this.dataMandrino5000.includes(cambio))
      this.piecerioHerramientaPrepunzado2aOP1 = { id: -1, nombre: cambio };
    else {
      this.piecerioHerramientaPrepunzado2aOP1 = cambio;
    }
  }


  cambiopiecerioHerramientaArandela2(cambio) {
    if (!this.dataMandrino5000.includes(cambio))
      this.piecerioHerramientaArandela2 = { id: -1, nombre: cambio };
    else {
      this.piecerioHerramientaArandela2 = cambio;
    }
  }

  cambioEquivalenciaP10000(cambio) {
    if (!this.dataEquivalenciasP10000.includes(cambio))
      this.equivalenciaP10000Seleccionada = { id: -1, nombre: cambio };
    else {
      this.equivalenciaP10000Seleccionada = cambio;
      this.loading = true;
      this.planosService.GetByIdEquivalenciaP10000(this.equivalenciaP10000Seleccionada.id)
        .pipe(first())
        .subscribe((result: any) => {
          var datos = result[0];
          //Datos tab geometria
          this.geometriaValueC = datos.c_value;
          this.geometriaValueD = datos.d_value;
          this.geometriaValueDVieja = datos.d_value_vieja;
          this.geometriaValueI = datos.i_value;
          this.geometriaValueL = datos.l_value;
          this.geometriaValueJ = datos.j_value;
          this.geometriaValueG = datos.g_value;
          this.geometriaValueF = datos.f_value;
          this.cambioDelSistema = datos.cambioDelSistema;

          //Datos tab p10000
          //hta superior
          var auxi = this.dataH15AMaestro.filter(x => x.id == datos.placah15AMaestro_id)[0];
          if (auxi == undefined)
            this.h15aMaestroSelected = { nombre: '', id: -1 };
          else {
            this.h15aMaestroSelected = auxi;
          }
          this.htaSupH20a = datos.h20a_value;
          this.htaSupH22a = datos.h22a_value;
          this.htaSupH51a1 = datos.h51a_value1;
          this.htaSupH51a2 = datos.h51a_value2;

          //hta inferior
          auxi = this.dataH30AMaestro.filter(x => x.id == datos.placah30AMaestro1_id)[0];
          if (auxi == undefined)
            this.h30aMaestroSelected1 = { nombre: '', id: -1 };
          else {
            this.h30aMaestroSelected1 = auxi;
          }

          auxi = this.dataH30AMaestro.filter(x => x.id == datos.placah30AMaestro2_id)[0];
          if (auxi == undefined)
            this.h30aMaestroSelected2 = { nombre: '', id: -1 };
          else {
            this.h30aMaestroSelected2 = auxi;
          }
          this.htaInfH39a = datos.h39a_value;
          this.htaInfH40a = datos.h40a_value;

          //Piecerio
          this.piecerioHerramientaPrepunzado1aOP1 = datos.prepunzonadoInferior1OP_diametro;
          this.piecerioHerramientaPrepunzado1aOP2 = datos.prepunzonadoInferior1OP_altura;
          this.piecerioHerramientaPrepunzado1aOP3 = datos.prepunzonadoInferior1OP_inferior;

          auxi = this.dataMandrino5000.filter(x => x.nombre == datos.prepunzonadoInferior2OP_diametro)[0];
          if (auxi != undefined)
            this.piecerioHerramientaPrepunzado2aOP1 = { nombre: datos.prepunzonadoInferior2OP_diametro, id: auxi.id };
          else
            this.piecerioHerramientaPrepunzado2aOP1 = { nombre: datos.prepunzonadoInferior2OP_diametro, id: -1 };

          this.piecerioHerramientaPrepunzado2aOP2 = datos.prepunzonadoInferior2OP_l;

          this.piecerioHerramientaPrepunzado2aOP3 = datos.prepunzonadoInferior2OP_l1;
          this.piecerioHerramientaPrepunzado2aOP4 = datos.prepunzonadoInferior2OP_inferior;

          auxi = this.datosSubextractor10000.filter(x => x.id == datos.subextractorP10000Maestro_id)[0];
          if (auxi == undefined)
            this.piecerioHerramientaSubextractorSelected = { nombre: '', id: -1 };
          else {
            this.piecerioHerramientaSubextractorSelected = auxi;
          }

          auxi = this.datosBasePrepunzonado.filter(x => x.id == datos.basePrepunzonadoInferiorMaestro_id)[0];
          if (auxi == undefined)
            this.piecerioHerramientaBasePrepunzonadoSelected = { nombre: '', id: -1 };
          else {
            this.piecerioHerramientaBasePrepunzonadoSelected = auxi;
          }

          this.piecerioHerramientaArandela1 = datos.arandelap10000_diametro;
          auxi = this.dataMandrino5000.filter(x => x.nombre == datos.arandelap10000_l)[0];
          if (auxi != undefined)
            this.piecerioHerramientaArandela2 = { nombre: datos.arandelap10000_l, id: auxi.id };
          else
            this.piecerioHerramientaArandela2 = { nombre: datos.arandelap10000_l, id: -1 };
          this.piecerioHerramientaArandela3 = datos.arandelap10000_l1;
          this.piecerioHerramientaArandelaResultado = datos.arandelap10000_inferior;

          auxi = this.dataPrepunzonadoSuperior.filter(x => x.nombre == datos.prepunzonadoSuperior_diametro)[0];
          if (auxi != undefined)
            this.piecerioHerramientaPrepunzadoSuperior1 = { nombre: datos.prepunzonadoSuperior_diametro, id: auxi.id };
          else
            this.piecerioHerramientaPrepunzadoSuperior1 = { nombre: datos.prepunzonadoSuperior_diametro, id: -1 };
          this.piecerioHerramientaPrepunzadoSuperior2 = datos.prepunzonadoSuperior_l;
          this.piecerioHerramientaPrepunzadoSuperior3 = datos.prepunzonadoSuperior_l1;
          this.piecerioHerramientaPrepunzadoSuperior4 = datos.prepunzonadoSuperior_inferior;

          auxi = this.dataMandrino10000.filter(x => x.id == datos.mandrinoMaestro10000_id)[0];
          if (auxi == undefined)
            this.mandrino10000Selected = { nombre: '', id: -1 };
          else {
            this.mandrino10000Selected = auxi;
          }

          this.notas_p10000 = datos.notas_p10000;

          this.loading = false;
        });
    }
  }

  cambioEquivalenciaP5000(cambio) {
    if (!this.dataEquivalenciasP5000.includes(cambio))
      this.equivalenciaP5000Seleccionada = { id: -1, nombre: cambio };
    else {
      this.equivalenciaP5000Seleccionada = cambio;
      this.loading = true;
      this.planosService.GetByIdEquivalenciaP5000(this.equivalenciaP5000Seleccionada.id)
        .pipe(first())
        .subscribe((result: any) => {
          var datos = result[0];
          this.h60h61Value = datos.h60H61;
          this.h70h71Value1 = datos.h70H71_value1;
          this.h70h71Value2 = datos.h70H71_value2;
          this.arandelap50001 = datos.arandelap5000_diametro;
          auxi = this.dataMandrino5000.filter(x => x.nombre == datos.arandelap5000_l)[0];
          if (auxi != undefined)
            this.arandelap50002 = { nombre: datos.arandelap5000_l, id: auxi.id };
          else
            this.arandelap50002 = { nombre: datos.arandelap5000_l, id: -1 };
          this.arandelap50003 = datos.arandelap5000_l1;
          this.arandelap50004 = datos.arandelap5000_inferior;
          var auxi = this.datosSubextractor5000.filter(x => x.id == datos.subextractorMaestroP5000_id)[0];
          if (auxi == undefined)
            this.subextractor5000Selected = { nombre: '', id: -1 };
          else {
            this.subextractor5000Selected = auxi;
          }
          this.subextractor5000Selected2 = datos.subextractorP5000_datos;
          auxi = this.dataMandrino5000.filter(x => x.id == datos.mandrinoMaestro5000_id)[0];
          if (auxi == undefined)
            this.mandrino5000Selected = { nombre: '', id: -1 };
          else {
            this.mandrino5000Selected = auxi;
          }
          this.notas_p5000 = datos.notas_p5000;

          this.loading = false;
        });
    }
  }

  cambioEquivalenciaLaminador(cambio) {
    if (!this.dataEquivalenciasLaminador.includes(cambio))
      this.equivalenciaLaminadorSeleccionada = { id: -1, nombre: cambio };
    else {
      this.equivalenciaLaminadorSeleccionada = cambio;
      this.loading = true;
      this.planosService.GetByIdEquivalenciaLaminador(this.equivalenciaLaminadorSeleccionada.id)
        .pipe(first())
        .subscribe((result: any) => {
          var datos = result[0];
          //Laminador
          var auxi = this.datosCebollas.filter(x => x.id == datos.cebollasMaestro_id)[0];
          if (auxi == undefined)
            this.cebollasSelected = { nombre: '', id: -1 };
          else {
            this.cebollasSelected = auxi;
          }
          this.porcenVelocidadCebollas1 = Number(datos.porcenVelocidadCebollasMin);
          this.porcenVelocidadCebollas2 = Number(datos.porcenVelocidadCebollasMax);
          auxi = this.datosPoleas.filter(x => x.id == datos.poleaMaestro_id)[0];
          if (auxi == undefined)
            this.poleaSelected = { nombre: '', id: -1 };
          else {
            this.poleaSelected = auxi;
          }
          this.proyectoPDF = datos.proyectoPDF;
          if (this.proyectoPDF != "" && this.proyectoPDF != null && this.proyectoPDF != undefined)
            this.mostrarPDFAux = true;
          else
            this.mostrarPDFAux = false;

          this.notas_laminador = datos.notas_laminador;
          this.loading = false;
        });
    }
  }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  onSubmit() {
    this.submitted = true;

    if (this.equivalenciaP10000Seleccionada == null || this.equivalenciaP10000Seleccionada == undefined
      || this.equivalenciaP10000Seleccionada.length == 0) {
      this.errorEquivalenciaP10000 = true;
    } else {
      this.errorEquivalenciaP10000 = false;
    }
    if (this.equivalenciaP5000Seleccionada == null || this.equivalenciaP5000Seleccionada == undefined
      || this.equivalenciaP5000Seleccionada.length == 0) {
      this.errorEquivalenciaP5000 = true;
    } else {
      this.errorEquivalenciaP5000 = false;
    }
    if (this.equivalenciaLaminadorSeleccionada == null || this.equivalenciaLaminadorSeleccionada == undefined
      || this.equivalenciaLaminadorSeleccionada.length == 0) {
      this.errorEquivalenciaLaminador = true;
    } else {
      this.errorEquivalenciaLaminador = false;
    }

    // stop here if form is invalid
    if (this.form.invalid || this.errorEquivalenciaP10000 || this.errorEquivalenciaP5000 || this.errorEquivalenciaLaminador) {
      return;
    }

    this.form.value.equivalenciaP10000 = this.equivalenciaP10000Seleccionada.nombre;
    this.form.value.idEquivalenciaP10000 = this.equivalenciaP10000Seleccionada.id;
    this.form.value.equivalenciaP5000 = this.equivalenciaP5000Seleccionada.nombre;
    this.form.value.idEquivalenciaP5000 = this.equivalenciaP5000Seleccionada.id;
    this.form.value.equivalenciaLaminador = this.equivalenciaLaminadorSeleccionada.nombre;
    this.form.value.idEquivalenciaLaminador = this.equivalenciaLaminadorSeleccionada.id;

    if (this.isAddMode) {
      this.crearPlano();
    } else {
      this.updatePlano();
    }

  }

  crearPlano() {
    //Todos los campos excepto notas y pdfs son obligatorios, comprobar que se pongan bien
    //geometria
    if ((this.geometriaValueA == null || this.geometriaValueA == undefined) ||
      (this.geometriaValueB == null || this.geometriaValueB == undefined) ||
      (this.geometriaValueC == null || this.geometriaValueC == undefined) ||
      (this.geometriaValueD == null || this.geometriaValueD == undefined) ||
      (this.geometriaValueE == null || this, this.geometriaValueE == undefined) ||
      (this.geometriaValueF == null || this.geometriaValueF == undefined) ||
      (this.geometriaValueG == null || this.geometriaValueG == undefined) ||
      (this.geometriaValueH == null || this.geometriaValueH == undefined) ||
      (this.geometriaValueI == null || this.geometriaValueI == undefined) ||
      (this.geometriaValueJ == null || this.geometriaValueJ == undefined) ||
      (this.geometriaValueL == null || this.geometriaValueL == undefined) ||
      (this.imagenGeometria == null || this.imagenGeometria == undefined)) {
      this.alertService.error(this.translateService.instant('datosForja.valoresObligatorios'));
      return;
    }

    //p10000 tab
    if ((this.h15aMaestroSelected == null || this.h15aMaestroSelected == undefined || this.h15aMaestroSelected.id == -1) ||
      (this.htaSupH20a == null || this.htaSupH20a == undefined || this.htaSupH20a == "") ||
      (this.htaSupH22a == null || this.htaSupH22a == undefined || this.htaSupH22a == "") ||
      (this.htaSupH51a1 == null || this.htaSupH51a1 == undefined || this.htaSupH51a1 == "") ||
      (this.htaSupH51a2 == null || this.htaSupH51a2 == undefined || this.htaSupH51a2 == "") ||
      (this.h30aMaestroSelected1 == null || this.h30aMaestroSelected1 == undefined || this.h30aMaestroSelected1.id == -1) ||
      (this.h30aMaestroSelected2 == null || this.h30aMaestroSelected2 == undefined || this.h30aMaestroSelected2.id == -1) ||
      (this.htaInfH39a == null || this.htaInfH39a == undefined || this.htaInfH39a == "") ||
      (this.htaInfH40a == null || this.htaInfH40a == undefined || this.htaInfH40a == "") ||
      (this.piecerioHerramientaPrepunzado1aOP1 == null || this.piecerioHerramientaPrepunzado1aOP1 == undefined) ||
      (this.piecerioHerramientaPrepunzado1aOP2 == null || this.piecerioHerramientaPrepunzado1aOP2 == undefined) ||
      (this.piecerioHerramientaPrepunzado2aOP1 == null || this.piecerioHerramientaPrepunzado2aOP1 == undefined) ||
      (this.piecerioHerramientaPrepunzado2aOP2 == null || this.piecerioHerramientaPrepunzado2aOP2 == undefined) ||
      (this.piecerioHerramientaPrepunzado2aOP3 == null || this.piecerioHerramientaPrepunzado2aOP3 == undefined) ||
      (this.piecerioHerramientaArandela1 == null || this.piecerioHerramientaArandela1 == undefined) ||
      (this.piecerioHerramientaArandela2 == null || this.piecerioHerramientaArandela2 == undefined) ||
      (this.piecerioHerramientaArandela3 == null || this.piecerioHerramientaArandela3 == undefined) ||
      (this.piecerioHerramientaPrepunzadoSuperior1 == null || this.piecerioHerramientaPrepunzadoSuperior1 == undefined) ||
      (this.piecerioHerramientaPrepunzadoSuperior2 == null || this.piecerioHerramientaPrepunzadoSuperior2 == undefined) ||
      (this.piecerioHerramientaPrepunzadoSuperior3 == null || this.piecerioHerramientaPrepunzadoSuperior3 == undefined) ||
      (this.piecerioHerramientaSubextractorSelected == null || this.piecerioHerramientaSubextractorSelected == undefined ||
        this.piecerioHerramientaSubextractorSelected.id == -1) ||
      (this.piecerioHerramientaBasePrepunzonadoSelected == null || this.piecerioHerramientaBasePrepunzonadoSelected == undefined ||
        this.piecerioHerramientaBasePrepunzonadoSelected.id == -1) ||
      (this.mandrino10000Selected == null || this.mandrino10000Selected == undefined || this.mandrino10000Selected.id == -1)) {
      this.alertService.error(this.translateService.instant('datosForja.valoresObligatorios'));
      return;
    }

    //DATOS LAMINADOR TAB
    if ((this.cebollasSelected == null || this.cebollasSelected == undefined || this.cebollasSelected.id == -1) ||
      (this.porcenVelocidadCebollas1 == null || this.porcenVelocidadCebollas1 == undefined) ||
      (this.porcenVelocidadCebollas2 == null || this.porcenVelocidadCebollas2 == undefined) ||
      (this.poleaSelected == null || this.poleaSelected == undefined || this.poleaSelected.id == -1) //|| //AQUI PDF
      //(this.proyectoPDF == null || this.proyectoPDF == undefined)
    ) {
      this.alertService.error(this.translateService.instant('datosForja.valoresObligatorios'));
      return;
    }


    //DATOS P5000
    if ((this.h60h61Value == null || this.h60h61Value == undefined) ||
      (this.h70h71Value1 == null || this.h70h71Value1 == undefined) ||
      (this.h70h71Value2 == null || this.h70h71Value2 == undefined) ||
      (this.subextractor5000Selected == null || this.subextractor5000Selected == undefined || this.subextractor5000Selected.id == -1) ||
      (this.subextractor5000Selected2 == null || this.subextractor5000Selected2 == undefined || this.subextractor5000Selected2 == '') ||
      (this.arandelap50001 == null || this.arandelap50001 == undefined) ||
      (this.arandelap50002 == null || this.arandelap50002 == undefined) ||
      (this.arandelap50003 == null || this.arandelap50003 == undefined) ||
      (this.mandrino5000Selected == null || this.mandrino5000Selected == undefined || this.mandrino5000Selected.id == -1)) {
      this.alertService.error(this.translateService.instant('datosForja.valoresObligatorios'));
      return;
    }

    this.planosService.existeEquivalenciaIgual(this.form.value.idEquivalenciaP10000, this.form.value.idEquivalenciaP5000,
      this.form.value.idEquivalenciaLaminador, this.geometriaValueC, this.geometriaValueD,
      this.geometriaValueF, this.geometriaValueG, this.geometriaValueI,
      this.geometriaValueJ, this.geometriaValueL,
      this.h15aMaestroSelected.id, this.htaSupH20a,
      this.htaSupH22a, this.htaSupH51a1, this.htaSupH51a2,
      this.h30aMaestroSelected1.id, this.htaInfH39a, this.h30aMaestroSelected2.id, this.htaInfH40a,
      this.piecerioHerramientaPrepunzado1aOP1,
      this.piecerioHerramientaPrepunzado1aOP2,
      this.piecerioHerramientaSubextractorSelected.id,
      this.piecerioHerramientaBasePrepunzonadoSelected.id,
      this.piecerioHerramientaPrepunzado2aOP1.nombre,
      this.piecerioHerramientaPrepunzado2aOP2,
      this.piecerioHerramientaPrepunzado2aOP3,
      this.piecerioHerramientaPrepunzadoSuperior1.nombre,
      this.piecerioHerramientaPrepunzadoSuperior2,
      this.piecerioHerramientaPrepunzadoSuperior3,
      this.piecerioHerramientaArandela1,
      this.piecerioHerramientaArandela2.nombre,
      this.piecerioHerramientaArandela3,
      this.mandrino10000Selected.id, this.h60h61Value, this.h70h71Value1, this.h70h71Value2,
      this.subextractor5000Selected.id, this.arandelap50001, this.arandelap50002.nombre, this.arandelap50003,
      this.mandrino5000Selected.id,
      this.cebollasSelected.id, this.porcenVelocidadCebollas1,
      this.porcenVelocidadCebollas2, this.poleaSelected.id)
      .pipe(first())
      .subscribe((response: any) => {
        var id = this.route.snapshot.params['id'];

        if (((response.resP10000 == true || response.resP5000 == true || response.resLaminador == true) && id != -1) || ((response.resP10000 == true && response.resP5000 == true && response.resLaminador == true) && id == -1)) {
          this.alertService.error(this.translateService.instant('datosForja.yaExisteEquivalenciaIgual'), { keepAfterRouteChange: true });
        }
        else if (((response.resP10000 != true && response.resP5000 != true && response.resLaminador != true) && id != -1) || (response.resP10000 != true || response.resP5000 != true || response.resLaminador != true) && id == -1) {
          //Hay que mirar si las equivalencias actuales tambien se encuentran enlazadas con algun otro plano
          this.planosService.existeEquivalenciasEnOtroPlano(-1, this.equivalenciaP10000Seleccionada.id,
            this.equivalenciaP5000Seleccionada.id, this.equivalenciaLaminadorSeleccionada.id)
            .pipe(first())
            .subscribe((result: any) => {
              if (result == true && id != -1) {
                //Existen otros planos que comparten alguna equivalencia con la actual, sacar aviso
                this.modalReference = this.modalService.open(this.editarInsertarSeguro, { backdrop: 'static', size: 's', keyboard: false, centered: true });
              } else {
                //No existe, podemos crearla
                this.planosService.create(this.form.value.codigoPlano, this.form.value.figura, this.form.value.equivalenciaP10000,
                  this.form.value.equivalenciaP5000, this.form.value.equivalenciaLaminador,
                  this.cambioDelSistema,
                  this.geometriaValueC, this.geometriaValueD, this.geometriaValueF, this.geometriaValueG, this.geometriaValueI,
                  this.geometriaValueJ, this.geometriaValueL, this.geometriaValueDVieja,
                  this.h15aMaestroSelected.id, this.htaSupH20a, this.htaSupH22a, this.htaSupH51a1, this.htaSupH51a2,
                  this.h30aMaestroSelected1.id,
                  this.htaInfH39a, this.h30aMaestroSelected2.id, this.htaInfH40a, this.piecerioHerramientaPrepunzado1aOP1,
                  this.piecerioHerramientaPrepunzado1aOP2, this.piecerioHerramientaPrepunzado1aOP3, this.piecerioHerramientaSubextractorSelected.id,
                  this.piecerioHerramientaBasePrepunzonadoSelected.id, this.piecerioHerramientaPrepunzado2aOP1.nombre, this.piecerioHerramientaPrepunzado2aOP2,
                  this.piecerioHerramientaPrepunzado2aOP3, this.piecerioHerramientaPrepunzado2aOP4, this.piecerioHerramientaArandela1,
                  this.piecerioHerramientaArandela2.nombre, this.piecerioHerramientaArandela3, this.piecerioHerramientaArandelaResultado,
                  this.piecerioHerramientaPrepunzadoSuperior1.nombre, this.piecerioHerramientaPrepunzadoSuperior2,
                  this.piecerioHerramientaPrepunzadoSuperior3, this.piecerioHerramientaPrepunzadoSuperior4, this.mandrino10000Selected.id,
                  this.notas_p10000, this.cebollasSelected.id, this.porcenVelocidadCebollas1, this.porcenVelocidadCebollas2,
                  this.poleaSelected.id, this.proyectoPDF, this.form.value.notas_general, this.h60h61Value,
                  this.h70h71Value1, this.h70h71Value2, this.subextractor5000Selected.id, this.subextractor5000Selected2,
                  this.arandelap50001, this.arandelap50002.nombre, this.arandelap50003, this.arandelap50004, this.mandrino5000Selected.id,
                  this.notas_p5000, this.notas_laminador)
                  .pipe(first())
                  .subscribe((response2: any) => {
                    if (response2 > 0) {
                      this.alertService.success(this.translateService.instant('botones.creadocorrectamente'), { keepAfterRouteChange: true });
                      this.atras();
                    } else {
                      this.alertService.error(this.translateService.instant('botones.errorcrear'), { keepAfterRouteChange: true });
                    }
                  });
              }
            });
        }
      });
  }

  updatePlano() {
    //this.modalReference = this.modalService.open(this.guardarDespuesDeEditar, { backdrop: 'static', size: 's', keyboard: false, centered: true }); 

    //Todos los campos excepto notas y pdfs son obligatorios, comprobar que se pongan bien
    //geometria
    if ((this.geometriaValueA == null || this.geometriaValueA == undefined) ||
      (this.geometriaValueB == null || this.geometriaValueB == undefined) ||
      (this.geometriaValueC == null || this.geometriaValueC == undefined) ||
      (this.geometriaValueD == null || this.geometriaValueD == undefined) ||
      (this.geometriaValueE == null || this, this.geometriaValueE == undefined) ||
      (this.geometriaValueF == null || this.geometriaValueF == undefined) ||
      (this.geometriaValueG == null || this.geometriaValueG == undefined) ||
      (this.geometriaValueH == null || this.geometriaValueH == undefined) ||
      (this.geometriaValueI == null || this.geometriaValueI == undefined) ||
      (this.geometriaValueJ == null || this.geometriaValueJ == undefined) ||
      (this.geometriaValueL == null || this.geometriaValueL == undefined) ||
      (this.imagenGeometria == null || this.imagenGeometria == undefined)) {
      this.alertService.error(this.translateService.instant('datosForja.valoresObligatorios'));
      return;
    }

    //p10000 tab
    if ((this.h15aMaestroSelected == null || this.h15aMaestroSelected == undefined || this.h15aMaestroSelected.id == -1) ||
      (this.htaSupH20a == null || this.htaSupH20a == undefined || this.htaSupH20a == "") ||
      (this.htaSupH22a == null || this.htaSupH22a == undefined || this.htaSupH22a == "") ||
      (this.htaSupH51a1 == null || this.htaSupH51a1 == undefined || this.htaSupH51a1 == "") ||
      (this.htaSupH51a2 == null || this.htaSupH51a2 == undefined || this.htaSupH51a2 == "") ||
      (this.h30aMaestroSelected1 == null || this.h30aMaestroSelected1 == undefined || this.h30aMaestroSelected1.id == -1) ||
      (this.h30aMaestroSelected2 == null || this.h30aMaestroSelected2 == undefined || this.h30aMaestroSelected2.id == -1) ||
      (this.htaInfH39a == null || this.htaInfH39a == undefined || this.htaInfH39a == "") ||
      (this.htaInfH40a == null || this.htaInfH40a == undefined || this.htaInfH40a == "") ||
      (this.piecerioHerramientaPrepunzado1aOP1 == null || this.piecerioHerramientaPrepunzado1aOP1 == undefined) ||
      (this.piecerioHerramientaPrepunzado1aOP2 == null || this.piecerioHerramientaPrepunzado1aOP2 == undefined) ||
      (this.piecerioHerramientaPrepunzado2aOP1 == null || this.piecerioHerramientaPrepunzado2aOP1 == undefined) ||
      (this.piecerioHerramientaPrepunzado2aOP2 == null || this.piecerioHerramientaPrepunzado2aOP2 == undefined) ||
      (this.piecerioHerramientaPrepunzado2aOP3 == null || this.piecerioHerramientaPrepunzado2aOP3 == undefined) ||
      (this.piecerioHerramientaArandela1 == null || this.piecerioHerramientaArandela1 == undefined) ||
      (this.piecerioHerramientaArandela2 == null || this.piecerioHerramientaArandela2 == undefined) ||
      (this.piecerioHerramientaArandela3 == null || this.piecerioHerramientaArandela3 == undefined) ||
      (this.piecerioHerramientaPrepunzadoSuperior1 == null || this.piecerioHerramientaPrepunzadoSuperior1 == undefined) ||
      (this.piecerioHerramientaPrepunzadoSuperior2 == null || this.piecerioHerramientaPrepunzadoSuperior2 == undefined) ||
      (this.piecerioHerramientaPrepunzadoSuperior3 == null || this.piecerioHerramientaPrepunzadoSuperior3 == undefined) ||
      (this.piecerioHerramientaSubextractorSelected == null || this.piecerioHerramientaSubextractorSelected == undefined ||
        this.piecerioHerramientaSubextractorSelected.id == -1) ||
      (this.piecerioHerramientaBasePrepunzonadoSelected == null || this.piecerioHerramientaBasePrepunzonadoSelected == undefined ||
        this.piecerioHerramientaBasePrepunzonadoSelected.id == -1) ||
      (this.mandrino10000Selected == null || this.mandrino10000Selected == undefined || this.mandrino10000Selected.id == -1)) {
      this.alertService.error(this.translateService.instant('datosForja.valoresObligatorios'));
      return;
    }

    //DATOS LAMINADOR TAB
    if ((this.cebollasSelected == null || this.cebollasSelected == undefined || this.cebollasSelected.id == -1) ||
      (this.porcenVelocidadCebollas1 == null || this.porcenVelocidadCebollas1 == undefined) ||
      (this.porcenVelocidadCebollas2 == null || this.porcenVelocidadCebollas2 == undefined) ||
      (this.poleaSelected == null || this.poleaSelected == undefined || this.poleaSelected.id == -1) //||
      //(this.proyectoPDF == null || this.proyectoPDF == undefined)
    ) {
      this.alertService.error(this.translateService.instant('datosForja.valoresObligatorios'));
      return;
    }


    //DATOS P5000
    if ((this.h60h61Value == null || this.h60h61Value == undefined) ||
      (this.h70h71Value1 == null || this.h70h71Value1 == undefined) ||
      (this.h70h71Value2 == null || this.h70h71Value2 == undefined) ||
      (this.subextractor5000Selected == null || this.subextractor5000Selected == undefined || this.subextractor5000Selected.id == -1) ||
      (this.subextractor5000Selected2 == null || this.subextractor5000Selected2 == undefined || this.subextractor5000Selected2 == '') ||
      (this.arandelap50001 == null || this.arandelap50001 == undefined) ||
      (this.arandelap50001 == null || this.arandelap50001 == undefined) ||
      (this.arandelap50002 == null || this.arandelap50002 == undefined) ||
      (this.arandelap50003 == null || this.arandelap50003 == undefined) ||
      (this.mandrino5000Selected == null || this.mandrino5000Selected == undefined || this.mandrino5000Selected.id == -1)) {
      this.alertService.error(this.translateService.instant('datosForja.valoresObligatorios'));
      return;
    }

    this.planosService.existeEquivalenciaIgual(this.form.value.idEquivalenciaP10000, this.form.value.idEquivalenciaP5000,
      this.form.value.idEquivalenciaLaminador, this.geometriaValueC, this.geometriaValueD,
      this.geometriaValueF, this.geometriaValueG, this.geometriaValueI,
      this.geometriaValueJ, this.geometriaValueL,
      this.h15aMaestroSelected.id, this.htaSupH20a,
      this.htaSupH22a, this.htaSupH51a1, this.htaSupH51a2,
      this.h30aMaestroSelected1.id, this.htaInfH39a, this.h30aMaestroSelected2.id, this.htaInfH40a,
      this.piecerioHerramientaPrepunzado1aOP1,
      this.piecerioHerramientaPrepunzado1aOP2,
      this.piecerioHerramientaSubextractorSelected.id,
      this.piecerioHerramientaBasePrepunzonadoSelected.id,
      this.piecerioHerramientaPrepunzado2aOP1.nombre,
      this.piecerioHerramientaPrepunzado2aOP2,
      this.piecerioHerramientaPrepunzado2aOP3,
      this.piecerioHerramientaPrepunzadoSuperior1.nombre,
      this.piecerioHerramientaPrepunzadoSuperior2,
      this.piecerioHerramientaPrepunzadoSuperior3,
      this.piecerioHerramientaArandela1,
      this.piecerioHerramientaArandela2.nombre,
      this.piecerioHerramientaArandela3,
      this.mandrino10000Selected.id, this.h60h61Value, this.h70h71Value1, this.h70h71Value2,
      this.subextractor5000Selected.id, this.arandelap50001, this.arandelap50002.nombre, this.arandelap50003,
      this.mandrino5000Selected.id,
      this.cebollasSelected.id, this.porcenVelocidadCebollas1,
      this.porcenVelocidadCebollas2, this.poleaSelected.id)
      .pipe(first())
      .subscribe((response: any) => {
        if (response.resP10000 == false || response.resP5000 == false || response.resLaminador == false) {
          this.modalReference = this.modalService.open(this.editarInsertarSeguro, { backdrop: 'static', size: 's', keyboard: false, centered: true });
          //this.alertService.error(this.translateService.instant('datosForja.yaExisteEquivalenciaIgual'), { keepAfterRouteChange: true });
        }//No existe, podemos actualizarla
        else {//if(response.resP10000!=true && response.resP5000!=true && response.resLaminador!=true){
          //Hay que mirar si las equivalencias actuales tambien se encuentran enlazadas con algun otro plano
          this.planosService.existeEquivalenciasEnOtroPlano(this.id, this.equivalenciaP10000Seleccionada.id,
            this.equivalenciaP5000Seleccionada.id, this.equivalenciaLaminadorSeleccionada.id)
            .pipe(first())
            .subscribe((result: any) => {
              //if(result==true){
              //Existen otros planos que comparten alguna equivalencia con la actual, sacar aviso
              //"preguntaSeguroPopUp": "Una de las equivalencias que has editado también está enlazada a otros planos, ¿estás seguro de querer editarla?",

              //}else{
              //Actualizar
              this.planosService.update(this.form.value.codigoPlano, this.form.value.figura, this.form.value.equivalenciaP10000,
                this.id,
                this.form.value.idEquivalenciaP10000, this.form.value.equivalenciaP5000, this.form.value.idEquivalenciaP5000,
                this.form.value.equivalenciaLaminador, this.form.value.idEquivalenciaLaminador,
                this.cambioDelSistema,
                this.geometriaValueC, this.geometriaValueD, this.geometriaValueF, this.geometriaValueG, this.geometriaValueI,
                this.geometriaValueJ, this.geometriaValueL, this.geometriaValueDVieja,
                this.h15aMaestroSelected.id, this.htaSupH20a, this.htaSupH22a, this.htaSupH51a1, this.htaSupH51a2,
                this.h30aMaestroSelected1.id,
                this.htaInfH39a, this.h30aMaestroSelected2.id, this.htaInfH40a, this.piecerioHerramientaPrepunzado1aOP1,
                this.piecerioHerramientaPrepunzado1aOP2, this.piecerioHerramientaPrepunzado1aOP3, this.piecerioHerramientaSubextractorSelected.id,
                this.piecerioHerramientaBasePrepunzonadoSelected.id, this.piecerioHerramientaPrepunzado2aOP1.nombre, this.piecerioHerramientaPrepunzado2aOP2,
                this.piecerioHerramientaPrepunzado2aOP3, this.piecerioHerramientaPrepunzado2aOP4, this.piecerioHerramientaArandela1,
                this.piecerioHerramientaArandela2.nombre, this.piecerioHerramientaArandela3, this.piecerioHerramientaArandelaResultado,
                this.piecerioHerramientaPrepunzadoSuperior1.nombre, this.piecerioHerramientaPrepunzadoSuperior2,
                this.piecerioHerramientaPrepunzadoSuperior3, this.piecerioHerramientaPrepunzadoSuperior4, this.mandrino10000Selected.id,
                this.notas_p10000, this.cebollasSelected.id, this.porcenVelocidadCebollas1, this.porcenVelocidadCebollas2,
                this.poleaSelected.id, this.proyectoPDF, this.form.value.notas_general, this.h60h61Value,
                this.h70h71Value1, this.h70h71Value2, this.subextractor5000Selected.id, this.subextractor5000Selected2,
                this.arandelap50001, this.arandelap50002.nombre, this.arandelap50003, this.arandelap50004, this.mandrino5000Selected.id,
                this.notas_p5000, this.notas_laminador)
                .pipe(first())
                .subscribe((response2: any) => {
                  if (response2 > 0) {
                    this.alertService.success(this.translateService.instant('botones.editadocorrectamente'), { keepAfterRouteChange: true });
                    this.atras();
                  } else {
                    this.alertService.error(this.translateService.instant('botones.erroreditar'), { keepAfterRouteChange: true });
                  }
                });
              //}
            });

        }
      });
  }

  aceptarEditarInsertar() {
    this.modalReference.close();
    this.loading = true;
    if (Number(this.id) != -1) {
      //Es actualizar
      this.planosService.update(this.form.value.codigoPlano, this.form.value.figura, this.form.value.equivalenciaP10000,
        this.id,
        this.form.value.idEquivalenciaP10000, this.form.value.equivalenciaP5000, this.form.value.idEquivalenciaP5000,
        this.form.value.equivalenciaLaminador, this.form.value.idEquivalenciaLaminador,
        this.cambioDelSistema,
        this.geometriaValueC, this.geometriaValueD, this.geometriaValueF, this.geometriaValueG, this.geometriaValueI,
        this.geometriaValueJ, this.geometriaValueL, this.geometriaValueDVieja,
        this.h15aMaestroSelected.id, this.htaSupH20a, this.htaSupH22a, this.htaSupH51a1, this.htaSupH51a2,
        this.h30aMaestroSelected1.id,
        this.htaInfH39a, this.h30aMaestroSelected2.id, this.htaInfH40a, this.piecerioHerramientaPrepunzado1aOP1,
        this.piecerioHerramientaPrepunzado1aOP2, this.piecerioHerramientaPrepunzado1aOP3, this.piecerioHerramientaSubextractorSelected.id,
        this.piecerioHerramientaBasePrepunzonadoSelected.id, this.piecerioHerramientaPrepunzado2aOP1.nombre, this.piecerioHerramientaPrepunzado2aOP2,
        this.piecerioHerramientaPrepunzado2aOP3, this.piecerioHerramientaPrepunzado2aOP4, this.piecerioHerramientaArandela1,
        this.piecerioHerramientaArandela2.nombre, this.piecerioHerramientaArandela3, this.piecerioHerramientaArandelaResultado,
        this.piecerioHerramientaPrepunzadoSuperior1.nombre, this.piecerioHerramientaPrepunzadoSuperior2,
        this.piecerioHerramientaPrepunzadoSuperior3, this.piecerioHerramientaPrepunzadoSuperior4, this.mandrino10000Selected.id,
        this.notas_p10000, this.cebollasSelected.id, this.porcenVelocidadCebollas1, this.porcenVelocidadCebollas2,
        this.poleaSelected.id, this.proyectoPDF, this.form.value.notas_general, this.h60h61Value,
        this.h70h71Value1, this.h70h71Value2, this.subextractor5000Selected.id, this.subextractor5000Selected2,
        this.arandelap50001, this.arandelap50002.nombre, this.arandelap50003, this.arandelap50004, this.mandrino5000Selected.id,
        this.notas_p5000, this.notas_laminador)
        .pipe(first())
        .subscribe((response2: any) => {
          if (response2 > 0) {
            this.alertService.success(this.translateService.instant('botones.editadocorrectamente'), { keepAfterRouteChange: true });
            this.loading = false;
            this.atras();
          } else {
            this.loading = false;
            this.alertService.error(this.translateService.instant('botones.erroreditar'), { keepAfterRouteChange: true });
          }
        });
    } else {
      //es insertar
      this.planosService.create(this.form.value.codigoPlano, this.form.value.figura, this.form.value.equivalenciaP10000,
        this.form.value.equivalenciaP5000, this.form.value.equivalenciaLaminador,
        this.cambioDelSistema,
        this.geometriaValueC, this.geometriaValueD, this.geometriaValueF, this.geometriaValueG, this.geometriaValueI,
        this.geometriaValueJ, this.geometriaValueL, this.geometriaValueDVieja,
        this.h15aMaestroSelected.id, this.htaSupH20a, this.htaSupH22a, this.htaSupH51a1, this.htaSupH51a2,
        this.h30aMaestroSelected1.id,
        this.htaInfH39a, this.h30aMaestroSelected2.id, this.htaInfH40a, this.piecerioHerramientaPrepunzado1aOP1,
        this.piecerioHerramientaPrepunzado1aOP2, this.piecerioHerramientaPrepunzado1aOP3, this.piecerioHerramientaSubextractorSelected.id,
        this.piecerioHerramientaBasePrepunzonadoSelected.id, this.piecerioHerramientaPrepunzado2aOP1.nombre, this.piecerioHerramientaPrepunzado2aOP2,
        this.piecerioHerramientaPrepunzado2aOP3, this.piecerioHerramientaPrepunzado2aOP4, this.piecerioHerramientaArandela1,
        this.piecerioHerramientaArandela2.nombre, this.piecerioHerramientaArandela3, this.piecerioHerramientaArandelaResultado,
        this.piecerioHerramientaPrepunzadoSuperior1.nombre, this.piecerioHerramientaPrepunzadoSuperior2,
        this.piecerioHerramientaPrepunzadoSuperior3, this.piecerioHerramientaPrepunzadoSuperior4, this.mandrino10000Selected.id,
        this.notas_p10000, this.cebollasSelected.id, this.porcenVelocidadCebollas1, this.porcenVelocidadCebollas2,
        this.poleaSelected.id, this.proyectoPDF, this.form.value.notas_general, this.h60h61Value,
        this.h70h71Value1, this.h70h71Value2, this.subextractor5000Selected.id, this.subextractor5000Selected2,
        this.arandelap50001, this.arandelap50002.nombre, this.arandelap50003, this.arandelap50004, this.mandrino5000Selected.id,
        this.notas_p5000, this.notas_laminador)
        .pipe(first())
        .subscribe((response2: any) => {
          if (response2 > 0) {
            this.alertService.success(this.translateService.instant('botones.creadocorrectamente'), { keepAfterRouteChange: true });
            this.loading = false;
            this.atras();
          } else {
            this.loading = false;
            this.alertService.error(this.translateService.instant('botones.errorcrear'), { keepAfterRouteChange: true });
          }
        });
    }
  }

  onClickCopia() {
    this.figuraModal = this.form.value.figura;
    this.codigoPlanoModal = this.form.value.codigoPlano;
    this.equivalenciaP10000Modal = this.equivalenciaP10000Seleccionada.nombre;
    this.equivalenciaP5000Modal = this.equivalenciaP5000Seleccionada.nombre;
    this.equivalenciaLaminadorModal = this.equivalenciaLaminadorSeleccionada.nombre;
    this.errorFiguraModal = false;
    this.errorPlanoModal = false;
    this.errorEquivalenciaP10000Modal = false;
    this.errorEquivalenciaP5000Modal = false;
    this.errorEquivalenciaLaminadorModal = false;
    this.modalReference = this.modalService.open(this.modalCopia, { backdrop: 'static', size: 'xxl', keyboard: false, centered: true });
  }

  btnCrearCopiaAceptar() {
    this.aceptado = true;

    if (this.codigoPlanoModal == null || this.codigoPlanoModal == undefined ||
      this.codigoPlanoModal == "") {
      this.errorPlanoModal = true;
    } else {
      this.errorPlanoModal = false;
    }

    if (this.figuraModal == null || this.figuraModal == undefined ||
      this.figuraModal == "") {
      this.errorFiguraModal = true;
    } else {
      this.errorFiguraModal = false;
    }

    if (this.equivalenciaP10000Modal == null || this.equivalenciaP10000Modal == undefined ||
      this.equivalenciaP10000Modal == "") {
      this.errorEquivalenciaP10000Modal = true;
    } else {
      this.errorEquivalenciaP10000Modal = false;
    }

    if (this.equivalenciaP5000Modal == null || this.equivalenciaP5000Modal == undefined ||
      this.equivalenciaP5000Modal == "") {
      this.errorEquivalenciaP5000Modal = true;
    } else {
      this.errorEquivalenciaP5000Modal = false;
    }

    if (this.equivalenciaLaminadorModal == null || this.equivalenciaLaminadorModal == undefined ||
      this.equivalenciaLaminadorModal == "") {
      this.errorEquivalenciaLaminadorModal = true;
    } else {
      this.errorEquivalenciaLaminadorModal = false;
    }

    //Varias posibilidades, al menos se debe cambiar un dato, o bien una de las tres equivalencia o el plano (con cambiar el nombre de uno de los dos bastaria
    //pues asi ya se agregaria uno nuevo en la base de datos)

    //Primero comprobar si todos los datos son iguales, no se puede crear una copia con todos los mismos nombres
    if (this.figuraModal == this.form.value.figura && this.codigoPlanoModal == this.form.value.codigoPlano
      && this.equivalenciaP10000Modal == this.equivalenciaP10000Seleccionada.nombre
      && this.equivalenciaP5000Modal == this.equivalenciaP5000Seleccionada.nombre
      && this.equivalenciaLaminadorModal == this.equivalenciaLaminadorSeleccionada.nombre) {
      this.alertService.error(this.translateService.instant('datosForja.codigoPlanoFiguraExiste'), { keepAfterRouteChange: true });
    }
    else if (this.equivalenciaP10000Modal == this.equivalenciaP10000Seleccionada.nombre
      && this.equivalenciaP5000Modal == this.equivalenciaP5000Seleccionada.nombre
      && this.equivalenciaLaminadorModal == this.equivalenciaLaminadorSeleccionada.nombre) {
      //Es una copia del enlace, que tendrá distinto codigo plano y figura, pero misma equivalencia
      this.planosService.createEquivalenciaCopia(this.codigoPlanoModal, this.figuraModal,
        this.equivalenciaP10000Seleccionada.id,
        this.equivalenciaP5000Seleccionada.id,
        this.equivalenciaLaminadorSeleccionada.id).pipe(first())
        .subscribe((response: any) => {
          if (response < 0) {
            this.alertService.error(this.translateService.instant('botones.errorcrear'), { keepAfterRouteChange: true });
          } else {
            this.alertService.success(this.translateService.instant('botones.creadocorrectamente'), { keepAfterRouteChange: true });
            this.atras();
            this.modalReference.close();
          }
        });
    }
    else {
      //Crearemos una nueva como si estuviesemos creando una nueva habiendo seleccionado antes alguna equivalencia
      this.modalReference.close();
      this.router.navigate(['/datosForja/editar/-1']);
      this.isAddMode = true;
      this.cargarDatos(true);
    }
    /* if(this.equivalenciaSeleccionada.equivalencia == this.equivalenciaModal){
      //Es una copia del enlace, que tendrá distinto codigo plano y figura, pero misma equivalencia
      this.planosService.existeCodigoPlanoFigura(this.codigoPlanoModal, this.figuraModal).pipe(first())
      .subscribe((response: any) => {
        if(response==true){
          this.alertService.error(this.translateService.instant('datosForja.codigoPlanoFiguraExiste'), { keepAfterRouteChange: true });
        }else{
          this.planosService.createEquivalenciaCopia(this.codigoPlanoModal, this.figuraModal, 
            this.equivalenciaSeleccionada.equivalencia, this.equivalenciaSeleccionada.id, this.cambioDelSistema, this.geometriaValueC, this.geometriaValueD, this.geometriaValueF, this.geometriaValueG, this.geometriaValueI, 
            this.geometriaValueJ, this.geometriaValueL).pipe(first())
          .subscribe((response: any) => {
            if(response<0){
              this.alertService.error(this.translateService.instant('botones.errorcrear'), { keepAfterRouteChange: true });
            }else{
              this.alertService.success(this.translateService.instant('botones.creadocorrectamente'), { keepAfterRouteChange: true });
              this.atras();
              this.modalReference.close();
            }
          });
        }
      });
    }else{
      this.planosService.existeEquivalencia(this.equivalenciaModal).pipe(first())
      .subscribe((response: any) => {
        if(response==true){
          this.alertService.error(this.translateService.instant('datosForja.equivalenciaExiste'), { keepAfterRouteChange: true });
        }else{
          this.modalReference.close();
          this.router.navigate(['/datosForja/editar/-1']);
          this.isAddMode=true;
          this.cargarDatos(true); 
        }
      });
    } */


  }

  atras() {
    this.router.navigate(['/datosForja']);
  }

  abrirModalImagen(imagen) {
    switch (imagen) {
      case "imagenH15aMaestra": {
        this.imagenModal = this.imagenH15aMaestra;
        break;
      }
      case "imagenH20a": {
        this.imagenModal = this.imagenH20a;
        break;
      }
      case "imagenH22a": {
        this.imagenModal = this.imagenH22a;
        break;
      }
      case "imagenH51a": {
        this.imagenModal = this.imagenH51a;
        break;
      }
      case "imagenH30aMaestro1": {
        this.imagenModal = this.imagenH30aMaestro1;
        break;
      }
      case "imagenH39a": {
        this.imagenModal = this.imagenH39a;
        break;
      }
      case "imagenH30aMaestro2": {
        this.imagenModal = this.imagenH30aMaestro2;
        break;
      }
      case "imagenH40a": {
        this.imagenModal = this.imagenH40a;
        break;
      }
      case "imagenP10000PrepunzonadoInferior1OP": {
        this.imagenModal = this.imagenP10000PrepunzonadoInferior1OP;
        break;
      }
      case "imagenP10000PrepunzonadoInferior2OP": {
        this.imagenModal = this.imagenP10000PrepunzonadoInferior2OP;
        break;
      }
      case "imagenP10000Subextractor": {
        this.imagenModal = this.imagenP10000Subextractor;
        break;
      }
      case "imagenP10000Arandela": {
        this.imagenModal = this.imagenP10000Arandela;
        break;
      }
      case "imagenP10000BasePrepunzonadoInferior": {
        this.imagenModal = this.imagenP10000BasePrepunzonadoInferior;
        break;
      }

      case "imagenP10000PrepunzonadoSuperior": {
        this.imagenModal = this.imagenP10000PrepunzonadoSuperior;
        break;
      }

      case "imagenP10000Mandrino": {
        this.imagenModal = this.imagenP10000Mandrino;
        break;
      }

      case "cebollas": {
        this.imagenModal = this.imagenCebollas;
        break;
      }

      case "poleas": {
        this.imagenModal = this.imagenPoleas;
        break;
      }

      case "imagenp5000h60h61": {
        this.imagenModal = this.imagenp5000h60h61;
        break;
      }

      case "imagenp5000h70h71": {
        this.imagenModal = this.imagenp5000h70h71;
        break;
      }

      case "imagenp5000subextractor": {
        this.imagenModal = this.imagenp5000subextractor;
        break;
      }

      case "imagenp5000Arandela": {
        this.imagenModal = this.imagenp5000Arandela;
        break;
      }
      case "imagenP5000Mandrino": {
        this.imagenModal = this.imagenP5000Mandrino;
        break;
      }

      default: {
        break;
      }
    }
    this.modalReference = this.modalService.open(this.modalImagenes, { backdrop: 'static', size: 'xxl', keyboard: false, centered: true });
  }

  abrirPDF(pdf) {
    var auxi = "";
    switch (pdf) {
      case "pdfH15aMaestra": {
        auxi = this.pdfH15aMaestra;
        break;
      }
      case "pdfH20a": {
        auxi = this.pdfH20a;
        break;
      }
      case "pdfH22a": {
        auxi = this.pdfH22a;
        break;
      }
      case "pdfH51a": {
        auxi = this.pdfH51a;
        break;
      }
      case "pdfH30aMaestro1": {
        auxi = this.pdfH30aMaestro1;
        break;
      }
      case "pdfH39a": {
        auxi = this.pdfH39a;
        break;
      }
      case "pdfH30aMaestro2": {
        auxi = this.pdfH30aMaestro2;
        break;
      }
      case "pdfH40a": {
        auxi = this.pdfH40a;
        break;
      }
      case "pdfP10000PrepunzonadoInferior1OP": {
        auxi = this.pdfP10000PrepunzonadoInferior1OP;
        break;
      }
      case "pdfP10000PrepunzonadoInferior2OP": {
        auxi = this.pdfP10000PrepunzonadoInferior2OP;
        break;
      }
      case "pdfP10000Subextractor": {
        auxi = this.pdfP10000Subextractor;
        break;
      }
      case "pdfP10000Arandela": {
        auxi = this.pdfP10000Arandela;
        break;
      }
      case "pdfP10000BasePrepunzonadoInferior": {
        auxi = this.pdfP10000BasePrepunzonadoInferior;
        break;
      }

      case "pdfP10000PrepunzonadoSuperior": {
        auxi = this.pdfP10000PrepunzonadoSuperior;
        break;
      }

      case "pdfP10000Mandrino": {
        auxi = this.pdfP10000Mandrino;
        break;
      }

      case "pdfcebollas": {
        auxi = this.pdfCebollas;
        break;
      }

      case "pdfpoleas": {
        auxi = this.pdfPoleas;
        break;
      }

      case "pdfp5000h60h61": {
        auxi = this.pdfp5000h60h61;
        break;
      }

      case "pdfp5000h70h71": {
        auxi = this.pdfp5000h70h71;
        break;
      }

      case "pdfp5000subextractor": {
        auxi = this.pdfp5000subextractor;
        break;
      }

      case "pdfp5000Arandela": {
        auxi = this.pdfp5000Arandela;
        break;
      }
      case "pdfP5000Mandrino": {
        auxi = this.pdfP5000Mandrino;
        break;
      }

      default: {
        break;
      }
    }
    if (auxi != "") {
      const win = window.open("about:blank", "_blank");
      let html = '';

      html += '<html>';
      html += '<body style="margin:0!important">';
      html += '<embed width="100%" height="100%" src="' + auxi + '" type="application/pdf" />';
      html += '</body>';
      html += '</html>';

      setTimeout(() => {
        win.document.write(html);
        win.document.title = "PDF";
      }, 0);
    }

  }

  onClickVisualizar() {
    this.router.navigate(['hojaImpresion/', this.id, 0]);
  }

  onClickProyectoLaminacion() {

  }

  onValueChangeD(e) {
    var cambio = Number(e.target.value.replace(',', '.'));
    if (cambio > this.geometriaValueG) {
      this.geometriaValueDVieja = this.geometriaValueD;
      this.geometriaValueD = this.geometriaValueG - 1;
      this.cambioDelSistema = true;
    } else {
      this.cambioDelSistema = false;
      this.geometriaValueD = cambio;
    }
  }

  onValueChangeG(e) {
    var cambio = Number(e.target.value.replace(',', '.'));
    if (this.geometriaValueD > cambio) {
      this.geometriaValueDVieja = this.geometriaValueD;
      this.geometriaValueD = cambio - 1;
      this.cambioDelSistema = true;
    } else {
      this.cambioDelSistema = false;
    }
    this.geometriaValueG = cambio;
  }

  imprimir() {
    var data = document.getElementById('fichaImprimir');  //Id of the table
    html2canvas(data, { scale: 2 }).then(canvas => {  // el {scale: 2} es para mejorar la calidad de la imagen del PDF
      // Few necessary setting options  
      let imgWidth = 297; //210 //porque estos son los tamaños de un a4     
      let imgHeight = canvas.height * imgWidth / canvas.width;

      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jsPDF('l', 'mm', 'a4'); // A4 size page of PDF  
      pdf.addImage(contentDataURL, 'PNG', 5, 5, imgWidth - 5, imgHeight - 5)
      pdf.save(this.form.value.codigoPlano + "_" + this.form.value.figura); // Generated PDF   
    });
  }

  //Funciones PDF Laminador
  archivoSeleccionadoPDFLaminador(e) {
    var th = this;
    th.mostrarPDFAux = false;
    setTimeout(async function () {
      var archivoBase64: any = "";
      if (th.proyectoPDF !== "" && th.proyectoPDF !== null)
        archivoBase64 = await th.toBase64(th.proyectoPDF[0]);
      else archivoBase64 = "";
      th.proyectoPDF = archivoBase64;

    }, 500);
  }

  archivoEliminadoPDFLaminador(e) {
    this.proyectoPDF_nombre = "";
    this.proyectoPDF = "";
    this.mostrarPDFAux = false;
  }

  toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  onClickVerPDF() {
    const win = window.open("about:blank", "_blank");
    let html = '';

    html += '<html>';
    html += '<body style="margin:0!important">';
    html += '<embed width="100%" height="100%" src="' + this.proyectoPDF + '" type="application/pdf" />';
    html += '</body>';
    html += '</html>';

    setTimeout(() => {
      win.document.write(html);
      win.document.title = "PDF_Laminador";
    }, 0);
  }
}