import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TipoUsuario, Usuario } from '@app/_models';
import { AlertService, MenuService, cebollasService, UsuariosTiposService } from '@app/_services';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-cebollas',
  templateUrl: './cebollas.component.html'
})
export class CebollasComponent implements OnInit {
  form: FormGroup;
  dataCebollas: any;
  mySelection: number[] = [];
  navigationSubscription;
  isDeleting = false;
  constructor(private cebollasService: cebollasService, 
    private alertService: AlertService, 
    private menuService: MenuService, 
    public router: Router, 
    private route: ActivatedRoute, 
    private formBuilder: FormBuilder,
    private translateService: TranslateService, private cdref: ChangeDetectorRef) {
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        if (this.router.url == '/cebollas') {
          this.menuService.titulo = this.translateService.instant('tablasMaestras.cebollas');
          this.cdref.detectChanges();
          this.cargarDatos();
        }
      }else{
        if (this.router.url == '/cebollas') {
          this.menuService.titulo = this.translateService.instant('tablasMaestras.cebollas');
          this.cdref.detectChanges();
        }
      }
    });
  }

  cargarDatos() {
    this.cebollasService.getAll()
      .pipe(first())
      .subscribe(cebollas =>
        this.dataCebollas = cebollas
      );
  }

  ngOnInit(): void {
    this.menuService.titulo = this.translateService.instant('tablasMaestras.cebollas');
  }
  cellClick(e) {
    if (e.columnIndex > 0) {
      this.router.navigate(['cebollas/editar/', e.dataItem.id]);
    }
  }
  onClickEditar() {
    if (this.mySelection[0] > 0) {
      this.router.navigate(['cebollas/editar/', this.mySelection[0]]);
    }
  }
  onClickNuevo() {
    this.router.navigate(['cebollas/crear']);
  }
  onClickEliminar() {
    this.mySelection.forEach(element => {
      this.cebollasService.delete(element)
        .pipe(first())
        .subscribe((result: any) => {
          if(result>0){
            this.dataCebollas = this.dataCebollas.filter(x => x.id !== element)
            this.isDeleting = false;
            this.alertService.success(this.translateService.instant('botones.eliminadocorrectamente'), { keepAfterRouteChange: true });
            this.atras();
          }else{
            this.alertService.error(this.translateService.instant('botones.erroreliminar'), { keepAfterRouteChange: true });
          }
            

          });
    });
  }

  atras() {
    this.router.navigate(['/cebollas']);
  }
}
