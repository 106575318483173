import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TextBoxComponent } from '@progress/kendo-angular-inputs';

@Component({
  selector: 'zitu-textbox',
  templateUrl: './zitu-textbox.component.html',
})
export class ZituTextboxComponent implements OnInit {

  @Input() label:string;
  @Input() placeholder:string;
  @Input() labelArriba:boolean=false;
  @Output() inputTextOut = new EventEmitter<string>();

  dni:string='';

  constructor() { }

  ngOnInit(): void {
  }



  valueChange(value, textBoxcontrol: TextBoxComponent) {
    this.inputTextOut.emit(value);
  }
}
