import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthGuard } from '@app/_helpers';
import { TipoUsuario } from '@app/_models';
import { environment } from '@environments/environment';

const baseUrl = `${environment.apiUrl}/manuales`;

@Injectable({
  providedIn: 'root'
})
export class ManualesService {

  constructor(private http: HttpClient) {
  }

  create(nombre, tipoDato, archivos) {
    var auxi = [];
    var auxi2 = [];
    archivos.forEach(element => {
      auxi.push(element.archivo);
      auxi2.push(element.nombre);
    }); 
    return this.http.post(baseUrl+"/create", {nombre: nombre, tipoDato: tipoDato, archivos: auxi, nombresArchivos: auxi2});
  }

  getAll() {
    return this.http.get(baseUrl);
  }

  update(nombre, id, archivos) {
    var auxi = [];
    var auxi2 = [];
    archivos.forEach(element => {
      auxi.push(element.archivo);
      auxi2.push(element.nombre);
    }); 
    return this.http.post(baseUrl + "/update", {nombre: nombre, id: id, archivos:auxi, nombresArchivos: auxi2});
  }

  delete(id) {
    return this.http.post(baseUrl + "/delete", {id: id});
  }

  getById(id: string) {
    return this.http.get(`${baseUrl}/getById/${id}`);
  }

}
