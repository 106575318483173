import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthGuard } from '@app/_helpers';
import { TipoUsuario } from '@app/_models';
import { environment } from '@environments/environment';

const baseUrl = `${environment.apiUrl}/BasePrepunzonadoInferior10000`;

@Injectable({
  providedIn: 'root'
})
export class basePrepunzonadoInferior10000Service {

  constructor(private http: HttpClient) {
  }

  create(nombre) {
    return this.http.post(baseUrl, {nombre: nombre});
  }

  update(nombre, id) {
    return this.http.post(baseUrl + "/update", {nombre: nombre, id: id});
  }

  getAll() {
    return this.http.get(baseUrl);
  }

  getById(id: string) {
    return this.http.get(`${baseUrl}/${id}`);
  }

  delete(id: number) {
    return this.http.post(baseUrl + "/delete", {id});
  }

}
