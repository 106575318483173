import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Idioma } from '@app/_models/idioma';
import { IdiomasService } from '@app/_services/idioma.service';
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';

@Component({
  selector: 'zitu-dropdown',
  templateUrl: './zitu-dropdown.component.html',
})
export class ZituDropdownComponent implements OnInit {
  //@Input() idOrg : string;
  @Input() autoSelect = true;
  @Output() selectedIdiomaOut = new EventEmitter<Idioma>();

  listaIdiomas: Idioma[] = [];
  selectedIdioma:Idioma;

  constructor(private idiomaService: IdiomasService) {
   }
  ngOnInit(): void {
    this.idiomaService.GetAll().subscribe((result) => {
      this.listaIdiomas = result.data;
      console.log('Idiomas cargados: '+this.listaIdiomas.length);
      if(this.listaIdiomas.length>0 && this.autoSelect){
        this.selectedIdioma=this.listaIdiomas[0];
        this.selectedIdiomaOut.emit( this.selectedIdioma);
      }
    });
  }
  idiomaChanged(value, dropDownControl: DropDownListComponent) {
    this.selectedIdiomaOut.emit(value);
  }
  

}