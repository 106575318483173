<div class="form-group">
  <!--Lista idiomas-->
  <div class="clearfix">
    <kendo-label text="{{ 'eventos.idiomas' | translate }}"> </kendo-label>
  </div>
  <kendo-dropdownlist
    #dropdownI
    [data]="listaIdiomas"
    textField="nombre"
    valueField="codigo"
    [(ngModel)]="selectedIdioma"
    (valueChange)="idiomaChanged($event, dropdownI)"
  ></kendo-dropdownlist>
</div>
