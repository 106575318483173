import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthGuard } from '@app/_helpers';
import { TipoUsuario } from '@app/_models';
import { environment } from '@environments/environment';

const baseUrl = `${environment.apiUrl}/Planos`;

@Injectable({
  providedIn: 'root'
})
export class PlanosService {

  constructor(private http: HttpClient) {
  }

  create(codigoPlano, figura, equivalenciaP10000, equivalenciaP5000, equivalenciaLaminador,
    cambioDelSistema,
    geometriaValueC, geometriaValueD, geometriaValueF, geometriaValueG, geometriaValueI, 
    geometriaValueJ, geometriaValueL, geometriaValueDVieja,
    h15aMaestroSelected, htaSupH20a, htaSupH22a, htaSupH51a1, htaSupH51a2,
    h30aMaestroSelected1,
    htaInfH39a, h30aMaestroSelected2, htaInfH40a, piecerioHerramientaPrepunzado1aOP1,
    piecerioHerramientaPrepunzado1aOP2, piecerioHerramientaPrepunzado1aOP3, piecerioHerramientaSubextractorSelected,
    piecerioHerramientaBasePrepunzonadoSelected, piecerioHerramientaPrepunzado2aOP1, piecerioHerramientaPrepunzado2aOP2,
    piecerioHerramientaPrepunzado2aOP3, piecerioHerramientaPrepunzado2aOP4, piecerioHerramientaArandela1,
    piecerioHerramientaArandela2, piecerioHerramientaArandela3, piecerioHerramientaArandelaResultado,
    piecerioHerramientaPrepunzadoSuperior1, piecerioHerramientaPrepunzadoSuperior2, 
    piecerioHerramientaPrepunzadoSuperior3, piecerioHerramientaPrepunzadoSuperior4, mandrino10000Selected,
    notas_p10000, cebollasSelected, porcenVelocidadCebollas1, porcenVelocidadCebollas2,
    poleaSelected, proyectoPDF, notas_general, h60h61Value, h70h71Value1, h70h71Value2, subextractor5000Selected,
    subextractor5000Selected2,
    arandelap50001, arandelap50002, arandelap50003, arandelap50004, mandrino5000Selected, notas_p5000, notas_laminador) {
    return this.http.post(baseUrl, 
      {codigoPlano: codigoPlano, figura: figura, equivalenciaP10000: equivalenciaP10000, equivalenciaP5000: equivalenciaP5000,
        equivalenciaLaminador: equivalenciaLaminador,
        cambioDelSistema: cambioDelSistema, D_value_vieja: geometriaValueDVieja,
        C_value: geometriaValueC, D_value: geometriaValueD, I_value: geometriaValueI, L_value: geometriaValueL,  
        J_value: geometriaValueJ, G_value: geometriaValueG, F_value: geometriaValueF, placah15AMaestro_id: h15aMaestroSelected,  
        h20a_value: htaSupH20a, h22a_value: htaSupH22a, h51a_value1: htaSupH51a1, h51a_value2: htaSupH51a2,
        placah30AMaestro1_id: h30aMaestroSelected1,  
        h39a_value: htaInfH39a, placah30AMaestro2_id: h30aMaestroSelected2, h40a_value: htaInfH40a,
        prepunzonadoInferior1OP_diametro: piecerioHerramientaPrepunzado1aOP1, 
        prepunzonadoInferior1OP_altura: piecerioHerramientaPrepunzado1aOP2,
        prepunzonadoInferior1OP_inferior: piecerioHerramientaPrepunzado1aOP3,
        subextractorP10000Maestro_id: piecerioHerramientaSubextractorSelected,
        basePrepunzonadoInferiorMaestro_id: piecerioHerramientaBasePrepunzonadoSelected,
        prepunzonadoSuperior_diametro: piecerioHerramientaPrepunzadoSuperior1,
        prepunzonadoSuperior_l: piecerioHerramientaPrepunzadoSuperior2,
        prepunzonadoSuperior_l1: piecerioHerramientaPrepunzadoSuperior3, 
        prepunzonadoSuperior_inferior: piecerioHerramientaPrepunzadoSuperior4,
        prepunzonadoInferior2OP_diametro: piecerioHerramientaPrepunzado2aOP1, 
        prepunzonadoInferior2OP_l: piecerioHerramientaPrepunzado2aOP2,
        prepunzonadoInferior2OP_l1: piecerioHerramientaPrepunzado2aOP3,
        prepunzonadoInferior2OP_inferior: piecerioHerramientaPrepunzado2aOP4,
        arandelap10000_diametro: piecerioHerramientaArandela1,
        arandelap10000_l: piecerioHerramientaArandela2,
        arandelap10000_l1: piecerioHerramientaArandela3,
        arandelap10000_inferior: piecerioHerramientaArandelaResultado, 
        mandrinoMaestro10000_id: mandrino10000Selected, notas_p10000: notas_p10000, h60H61: h60h61Value,
        h70H71_value1: h70h71Value1, h70H71_value2: h70h71Value2,
        subextractorMaestroP5000_id: subextractor5000Selected, subextractorP5000_datos: subextractor5000Selected2, 
        arandelap5000_diametro: arandelap50001, 
        arandelap5000_l: arandelap50002, arandelap5000_l1: arandelap50003, arandelap5000_inferior: arandelap50004,
        notas_p5000: notas_p5000, cebollasMaestro_id: cebollasSelected, porcenVelocidadCebollasMin: porcenVelocidadCebollas1,
        porcenVelocidadCebollasMax: porcenVelocidadCebollas2, poleaMaestro_id: poleaSelected, proyectoPDF: proyectoPDF,  
        notas_general: notas_general, mandrinoMaestro5000_id: mandrino5000Selected, notas_laminador:notas_laminador});
  }

  createEquivalenciaCopia(codigoPlano, figura, 
    idEquivalenciaP10000, 
    idEquivalenciaP5000,
    idEquivalenciaLaminador) {
    return this.http.post(baseUrl+'/createEquivalenciaCopia', {codigoPlano: codigoPlano, 
      figura: figura, idEquivalenciaP10000: idEquivalenciaP10000, 
      idEquivalenciaP5000: idEquivalenciaP5000,  
      idEquivalenciaLaminador: idEquivalenciaLaminador});
  }

  update(codigoPlano, figura, equivalenciaP10000, id, idEquivalenciaP10000, equivalenciaP5000,
    idEquivalenciaP5000, 
    equivalenciaLaminador, idEquivalenciaLaminador, cambioDelSistema,
    geometriaValueC, geometriaValueD, geometriaValueF, geometriaValueG, geometriaValueI, 
    geometriaValueJ, geometriaValueL, geometriaValueDVieja,
    h15aMaestroSelected, htaSupH20a, htaSupH22a, htaSupH51a1, htaSupH51a2,
    h30aMaestroSelected1,
    htaInfH39a, h30aMaestroSelected2, htaInfH40a, piecerioHerramientaPrepunzado1aOP1,
    piecerioHerramientaPrepunzado1aOP2, piecerioHerramientaPrepunzado1aOP3, piecerioHerramientaSubextractorSelected,
    piecerioHerramientaBasePrepunzonadoSelected, piecerioHerramientaPrepunzado2aOP1, piecerioHerramientaPrepunzado2aOP2,
    piecerioHerramientaPrepunzado2aOP3, piecerioHerramientaPrepunzado2aOP4, piecerioHerramientaArandela1,
    piecerioHerramientaArandela2, piecerioHerramientaArandela3, piecerioHerramientaArandelaResultado,
    piecerioHerramientaPrepunzadoSuperior1, piecerioHerramientaPrepunzadoSuperior2, 
    piecerioHerramientaPrepunzadoSuperior3, piecerioHerramientaPrepunzadoSuperior4, mandrino10000Selected,
    notas_p10000, cebollasSelected, porcenVelocidadCebollas1, porcenVelocidadCebollas2,
    poleaSelected, proyectoPDF, notas_general, h60h61Value, h70h71Value1, h70h71Value2, subextractor5000Selected,
    subextractor5000Selected2,
    arandelap50001, arandelap50002, arandelap50003, arandelap50004, mandrino5000Selected, notas_p5000, notas_laminador) {
    return this.http.post(baseUrl + "/update", {
      codigoPlano: codigoPlano, figura: figura, equivalenciaP10000: equivalenciaP10000, id: id, idEquivalenciaP10000: idEquivalenciaP10000,
      equivalenciaP5000: equivalenciaP5000, idEquivalenciaP5000: idEquivalenciaP5000, equivalenciaLaminador: equivalenciaLaminador,
      idEquivalenciaLaminador: idEquivalenciaLaminador, 
      cambioDelSistema: cambioDelSistema, D_value_vieja: geometriaValueDVieja, 
      C_value: geometriaValueC, D_value: geometriaValueD, I_value: geometriaValueI, L_value: geometriaValueL,  
      J_value: geometriaValueJ, G_value: geometriaValueG, F_value: geometriaValueF, placah15AMaestro_id: h15aMaestroSelected,  
      h20a_value: htaSupH20a, h22a_value: htaSupH22a, h51a_value1: htaSupH51a1, h51a_value2: htaSupH51a2,
      placah30AMaestro1_id: h30aMaestroSelected1,  
      h39a_value: htaInfH39a, placah30AMaestro2_id: h30aMaestroSelected2, h40a_value: htaInfH40a,
      prepunzonadoInferior1OP_diametro: piecerioHerramientaPrepunzado1aOP1, 
      prepunzonadoInferior1OP_altura: piecerioHerramientaPrepunzado1aOP2,
      prepunzonadoInferior1OP_inferior: piecerioHerramientaPrepunzado1aOP3,
      subextractorP10000Maestro_id: piecerioHerramientaSubextractorSelected,
      basePrepunzonadoInferiorMaestro_id: piecerioHerramientaBasePrepunzonadoSelected,
      prepunzonadoSuperior_diametro: piecerioHerramientaPrepunzadoSuperior1,
      prepunzonadoSuperior_l: piecerioHerramientaPrepunzadoSuperior2,
      prepunzonadoSuperior_l1: piecerioHerramientaPrepunzadoSuperior3, 
      prepunzonadoSuperior_inferior: piecerioHerramientaPrepunzadoSuperior4,
      prepunzonadoInferior2OP_diametro: piecerioHerramientaPrepunzado2aOP1, 
      prepunzonadoInferior2OP_l: piecerioHerramientaPrepunzado2aOP2,
      prepunzonadoInferior2OP_l1: piecerioHerramientaPrepunzado2aOP3,
      prepunzonadoInferior2OP_inferior: piecerioHerramientaPrepunzado2aOP4,
      arandelap10000_diametro: piecerioHerramientaArandela1,
      arandelap10000_l: piecerioHerramientaArandela2,
      arandelap10000_l1: piecerioHerramientaArandela3,
      arandelap10000_inferior: piecerioHerramientaArandelaResultado, 
      mandrinoMaestro10000_id: mandrino10000Selected, notas_p10000: notas_p10000, h60H61: h60h61Value,
      h70H71_value1: h70h71Value1, h70H71_value2: h70h71Value2,
      subextractorMaestroP5000_id: subextractor5000Selected, subextractorP5000_datos: subextractor5000Selected2, 
      arandelap5000_diametro: arandelap50001, 
      arandelap5000_l: arandelap50002, arandelap5000_l1: arandelap50003, arandelap5000_inferior: arandelap50004,
      notas_p5000: notas_p5000, cebollasMaestro_id: cebollasSelected, porcenVelocidadCebollasMin: porcenVelocidadCebollas1,
      porcenVelocidadCebollasMax: porcenVelocidadCebollas2, poleaMaestro_id: poleaSelected, proyectoPDF: proyectoPDF,  
      notas_general: notas_general, mandrinoMaestro5000_id: mandrino5000Selected, notas_laminador:notas_laminador  });
  }

  getAll() {
    return this.http.get(baseUrl);
  }

  getMaestrasGeometria() {
    return this.http.get(baseUrl+"/getMaestrasGeometria");
  }

  getAllFiltrados(listaCodigosPlanos, listaFiguras, listaIdsEquivalenciasP10000, 
    listaIdsEquivalenciasP5000, listaIdsEquivalenciasLaminador) {
    return this.http.post(baseUrl + "/getAllFiltrados", {listaCodigosPlanos, listaFiguras, listaIdsEquivalenciasP10000,
      listaIdsEquivalenciasP5000, listaIdsEquivalenciasLaminador
    });
  }

  getAllEquivalencias() {
    return this.http.get(baseUrl + "/getAllEquivalencias");
  }

  getAllMaestras() {
    return this.http.get(baseUrl + "/getAllMaestras");
  }

  getAllEquivalenciasP10000() {
    return this.http.get(baseUrl + "/getAllEquivalenciasP10000");
  }

  getAllEquivalenciasP5000() {
    return this.http.get(baseUrl + "/getAllEquivalenciasP5000");
  }

  getAllEquivalenciasLaminador() {
    return this.http.get(baseUrl + "/getAllEquivalenciasLaminador");
  }


  getAllFiltro() {
    return this.http.get(baseUrl + "/getAllFiltro");
  }

  getById(id) {
    return this.http.get(`${baseUrl}/${id}`);
  }

  GetByIdEquivalenciaP10000(id) {
    return this.http.get(`${baseUrl}/GetByIdEquivalenciaP10000/${id}`);
  }

  GetByIdEquivalenciaP5000(id) {
    return this.http.get(`${baseUrl}/GetByIdEquivalenciaP5000/${id}`);
  }

  GetByIdEquivalenciaLaminador(id) {
    return this.http.get(`${baseUrl}/GetByIdEquivalenciaLaminador/${id}`);
  }

  existeEquivalencia(equivalenciaNueva) {
    return this.http.get(`${baseUrl}/existeEquivalencia/${equivalenciaNueva}`);
  }

  existeCodigoPlanoFigura(codigoPlano, figura) {
    return this.http.post(baseUrl + "/existeCodigoPlanoFigura", {codigoPlano, figura});
  }

  existeEquivalenciaIgual(idEquivalenciaP10000, idEquivalenciaP5000, idEquivalenciaLaminador,  geometriaValueC, geometriaValueD, 
    geometriaValueF, geometriaValueG, geometriaValueI,
    geometriaValueJ, geometriaValueL,
    placah15AMaestro_id, h20a_value, 
    h22a_value, h51a_value1, h51a_value2,
    placah30AMaestro1_id, h39a_value, placah30AMaestro2_id, h40a_value, prepunzonadoInferior1OP_diametro,
    prepunzonadoInferior1OP_altura,
    subextractorP10000Maestro_id,
    basePrepunzonadoInferiorMaestro_id,
    prepunzonadoInferior2OP_diametro,
    prepunzonadoInferior2OP_l,
    prepunzonadoInferior2OP_l1,
    prepunzonadoSuperior_diametro,
    prepunzonadoSuperior_l,
    prepunzonadoSuperior_l1,
    arandelap10000_diametro,
    arandelap10000_l,
    arandelap10000_l1,
    mandrinoMaestro10000_id, h60H61, h70H71_value1, h70H71_value2,
    subextractorMaestroP5000_id, arandelap5000_diametro, arandelap5000_l, arandelap5000_l1,
    mandrinoMaestro5000_id,
    cebollasMaestro_id, porcenVelocidadCebollasMin,
    porcenVelocidadCebollasMax, poleaMaestro_id) {
    return this.http.post(baseUrl + "/existeEquivalenciaIgual", {idEquivalenciaP10000: idEquivalenciaP10000,
      idEquivalenciaP5000: idEquivalenciaP5000, idEquivalenciaLaminador: idEquivalenciaLaminador, 
      C_value: geometriaValueC, D_value: geometriaValueD, 
      F_value: geometriaValueF, G_value: geometriaValueG, I_value: geometriaValueI,
      J_value: geometriaValueJ, L_value: geometriaValueL,
      placah15AMaestro_id: placah15AMaestro_id, h20a_value: h20a_value, 
      h22a_value: h22a_value, h51a_value1: h51a_value1, h51a_value2: h51a_value2,
      placah30AMaestro1_id: placah30AMaestro1_id, h39a_value: h39a_value, placah30AMaestro2_id: placah30AMaestro2_id, h40a_value: h40a_value, 
      prepunzonadoInferior1OP_diametro: prepunzonadoInferior1OP_diametro,
      prepunzonadoInferior1OP_altura: prepunzonadoInferior1OP_altura,
      subextractorP10000Maestro_id: subextractorP10000Maestro_id,
      basePrepunzonadoInferiorMaestro_id: basePrepunzonadoInferiorMaestro_id,
      prepunzonadoSuperior_diametro: prepunzonadoSuperior_diametro,
      prepunzonadoSuperior_l: prepunzonadoSuperior_l,
      prepunzonadoSuperior_l1: prepunzonadoSuperior_l1,
      prepunzonadoInferior2OP_diametro: prepunzonadoInferior2OP_diametro,
      prepunzonadoInferior2OP_l: prepunzonadoInferior2OP_l,
      prepunzonadoInferior2OP_l1: prepunzonadoInferior2OP_l1,
      arandelap10000_diametro: arandelap10000_diametro,
      arandelap10000_l: arandelap10000_l,
      arandelap10000_l1: arandelap10000_l1,
      mandrinoMaestro10000_id: mandrinoMaestro10000_id, h60H61: h60H61, h70H71_value1: h70H71_value1, h70H71_value2: h70H71_value2,
      subextractorMaestroP5000_id: subextractorMaestroP5000_id, arandelap5000_diametro: arandelap5000_diametro, 
      arandelap5000_l: arandelap5000_l, arandelap5000_l1: arandelap5000_l1,
      mandrinoMaestro5000_id: mandrinoMaestro5000_id,
      cebollasMaestro_id: cebollasMaestro_id, porcenVelocidadCebollasMin: porcenVelocidadCebollasMin,
      porcenVelocidadCebollasMax: porcenVelocidadCebollasMax, poleaMaestro_id: poleaMaestro_id});
  }

  existeEquivalenciasEnOtroPlano(id, idEquivalenciaP10000, 
    idEquivalenciaP5000,  idEquivalenciaLaminador){
      return this.http.post(baseUrl + "/existeEquivalenciasEnOtroPlano", {idEquivalenciaP10000: idEquivalenciaP10000,
        idEquivalenciaP5000: idEquivalenciaP5000, idEquivalenciaLaminador: idEquivalenciaLaminador, id: id});
    }

  delete(id: number, idEquivalenciaP10000, idEquivalenciaP5000, idEquivalenciaLaminador) {
    return this.http.post(baseUrl + "/delete", {id, idEquivalenciaP10000, idEquivalenciaP5000, idEquivalenciaLaminador});
  }

  insertImagenesAuxiliares(HTASuperiorH15AMaestro,HTASuperiorH20a,
    HTASuperiorH22a,HTASuperiorH51a,
    HTAInferiorH30AMaestro1,HTAInferiorH39a,HTAInferiorH30AMaestro2, HTAInferiorH40a,
    PiecerioPrepunzonadoInferior1OP, PiecerioSubextractor,
    PiecerioBasePrepunzonadoInferior, PiecerioMandrino10000, PiecerioMandrino5000,
    PiecerioPrepunzonadoSuperior, PiecerioPrepunzonadoInferior2OP,
    P10000Arandela, Cebollas, Poleas, h60h61, h70h71,subextractorExtractor, P5000Arandela,
    pdfHTASuperiorH15AMaestro,pdfHTASuperiorH20a,
    pdfHTASuperiorH22a,pdfHTASuperiorH51a,
    pdfHTAInferiorH30AMaestro1,pdfHTAInferiorH39a,pdfHTAInferiorH30AMaestro2, pdfHTAInferiorH40a,
    pdfPiecerioPrepunzonadoInferior1OP, pdfPiecerioSubextractor,
    pdfPiecerioBasePrepunzonadoInferior, pdfPiecerioMandrino10000, pdfPiecerioMandrino5000,
    pdfPiecerioPrepunzonadoSuperior, pdfPiecerioPrepunzonadoInferior2OP,
    pdfP10000Arandela, pdfCebollas, pdfPoleas, pdfh60h61, pdfh70h71,pdfsubextractorExtractor, pdfP5000Arandela) {
    return this.http.post(baseUrl + "/insertImagenesAuxiliares", {HTASuperiorH15AMaestro,HTASuperiorH20a,
      HTASuperiorH22a,HTASuperiorH51a,
      HTAInferiorH30AMaestro1,HTAInferiorH39a,HTAInferiorH30AMaestro2, HTAInferiorH40a,
      PiecerioPrepunzonadoInferior1OP, PiecerioSubextractor,
      PiecerioBasePrepunzonadoInferior, PiecerioMandrino10000, PiecerioMandrino5000,
      PiecerioPrepunzonadoSuperior, PiecerioPrepunzonadoInferior2OP,
      P10000Arandela, Cebollas, Poleas, h60h61, h70h71,subextractorExtractor, P5000Arandela,
      pdfHTASuperiorH15AMaestro,pdfHTASuperiorH20a,
      pdfHTASuperiorH22a,pdfHTASuperiorH51a,
      pdfHTAInferiorH30AMaestro1,pdfHTAInferiorH39a,pdfHTAInferiorH30AMaestro2, pdfHTAInferiorH40a,
      pdfPiecerioPrepunzonadoInferior1OP, pdfPiecerioSubextractor,
      pdfPiecerioBasePrepunzonadoInferior, pdfPiecerioMandrino10000, pdfPiecerioMandrino5000,
      pdfPiecerioPrepunzonadoSuperior, pdfPiecerioPrepunzonadoInferior2OP,
      pdfP10000Arandela, pdfCebollas, pdfPoleas, pdfh60h61, pdfh70h71,pdfsubextractorExtractor, pdfP5000Arandela});
  }

  getImagenesAuxiliares() {
    return this.http.get(`${baseUrl}/getImagenesAuxiliares`);
  }

  filtrarPiezasMedidas(valueCombo, valor1, valor2, valor3, es5000) {
    return this.http.post(baseUrl + "/filtrarPiezasMedidas", {valueCombo, valor1, valor2, valor3, es5000});
  }


}
